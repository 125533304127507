import styled from 'styled-components';
import { font } from '../../../../assets/common';

export const FirstSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  div {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
  }

  input {
    width: 496px;
    height: 44px;
    padding: 12px 16px 12px 16px;
    gap: 0px;
    border-radius: 4px 0px 0px 0px;
    border: 1px 0px 0px 0px;
    opacity: 0px;
    border: 1px solid #d3dee8;
    border-radius: 4px;
  }
  .text {
    font-family: $inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 120%;
    text-align: center;
  }
  .confirm {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    width: auto;
    height: 30px;

    background: #00a19c;
    border-radius: 4px;
    cursor: pointer;
  }

  .disabled {
    background: #e7e7f0;
    cursor: no-drop;
  }
`;

export const Title = styled.div`
  font-family: ${font.inter};
  font-size: 20px;
  font-weight: 700;
  line-height: 24.2px;
  letter-spacing: 0.8333333134651184px;
`;

export const Item = styled.div`
  font-family: ${font.inter};
  margin-top: 20px;
  width: 1222px;
  padding: 17px 24px 17px 24px;
  gap: 16px;
  border-radius: 6px;
  border: 1px solid #dedede;
  opacity: 0px;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
  color: #3f3c4c;
  font-weight: 400;

  .check-box {
    width: 20px;
  }
  .advocator {
    width: 220px;
  }
  .name {
    width: 630px;
  }

  .weight-700 {
    font-weight: 700;
  }
  .weight-600 {
    font-weight: 700;
  }
`;

export const Footer = styled.div`
  margin-top: 20px;
  .weight-700 {
    font-weight: 700;
  }
  .ant-pagination-item-active {
    background: #00a19c;
    border-color: #00a19c;
    color: #fff;
  }

  .ant-pagination-item:hover {
    border-color: #00a19c;
  }
`;
