import { Radio, Space } from 'antd';
import { useFormik } from 'formik';
import { get, isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { BasicButton, BasicLoading, BasicRadioGroup, BasicSelect, BasicSwitch } from '../../../../../assets/common';
import { useGetRoleLevel } from '../../../../../hooks/useGetRoleLevel';
import { adminApi } from '../../../../../services/admin';
import { pushMessage } from '../../../../../store/alertMessageSlice';
import { ALERT_MESSAGE_TYPE, MANAGING_POSITION_CATEGORY, MESSAGE_TYPES } from '../../../../../utils/constants';
import { AiInput, AiLabel } from '../../../components/NewSuccessionPlanningForm/Styled';
import * as styles from './add-managing-position-category.module.scss';

const AddManagingPositionCategory = () => {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const roleActive = useSelector((state) => state.user.roleActive);
  const { data: roleLevelData } = useGetRoleLevel({ keyword: '', limit: 1000, page: 1 });

  const [roleLevel, setRoleLevel] = useState([]);
  const [dataExist, setDataExist] = useState({
    abbreviation: false,
    category: false,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!isEmpty(roleLevelData)) {
      setRoleLevel(
        roleLevelData.map((item) => ({
          label: item.role_level,
          value: item.id,
        }))
      );
    }
  }, [roleLevelData]);

  const formValidationSchema = yup.object().shape({
    category: yup.string().required(true),
    is_dashboard: yup.boolean(),
    placement: yup
      .number()
      .nullable()
      .when('is_dashboard', {
        is: true,
        then: (schema) => schema.required(true),
      }),
    abbreviation: yup.string().required(true).max(5, true),
    role_levels: yup.array().required(true).max(3, true).min(3, true),
  });

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      category: '',
      abbreviation: '',
      is_dashboard: false,
      role_levels: [],
      placement: null,
    },
    validationSchema: formValidationSchema,
    onSubmit: (values) => {
      const body = {
        category: values.category.trim(),
        abbreviation: values.abbreviation.trim(),
        is_dashboard: values.is_dashboard,
        role_levels: values.role_levels,
        placement: values.is_dashboard ? values.placement : null,
      };
      id ? handleClickSave(body) : handleClickAdd(body);
      formik.setSubmitting(false);
    },
  });

  const getDetail = useCallback(async () => {
    if (!id) return;
    try {
      setLoading(true);
      const res = await adminApi.getDetailPositionCategory(id, roleActive.roleId);
      if (res.status === 200) {
        const result = res.data.result;
        const role_levels = result.role_levels.map((item) => item.role_level_id);
        formik.setValues({ ...result, role_levels });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleSelectRoleLevel = (value) => {
    formik.setFieldValue('role_levels', value);
  };

  const handleShowMessage = useCallback(
    (type, message) => {
      dispatch(
        pushMessage({
          type,
          message,
          timeShow: 3000,
          isScroll: [MESSAGE_TYPES.ERROR, MESSAGE_TYPES.WARNING].includes(type),
        })
      );
    },
    [dispatch]
  );

  const handleClickBack = () => {
    history.push('/admin/master-data-management/managing-position-category');
  };

  const handleClickAdd = async (body) => {
    try {
      const res = await adminApi.addPositionCategory(body, roleActive.roleId);
      if (res.status === 200) {
        history.push('/admin/master-data-management/managing-position-category');
        handleShowMessage(ALERT_MESSAGE_TYPE.SUCCESS, MANAGING_POSITION_CATEGORY.ADD_MANAGING_POSITION_CATEGORY_SUCCESS);
      }
    } catch (error) {
      console.error(error);
      const errors = error.response.data.errors;
      handleShowMessage(ALERT_MESSAGE_TYPE.ERROR, errors);
    }
  };

  const handleClickSave = async (body) => {
    try {
      setLoading(true);
      const res = await adminApi.editPositionCategory(id, body, roleActive.roleId);
      if (res.status === 200) {
        history.push('/admin/master-data-management/managing-position-category');
        handleShowMessage(ALERT_MESSAGE_TYPE.SUCCESS, MANAGING_POSITION_CATEGORY.EDIT_SUCCESS);
      }
    } catch (error) {
      console.log(error);
      const err = get(error, 'response.data.errors');
      handleShowMessage(ALERT_MESSAGE_TYPE.ERROR, error.response.data.errors);
      if (err === MANAGING_POSITION_CATEGORY.CATEGORY_EXIST) {
        setDataExist({ category: true });
      }
      if (err === MANAGING_POSITION_CATEGORY.ABBREVIATIONS_EXIST) {
        setDataExist({ abbreviation: true });
      }
      if (err === MANAGING_POSITION_CATEGORY.CATEGORY_AND_ABBREVIATIONS_EXIST) {
        setDataExist({
          abbreviation: true,
          category: true,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!formik.isSubmitting) return;
    if (formik.errors.abbreviation || formik.errors.category || formik.errors.placement) {
      handleShowMessage(MESSAGE_TYPES.ERROR, MANAGING_POSITION_CATEGORY.REQUIRED_FIELDS);
    }
    if (formik.errors.role_levels) {
      handleShowMessage(MESSAGE_TYPES.ERROR, MANAGING_POSITION_CATEGORY.MUST_SELECTED_ROLE_LEVEL);
    }
  }, [
    formik.errors.abbreviation,
    formik.errors.category,
    formik.errors.placement,
    formik.errors.role_levels,
    formik.isSubmitting,
    handleShowMessage,
  ]);

  useEffect(() => {
    getDetail();
  }, [getDetail]);

  return (
    <BasicLoading spinning={loading}>
      <div className="max-width">
        <h3 className={styles.mainTitle}>
          {id ? 'Edit Position Category' : 'Add New Category'}
          <p className={styles.noteTitle}>Managing Position Category</p>
        </h3>
        <div className={styles.wrapper}>
          <h4 className={styles.title}>{id ? 'Details' : 'Add New Details'}</h4>
          <div className="row">
            <div className="col-3">
              <AiLabel>Category</AiLabel>
              <AiInput
                name="category"
                value={formik.values.category}
                placeholder="Enter Category"
                pattern="[a-zA-Z\s]"
                onChange={formik.handleChange}
                errors={(formik.errors.category && formik.touched.category) || dataExist?.category}
              />
            </div>
            <div className="col-2">
              <AiLabel>Abbreviations</AiLabel>
              <AiInput
                name="abbreviation"
                maxLength="5"
                value={formik.values.abbreviation}
                placeholder="Enter Abbreviations"
                onChange={formik.handleChange}
                errors={(formik.errors.abbreviation && formik.touched.abbreviation) || dataExist?.abbreviation}
              />
            </div>
            <div className="col-7">
              <AiLabel>Role Level</AiLabel>
              <BasicSelect
                data-testid="role-level"
                mode="multiple"
                showArrow
                options={roleLevel}
                getPopupContainer={(trigger) => trigger.parentElement}
                name="role_levels"
                placeholder="Enter Role Level"
                value={formik.values.role_levels}
                error={formik.values.role_levels.length !== 3 && formik.touched.role_levels}
                onChange={handleSelectRoleLevel}
                optionFilterProp="label"
              />
            </div>
          </div>
          <h5 className={styles.titleInDashboard}>In Dashboard</h5>
          <div className="row">
            <div className="col-3">
              <AiLabel>Show in Dashboard</AiLabel>
              <div className={styles.showInDashboard}>
                <BasicSwitch
                  data-testid="show-in-dash-board"
                  checked={formik.values.is_dashboard}
                  onChange={(checked) => formik.setFieldValue('is_dashboard', checked)}
                />
              </div>
            </div>
            <div className="col-2">
              <AiLabel>Position Category Placement</AiLabel>
              <BasicRadioGroup
                onChange={(event) => formik.setFieldValue('placement', event.target.value)}
                value={formik.values.placement}
                disabled={!formik.values.is_dashboard}
              >
                <Space direction="vertical">
                  <Radio value={1}>Left</Radio>
                  <Radio value={2}>Right</Radio>
                </Space>
              </BasicRadioGroup>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end mb-2">
          <BasicButton mode="cancel" onClick={() => handleClickBack()} style={{ marginRight: '10px' }}>
            Cancel
          </BasicButton>
          <BasicButton mode="teal" onClick={formik.handleSubmit} type="submit">
            {id ? 'Save' : 'Add'}
          </BasicButton>
        </div>
      </div>
    </BasicLoading>
  );
};

export default AddManagingPositionCategory;
