import React, { useMemo } from 'react';
import * as styles from './print-multiple.module.scss';
import { isEmpty } from 'lodash';
import { avatar } from '../../../../../assets/img';
import { PDC_PROPOSAL_TYPES } from '../../../../../utils/constants';
import PreReadLabel from '../PreReadLabel/PreReadLabel';

const PrintMultiple = ({ talents, positions, mainType, preRead }) => {
  const proposal = useMemo(() => {
    if (mainType === PDC_PROPOSAL_TYPES.MULTIPLE_POSITION) {
      return talents[0];
    }
    return positions[0];
  }, [talents, positions, mainType]);

  return (
    <div className={styles.proposalWrap}>
      <div className={styles.proposalPositionList}>
        {proposal && (
          <div className={styles.proposalItem}>
            <div className={`${styles.proposalItemContent}`} style={{ alignItems: 'center' }}>
              <div className={styles.infoLeft}>
                {![proposal.position_code, proposal.birth_name].includes('VACANT') && (
                  <div className={styles.avatar}>
                    <img src={proposal.image_url || avatar} alt="" />
                  </div>
                )}
                <div className={styles.name} style={{ minWidth: '400px', maxWidth: '535px' }}>
                  {![proposal.position_code, proposal.birth_name].includes('VACANT') ? (
                    <div className={styles.fieldNameLink}>
                      <div className={styles.fieldName}>
                        {proposal.birth_name || '-'} ({proposal.top_talent_status || '-'}, {proposal.age || '-'})
                      </div>
                    </div>
                  ) : (
                    <div className={styles.fieldNameDiff}>
                      <span>{proposal.birth_name || '-'}</span>
                    </div>
                  )}
                  <div className={styles.fieldDesc1}>
                    {proposal.position_name}, {proposal.business_unit}
                  </div>
                  <div className={styles.fieldDesc2}>
                    {proposal.division || '-'} | {proposal.opu_name || '-'}
                  </div>
                </div>
              </div>
              <div className={styles.fieldItem}>
                <span className={styles.greyLabel}>JG</span>
                <span className={styles.boldValue}>{proposal.job_grade || '-'}</span>
              </div>
              <div className={styles.fieldItem}>
                <span className={styles.greyLabel}>SG</span>
                <span className={styles.boldValue}>{proposal.sg || '-'}</span>
              </div>
              <div className={styles.fieldItem}>
                <span className={styles.greyLabel}>Retirement:</span>
                <span className={styles.boldValue}>{proposal.retirement_or_contract || '-'}</span>
              </div>
            </div>
          </div>
        )}
      </div>
      {mainType === PDC_PROPOSAL_TYPES.MULTIPLE_POSITION && (
        <div className={styles.proposalList}>
          {!isEmpty(positions) &&
            positions.map((p, index) => (
              <div key={`${p?.staff_id}.${index}`} className={styles.proposalItem}>
                <div className={styles.proposalItemHead}>
                  <h3>Position {index + 1}</h3>
                  {preRead && index === 0 && <PreReadLabel />}
                </div>
                <div className={`${styles.proposalItemContent}`}>
                  <div className={styles.contentItem}>
                    <div className={styles.fieldItem}>
                      <span className={styles.positionField}>Position</span>
                      <span className={styles.positionName}>{p?.position_name || '-'}</span>
                    </div>
                    <div className={styles.fieldItem}>
                      <span className={styles.positionCode}>PID: </span>
                      <span className={styles.positionCode}>{p?.position_code || '-'}</span>
                    </div>
                    <div className={styles.fieldItem}>
                      <span className={styles.positionCode}>Current Incumbent: </span>
                      <span className={styles.positionCode}>{p?.birth_name || '-'}</span>
                    </div>
                  </div>
                  <div className={styles.contentItem}>
                    <span className={styles.greyLabel} style={{ display: 'block', marginBottom: '10px', color: '#525252', fontSize: '16px' }}>
                      Job Purpose
                    </span>
                    <div
                      className={styles.purposeItem}
                      style={{
                        wordBreak: 'break-word',
                        pointerEvents: 'none',
                      }}
                    >
                      {!isEmpty(p?.job_purpose) ? (
                        p?.job_purpose.map((i, jobIndex) => {
                          return (
                            <span
                              style={{ display: 'block', marginBottom: '5px' }}
                              key={`${p?.position_code}_${jobIndex}`}
                              dangerouslySetInnerHTML={{ __html: i }}
                            ></span>
                          );
                        })
                      ) : (
                        <span
                          key={p?.position_code}
                          style={{
                            display: 'block',
                            marginBottom: '5px',
                          }}
                          dangerouslySetInnerHTML={{ __html: '' }}
                        ></span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default PrintMultiple;
