import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';

import { AiButton } from '../../../assets/common';
import {
  art_work,
  complete_meeting_image,
  confirm_update,
  delete_approved_sp,
  delete_document,
  delete_ms_from_agenda,
  editFromCancel,
  illustration_contribution,
  illustrationData,
  paperForward,
  person_and_box,
  picDeleteMeeting,
  run_matching,
  save_simulation,
  finalize_other_agenda,
  unfinalize_other_agenda,
} from '../../../assets/img';
import { AiDesc, AiModel, AiTitle } from './Styled';

const configure = {
  editFromCancel: {
    title: 'Are you sure you want to Edit the Meeting?',
    desc: ' Would you like to edit the meeting now?',
    btnCancel: 'Cancel',
    btnSubmit: 'Edit Meeting',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: editFromCancel,
  },
  paperForward: {
    title: 'Are you sure to bring this paper forward to the next discussion?',
    desc: ' The paper will be archived in the system and you can add it back in the next meeting by clicking “Add From Archive”.',
    btnCancel: 'Cancel',
    btnSubmit: 'Yes',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: paperForward,
  },
  deleteMeeting: {
    title: 'Are you sure you want to delete the Meeting?',
    desc: 'You are about to delete the Meeting.',
    btnCancel: 'Cancel',
    btnSubmit: 'Delete Meeting',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'remove',
    img: picDeleteMeeting,
  },
  deletePDCMeeting: {
    title: 'Are you sure you want to delete the Meeting?',
    desc: 'You about to delete the Meeting.',
    btnCancel: 'Cancel',
    btnSubmit: 'Delete Meeting',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'remove',
    img: picDeleteMeeting,
  },
  deleteTalentProfileNote: {
    title: 'Are you sure you want to delete this notes?',
    desc: 'You can’t undo this action.',
    btnCancel: 'Cancel',
    btnSubmit: 'Delete Notes',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'danger',
    img: delete_ms_from_agenda,
  },
  deleteAssessmentData: {
    title: 'Are you sure you want to delete this Assessment Data?',
    desc: 'You can’t undo this action.',
    btnCancel: 'Cancel',
    btnSubmit: 'Delete',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'danger',
    img: delete_ms_from_agenda,
  },
  deleteMobilityPlanData: {
    title: 'Are you sure you want to delete this Mobility Plan?',
    desc: 'You can’t undo this action.',
    btnCancel: 'Cancel',
    btnSubmit: 'Delete',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'danger',
    img: delete_ms_from_agenda,
  },
  deleteTalentReview: {
    title: 'Are you sure you want to delete this Talent Review?',
    desc: 'You can’t undo this action.',
    btnCancel: 'Cancel',
    btnSubmit: 'Delete',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'danger',
    img: delete_ms_from_agenda,
  },
  deleteOtherExperience: {
    title: 'Are you sure you want to delete the Experience?',
    desc: 'You are about to delete the experience. This process cannot be undone.',
    btnCancel: 'Cancel',
    btnSubmit: 'Delete',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'danger',
    img: delete_ms_from_agenda,
  },
  deleteDevelopmentPlanTalentProfile: {
    title: 'Are you sure you want to delete this Development Plan?',
    desc: 'You can’t undo this action.',
    btnCancel: 'Cancel',
    btnSubmit: 'Delete',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'danger',
    img: delete_ms_from_agenda,
  },
  withoutSaving: {
    title: 'You are about to leave this page without saving.',
    desc: 'Please ensure the changes are saved before leaving the page.',
    btnCancel: 'Discard Changes',
    btnSubmit: 'Save as Draft',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: picDeleteMeeting,
  },
  withoutSavingAgenda: {
    title: 'You are about to leave this page without saving.',
    btnCancel: 'Exit without Saving',
    btnSubmit: 'Save Changes',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: picDeleteMeeting,
  },
  pushAndSendInvite: {
    title: 'Great! Everything is ready.',
    desc: 'Would you like to publish and send invite for the meeting?',
    btnCancel: 'Cancel',
    btnSubmit: 'Publish and Send Invite',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: paperForward,
  },
  updateMeeting: {
    title: 'Are you sure you want to update the Meeting?',
    desc: 'Please ensure the changes are saved before leaving the page.',
    btnCancel: 'Discard Changes',
    btnSubmit: 'Update Meeting',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: paperForward,
  },
  resendNewInvite: {
    title: 'Would you like to resend new invite?',
    desc: 'Please ensure the meeting details and agenda is correct. Would you like to resend the new invite?',
    btnCancel: 'No',
    btnSubmit: 'Yes',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: paperForward,
  },
  deleteAgenda: {
    title: 'Are you sure you want to delete the Agenda?',
    desc: 'You are about to delete the Agenda. This process cannot be undone.',
    btnCancel: 'Cancel',
    btnSubmit: 'Delete Agenda',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'danger',
    img: paperForward,
  },
  cancelMeeting: {
    title: 'Are you sure you want to cancel the Meeting?',
    desc: 'You are about to cancel the meeting. You can republish or delete the meeting in the TC Meeting page later.',
    btnCancel: 'Cancel',
    btnSubmit: 'Cancel Meeting',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'danger',
    img: paperForward,
  },
  startMeeting: {
    title: 'Would you like to start the meeting now?',
    desc: 'Please ensure the meeting details and agenda are correct. Once the meeting start, you can no longer change the meeting details.',
    btnCancel: 'No',
    btnSubmit: 'Yes',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: paperForward,
  },
  editPaper: {
    title: "You're about to Edit the Agenda Paper.",
    desc: 'Would you like to edit now?',
    btnCancel: 'Cancel',
    btnSubmit: 'Edit Paper',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: illustrationData,
  },
  changeToPaperReadyStatus: {
    title: 'Great! Succession Planning detail has been filled up.',
    desc: 'Please ensure the Succession Planning detail is correct. Would you like to finalize it now?',
    btnCancel: 'Cancel',
    btnSubmit: 'Paper Ready',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: illustrationData,
  },
  changeToPaperReadyStatusOtherAgenda: {
    title: `Great! Other’s Agenda has been fill up.`,
    desc: `Please ensure the Other’s Agenda detail is correct. Would you like to finalize it now?`,
    btnCancel: 'Cancel',
    btnSubmit: 'Paper Ready',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: illustrationData,
  },
  editPaperOtherAgenda: {
    title: `You’re about to Edit the Agenda Paper.`,
    desc: `Would you like to edit it now?`,
    btnCancel: 'Cancel',
    btnSubmit: 'Edit Paper',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: illustrationData,
  },
  changeToPaperReadyMobility: {
    title: 'Great! Mobility Simulation details has been fill up.',
    desc: 'Please ensure the Mobility Simulation detail is correct. Would you like to finalize it now?',
    btnCancel: 'Cancel',
    btnSubmit: 'Paper Ready',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: illustrationData,
  },

  changeToPaperReadyStatusMSAgenda: {
    title: 'Great! Mobility Discussion Details has been fill up.',
    desc: 'Please ensure the Mobility Discussion detail is correct. Would you like to finalize it now?',
    btnCancel: 'Cancel',
    btnSubmit: 'Paper Ready',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: illustrationData,
  },

  confirmDeleteRemark: {
    title: 'You’re about to delete the Remarks.',
    desc: 'Are you sure you want to delete the remarks?',
    btnCancel: 'Cancel',
    btnSubmit: 'Delete Remarks',
    borderCancel: false,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'danger',
    img: person_and_box,
  },
  finalizeSPAgenda: {
    title: 'Great! You’re about to finalize the Agenda.',
    desc: 'Please ensure the Succession Planning detail is correct. Would you like to finalize it now?',
    btnCancel: 'Cancel',
    btnSubmit: 'Finalize',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: person_and_box,
  },
  unFinalizeSPAgenda: {
    title: 'You’re about to Unfinalized the Succession Planning Agenda.',
    desc: 'Would you like to unfinalized it now?',
    btnCancel: 'Cancel',
    btnSubmit: 'Unfinalized',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: person_and_box,
  },
  finalizeMSAgenda: {
    title: 'Great! You’re about to finalize the Agenda.',
    desc: 'Please ensure the Mobility Simulation detail is correct. Would you like to finalize it now?',
    btnCancel: 'Cancel',
    btnSubmit: 'Finalize',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: person_and_box,
  },
  unFinalizeMSAgenda: {
    title: 'You’re about to Unfinalized the Mobility Simulation Agenda.',
    desc: 'Please note that meeting invitees will not be notified for any changes made to the agenda when it is finalize again later. Would you like to unfinalized it now?',
    btnCancel: 'Cancel',
    btnSubmit: 'Unfinalized',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: person_and_box,
  },
  deleteMoM: {
    title: 'Are you sure you want to delete the MoM?',
    desc: 'You are about to delete MoM. This process cannot be undone.',
    borderCancel: true,
    borderSubmit: false,
    btnCancel: 'Cancel',
    btnSubmit: 'Delete',
    modeCancel: '',
    modeSubmit: 'danger',
    img: delete_document,
  },
  deleteFileOthersAgenda: {
    title: 'Are you sure you want to delete the file?',
    desc: 'You are about to delete file. This process cannot be undone.',
    borderCancel: true,
    borderSubmit: false,
    btnCancel: 'Cancel',
    btnSubmit: 'Delete',
    modeCancel: '',
    modeSubmit: 'danger',
    img: delete_document,
  },
  completeMeeting: {
    title: 'Great! You’re about to end the Meeting!',
    desc: 'Would you like to complete the Meeting now?',
    btnCancel: 'Cancel',
    btnSubmit: 'Complete Meeting',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: complete_meeting_image,
  },
  saveTheMeeting: {
    title: 'Great! You’re about to save the meeting.',
    desc: 'Would you like to save the meeting?',
    btnCancel: 'Cancel',
    btnSubmit: 'Save',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: paperForward,
  },
  concludeMeeting: {
    title: 'You’re about to conclude the Meeting.',
    desc: 'Are you sure to conclude this meeting? Please note that changes cannot be made once meeting has been concluded.',
    btnCancel: 'Cancel',
    btnSubmit: 'Conclude Meeting',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: illustration_contribution,
  },
  notifyFacilitator: {
    title: 'Great! Everything is ready to notify facilitator.',
    desc: 'Please ensure that the Meeting Details is correct before notify facilitator.',
    btnCancel: 'Cancel',
    btnSubmit: 'Notify Facilitator',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: complete_meeting_image,
  },
  endConfirmConcludeMeeting: {
    title: 'Great! You have concluded the Meeting!',
    desc: 'Meeting has been concluded and the finalized MOM can be viewed in the completed meeting list page in TC Meeting. Thank you!',
    btnCancel: 'Back to Home',
    borderCancel: true,
    modeCancel: '',
    img: illustration_contribution,
  },
  confirmDeleteMobilitySimulation: {
    title: 'You’re about to delete the Mobility Simulation.',
    desc: 'Are you sure you want to delete the mobility simulation? It will be removed from any Mobility Discussion Agenda that includes it.',
    btnCancel: 'Cancel',
    btnSubmit: 'Delete Mobility Simulation',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'danger',
    img: delete_document,
  },
  confirmDeletePosition: {
    title: 'You’re about to delete the Position.',
    desc: 'Are you sure you want to delete the position?',
    btnCancel: 'Cancel',
    btnSubmit: 'Delete',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'danger',
    img: delete_document,
  },
  confirmDeleteTalent: {
    title: 'You’re about to delete the Talent.',
    desc: 'Are you sure you want to delete the talent?',
    btnCancel: 'Cancel',
    btnSubmit: 'Delete',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'danger',
    img: delete_document,
  },
  saveSimulation: {
    title: 'Great! Mobility Simulation has been filled up.',
    desc: 'Would you like to save it now?',
    btnCancel: 'Cancel',
    btnSubmit: 'Save Simulation',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: save_simulation,
  },
  duplicateSimulation: {
    title: 'Great! Mobility Simulation  is ready to be added to the list.',
    desc: 'Would you like to save it now?',
    btnCancel: 'Cancel',
    btnSubmit: 'Save Simulation',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: save_simulation,
  },
  deleteSimulationFromAgenda: {
    title: 'You’re about to delete the Mobility Discussion.',
    desc: 'Are you sure you want to delete the mobility discussion?',
    btnCancel: 'Cancel',
    btnSubmit: 'Delete',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'danger',
    img: delete_ms_from_agenda,
  },
  deleteRemarks: {
    title: 'Are you sure you want to delete this remark?',
    desc: 'You cant undo this action.',
    btnCancel: 'No',
    btnSubmit: 'Yes',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'danger',
    img: delete_ms_from_agenda,
  },
  deleteApprovedSuccessionPlanning: {
    title: 'Are you sure you want to delete the Approved Succession plan record?',
    desc: 'You are about to delete the approved succession plan record. This process cannot be undone.',
    btnCancel: 'Cancel',
    btnSubmit: 'Delete',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'danger',
    img: delete_approved_sp,
  },
  deleteBusinessUnit: {
    title: 'Are you sure you want to delete the business unit?',
    desc: 'You are about to delete the business unit. This process cannot be undone.',
    btnCancel: 'Cancel',
    btnSubmit: 'Delete',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'danger',
    img: delete_approved_sp,
  },
  deleteKeyPositionFromListOfCriticalPosition: {
    title: 'Are you sure you want to delete the criteria?',
    desc: 'You are about to delete the criteria. This process cannot be undone.',
    btnCancel: 'Cancel',
    btnSubmit: 'Delete',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'danger',
    img: delete_document,
  },
  deleteMatchingCriteria: {
    title: 'Are you sure you want to delete the position?',
    desc: 'You are about to delete the approved succession plan record. This process cannot be undone.',
    btnCancel: 'Cancel',
    btnSubmit: 'Delete',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'danger',
    img: delete_approved_sp,
  },
  saveMatchingCriteria: {
    title: 'Great! Details has been fill up.',
    desc: 'Please ensure the Criteria detail is correct. Would you like to save it now?',
    btnCancel: 'Cancel',
    btnSubmit: 'Save',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: save_simulation,
  },
  confirmRunMatchingCriteria: {
    title: 'Criteria has been saved successfully!',
    desc: 'You can run this matching criteria set now!',
    btnCancel: 'Cancel',
    btnSubmit: 'Run',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: run_matching,
  },
  editMatchingCriteria: {
    title: 'Great! Details has been edited.',
    desc: 'Would you like to save it now?',
    btnCancel: 'Cancel',
    btnSubmit: 'Save',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: save_simulation,
  },
  editSetMatchingCriteria: {
    btnCancel: 'Cancel',
    btnSubmit: 'Save Changes',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: save_simulation,
  },
  processRunSetMatchingCriteria: {
    btnSubmit: 'Okay',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: save_simulation,
  },
  addNewApprovedSuccessionPlanning: {
    title: 'Great! Details has been fill up.',
    desc: 'Would you like to add it now?',
    btnCancel: 'Cancel',
    btnSubmit: 'Add',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: save_simulation,
  },
  updateApprovedSuccessionPlanning: {
    title: 'Great! Details has been edited.',
    desc: 'Would you like to save it now?',
    btnCancel: 'Cancel',
    btnSubmit: 'Save',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: save_simulation,
  },
  confirmRemoveSuccessor: {
    title: 'Are you sure you want to delete the Successor?',
    desc: 'You are about to delete successor. This process cannot be undone.',
    btnCancel: 'Cancel',
    btnSubmit: 'Delete',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'danger',
    img: save_simulation,
  },
  deleteManagingCriteriaCP: {
    title: 'Are you sure you want to delete the Matching Criteria?',
    desc: 'You are about to delete the position. This process cannot be undone.',
    btnCancel: 'Cancel',
    btnSubmit: 'Delete',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'danger',
    img: delete_approved_sp,
  },
  deleteMultiManagingCriteriaCP: {
    title: 'Are you sure you want to delete the Matching Criteria?',
    desc: 'You are about to delete the position. This process cannot be undone.',
    btnCancel: 'Cancel',
    btnSubmit: 'Delete',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'danger',
    img: delete_approved_sp,
  },
  deleteDevelopmentPlan: {
    title: 'You’re about to delete the Development Plan.',
    desc: 'Are you sure you want to delete the Development Plan?',
    btnCancel: 'Cancel',
    btnSubmit: 'Delete',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'danger',
    img: delete_approved_sp,
  },
  deleteTalentInTalentAgenda: {
    title: 'You’re about to delete the Talent.',
    desc: 'Are you sure you want to delete the talent?',
    btnCancel: 'Cancel',
    btnSubmit: 'Delete',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'danger',
    img: delete_ms_from_agenda,
  },
  paperReadyTalentAgenda: {
    title: 'Great! Talent Review details has been fill up.',
    desc: 'Please ensure the Talent Review detail is correct. Would you like to finalize it now?',
    btnCancel: 'Cancel',
    btnSubmit: 'Paper Ready',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: illustrationData,
  },
  editPaperTalentAgenda: {
    title: 'You’re about to Edit the Agenda Paper.',
    desc: 'Would you like to edit it now?',
    btnCancel: 'Cancel',
    btnSubmit: 'Edit Paper',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: illustrationData,
  },
  finalizeTalentAgenda: {
    title: 'Great! You’re about to finalize the Agenda.',
    desc: 'Please ensure the Talent Review detail is correct. Would you like to finalize it now?',
    btnCancel: 'Cancel',
    btnSubmit: 'Finalize',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: save_simulation,
  },
  unFinalizeTalentAgenda: {
    title: 'You’re about to Unfinalized the Succession Planning Agenda.',
    desc: 'Please note that meeting invitees will not be notified for any changes made  to the agenda when it is finalize again later. Would you like to unfinalized it now?',
    btnCancel: 'Cancel',
    btnSubmit: 'Unfinalized',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: save_simulation,
  },
  deleteSGJGMapping: {
    title: 'Are you sure you want to delete the Mapping?',
    desc: 'You are about to delete the Mapping. This process cannot be undone.',
    btnCancel: 'Cancel',
    btnSubmit: 'Delete',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'danger',
    img: delete_approved_sp,
  },
  deleteRoleLevel: {
    title: 'Are you sure you want to delete the role level?',
    desc: 'You are about to delete the role level. This process cannot be undone.',
    btnCancel: 'Cancel',
    btnSubmit: 'Delete',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'danger',
    img: delete_approved_sp,
  },
  deleteBuMapping: {
    title: 'Are you sure you want to delete the business unit mapping?',
    desc: 'You are about to delete the business unit mapping. This process cannot be undone.',
    btnCancel: 'Cancel',
    btnSubmit: 'Delete',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'danger',
    img: delete_approved_sp,
  },
  deleteFunctionList: {
    title: 'Are you sure you want to delete the function?',
    desc: 'You are about to delete the function. This process cannot be undone.',
    btnCancel: 'Cancel',
    btnSubmit: 'Delete',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'danger',
    img: delete_approved_sp,
  },

  deleteHistorical: {
    title: 'Are you sure you want to delete this Historical Talent Status?',
    desc: `You can't undo this action.`,
    btnCancel: 'Cancel',
    btnSubmit: 'Delete',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'danger',
    img: delete_approved_sp,
  },
  deleteTalentProfile: {
    title: 'You’re about to delete the talent from the list.',
    desc: 'You are about to delete the talent from the list. This process cannot be undone.',
    btnCancel: 'Cancel',
    btnSubmit: 'Delete',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'danger',
    img: delete_approved_sp,
  },
  deleteTopTalentStatus: {
    title: 'Are you sure you want to delete the top talent status?',
    desc: 'You are about to delete the top talent status. This process cannot be undone.',
    btnCancel: 'Cancel',
    btnSubmit: 'Delete',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'danger',
    img: delete_approved_sp,
  },
  deleteUnsearchablePosition: {
    title: 'You’re about to delete the position from the list.',
    desc: 'You are about to delete the position from the list. This process cannot be undone.',
    btnCancel: 'Cancel',
    btnSubmit: 'Delete',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'danger',
    img: delete_approved_sp,
  },
  deleteReadinessRules: {
    title: 'Are you sure you want to delete the rules?',
    desc: 'You are about to delete the rules. This process cannot be undone.',
    btnCancel: 'Cancel',
    btnSubmit: 'Delete',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'danger',
    img: delete_approved_sp,
  },
  deleteManagingCriticalPosition: {
    title: 'Are you sure you want to delete the position?',
    desc: 'You are about to delete the position. This process cannot be undone.',
    btnCancel: 'Cancel',
    btnSubmit: 'Delete',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'danger',
    img: delete_approved_sp,
  },
  deleteExternalTalent: {
    title: 'Are you sure you want to delete the external talent?',
    desc: 'You are about to delete the external talent. This process cannot be undone.',
    btnCancel: 'Cancel',
    btnSubmit: 'Delete',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'danger',
    img: delete_approved_sp,
  },
  deleteTopTalentCategory: {
    title: 'Are you sure you want to delete the category?',
    desc: 'You are about to delete the category. This process cannot be undone.',
    btnCancel: 'Cancel',
    btnSubmit: 'Delete',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'danger',
    img: delete_approved_sp,
  },
  deleteSetMatchingCriteria: {
    desc: `You can't undo this action`,
    btnCancel: 'Cancel',
    btnSubmit: 'Delete',
    borderCancel: true,
    borderSubmit: true,
    modeCancel: '',
    modeSubmit: 'danger',
    img: delete_approved_sp,
  },
  deleteManagingCategory: {
    title: 'Are you sure you want to delete the category?',
    desc: 'You are about to delete the category. This process cannot be undone.',
    btnCancel: 'Cancel',
    btnSubmit: 'Delete',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'danger',
    img: delete_approved_sp,
  },
  deleteReportBuilder: {
    title: 'Are you sure you want to delete report from Report Builder?',
    desc: 'You can’t undo this action.',
    btnCancel: 'Cancel',
    btnSubmit: 'Delete',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'danger',
    img: delete_approved_sp,
  },
  deleteTalentReportBuilder: {
    title: 'Are you sure you want to delete talent from the report?',
    desc: 'You can’t undo this action.',
    btnCancel: 'Cancel',
    btnSubmit: 'Delete',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'danger',
    img: delete_approved_sp,
  },
  deleteColumnTalentReportBuilder: {
    title: 'Are you sure you want to delete column from the report?',
    desc: 'You can’t undo this action.',
    btnCancel: 'Cancel',
    btnSubmit: 'Delete',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'danger',
    img: delete_approved_sp,
  },
  cancelUpdateReportBuilder: {
    title: 'You are about to leave this page without saving.',
    desc: 'Please ensure the changes is saved before leaving the page.',
    btnCancel: 'Exit',
    btnSubmit: 'Save Changes',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: confirm_update,
  },
  cancelModalAspirationMatching: {
    title: 'You are about to leave this page without saving.',
    desc: 'Please ensure the changes is saved before leaving the page.',
    btnCancel: 'Exit',
    btnSubmit: 'Save Changes',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: confirm_update,
  },
  confirmAddNewTalent: {
    title: `The changes you made haven't been saved yet.`,
    desc: 'Before proceeding to "Add Talent," please save the changes.',
    btnCancel: 'Cancel',
    btnSubmit: 'Save Changes',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: confirm_update,
  },
  deleteRecordComplementaryExperience: {
    title: `Are you sure you want to delete this job function?`,
    desc: 'You can’t undo this action .',
    btnCancel: 'No',
    btnSubmit: 'Yes',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: 'geyser',
    modeSubmit: 'remove',
    img: delete_approved_sp,
  },
  otherAgendaFinalize: {
    title: `Great! You’re about to finalize the Agenda.`,
    desc: 'Please ensure the Others details are correct. Would you like to finalize it now?',
    btnCancel: 'Cancel',
    btnSubmit: 'Finalize',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: finalize_other_agenda,
  },
  otherAgendaUnFinalize: {
    title: `You’re about to Unfinalized the Other’s Agenda.`,
    desc: 'Please note that meeting invitees will not be notified for any changes made  to the agenda when it is finalize again later. Would you like to unfinalized it now?',
    btnCancel: 'Cancel',
    btnSubmit: 'Unfinalized',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: unfinalize_other_agenda,
  },
  leaveWhileAddAgenda: {
    title: `You are about to leave this page without saving.`,
    desc: 'Please ensure the agenda is saved before leaving the page.',
    btnCancel: 'Exit without Saving',
    btnSubmit: 'Save as Draft',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: 'exit',
    modeSubmit: 'teal',
    img: art_work,
  },
  withoutSavingAspirationMatching: {
    title: 'You are about to leave this page without saving.',
    btnCancel: 'Exit without Saving',
    btnSubmit: 'Save Changes',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: picDeleteMeeting,
  },
  withoutSavingNewsAndAnnouncement: {
    title: 'You are about to leave this page without saving.',
    desc: 'Please ensure the News and Announcement is saved before leaving the page.',
    btnCancel: 'Exit without Saving',
    btnSubmit: 'Save as Draft',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: picDeleteMeeting,
  },
  deleteTalentVideo: {
    title: 'Are you sure you want to delete the talent video?',
    desc: `You can't undo this action.`,
    btnCancel: 'No',
    btnSubmit: 'Yes',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'danger',
    img: delete_approved_sp,
  },
  deleteNewsAnnouncement: {
    title: 'You’re about to delete announcement from the list.',
    desc: `You are about to delete the announcement from the list. This process cannot be undone.`,
    btnCancel: 'Cancel',
    btnSubmit: 'Delete',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: 'geyser',
    modeSubmit: 'remove',
    img: delete_approved_sp,
  },
  archivePdcMobility: {
    title: 'Are you sure you want to archive this proposal?',
    desc: 'The paper will be archived in the system and you can add it back in the next meeting by clicking “Add Existing Mobility”.',
    btnCancel: 'Cancel',
    btnSubmit: 'Yes',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: paperForward,
  },
  deletePdcMobility: {
    title: 'Are you sure you want to delete the Mobility?',
    desc: 'You about to delete the Mobility.',
    btnCancel: 'Cancel',
    btnSubmit: 'Delete Mobility',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'remove',
    img: picDeleteMeeting,
  },
  deletePdcMobilityProposal: {
    title: 'You’re about to delete the Mobility Proposal.',
    desc: 'Are you sure you want to delete the mobility proposal?',
    btnCancel: 'Cancel',
    btnSubmit: 'Delete Mobility Proposal',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'remove',
    img: picDeleteMeeting,
  },
  changeToPaperReadyMobilityStatus: {
    title: 'Great! Mobility Agenda details has been fill up.',
    desc: 'Please ensure the Mobility Agenda detail is correct. Would you like to finalize it now?',
    btnCancel: 'Cancel',
    btnSubmit: 'Paper Ready',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: illustrationData,
  },
  changeToPaperInProgressMobilityStatus: {
    title: 'You’re about to Edit the Mobility Agenda.',
    desc: 'Would you like to edit it now?',
    btnCancel: 'Cancel',
    btnSubmit: 'Edit Paper',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: illustrationData,
  },
  pdcUpdateMeeting: {
    title: 'Are you sure you want to update the PDC Meeting?',
    desc: 'Please ensure the changes is saved before leaving the page',
    btnCancel: 'Discard Changes',
    btnSubmit: 'Update Meeting',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: paperForward,
  },
  pdcStartMeeting: {
    title: 'Would you like to start the meeting now?',
    desc: 'Please ensure the meeting details and agenda is correct. Once the meeting start, you can no longer change the meeting details.',
    btnCancel: 'No',
    btnSubmit: 'Yes',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: paperForward,
  },
  pdcCancelMeeting: {
    title: 'Are you sure you want to cancel the Meeting?',
    desc: 'You are about to cancel the meeting. You can republish or delete the meeting in the PDC meeting page later.',
    btnCancel: 'Cancel',
    btnSubmit: 'Cancel Meeting',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'remove',
    img: paperForward,
  },
  paperFinalizePDCMeeting: {
    title: 'Great! You’re about to finalize the Mobility Agenda.',
    desc: 'Please ensure the details are correct. Would you like to finalize it now?',
    btnCancel: 'Cancel',
    btnSubmit: 'Finalize',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: illustrationData,
  },
  paperUnFinalizePDCMeeting: {
    title: 'You’re about to unfinalize the Mobility Agenda.',
    desc: 'Please note that meeting invitees will not be notified for any changes made to the agenda when it is finalize again later. Would you like to unfinalize it now?',
    btnCancel: 'Cancel',
    btnSubmit: 'Unfinalized',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: illustrationData,
  },
  concludePDCMeeting: {
    title: 'You’re about to conclude the PDC Meeting.',
    desc: 'Are you sure to conclude this PDC meeting? Please note that changes cannot be made once meeting has been concluded.',
    btnCancel: 'Cancel',
    btnSubmit: 'Conclude Meeting',
    borderCancel: true,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: 'teal',
    img: illustration_contribution,
  },
};

const ModelTC = (dataModal, { ...rest }) => {
  let { info } = dataModal;
  const [itemIncumbent, setItemIncumbent] = useState({
    title: '',
    desc: '',
    btnCancel: '',
    btnSubmit: '',
    borderCancel: false,
    borderSubmit: false,
    modeCancel: '',
    modeSubmit: '',
    img: '',
  });
  useEffect(() => {
    if (info.title && info.desc) {
      setItemIncumbent({ ...configure[info.type], title: info.title, desc: info.desc });
      return;
    }
    if (info.modeSubmit) {
      setItemIncumbent({ ...configure[info.type], modeSubmit: info.modeSubmit });
      return;
    }
    setItemIncumbent(configure[info.type]);
  }, [info.type, info.title, info.desc]);

  const handleClose = () => {
    info.setVisible(false);
    info.onClose && info.onClose();
  };

  return (
    <AiModel
      {...rest}
      centered
      open={info.visible}
      width={1283}
      height={670}
      footer={null}
      onCancel={() => {
        info.setVisible(false);
      }}
      zIndex={2000}
    >
      <Row align="middle">
        <Col span={12}>
          <AiTitle>{itemIncumbent.title}</AiTitle>
          <AiDesc>{itemIncumbent?.desc}</AiDesc>
          {itemIncumbent?.btnCancel && (
            <AiButton
              border={`${itemIncumbent?.borderCancel}`}
              mode={itemIncumbent.modeCancel}
              onClick={handleClose}
              disabled={info.loading}
              data-testid="btn-cancel-modelTC"
            >
              {itemIncumbent.btnCancel}
            </AiButton>
          )}
          <AiButton
            border={`${itemIncumbent?.borderSubmit}`}
            mode={itemIncumbent.modeSubmit}
            className="ml-2"
            onClick={() => info.handleSubmit()}
            disabled={info.loading || info.disableSubmit}
            loading={info.loading}
            data-testid="btn-submit-modelTC"
          >
            {itemIncumbent.btnSubmit}
          </AiButton>
        </Col>
        <Col span={1}></Col>
        <Col span={8}>
          <img src={itemIncumbent.img} alt="img" />
        </Col>
      </Row>
    </AiModel>
  );
};
export default ModelTC;
