export const PDC_MOBILITY_STATUS = {
  PAPER_IN_PROGRESS: 'Paper in Progress',
  PAPER_READY: 'Paper Ready',
  FINALIZED: 'Paper Finalized',
};

export const PDC_MOBILITY_PROPOSAL_TYPE = {
  SINGLE: 'single',
  MULTIPLE: 'multiple',
};

export const PDC_MOBILITY_CARD_TYPE = {
  POSITION: 'position',
  TALENT: 'talent',
};

export const INIT_PDC_MOBILITY_PROPOSAL = {
  name: '',
  type: null,
  is_main: false,
  cards: [],
};

export const PDC_MOBILITY_TYPE_OPTIONS = [
  {
    label: 'Single Mobility',
    value: PDC_MOBILITY_PROPOSAL_TYPE.SINGLE,
  },
  {
    label: 'Multiple Mobility',
    value: PDC_MOBILITY_PROPOSAL_TYPE.MULTIPLE,
  },
];

export const PDC_SAVE_FOR = {
  DELETE: 'delete',
  SAVE_MOBILITY: 'save_mobility',
  NEXT: 'next',
  EXIT: 'exit',
};

export const PDC_LEADERSHIP_EFFECTIVE_PROFICIENCY_COLOR = {
  CURRENT: '#FFEFD0',
  NEXT: '#D5DFFF',
};

export const PDC_LEADERSHIP_DOT_COLOR = {
  sma: {
    color: '#5D7083',
    text: 'SMA',
  },
  external: {
    color: '#FFBD7C',
    text: 'External Assessment',
  },
};

export const PDC_EDGE_OPTIONS = ['All', 'SMA', 'External'];

export const PDC_LEADERSHIP_ROW_NAME = [
  {
    name: 'ENERGISE',
    color: '#6AC2BE',
    childColor: '#E0F3F3',
    childNames: ['Interpersonal Effectiveness', 'Foster Collaboration & Teamwork'],
  },
  {
    name: 'DECIDE',
    color: '#8B58B1',
    childColor: '#E5EDF5',
    childNames: ['Analysis & Problem Solving', 'Set Goals & Drive Directions'],
  },
  {
    name: 'GROW',
    color: '#3685D3',
    childColor: '#E1F2FE',
    childNames: ['Lead Change & Innovation', 'Commitment to Learning & Development'],
  },
  {
    name: 'EXECUTE',
    color: '#68B172',
    childColor: '#E7F4E8',
    childNames: ['Deliver Performance', 'Professionalism & Expertise'],
  },
];

export const PRINT_MEETING_OPTIONS = [
  { label: 'Meeting Details', value: 'details' },
  { label: 'Summary', value: 'summary' },
];
export const PRINT_MOBILITY_OPTIONS = [
  { label: 'Mobility', value: 'mobility' },
  { label: 'Proposal', value: 'proposal' },
  { label: 'Compare Successor', value: 'compareSuccessor' },
  { label: 'Assessment', value: 'assessment' },
  { label: 'Compare Assessment', value: 'compareAssessment' },
  { label: 'Aspirations & Risks', value: 'aspirationAndRisks' },
  { label: 'Compare Aspirations & Risks', value: 'compareAspirationAndRisks' },
  { label: 'Supporting Documents', value: 'supportingDocuments' },
];
