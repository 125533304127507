import { isEmpty, map } from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import { DATE_TIME_FORMAT, PERMISSION } from '../utils/constants';
import { getCurrentTime } from '../utils/helper';
import axiosClient from './axiosClient';

export const pdcMeetingApi = {
  createMeeting: (data, roleActive) => {
    const headerApi = {
      headers: {
        role: roleActive.roleId,
      },
    };
    return axiosClient.post('/meetings-pdc/create', data, headerApi);
  },

  updateMeeting: (meetingData, roleActive) => {
    const { idMeeting, ...data } = meetingData;
    const headerApi = {
      headers: {
        role: roleActive.roleId,
      },
    };
    return axiosClient.put(
      `/meetings-pdc/update/${idMeeting}`,
      data,
      headerApi
    );
  },

  getMeeting: ({ idMeeting, roleUser }) => {
    const { roleActive } = roleUser;
    const url = `/meeting-pdc/${idMeeting}`;
    return axiosClient.get(url, {
      headers: {
        role: roleActive.roleId,
      },
      transformResponse: (resRaw) => {
        const res = JSON.parse(resRaw);
        if (
          !res?.result?.is_secretariat_or_facilitator &&
          !res?.result?.meeting_pdc_id &&
          !res?.result?.category
        ) {
          return null;
        }
        const {
          name,
          start_at,
          end_at,
          roles: {
            chairman,
            committee,
            secretariat,
            facilitator,
            additional_view_access,
          },
          _meeting_committee_ids,
          _meeting_facilitator_ids,
          ...rest
        } = res.result;
        return {
          ...res,
          result: {
            ...rest,
            meeting_name: name,
            date: moment(new Date(start_at)).format(
              DATE_TIME_FORMAT.DATE_SPACE
            ),
            start_time: getCurrentTime(start_at),
            end_time: getCurrentTime(end_at),
            chairman: map(chairman, (it) => ({ label: it.name, value: it.id })),
            meeting_committee: map(committee, ({ name, id }) => ({
              label: name,
              value: id,
            })),
            meeting_facilitator: map(facilitator, ({ name, id }) => ({
              label: name,
              value: id,
            })),
            meeting_secretariats: !isEmpty(secretariat)
              ? secretariat.map((item) => ({
                  ...item,
                  role: item?.role || item?.name,
                }))
              : [],
            meeting_additional_view_accesses: !isEmpty(additional_view_access)
              ? additional_view_access.map((item) => ({
                  ...item,
                  role: item?.role || item?.name,
                }))
              : [],
          },
        };
      },
    });
  },

  deleteMeeting: ({ idMeeting }) => {
    const url = `/meetings-pdc/${idMeeting}`;
    return axiosClient.delete(url, {});
  },

  updateStepMeeting: ({ idMeeting, body }) => {
    return axiosClient.patch(`/meetings-pdc/${idMeeting}/step`, body);
  },

  getPdcMobilities: ({ idMeeting, page, roleId }) => {
    return axiosClient.get(`/meetings-pdc/${idMeeting}/mobilities`, {
      params: { page },
      headers: {
        role: roleId,
      },
    });
  },

  getPrintPdcMobilities: ({ idMeeting, roleId }) => {
    return axiosClient.get(`/meetings-pdc/${idMeeting}/flat-mobilities`, {
      headers: {
        role: roleId,
      },
    });
  },

  getPdcMobilityDetails: (idMeeting, mobilityId) => {
    return axiosClient.get(`/meetings-pdc/${idMeeting}/mobility/${mobilityId}`);
  },

  createPdcMobility: (idMeeting, body) => {
    return axiosClient.post(`/meetings-pdc/${idMeeting}/mobility/`, body);
  },

  updatePdcMobility: (mobilityId, body) => {
    return axiosClient.put(`/meetings-pdc/mobility/${mobilityId}`, body);
  },

  archivePdcMobility: (mobilityId) => {
    return axiosClient.put(`/meetings-pdc/archive-mobility/${mobilityId}`);
  },

  deletePdcMobility: (mobilityId) => {
    return axiosClient.delete(`/meetings-pdc/mobility/${mobilityId}`);
  },

  getProposalList: (mobilityId, proposalId) => {
    return axiosClient.get(
      `meetings-pdc/proposal-list?mobility_id=${mobilityId}&mobility_proposal_id=${proposalId}`
    );
  },

  getListProposalType: () => {
    return axiosClient.get(`meetings-pdc/proposal-types`);
  },

  getProposalItemList: (proposal_type) => {
    return axiosClient.get(
      `meetings-pdc/default-proposal-items?proposal_type=${
        proposal_type || 'Other Proposal'
      }`
    );
  },

  getTemplateContent: ({
    staff_id,
    position_code,
    mobility_id,
    data,
    roleId,
    mobility_proposal_id,
  }) => {
    const headerApi = {
      headers: {
        role: roleId,
      },
    };
    return axiosClient.post(
      `/meetings-pdc/template-content?staff_id=${staff_id}&position_code=${position_code}&mobility_id=${mobility_id}&mobility_proposal_id=${mobility_proposal_id}`,
      data,
      headerApi
    );
  },

  getSuccessorList: ({ keyword, page, limit }) => {
    return axiosClient.get(`/meetings-pdc/suscessors-list`, {
      params: { search_text: keyword, page, limit },
    });
  },

  getReplacementList: ({ keyword, page, limit }) => {
    return axiosClient.get(`/meetings-pdc/replacement`, {
      params: { search_text: keyword, page, limit },
    });
  },

  updateProposalList: (data, roleId, userInfos) => {
    const headerApi = {
      headers: {
        role: roleId,
        userInfos: JSON.stringify(userInfos),
      },
    };
    return axiosClient.post(
      '/meetings-pdc/proposal-list/save',
      data,
      headerApi
    );
  },

  getSearchStaff: (params, role) => {
    const url = '/meetings-pdc/search-staff';
    const options = {
      headers: { ...role },
      params,
    };
    return axiosClient.get(url, options);
  },

  searchTalentPosition: (search_text, selected_cards) => {
    return axiosClient.post(`/meeting-pdc/positions-talents`, {
      search_text,
      selected_cards,
    });
  },

  getRecommendedReplacement: (position_code, selected_cards) => {
    return axiosClient.post(
      `/meeting-pdc/positions-talents/recommended-replacement`,
      {
        position_code,
        selected_cards,
      }
    );
  },

  deleteMobilityProposal: (id) => {
    return axiosClient.delete(`/meetings-pdc/delete-proposal/${id}`);
  },

  getStaffDetails: (staff_id) => {
    return axiosClient.get(
      `/meetings-pdc/proposal-detail/find-staff/${staff_id}`
    );
  },

  createOrUpdateSuccessorList: (payload) => {
    const { data, roleId, userInfos } = payload;
    const headerApi = {
      headers: {
        role: roleId,
        userInfos: JSON.stringify(userInfos),
      },
    };
    return axiosClient.post(`/meetings-pdc/proposal-detail`, data, headerApi);
  },

  getProposalMultipleTalentOrPosition: (mobilityId, mobility_type) => {
    return axiosClient.get(
      `/meetings-pdc/multiple-mobility/${mobilityId}/mobility_type/${mobility_type}`,
      {
        params: {
          type: mobility_type,
        },
      }
    );
  },

  updateProposalMultiplePosition: (body, roleId) => {
    return axiosClient.post(
      `/meetings-pdc/mobility-agenda/multiple-position/update`,
      body,
      {
        headers: {
          role: roleId,
        },
      }
    );
  },

  getAssessments: (mobilityId, roleId) => {
    return axiosClient.get(`/meetings-pdc/assessment/${mobilityId}`, {
      headers: {
        role: roleId,
      },
      params: {
        mobility_id: mobilityId,
      },
    });
  },

  getAssessmentEdgeChart: (staffId, type, year, roleId) => {
    return axiosClient.get(
      `/meetings-pdc/leaderships/effective-role/edges/${staffId}`,
      {
        headers: {
          role: roleId,
          permission: 6,
        },
        params: {
          types: type !== 'All' ? [type] : ['external', 'sma'],
          year,
        },
      }
    );
  },

  updateAssessment: (body, mobilityId, roleId, proposalId) => {
    return axiosClient.put(`/meetings-pdc/assessment/${mobilityId}`, body, {
      headers: {
        role: roleId,
      },
      params: {
        proposal_mobility_id: proposalId,
      },
    });
  },

  getCompareAssessmentEdgeChart: (
    mobilityId,
    type,
    year,
    roleId,
    staffId,
    positionCode
  ) => {
    return axiosClient.get(
      `/meetings-pdc/leaderships/multiple-effective-role/edges/${mobilityId}`,
      {
        headers: {
          role: roleId,
          permission: 6,
        },
        params: {
          types: type !== 'All' ? [type] : ['external', 'sma'],
          year,
          staff_id: staffId,
          position_code: positionCode,
        },
      }
    );
  },

  createReportingDocuments: (data, roleId) => {
    return axiosClient.post(`/pdc/reporting-documents`, data, {
      headers: {
        role: roleId,
      },
    });
  },

  getReportingDocuments: (idMeeting, mobility_id, roleId) => {
    return axiosClient.get(
      `/pdc/reporting-documents/${idMeeting}/${mobility_id}`,
      {
        headers: {
          role: roleId,
          permission: PERMISSION.NO_SPECIFIC,
        },
        params: {
          meeting_id: idMeeting,
          mobility_id: mobility_id,
        },
      }
    );
  },

  uploadFileReportingDocuments: (data, roleId) => {
    const formData = new FormData();
    formData.append('file', data.file);
    return axiosClient.post(`/pdc/reporting-documents/attachment`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        role: roleId,
      },
    });
  },

  deleteFileReportingDocument: (id) => {
    const url = `/pdc/reporting-documents/attachment/${id}`;
    return axiosClient.delete(url);
  },

  deleteFilePdcMeeting: (idMeeting) => {
    const url = `/meetings-pdc/${idMeeting}/MoM?meeting_id=${idMeeting}`;
    return axiosClient.delete(url);
  },

  getAspirationRisk: (staff_ids, mobility_id, mobility_proposal_id, roleId) => {
    return axiosClient.get(`/meetings-pdc/aspiration-risks/view`, {
      headers: {
        role: roleId,
      },
      params: {
        staff_ids,
        mobility_id,
        mobility_proposal_id,
      },
    });
  },

  getAspirationRiskSuccessor: (
    staff_id,
    mobility_id,
    mobility_proposal_id,
    roleId
  ) => {
    return axiosClient.get(`/meetings-pdc/aspiration-risks/sucessor-list`, {
      headers: {
        role: roleId,
      },
      params: {
        staff_id,
        mobility_id,
        mobility_proposal_id,
      },
    });
  },

  updateAspirationRisk: (data) => {
    return axiosClient.post('/meetings-pdc/aspiration-risks/save', data);
  },

  updateMobilityAgendaStatus: (data, roleId) => {
    const headerApi = {
      headers: {
        role: roleId,
      },
    };
    return axiosClient.put(
      `/meetings-pdc/update-pdc-mobility-status`,
      data,
      headerApi
    );
  },

  updateMeetingStatus: (idMeeting, status, roleId) => {
    return axiosClient.put(
      `/meetings-pdc/update-status/${idMeeting}`,
      {
        status,
      },
      {
        headers: {
          role: roleId,
        },
      }
    );
  },

  getRoleInPdcMeeting: ({ idMeeting, roleId }) => {
    const url = `/meetings-pdc/${idMeeting}/my-roles`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
    });
  },

  fetchPDCMobilityExist: (query, roleId) => {
    const stringified = queryString.stringify(query);
    const url = `/meetings-pdc/archived-mobility-list?${stringified}`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
    });
  },

  fetchAddMobilityFromMobilityExist: (body, roleId) => {
    const url = `/meetings-pdc/add-existing`;
    return axiosClient.put(url, body, {
      headers: {
        role: roleId,
      },
    });
  },

  fetchSummaryProposal: (meetingId, roleId) => {
    const url = `/meetings-pdc/summary/${meetingId}`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
    });
  },

  fetchInformationLastStep: (meetingId, roleId) => {
    const url = `/meetings-pdc/${meetingId}/last-step`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
    });
  },

  getBuSortListOfMeeting: (meetingId, roleId) => {
    const url = `/meetings-pdc/${meetingId}/mobilities/bu-sort-list`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
    });
  },

  updateBuSortListOfMeeting: (data, meetingId, roleId) => {
    const url = `meetings-pdc/${meetingId}/mobilities/sort-by-bu`;
    return axiosClient.put(url, data, {
      headers: {
        role: roleId,
      },
    });
  },

  updateMobilitiesOfMeeting: (data, meetingId, roleId) => {
    const url = `/meetings-pdc/${meetingId}/mobilities`;
    return axiosClient.put(url, data, {
      headers: {
        role: roleId,
      },
    });
  },

  updateStepMobilityAgenda: (mobilityId, body) => {
    return axiosClient.patch(`/meetings-pdc/mobility/${mobilityId}/step`, body);
  },

  searchPDCRemarkStaff: (search, meeting_id) => {
    return axiosClient.get(`/meetings-pdc/remarks/search-talent`, {
      params: {
        search,
        meeting_id,
      },
    });
  },

  savePDCRemarks: ({ body, roleId, userInfos }) => {
    return axiosClient.post('/meetings-pdc/remarks/add', body, {
      headers: {
        role: roleId,
        userInfos: JSON.stringify(userInfos),
      },
    });
  },

  getRemarksList: (meeting_id, category, page) => {
    return axiosClient.get(`/meetings-pdc/remarks/get-list`, {
      params: {
        meeting_id,
        category,
        page,
      },
    });
  },

  fetchCompletePDCMeeting: (meetingId, roleId) => {
    const url = `/meetings-pdc/${meetingId}/complete`;
    return axiosClient.put(
      url,
      {},
      {
        headers: {
          role: roleId,
        },
      }
    );
  },

  notifyPDCMeeting: (meetingId, roleId) => {
    const url = `/meetings-pdc/${meetingId}/notify-facilitator`;
    return axiosClient.post(
      url,
      {},
      {
        headers: {
          role: roleId,
        },
      }
    );
  },

  editMeetingFromCancel: (role, meetingId) => {
    const url = `/meetings-pdc/submit-edit-cancelled/${meetingId}`;
    return axiosClient.patch(
      url,
      {},
      {
        headers: {
          role: role.roleId,
        },
        params: {
          meeting_id: meetingId,
        },
      }
    );
  },

  deletePDCRemark: (meeting_id, id, roleId, userInfos) => {
    const url = `/meetings-pdc/${meeting_id}/remark/delete/${id}`;
    return axiosClient.delete(url, {
      headers: {
        role: roleId,
        userInfos: JSON.stringify(userInfos),
      },
    });
  },

  updatePDCRemark: ({ body, meeting_id, id, roleId, userInfos }) => {
    const url = `/meetings-pdc/${meeting_id}/remark/update/${id}`;
    return axiosClient.put(url, body, {
      headers: {
        role: roleId,
        userInfos: JSON.stringify(userInfos),
      },
    });
  },

  concludePDCMeeting: ({ meeting_id, roleId }) => {
    const url = `meeting-pdc/${meeting_id}/conclude`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
    });
  },

  removeSuccessor: (
    staff_id,
    position_code,
    mobility_id,
    mobility_proposal_id,
    roleId
  ) => {
    const url = `meetings-pdc/successor/remove`;
    return axiosClient.delete(url, {
      headers: {
        role: roleId,
      },
      params: {
        staff_id,
        position_code,
        mobility_id,
        mobility_proposal_id,
      },
    });
  },

  generateMoMForPdcMeeting: (data, roleId) => {
    return axiosClient.post(`/meetings-pdc/generate-MoM`, data, {
      headers: {
        role: roleId,
      },
    });
  },

  resendInvitationsPDCMeetingId: (meetingId, roleId) => {
    return axiosClient.put(
      `/meetings-pdc/${meetingId}/resend-invitations`,
      {},
      {
        headers: {
          role: roleId,
        },
      }
    );
  },
};
