import { Spin } from 'antd';
import { size } from 'lodash';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { BasicButton } from '../../../assets/common';
import { Arrow_Down_2, avatar, Compare_2 } from '../../../assets/img';
import { BreadCrumb } from '../../../components';
import GlobalAlertMessage from '../../../components/GlobalAlertMessage/GlobalAlertMessage';
import { useUpdatePdcMobilityStepNumber } from '../../../hooks/useUpdatePdcMobilityStepNumber';
import { pdcMeetingApi } from '../../../services/pdcMeetingApi';
import { PDC_PROPOSAL_TYPES } from '../../../utils/constants';
import { getBreadCrumbList, getPdcMobilityProposalType } from '../../../utils/helper';
import AssessmentCompareLeadershipModal from '../components/AssessmentCompareLeadershipModal/AssessmentCompareLeadershipModal';
import AssessmentLeadershipRemarks from '../components/AssessmentLeadershipRemarks/AssessmentLeadershipRemarks';
import MobilityBotButtonControls from '../components/MobilityButtonControls/MobilityBotButtonControls';
import MobilityTopButtonControls from '../components/MobilityButtonControls/MobilityTopButtonControls';
import MobilityFinalizeBar from '../components/MobilityFinalizeBar/MobilityFinalizeBar';
import PdcMobilityProcessBar from '../components/PdcMobilityProcessBar/PdcMobilityProcessBar';
import * as styles from './pdc-assessment.module.scss';

const BREAD_CRUMB = getBreadCrumbList(['Homepage', 'PDC Meeting', 'Mobility Agenda', 'Assessment']);

const PDCAssessment = (props) => {
  const { idMeeting, mobilityId, proposalId } = useParams();
  const { search } = useLocation();
  const { mobilityType } = queryString.parse(search);
  const [proposals, setProposals] = useState([]);
  const [staffId, setStaffId] = useState(null);
  const [positionCode, setPositionCode] = useState(null);
  const [loading, setLoading] = useState(false);
  const [expandIds, setExpandIds] = useState([]);
  const [openCompare, setOpenCompare] = useState(false);
  const roleId = useSelector((state) => state.user.roleActive.roleId);
  const history = useHistory();

  // Get the condition to edit mobility agenda
  const {
    isOnlyViewAgenda,
    isShowTopButtons,
    isShowBotButtons,
    isAdditionalViewAccess,
    meetingDetail,
    mobilityDetails,
    isEnableMobilityTab,
    proposalMainStep,
    fetchMobilityDetails,
    isShownMobilityTabBar,
    isHRPartners,
    isChairMainOrCommiteeOrAdvocator,
  } = props;
  const { updateStepNumber } = useUpdatePdcMobilityStepNumber();

  const isMultiple = useMemo(() => {
    if (!mobilityDetails) return 0;
    return mobilityDetails.proposals.filter((m) => m.is_main && m.type === 'multiple').length;
  }, [mobilityDetails]);

  const fetchProposals = useCallback(async () => {
    try {
      setLoading(true);
      const res = await pdcMeetingApi.getAssessments(mobilityId, roleId);
      if (res.status === 200) {
        const result = res.data.result;
        setProposals(result);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [mobilityId]);

  useEffect(() => {
    fetchProposals();
  }, [fetchProposals]);

  const handleClickExpand = ({ staff_id }) => {
    const isCollapse = expandIds.includes(staff_id);
    const newExpandIds = isCollapse ? expandIds.filter((id) => id !== staff_id) : [...expandIds, staff_id];
    setExpandIds(newExpandIds);
  };

  const handleSaveAsDraft = async () => {
    try {
      setLoading(true);
      const body = proposals.map((p) => ({ strengths: p.strengths, development_areas: p.development_areas, staff_id: p.staff_id }));
      const proposalId = proposals[0]?.mobility_proposal_id;
      const res = await pdcMeetingApi.updateAssessment(body, mobilityId, roleId, proposalId);
      if (res.status === 200) {
        if (proposalMainStep?.step < 3) {
          await updateStepNumber(mobilityId, 3);
        }
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const getProposalAsMainType = useMemo(() => {
    return getPdcMobilityProposalType(mobilityDetails);
  }, [mobilityDetails]);

  const proposalMainId = useMemo(() => {
    return mobilityDetails?.main_proposal_id || null;
  }, [mobilityDetails]);

  const getProposalListUrl = useMemo(() => {
    if (!proposalMainId) return;
    const isLength = size(mobilityDetails.proposals.find((i) => i?.is_main)?.cards);
    if (getProposalAsMainType === PDC_PROPOSAL_TYPES.MULTIPLE_POSITION && isLength > 1) {
      return `/pdc-meeting/${idMeeting}/mobility/${mobilityDetails?.id}/position-proposal/${proposalMainId}`;
    } else if (getProposalAsMainType === PDC_PROPOSAL_TYPES.MULTIPLE_TALENT && isLength > 1) {
      return `/pdc-meeting/${idMeeting}/mobility/${mobilityDetails?.id}/talent-proposal/${proposalMainId}`;
    } else {
      return `/pdc-meeting/${idMeeting}/mobility/${mobilityDetails?.id}/proposal/${proposalMainId}`;
    }
  }, [mobilityDetails]);

  const handleClickBack = async () => {
    if (!mobilityType) {
      history.push(getProposalListUrl);
    } else {
      history.goBack();
    }
  };

  const handleClickNext = async () => {
    const isSaved = await handleSaveAsDraft();
    if (isSaved) {
      history.push(`/pdc-meeting/${idMeeting}/mobility/${mobilityId}/aspiration-risks/${proposalId}`);
    }
  };

  const handleClickViewCompare = (proposal) => {
    setOpenCompare(true);
    setStaffId(proposal?.staff_id);
    setPositionCode(proposal?.position_code);
  };

  return (
    <>
      <div style={{ marginBottom: '15px' }}>
        <BreadCrumb level={4} breadCrumbList={BREAD_CRUMB} />
      </div>
      <GlobalAlertMessage style={{ marginBottom: '36px' }} />
      {isShowTopButtons && (
        <MobilityTopButtonControls
          idMeeting={idMeeting}
          mobilityId={mobilityId}
          mobilityDetails={mobilityDetails}
          fetchMobility={fetchMobilityDetails}
          isAdditionalViewAccess={isAdditionalViewAccess}
          isHRPartners={isHRPartners}
          isChairMainOrCommiteeOrAdvocator={isChairMainOrCommiteeOrAdvocator}
          meetingDetail={meetingDetail}
        />
      )}
      {isShownMobilityTabBar ? (
        <MobilityFinalizeBar mobilityDetails={mobilityDetails} />
      ) : (
        <PdcMobilityProcessBar currentStep={3} mobilityDetails={mobilityDetails} isEnableMobilityTab={isEnableMobilityTab} />
      )}

      <Spin spinning={loading}>
        <div className={styles.wrapper}>
          <div className={styles.title}>Proposal List</div>
          <div className={styles.proposals}>
            {proposals.map((proposal, idx) => (
              <div key={proposal.staff_id}>
                <div className={styles.title}>Proposal {idx + 1}</div>
                <div className={`${styles.proposal} ${expandIds.includes(proposal.staff_id) ? styles.expand : ''}`}>
                  <div className={styles.header}>
                    <div className={styles.name}>
                      <img src={proposal.image || avatar} alt="avatar" />
                      <div>
                        <div className={styles.label}>Name</div>
                        <div className={styles.normalContent}>{proposal.birth_name}</div>
                      </div>
                    </div>
                    <div className={styles.position}>
                      <div className={styles.label}>Position</div>
                      <div className={styles.normalContent}>{proposal.position_name || 'N/A'}</div>
                    </div>
                    <div className={styles.more}>
                      <div className={styles.type}>{proposal.proposal_type}</div>
                      <div className={styles.btnRow}>
                        {!isMultiple && (
                          <BasicButton mode="icon" onClick={() => handleClickViewCompare(proposal)}>
                            <img src={Compare_2} alt="compare" />
                          </BasicButton>
                        )}
                        <BasicButton mode="icon" onClick={() => handleClickExpand(proposal)}>
                          <img src={Arrow_Down_2} alt="expand" className={styles.expandBtn} />
                        </BasicButton>
                      </div>
                    </div>
                  </div>
                  <div className={styles.expandContent}>
                    <AssessmentLeadershipRemarks
                      proposal={proposal}
                      setProposals={setProposals}
                      isExpand={expandIds.includes(proposal.staff_id)}
                      isOnlyViewAgenda={isOnlyViewAgenda || isHRPartners}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {(isShowBotButtons || !mobilityId) && (
          <MobilityBotButtonControls
            idMeeting={idMeeting}
            mobilityId={mobilityId}
            isDisabled={false}
            handleSaveAsDraft={handleSaveAsDraft}
            mobilityDetails={mobilityDetails}
            fetchMobility={fetchMobilityDetails}
            handleClickBack={handleClickBack}
            handleClickNext={handleClickNext}
            isOnlyViewAgenda={isOnlyViewAgenda}
            isHRPartners={isHRPartners}
            isAdditionalViewAccess={isAdditionalViewAccess}
            isChairMainOrCommiteeOrAdvocator={isChairMainOrCommiteeOrAdvocator}
            meetingDetail={meetingDetail}
          />
        )}
      </Spin>

      <AssessmentCompareLeadershipModal
        open={openCompare}
        setOpen={setOpenCompare}
        proposals={proposals}
        isOnlyViewAgenda={isOnlyViewAgenda || isHRPartners}
        staffId={staffId}
        positionCode={positionCode}
      />
    </>
  );
};

export default PDCAssessment;
