import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import * as styles from './pdc-talent-position-card.module.scss';
import CustomAntModal from '../../../../components/CustomAntModal/CustomAntModal';
import { BasicButton, BasicInput } from '../../../../assets/common';
import { Divider, Dropdown } from 'antd';
import { avatar_not_found, more_dot_card, remove_card } from '../../../../assets/img';
import { compareCard } from '../../../../utils/helper';
import { cloneDeep } from 'lodash';
import { useDraggable } from '@dnd-kit/core';

const PDCTalentPositionCard = (props) => {
  const { card, setCard, isEditingProposal, handleDeleteCard, selectedCard, setSelectedCard, isRecommended, recommendedIndex, isPosition } = props;
  const [openAdditional, setOpenAdditional] = useState(false);
  const [additionalInput, setAdditionalInput] = useState('');
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: isRecommended ? `${card.staff_id}-${card.position_code}` : null,
    disabled: !isRecommended,
    data: card,
  });
  const dragStyle = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;

  const isVacant = useMemo(() => {
    return !isRecommended && isPosition && !card.has_incumbent;
  }, [isRecommended, isPosition, card.position_code]);

  const handleClickAddAdditional = () => {
    const newCard = cloneDeep(card);
    newCard.additional_input = additionalInput;
    setCard(newCard);
    setOpenAdditional(false);
  };

  const handleRemoveSuccessor = (index) => {
    const newCard = cloneDeep(card);
    newCard.successors.splice(index, 1);
    setCard(newCard);
  };

  const handleRemoveAdditionalInput = () => {
    const newCard = cloneDeep(card);
    newCard.additional_input = '';
    setCard(newCard);
  };

  const handleSelectedCard = (card) => {
    if (!isEditingProposal || isRecommended || (selectedCard && compareCard(selectedCard, card))) return;
    setSelectedCard(card);
  };

  const handleClickDelete = (e) => {
    e.stopPropagation();
    handleDeleteCard(card);
  };

  const styleSelectedCard = useMemo(() => {
    if (isRecommended) {
      return { borderTop: `4px solid #${card.tag_color}` };
    }
    if (selectedCard?.position_code !== card?.position_code || selectedCard?.staff_id !== card?.staff_id) return {};
    return { border: '1px solid #005cb9' };
  }, [isRecommended, selectedCard?.position_code, selectedCard?.staff_id]);

  return (
    <>
      <div className={styles.talentCard} style={{ ...styleSelectedCard, ...dragStyle }} ref={setNodeRef} {...listeners} {...attributes}>
        {isEditingProposal && !isRecommended && <div onClick={() => handleSelectedCard(card)} className={styles.cardOverlay} />}
        <div className={styles.info}>
          <div className={styles.row1}>
            {!isVacant ? (
              <>
                <img src={card.image_url || avatar_not_found} alt="avatar" className={styles.avatar} />
                <div className={styles.basicInfo}>
                  <div className={styles.line1}>
                    {recommendedIndex && <div className={styles.recommendedIndex}>{recommendedIndex}</div>}
                    <div>
                      {card.birth_name || 'N/A'} ({card.top_talent_status || 'N/A'}, {card.age || 'N/A'})
                    </div>
                    {card.matching_percentage && <div className={styles.matchingPercentage}>{card.matching_percentage}%</div>}
                  </div>
                  <div className={styles.line2}>
                    {card.position_name || 'N/A'}, {card.business_unit || 'N/A'}
                  </div>
                  <div className={styles.line3}>
                    {card.division || 'N/A'} | {card.opu_name || 'N/A'}
                  </div>
                  {isRecommended && card.approved_tag && <div className={styles.approvedTag}>Approved SP ({card.approved_tag})</div>}
                </div>
              </>
            ) : (
              <div className={styles.vacant}>
                <div>VACANT</div>
                <div className={styles.line2}>
                {card.position_name || 'N/A'}, {card.business_unit || 'N/A'}
                </div>
                <div className={styles.line3}>
                  {card.division || 'N/A'} | {card.opu_name || 'N/A'}
                </div>
              </div>
            )}
            {isEditingProposal && !isRecommended && (
              <div className={styles.btnRow}>
                <Dropdown
                  menu={{
                    items: [
                      {
                        label: <div className={styles.additionalBtn}>Add Additional Input</div>,
                        key: '0',
                        onClick: () => setOpenAdditional(true),
                      },
                    ],
                  }}
                  trigger={['click']}
                  style={{ minWidth: 110, zIndex: 10 }}
                  placement="bottomRight"
                  getPopupContainer={(trigger) => trigger.parentElement}
                >
                  <img src={more_dot_card} style={{ zIndex: 10 }} alt="dot" />
                </Dropdown>
                <BasicButton mode="icon" onClick={handleClickDelete} style={{ position: 'relative', zIndex: 10 }}>
                  <img src={remove_card} alt="delete-card" />
                </BasicButton>
              </div>
            )}
          </div>
          {isVacant && (
            <div className={styles.vacantJg}>
              <div>JG: {card.job_grade || 'N/A'}</div>
            </div>
          )}
          {!isVacant && (
            <div className={styles.row2}>
              <div>
                <div>JG</div>
                <span>{card.job_grade || 'N/A'}</span>
              </div>
              <div>
                <div>SG</div>
                <span>
                  {card.sg || 'N/A'} ({card.date_in_sg || 'N/A'})
                </span>
              </div>
              <div>
                <div>Retirement</div>
                <span>{card.retirement_or_contract || 'N/A'}</span>
              </div>
            </div>
          )}
          {!isRecommended && !isPosition && (
            <div className={styles.successorSection}>
              <div>SUCCESSOR</div>
              {card?.successors?.map((successor, index) => (
                <div key={successor} className={styles.successor}>
                  <div className="d-flex align-items-center">
                    <span>{index + 1}</span> {successor}
                  </div>
                  {isEditingProposal && (
                    <BasicButton mode="icon" onClick={() => handleRemoveSuccessor(index)}>
                      <img src={remove_card} alt="delete-successor" />
                    </BasicButton>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
        {card?.additional_input && (
          <div className={styles.additionalInput}>
            {card.additional_input}
            {isEditingProposal && (
              <BasicButton className={styles.removeBtn} mode="icon" onClick={handleRemoveAdditionalInput}>
                <img src={remove_card} alt="delete-additional-input" />
              </BasicButton>
            )}
          </div>
        )}
      </div>

      <CustomAntModal hideDefaultBtn width={547} closeType={1} open={openAdditional} setOpen={setOpenAdditional} styles={{ closePosition: 30 }}>
        <div className={styles.additionalModal}>
          <div className={styles.title}>Add Additional Input</div>
          <BasicInput
            placeholder="Enter Additional Input"
            defaultValue={card.additional_input}
            onBlur={(e) => {
              setAdditionalInput(e.target.value);
            }}
          />
          <Divider />
          <BasicButton mode="teal" width="100%" onClick={handleClickAddAdditional}>
            Save
          </BasicButton>
        </div>
      </CustomAntModal>
    </>
  );
};

PDCTalentPositionCard.propTypes = {
  card: PropTypes.object,
  setCard: PropTypes.func,
  selectedCard: PropTypes.object,
  setSelectedCard: PropTypes.func,
  handleDeleteCard: PropTypes.func,
  isEditingProposal: PropTypes.bool,
  isRecommended: PropTypes.bool,
  isPosition: PropTypes.bool,
  recommendedIndex: PropTypes.number,
};

export default PDCTalentPositionCard;
