import { isArray } from 'lodash';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styles from './user-career-table.module.scss';
import { CustomTooltip } from '../PdcProposalItem/ProposalTemplateContent';

const UserCareerTable = (props) => {
  const { data, isSearchTalent } = props;
  const loading = false;

  const displayPerformance = (text, type) => {
    if (loading) {
      if (type === 'th') {
        return (
          <th style={{ backgroundColor: '#f4f5f8' }}>
            <Skeleton count={1} height={21} width={490}></Skeleton>
          </th>
        );
      } else {
        return (
          <td>
            <Skeleton count={1} height={21} width={490}></Skeleton>
          </td>
        );
      }
    } else {
      return <>{text}</>;
    }
  };
  return (
    <table
      className={styles.userCareer}
      style={{ width: isSearchTalent ? '100%' : 'auto' }}
    >
      <thead>
        <tr>
          {displayPerformance(
            <>
              <th>FY</th>
              {isArray(data) &&
                data.map((item, index) => {
                  return (
                    <th key={index}>
                      &apos;{item?.year ? `${item.year.slice(2)}` : '-'}
                    </th>
                  );
                })}
            </>,
            'th'
          )}
        </tr>
      </thead>
      <tbody>
        {isArray(data) && (
          <tr>
            {displayPerformance(
              <>
                <td>{`Rating`}</td>
                {isArray(data) &&
                  data.map((item, index) => {
                    if (item) return <td key={index}>{item?.rating !== 'false' ? item?.rating : '-'}</td>;
                    return <td key={index}></td>;
                  })}
              </>
            )}
          </tr>
        )}
        {!isSearchTalent && isArray(data) && (
          <tr>
            {displayPerformance(
              <>
                <td>{'BePCB'}</td>
                {isArray(data) &&
                  data.map((item, index) => {
                    if (item)
                      return (
                        <td key={index}>
                          <CustomTooltip
                            placement={'bottom'}
                            className={'customTooltip'}
                            overlayStyle={{ minWidth: '76px' }}
                            title={
                              <div
                                className={'tooltipContent'}
                                style={{ textAlign: 'center' }}
                              >
                                <span
                                  style={{
                                    fontWeight: 600,
                                    fontSize: '12px',
                                    padding: '5px 12px',
                                    margin: '0 auto',
                                  }}
                                >
                                  {item?.description !== 'false'
                                    ? item?.description
                                    : item?.bepcb !== 'false'
                                    ? item?.bepcb
                                    : '-'}
                                </span>
                              </div>
                            }
                            overlayInnerStyle={{
                              borderRadius: '6px',
                              backgroundColor: '#000',
                              opacity: 1,
                              padding: '12px',
                            }}
                          >
                            <div
                              style={{
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                maxWidth: `${70}px`,
                                display: 'block',
                                padding: '5px',
                              }}
                            >
                              {item?.bepcb !== 'false' ? item?.bepcb : '-'}
                            </div>
                          </CustomTooltip>
                        </td>
                      );
                    return <td key={index}></td>;
                  })}
              </>
            )}
          </tr>
        )}
      </tbody>
    </table>
  );
};
export default UserCareerTable;
