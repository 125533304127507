import { Spin } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModelTC, Pagination } from '..';
import { BasicButton } from '../../assets/common';
import { edit_talent_profile } from '../../assets/img';
import { addDevelopmentPlan, deleteDevelopmentPlan, editDevelopmentPlan, fetchDevelopmentPlan } from '../../services/talentProfiles';
import { pushMessage } from '../../store/alertMessageSlice';
import { DATA_FROM, PERMISSION } from '../../utils/constants';
import CustomAntModal from '../CustomAntModal/CustomAntModal';
import More3DotDropdown from '../More3DotDropdown/More3DotDropdown';
import AddEditDevelopmentPlan from './AddEditDevelopmentPlan';
import styles from './development-plan.module.scss';

const DataTable = (props) => {
  const { loading, isModal, plans, total, page, setPage, limit, handleEdit, handleDelete } = props;
  return (
    <>
      <Spin spinning={loading} size="small">
        <div className={styles.tablePlan}>
          <table className="table">
            <thead>
              <tr>
                <th scope="col" className="text-center">
                  No.
                </th>
                <th scope="col" style={{ width: '45%' }}>
                  Development Plan
                </th>
                <th scope="col" className="text-center">
                  Date
                </th>
                <th scope="col" className="text-center">
                  Platform
                </th>
                <th scope="col" className="text-center">
                  Status
                </th>
                {isModal && <th scope="col"></th>}
              </tr>
            </thead>
            <tbody>
              {!loading && !!plans && plans.length > 0 ? (
                plans?.map((plan, idx) => (
                  <tr key={idx}>
                    <td className="text-center">{(page - 1) * limit + idx + 1}</td>
                    <td style={{ wordBreak: 'break-word' }}>{plan.description || '-'}</td>
                    <td className="text-center">{plan.start_date ? moment(plan.start_date).format('D MMMM YYYY') : '-'}</td>
                    <td className="text-center" style={{ wordBreak: 'break-word' }}>
                      {plan.library_goal || '-'}
                    </td>
                    <td className="text-center">
                      {(plan.status?.split(' ').length > 1
                        ? plan.status
                            ?.split(' ')
                            ?.map((e) => e?.replace(/\w/, (firstLetter) => firstLetter.toUpperCase()))
                            ?.join(' ')
                        : plan.status?.replace(/\w/, (firstLetter) => firstLetter?.toUpperCase())) || '-'}
                    </td>
                    {isModal && (
                      <td data-testid="more">
                        <More3DotDropdown
                          item={plan}
                          handleClickEdit={handleEdit}
                          handleClickDelete={handleDelete}
                          editable={Boolean(plan?.is_editable) && plan.from === DATA_FROM.MANUAL}
                        />
                      </td>
                    )}
                  </tr>
                ))
              ) : (
                <tr key="0" className={styles.tablePlanBody}>
                  <td className="text-center">-</td>
                  <td>-</td>
                  <td className="text-center">-</td>
                  <td className="text-center">-</td>
                  <td className="text-center">-</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Spin>
      {!loading && plans && plans.length > 0 && <Pagination page={page} rowPerPages={4} totalResults={total} setPage={setPage} />}
    </>
  );
};

const DevelopmentPlan = (props) => {
  const { profileId } = props;
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [page, setPage] = useState(1);
  const [totalResults, setTotalResults] = useState(1);
  const [listPlan, setListPlan] = useState([]);
  const limit = 4;

  const [loading, setLoading] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [openAddEdit, setOpenAddEdit] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [dataEdit, setDataEdit] = useState(null);

  const isEditPermission = useMemo(() => {
    if (isEmpty(user?.permissions)) return false;
    return user.permissions.includes(PERMISSION.TP_EDIT_DEVELOPMENT_PLAN);
  }, [user.permissions]);

  const handleClickAdd = () => {
    setDataEdit(null);
    setIsEdit(false);
    setOpenAddEdit(true);
    setOpenViewModal(false);
  };

  const handleClickEdit = (item) => {
    setDataEdit(item);
    setIsEdit(true);
    setOpenAddEdit(true);
    setOpenViewModal(false);
  };

  const handleClickDelete = (item) => {
    setDataEdit(item);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    try {
      const res = await deleteDevelopmentPlan(dataEdit.id, user);
      if (res.status === 200) {
        setPage(1);
        fetchApi();
        setOpenDelete(false);
        setOpenViewModal(false);
        dispatch(
          pushMessage({
            message: `Talent Development Plan successfully deleted`,
            type: 'success',
            timeShow: 0,
          })
        );
      }
    } catch (error) {}
  };

  const handleSubmitAddEdit = async (data, handleResetForm) => {
    const body = { staff_id: profileId, ...data };
    const handleSuccess = () => {
      handleResetForm();
      setOpenAddEdit(false);
      fetchApi();
      dispatch(
        pushMessage({
          message: `Talent Development Plan successfully ${isEdit ? 'edited' : 'added'}`,
          type: 'success',
          timeShow: 0,
        })
      );
    };
    try {
      if (isEdit) {
        const res = await editDevelopmentPlan(dataEdit.id, body, user);
        if (res.status === 200) {
          handleSuccess();
        }
      } else {
        const res = await addDevelopmentPlan(body, user);
        if (res.status === 201) {
          handleSuccess();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchApi = useCallback(async () => {
    try {
      setLoading(true);
      const body = { page, limit };
      const response = await fetchDevelopmentPlan(profileId, user, body);
      if (response?.status === 200 && response?.data?.result) {
        setListPlan(response?.data?.result?.developmentPlan);
        setTotalResults(response?.data?.result?.totalDevPlan);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [page, profileId, user]);

  useEffect(() => {
    if (user.roleActive) {
      fetchApi();
    }
  }, [user.roleActive, fetchApi]);

  return (
    <>
      <div className={styles.SectionPlan} data-testid="development-plan">
        <div className={styles.header}>
          <div className={styles.SectionName}>Development Plan</div>
          {isEditPermission && <img alt="edit" src={edit_talent_profile} onKeyDown={() => {}} onClick={() => setOpenViewModal(true)} />}
        </div>

        <DataTable plans={listPlan} total={totalResults} page={page} setPage={setPage} limit={limit} loading={loading} />
      </div>
      <CustomAntModal
        open={openViewModal}
        setOpen={setOpenViewModal}
        title={'Development Plan'}
        width={1000}
        styles={{
          titleFontWeight: 600,
          titleColor: '#787587',
        }}
        hideDefaultBtn
      >
        <div className="d-flex justify-content-end">
          <BasicButton mode="teal" onKeyDown={() => {}} onClick={handleClickAdd}>
            Add Development Plan
          </BasicButton>
        </div>
        <DataTable
          isModal
          plans={listPlan}
          total={totalResults}
          page={page}
          setPage={setPage}
          limit={limit}
          handleEdit={handleClickEdit}
          handleDelete={handleClickDelete}
          loading={loading}
        />
      </CustomAntModal>

      <AddEditDevelopmentPlan open={openAddEdit} setOpen={setOpenAddEdit} isEdit={isEdit} dataEdit={dataEdit} handleSubmit={handleSubmitAddEdit} />

      <ModelTC
        info={{
          type: 'deleteDevelopmentPlanTalentProfile',
          visible: openDelete,
          setVisible: setOpenDelete,
          handleSubmit: () => handleDelete(),
          onClose: () => setOpenDelete(false),
        }}
      />
    </>
  );
};

export default DevelopmentPlan;
