import { createSlice } from '@reduxjs/toolkit';
import { isArray } from 'lodash';

export const initialStateMeeting = {
  meeting_pdc_id: null,
  meeting_name: '',
  start_time: '',
  end_time: '',
  date: '',
  category: {
    name: '',
    id: '',
  },
  category_value: null,
  location: '',
  chairman: [],
  meeting_committee: [],
  meeting_facilitator: [],
  meeting_secretariats: [],
  meeting_additional_view_accesses: [],
  status: '',
  is_secretariat_or_facilitator: true,
};
const meetingDetailSlice = createSlice({
  name: 'pdcMeetingDetails',
  initialState: {
    meeting: initialStateMeeting,
    meetingRole: {},
    loading: false,
    isSubmittedFormChanges: false,
    isEditMode: false,
  },
  reducers: {
    addOrUpdateMeeting: (state, action) => {
      if (isArray(action.payload.chairman)) {
        state.meeting = {
          ...action.payload,
          is_secretariat_or_facilitator: action.payload?.is_secretariat_or_facilitator || false,
        };
      } else {
        state.meeting = {
          ...action.payload,
          chairman: [action.payload.chairman],
          start_time: action.payload.start_time,
          end_time: action.payload.end_time,
          is_secretariat_or_facilitator: action.payload?.is_secretariat_or_facilitator || false,
        };
      }
    },
    updateRoles: (state, action) => {
      state.meetingRole = action.payload;
    },
    clearPDCMeeting: (state) => {
      state.meeting = initialStateMeeting;
    },
    updateSubmittedFormChanges: (state, action) => {
      state.isSubmittedFormChanges = action.payload;
    },
    switchEditMode: (state, action) => {
      state.isEditMode = action.payload;
    },
  },
});
const { actions, reducer: pdcMeetingDetailReducer } = meetingDetailSlice;
export const { getMeeting, addOrUpdateMeeting, clearPDCMeeting, updateRoles, updateSubmittedFormChanges, switchEditMode } = actions;
export default pdcMeetingDetailReducer;
