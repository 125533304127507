import { get } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { AiButton, BasicButton } from '../../../assets/common';
import { homepage_icon } from '../../../assets/img';
import { BreadCrumb, ModelTC } from '../../../components';
import { pdcMeetingApi } from '../../../services/pdcMeetingApi';
import { MEETING_DETAIL_STATUS, NOTIFICATION_TYPE, ROLE } from '../../../utils/constants';
import { showNotification } from '../../../utils/notification';
import ContainerSummary from '../components/ContainerSummary/ContainerSummary';
import MeetingProgressBar from '../components/MeetingProgressBar/MeetingProgressBar';
import { Footer, MeetingName } from './LastStepPDCMeetingStyled';
import { useGetRoleInPdcMeeting } from '../../../hooks/useGetRoleInPdcMeeting';
import { useGetPDCMeeting } from '../../../hooks/useGetPDCMeeting';
import { useUpdatePdcMeetingStepNumber } from '../../../hooks/useUpdatePdcMeetingStepNumber';
import PrintSelectButton from '../PDCPrintPage/components/PrintSelectButton/PrintSelectButton';

const LastStepPDCMeeting = () => {
  const breadCrumbList = [{ name: 'Homepage', icon: homepage_icon, url: '/homepage' }, { name: 'PDC Meeting', url: '/pdc' }, { name: 'Summary' }];
  const [lastStep, setLastStep] = useState({
    openConfirmDelete: false,
    loading: false,
    meetingName: '',
  });
  const [showPublicConfirm, setShowPublicConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  const { idMeeting } = useParams();
  const roleActive = useSelector((state) => state.user.roleActive);
  const history = useHistory();

  // Get pdc meeting
  const { data: meetingDetail } = useGetPDCMeeting({ idMeeting });

  const { data: meetingRole } = useGetRoleInPdcMeeting({ idMeeting });

  // Fetch api to update step number
  const { updateMeetingStepNumber } = useUpdatePdcMeetingStepNumber();

  const isAdminRole = useMemo(() => {
    return roleActive?.roleName ? roleActive?.roleName.includes(ROLE.ADMIN) : false;
  }, [roleActive]);

  async function deleteMeeting() {
    try {
      setLastStep((prev) => ({ ...prev, loading: true }));
      await pdcMeetingApi.deleteMeeting({ idMeeting });
      setLastStep((prev) => ({ ...prev, openConfirmDelete: false, loading: false }));
      history.push('/pdc');
    } catch (error) {
      console.log(error);
      showNotification(`Delete Meeting failed`, NOTIFICATION_TYPE.FAILED);
      setLastStep((prev) => ({ ...prev, openConfirmDelete: false, loading: false }));
    }
  }

  const handlePublishMeeting = async () => {
    try {
      setLoading(true);
      const res = await pdcMeetingApi.updateMeetingStatus(idMeeting, MEETING_DETAIL_STATUS.PUBLISHED, roleActive?.roleId);
      if (res.status === 200) {
        if (Number(meetingDetail?.on_step) < 3) {
          await updateMeetingStepNumber(idMeeting, 3);
        }
        history.push(`/pdc-meeting/${idMeeting}/summary/published`);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!roleActive?.roleId) return;
    (async function getInformationLastStep() {
      try {
        const res = await pdcMeetingApi.fetchInformationLastStep(idMeeting, roleActive?.roleId);
        setLastStep((prev) => ({ ...prev, meetingName: get(res, 'data.result.name') }));
      } catch (error) {
        console.log(error);
      }
    })();
  }, [roleActive?.roleId]);

  return (
    <div>
      <BreadCrumb level={6} breadCrumbList={breadCrumbList} />
      <MeetingProgressBar meetingDetail={meetingDetail} />
      <MeetingName>
        <div style={{ maxWidth: '1200px', wordBreak: 'break-word' }}>Proposal for {lastStep.meetingName}</div>
        {(meetingRole?.is_secretariat_or_facilitator || isAdminRole) && <PrintSelectButton border="1px solid" />}
      </MeetingName>
      <ContainerSummary />
      <Footer>
        <div>
          {(meetingRole?.is_secretariat_or_facilitator || isAdminRole) && (
            <AiButton mode="remove" onClick={() => setLastStep((prev) => ({ ...prev, openConfirmDelete: true }))}>
              Delete Meeting
            </AiButton>
          )}
        </div>
        <div className="right">
          <BasicButton mode="border-teal" onClick={() => history.push(`/pdc`)}>
            Exit
          </BasicButton>
          <BasicButton mode="border-teal" onClick={() => history.push(`/pdc-meeting/${idMeeting}/mobilities`)}>
            Back
          </BasicButton>
          {(meetingRole?.is_secretariat_or_facilitator || isAdminRole) && (
            <AiButton mode="teal" onClick={() => setShowPublicConfirm(true)}>
              Publish and Send Invite
            </AiButton>
          )}
        </div>
      </Footer>
      {lastStep.openConfirmDelete && (
        <ModelTC
          info={{
            modeSubmit: 'remove',
            type: 'deleteMeeting',
            visible: lastStep.openConfirmDelete,
            setVisible: () => setLastStep((prev) => ({ ...prev, openConfirmDelete: false })),
            handleSubmit: () => deleteMeeting(),
            onClose: () => setLastStep((prev) => ({ ...prev, openConfirmDelete: false })),
            loading: lastStep.loading,
          }}
        />
      )}

      <ModelTC
        info={{
          type: 'pushAndSendInvite',
          visible: showPublicConfirm,
          setVisible: setShowPublicConfirm,
          handleSubmit: handlePublishMeeting,
          loading: loading,
        }}
      />
    </div>
  );
};

export default LastStepPDCMeeting;
