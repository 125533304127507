import styled from 'styled-components';
import { font } from '../../../../assets/common';

export const Table = styled.div`
  .table-container {
    max-width: 1230px;
    text-align: center;
    font-family: ${font.inter};
    font-weight: 500;
    margin-top: 20px;
  }

  h1 {
    margin-bottom: 20px;
    color: #333;
  }

  .styled-table {
    width: 100%;
    margin: 0 auto; /* Center the table */
    border-collapse: collapse;
    font-size: 14px;
    line-height: 16.95px;
    text-align: left;

    border-radius: 6px;
    overflow: hidden;
    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px #ddd; /* this draws the table border  */
  }

  /* Fix the width of the first column */
  .styled-table th:first-child,
  .styled-table td:first-child {
    width: 230px;
  }

  .styled-table thead tr {
    background-color: #00a19c;
    color: #ffffff;
    text-align: center;
  }

  .styled-table th,
  .styled-table td {
    padding: 12px 15px;
    border: 1px solid #ddd;
    font-weight: 500 !important;
    text-align: center;
  }

  .styled-table td:last-child {
    background-color: #f4fbfb;
  }
  .styled-table tbody tr:last-of-type {
    background-color: #f4fbfb;
  }
  .styled-table tbody tr:last-of-type td:last-child {
    background-color: #e0f3f3;
  }
`;
