import React from 'react';
import * as styles from './auto-resize-text-area.module.scss';

const AutoResizeTextarea = (props) => {
  const { value, placeholder, style, className } = props;

  return (
    <div style={style} className={`${styles.defaultStyle} ${className}`}>
      {value ? value : <span>{placeholder}</span>}
    </div>
  );
};

export default AutoResizeTextarea;
