import React from 'react';
import * as styles from './pre-read-label.module.scss';

const PreReadLabel = () => {
  return (
    <div className={styles.wrapper}>
       Pre-read
    </div>
  );
};

export default PreReadLabel;
