import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import * as styles from './print-mobility.module.scss';
import { Spin } from 'antd';
import { BasicInput, BasicLabel, BasicSelect } from '../../../../../assets/common';
import { pdcMeetingApi } from '../../../../../services/pdcMeetingApi';
import { PDC_MOBILITY_CARD_TYPE, PDC_MOBILITY_PROPOSAL_TYPE, PDC_MOBILITY_TYPE_OPTIONS } from '../../../constants';
import { chunk, isEmpty } from 'lodash';
import { avatar_not_found, mobility_arrow_1, mobility_arrow_2, mobility_arrow_3 } from '../../../../../assets/img';
import PreReadLabel from '../PreReadLabel/PreReadLabel';

const MAX_CARD = 6;
const MAX_CARDS_PER_ROW = 3;
const MIN_MULTIPLE_CARD_FIRST_ROW = 1;

const PrintMobility = ({ mobilityInfo, idMeeting, preRead, handleFetchComplete }) => {
  const { id, mobility_proposal_id, proposal_main_type } = mobilityInfo;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [proposal, setProposal] = useState(null);

  useEffect(() => {
    const fetchDetail = async () => {
      if (!id) return;
      try {
        setLoading(true);
        const res = await pdcMeetingApi.getPdcMobilityDetails(idMeeting, id);
        if (res.status === 200) {
          const result = res.data.result;
          setData(result);
          setProposal(result.proposals.find((p) => p.id === mobility_proposal_id));
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
        handleFetchComplete('Mobility');
      }
    };
    fetchDetail();
  }, [idMeeting, id]);

  const displaySingleMobility = useMemo(() => {
    if (!proposal) return <></>;
    const cards = proposal.cards.length > 0 ? proposal.cards : [];
    const chunkRows = chunk(cards, MAX_CARDS_PER_ROW);
    if (isEmpty(chunkRows)) return <></>;
    return (
      <div className={styles.listCardSingle} style={chunkRows[0].length < MAX_CARDS_PER_ROW ? null : { alignItems: 'start' }}>
        {chunkRows.map((row, index) => {
          const isOdd = !(index % 2);
          return (
            <React.Fragment key={index}>
              <div className={isOdd ? styles.rowOdd : styles.rowEven}>
                {row.map((card, idx) => {
                  const isLast = idx + 1 === row.length;
                  return (
                    <React.Fragment key={`${card.staff_id}-${card.position_code}`}>
                      <div className="d-flex">
                        {!isLast && isOdd && <img src={mobility_arrow_1} alt="arrow_connect_1" className={styles.arrow} />}
                        <Card card={card} isPosition={!index && !idx} />
                        {!isLast && !isOdd && <img src={mobility_arrow_3} alt="arrow_connect_3" className={styles.arrow} />}
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
              <div className={styles.rowConnectArrow}>
                {chunkRows[index + 1] && <img src={mobility_arrow_2} alt="arrow_connect_2" className={styles.arrow} />}
              </div>
            </React.Fragment>
          );
        })}
      </div>
    );
  }, [proposal]);

  const displayMultipleMobility = useMemo(() => {
    if (!proposal) return <></>;
    const talentCards = getSortedCards(proposal.cards, PDC_MOBILITY_CARD_TYPE.TALENT);
    const positionCards = getSortedCards(proposal.cards, PDC_MOBILITY_CARD_TYPE.POSITION);
    const displayTalentCards = notHasAddCard(talentCards, positionCards) ? talentCards : [...talentCards, null];
    const displayPositionCards = notHasAddCard(positionCards, talentCards) ? positionCards : [...positionCards, null];
    const maxLength = Math.max(talentCards.length, positionCards.length);
    const maxLengthDisplay = Math.max(displayTalentCards.length, displayPositionCards.length);
    const chunkByRows = Array.from({ length: maxLengthDisplay }, (_, index) => [displayTalentCards[index], displayPositionCards[index]]);
    return (
      <div className={styles.listCardMultiple}>
        {chunkByRows.map((row, index) => (
          <div key={index} className={styles.rows}>
            {row.map((card, idx) => {
              const isPosition = Boolean(idx);
              return (
                <React.Fragment key={idx}>
                  {card && (
                    <div key={`${card.staff_id}-${card.position_code}`} className={styles.wrapCard}>
                      <Card card={card} isPosition={isPosition} />
                      <ConnectLineMultipleMobility
                        index={index}
                        rowIndex={idx}
                        maxLength={maxLength}
                        talentLength={talentCards.length}
                        positionLength={positionCards.length}
                      />
                    </div>
                  )}
                  {card === undefined && <div className={styles.tempCard} />}
                </React.Fragment>
              );
            })}
          </div>
        ))}
      </div>
    );
  }, [proposal]);

  return (
    <Spin spinning={loading}>
      <div className={styles.wrapper}>
        <div className={styles.firstForm}>
          <div className={styles.title}>
            Proposal Session Details
            {preRead && <PreReadLabel />}
          </div>
          <div>
            <BasicLabel required>Title</BasicLabel>
            <BasicInput placeholder="Enter Mobility Proposal Session Title" defaultValue={data?.title} disabled />
          </div>
          <div>
            <BasicLabel required>Advocators</BasicLabel>
            <BasicSelect mode="multiple" value={data?.advocators?.map((a) => ({ label: a.name, value: a.id }))} disabled />
          </div>
          <div>
            <BasicLabel required>HR Partners</BasicLabel>
            <BasicSelect mode="multiple" value={data?.hr_partners?.map((hr) => ({ label: hr.name, value: hr.id }))} disabled />
          </div>
        </div>
        <div className={styles.divider} />
        <div className={styles.mobilitySection}>
          <div className={styles.topBar}>
            <div className={styles.left}>
              <div className={styles.title}>{proposal?.name}</div>
            </div>
            <div className={styles.right}>
              <div className={styles.select}>
                <BasicSelect
                  height={32}
                  disabled
                  value={proposal_main_type === PDC_MOBILITY_PROPOSAL_TYPE.SINGLE ? 'Single Mobility' : 'Multiple Mobility'}
                  options={PDC_MOBILITY_TYPE_OPTIONS}
                  placeholder="Select Mobility Type"
                />
              </div>
              {preRead && <PreReadLabel />}
            </div>
          </div>

          <div>
            {proposal_main_type === PDC_MOBILITY_PROPOSAL_TYPE.SINGLE && displaySingleMobility}
            {proposal_main_type !== PDC_MOBILITY_PROPOSAL_TYPE.SINGLE && displayMultipleMobility}
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default PrintMobility;

PrintMobility.propTypes = {
  mobilityInfo: PropTypes.object,
  preRead: PropTypes.bool,
  idMeeting: PropTypes.string,
  handleFetchComplete: PropTypes.func,
};

const Card = (props) => {
  const { card, isPosition } = props;

  const isVacant = useMemo(() => {
    return isPosition && !card.has_incumbent;
  }, [isPosition, card.position_code]);

  return (
    <div className={styles.talentCard}>
      <div className={styles.info}>
        <div className={styles.row1}>
          {!isVacant ? (
            <>
              <img src={card.image_url || avatar_not_found} alt="avatar" className={styles.avatar} />
              <div className={styles.basicInfo}>
                <div className={styles.line1}>
                  <div>
                    {card.birth_name || 'N/A'} ({card.top_talent_status || 'N/A'}, {card.age || 'N/A'})
                  </div>
                  {card.matching_percentage && <div className={styles.matchingPercentage}>{card.matching_percentage}%</div>}
                </div>
                <div className={styles.line2}>
                  {card.position_name || 'N/A'}, {card.business_unit || 'N/A'}
                </div>
                <div className={styles.line3}>
                  {card.division || 'N/A'} | {card.opu_name || 'N/A'}
                </div>
              </div>
            </>
          ) : (
            <div className={styles.vacant}>
              <div>VACANT</div>
              <div className={styles.line2}>
                {card.position_name || 'N/A'}, {card.business_unit || 'N/A'}
              </div>
              <div className={styles.line3}>
                {card.division || 'N/A'} | {card.opu_name || 'N/A'}
              </div>
            </div>
          )}
        </div>
        {isVacant && (
          <div className={styles.vacantJg}>
            <div>JG: {card.job_grade || 'N/A'}</div>
          </div>
        )}
        {!isVacant && (
          <div className={styles.row2}>
            <div>
              <div>JG</div>
              <span>{card.job_grade || 'N/A'}</span>
            </div>
            <div>
              <div>SG</div>
              <span>
                {card.sg || 'N/A'} ({card.date_in_sg || 'N/A'})
              </span>
            </div>
            <div>
              <div>Retirement</div>
              <span>{card.retirement_or_contract || 'N/A'}</span>
            </div>
          </div>
        )}
        {!isPosition && (
          <div className={styles.successorSection}>
            <div>SUCCESSOR</div>
            {card?.successors?.map((successor, index) => (
              <div key={successor} className={styles.successor}>
                <div className="d-flex align-items-center">
                  <span>{index + 1}</span> {successor}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      {card?.additional_input && <div className={styles.additionalInput}>{card.additional_input}</div>}
    </div>
  );
};

Card.propTypes = {
  card: PropTypes.object,
  isPosition: PropTypes.bool,
  recommendedIndex: PropTypes.number,
};

const ConnectLineMultipleMobility = ({ index, rowIndex, maxLength, talentLength, positionLength }) => {
  return (
    <>
      {index === 0 && rowIndex === 0 && <img src={mobility_arrow_1} alt="connect-arrow" className={styles.connector} />}
      {rowIndex === 0 && (
        <>
          {index === 0 && <img src={mobility_arrow_1} alt="connect-arrow" className={styles.connector} />}
          {index > 0 && index < maxLength - 1 && <div className={styles.leftConnect} />}
        </>
      )}
      {index === 0 && rowIndex === 0 && maxLength > MIN_MULTIPLE_CARD_FIRST_ROW && (
        <>
          {talentLength < positionLength ? (
            <div className={`${styles.lineBetweenFirst} ${styles.left}`} />
          ) : (
            <div className={`${styles.lineBetweenFirst} ${styles.right}`} />
          )}
        </>
      )}
      {index > 0 && index < maxLength - 1 && <div className={`${styles.lineBetween} ${rowIndex === 0 ? styles.left : styles.right}`} />}
      {index > 0 && index === maxLength - 1 && (
        <div className={`${styles.lineBetweenLast} ${rowIndex === 0 ? styles.left : styles.right}`}>
          <span />
        </div>
      )}
      {rowIndex === 1 && (
        <>
          {index > 0 && index < maxLength - 1 && (
            <div className={styles.rightConnect}>
              <span />
            </div>
          )}
        </>
      )}
    </>
  );
};
ConnectLineMultipleMobility.propTypes = {
  index: PropTypes.number,
  rowIndex: PropTypes.number,
  maxLength: PropTypes.number,
  talentLength: PropTypes.number,
  positionLength: PropTypes.number,
};

const getSortedCards = (cards, type) => {
  return cards.filter((card) => card.type === type).sort((a, b) => a.order - b.order);
};

const notHasAddCard = (mainCards, otherCards) => {
  return (
    (mainCards.length === MIN_MULTIPLE_CARD_FIRST_ROW && !otherCards.length) ||
    otherCards.length > MIN_MULTIPLE_CARD_FIRST_ROW ||
    mainCards.length === MAX_CARD
  );
};
