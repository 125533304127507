import React, { useState } from 'react';
import * as styles from './last-step-published.module.scss';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import { BreadCrumb } from '../../../components';
import { homepage_icon, paperForward } from '../../../assets/img';
import { Col, Row, Spin } from 'antd';
import { BasicButton } from '../../../assets/common';
import { pdcMeetingApi } from '../../../services/pdcMeetingApi';
import { MEETING_DETAIL_STATUS, MESSAGE_TYPES } from '../../../utils/constants';
import { useGetPDCMeeting } from '../../../hooks/useGetPDCMeeting';
import { useDispatch, useSelector } from 'react-redux';
import { pushMessage } from '../../../store/alertMessageSlice';

const breadCrumbList = [{ name: 'Homepage', icon: homepage_icon, url: '/homepage' }, { name: 'PDC Meeting', url: '/pdc' }, { name: 'Summary' }];

const LastStepPublished = () => {
  const { idMeeting } = useParams();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const { data } = useGetPDCMeeting({ idMeeting });
  const roleActive = useSelector((state) => state.user.roleActive);

  const handleStartMeeting = async () => {
    try {
      setLoading(true);
      const res = await pdcMeetingApi.updateMeetingStatus(idMeeting, MEETING_DETAIL_STATUS.IN_PROGRESS, roleActive?.roleId);
      if (res.status === 200) {
        dispatch(
          pushMessage({
            type: MESSAGE_TYPES.SUCCESS,
            message: `${data.meeting_name} is started!`,
            show1Time: true,
            timeShow: 0,
          })
        );
        history.push(`/pdc-meeting/${idMeeting}`);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleBackToCalendar = () => {
    history.push('/pdc');
  };

  return (
    <>
      <BreadCrumb level={6} breadCrumbList={breadCrumbList} />
      <Spin spinning={loading}>
        <div className={styles.wrapper}>
          <Row>
            <Col span={12}>
              <p className={styles.title}>Great! Meeting has been published and invitation has been sent!</p>
              <p className={styles.desc}>Would you like to start the meeting now? or you can back to Home menu.</p>
              <BasicButton border="1px solid #E9EDF0" onClick={handleBackToCalendar}>
                Back to Calendar
              </BasicButton>
              <BasicButton mode="teal" className="ml-2" onClick={handleStartMeeting} disabled={data.status !== MEETING_DETAIL_STATUS.PUBLISHED}>
                Start Meeting Now
              </BasicButton>
            </Col>
            <Col span={12}>
              <img src={paperForward} alt="paperForward" />
            </Col>
          </Row>
        </div>
      </Spin>
    </>
  );
};

export default LastStepPublished;
