import { Spin } from 'antd';
import { useFormik } from 'formik';
import { isEmpty, size } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import * as yup from 'yup';
import { BasicInput, BasicLabel } from '../../../assets/common';
import { AsyncMultipleSelect, BreadCrumb, ModelTC } from '../../../components';
import GlobalAlertMessage from '../../../components/GlobalAlertMessage/GlobalAlertMessage';
import { isCheckError, onChangeSelectData, showError } from '../../../hooks/useFormik';
import { useShowPopupSaveAgenda } from '../../../hooks/useShowPopupSaveAgenda';
import { useUpdatePdcMeetingStepNumber } from '../../../hooks/useUpdatePdcMeetingStepNumber';
import { pdcMeetingApi } from '../../../services/pdcMeetingApi';
import { pushMessage } from '../../../store/alertMessageSlice';
import {
  FIELD_STYLE_DISABLED,
  FILL_OUT_REQUIRE_MESSAGE,
  MESSAGE_TYPES,
  NOTIFICATION_TYPE,
  PDC_PROPOSAL_TYPES,
  USER_ACCESS_MESSAGE,
} from '../../../utils/constants';
import { getAsyncOptionAdvocatorsHrPartners, getBreadCrumbList, getPdcMobilityProposalType } from '../../../utils/helper';
import { showNotification } from '../../../utils/notification';
import FirstStepMobilitySection from '../components/FirstStepMobilitySection/FirstStepMobilitySection';
import MobilityBotButtonControls from '../components/MobilityButtonControls/MobilityBotButtonControls';
import MobilityTopButtonControls from '../components/MobilityButtonControls/MobilityTopButtonControls';
import MobilityFinalizeBar from '../components/MobilityFinalizeBar/MobilityFinalizeBar';
import PdcMobilityProcessBar from '../components/PdcMobilityProcessBar/PdcMobilityProcessBar';
import { INIT_PDC_MOBILITY_PROPOSAL, PDC_SAVE_FOR } from '../constants';
import * as styles from './create-edit-pdc-mobility.module.scss';

const BREAD_CRUMB = getBreadCrumbList(['Homepage', 'PDC Meeting', 'Mobility Agenda', 'Mobility']);
const INIT_DETAILS = { title: '', advocators: [], hr_partners: [], proposals: [] };

const CreateEditPdcMobility = (props) => {
  const { idMeeting, mobilityId } = useParams();

  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState(INIT_DETAILS);
  const [isSaved, setIsSaved] = useState(false);
  const [isEditingProposal, setIsEditingProposal] = useState(false);
  const [selectedProposal, setSelectedProposal] = useState(INIT_PDC_MOBILITY_PROPOSAL);
  const [fetched, setFetched] = useState(true);

  // Get the condition to edit mobility agenda
  const {
    isOnlyViewAgenda,
    isShowTopButtons,
    isShowBotButtons,
    isAdditionalViewAccess,
    meetingDetail,
    mobilityDetails,
    isEnableMobilityTab,
    fetchMobilityDetails,
    isShownMobilityTabBar,
    isHRPartners,
    isChairMainOrCommiteeOrAdvocator,
  } = props;

  // Fetch api to update step number
  const { updateMeetingStepNumber } = useUpdatePdcMeetingStepNumber();

  const history = useHistory();
  const { roleId } = useSelector((state) => state.user.roleActive);
  const dispatch = useDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: details.title || '',
      advocators: details.advocators?.map((ad) => ({ label: ad.name, value: ad.id })) || [],
      hr_partners: details.hr_partners?.map((hr) => ({ label: hr.name, value: hr.id })) || [],
      status: details.status,
      step: details.step || 1,
    },
    validationSchema: yup.object().shape({
      title: yup.string().required('Title is required.'),
      advocators: yup.array().min(1, 'Advocators is required.'),
      hr_partners: yup.array().min(1, 'HR Partners is required.'),
    }),
    onSubmit: async (values) => {
      return values;
    },
  });

  const isChangeTitle = useMemo(() => {
    return formik.values.title !== details.title;
  }, [formik.values.title, details.title]);
  const isChangeAdvocators = useMemo(() => {
    return checkChangesUsers(formik.values.advocators, details.advocators);
  }, [formik.values.advocators, details.advocators]);
  const isChangeHrPartners = useMemo(() => {
    return checkChangesUsers(formik.values.hr_partners, details.hr_partners);
  }, [formik.values.hr_partners, details.hr_partners]);

  const { isShowPopupSaveAgenda, handleKeepNavigate, setIsShowPopupSaveAgenda } = useShowPopupSaveAgenda(
    (isChangeTitle || isChangeAdvocators || isChangeHrPartners || isEditingProposal) && !isSaved
  );

  const updateSelectedMainProposal = (currentProposals) => {
    const only1Proposal = currentProposals.length === 1 ? currentProposals[0] : INIT_PDC_MOBILITY_PROPOSAL;
    const mainProposal = selectedProposal.name
      ? currentProposals.find((p) => p.name === selectedProposal.name)
      : currentProposals.find((p) => p.is_main);
    setSelectedProposal(mainProposal || only1Proposal);
  };

  const fetchDetail = useCallback(
    async ({ saveFor } = {}) => {
      if (!mobilityId) return;
      try {
        setLoading(true);
        setFetched(false);
        const res = await pdcMeetingApi.getPdcMobilityDetails(idMeeting, mobilityId);
        if (res.status === 200) {
          const result = res.data.result;
          setDetails(result);
          if (saveFor !== PDC_SAVE_FOR.SAVE_MOBILITY) {
            updateSelectedMainProposal(result.proposals);
            return;
          }
          setFetched(true);
        }
      } catch (error) {
        console.error(error);
        showNotification(USER_ACCESS_MESSAGE.AN_UNEXPECTED_ERROR, NOTIFICATION_TYPE.FAILED);
      } finally {
        setLoading(false);
      }
    },
    [mobilityId]
  );

  useEffect(() => {
    if (fetched && !mobilityId && selectedProposal.id && !isNaN(selectedProposal.id)) return;
    const newSelectedProposal = details.proposals.find(
      (p) => p.name === selectedProposal.name && p.type === selectedProposal.type && p.is_main === selectedProposal.is_main
    );
    if (newSelectedProposal) {
      setSelectedProposal(newSelectedProposal);
    }
  }, [fetched]);

  const handleSaveSuccessMessage = (saveFor, isEdit) => {
    switch (saveFor) {
      case PDC_SAVE_FOR.DELETE:
        return dispatch(
          pushMessage({
            type: MESSAGE_TYPES.SUCCESS,
            message: `Mobility Proposal has been successfully deleted.`,
            timeShow: 0,
          })
        );
      case PDC_SAVE_FOR.SAVE_MOBILITY:
        return dispatch(
          pushMessage({
            type: MESSAGE_TYPES.SUCCESS,
            message: isEdit ? `Mobility Proposal has been successfully edited.` : `Mobility Proposal has been successfully saved.`,
            timeShow: 0,
          })
        );
      case PDC_SAVE_FOR.NEXT:
      case PDC_SAVE_FOR.EXIT:
        return;

      default:
        return dispatch(
          pushMessage({
            type: MESSAGE_TYPES.SUCCESS,
            message: `Your changes has been saved successfully.`,
            timeShow: 0,
          })
        );
    }
  };

  const handleSaveAsDraft = async ({ proposals, saveFor, isEdit } = {}) => {
    const formValue = await formik.submitForm();
    if (!formValue || (isEditingProposal && saveFor !== PDC_SAVE_FOR.SAVE_MOBILITY)) {
      dispatch(
        pushMessage({
          type: MESSAGE_TYPES.ERROR,
          message: FILL_OUT_REQUIRE_MESSAGE,
          timeShow: 0,
        })
      );
      return false;
    }
    const bodyProposal = proposals || details.proposals;
    const body = { ...formValue, proposals: mapBodyProposals(bodyProposal) };
    try {
      setLoading(true);
      const res = await (mobilityId ? pdcMeetingApi.updatePdcMobility(mobilityId, body) : pdcMeetingApi.createPdcMobility(idMeeting, body));
      if (res.status === 200) {
        setIsSaved(true);
        handleSaveSuccessMessage(saveFor, isEdit);

        if (Number(meetingDetail?.on_step) < 2) {
          await updateMeetingStepNumber(idMeeting, 2);
        }

        if ([PDC_SAVE_FOR.DELETE, PDC_SAVE_FOR.NEXT, PDC_SAVE_FOR.EXIT].includes(saveFor)) {
          setLoading(false);
          const response = await pdcMeetingApi.getPdcMobilityDetails(idMeeting, mobilityId || res?.data?.result?.id);
          return saveFor === PDC_SAVE_FOR.NEXT ? response?.data?.result : true;
        }

        if (!mobilityId) {
          setTimeout(() => {
            history.push(`/pdc-meeting/${idMeeting}/mobility/${res?.data?.result?.id}`);
          });
        } else {
          fetchDetail({ saveFor });
        }
        return true;
      }
    } catch (error) {
      console.error(error);
      showNotification(USER_ACCESS_MESSAGE.AN_UNEXPECTED_ERROR, NOTIFICATION_TYPE.FAILED);
      setLoading(false);
      return false;
    }
  };

  const handleSaveBeforeExit = async () => {
    setIsShowPopupSaveAgenda(false);
    const isSaveSuccess = await handleSaveAsDraft({ saveFor: PDC_SAVE_FOR.EXIT });
    if (!isSaveSuccess) return;
    handleKeepNavigate();
  };

  const handleClickNext = async () => {
    const data = await handleSaveAsDraft({ saveFor: PDC_SAVE_FOR.NEXT });
    const proposalMainType = getPdcMobilityProposalType(data);
    if (!proposalMainType || !data?.main_proposal_id) return;
    const isLength = size(details.proposals.find((i) => i?.is_main)?.cards);
    if (proposalMainType === PDC_PROPOSAL_TYPES.MULTIPLE_POSITION && isLength > 1) {
      history.push(
        `/pdc-meeting/${idMeeting}/mobility/${details?.id || data?.id}/position-proposal/${details?.main_proposal_id || data?.main_proposal_id}`
      );
    } else if (proposalMainType === PDC_PROPOSAL_TYPES.MULTIPLE_TALENT && isLength > 1) {
      history.push(
        `/pdc-meeting/${idMeeting}/mobility/${details?.id || data?.id}/talent-proposal/${details?.main_proposal_id || data?.main_proposal_id}`
      );
    } else {
      history.push(`/pdc-meeting/${idMeeting}/mobility/${details?.id || data?.id}/proposal/${details?.main_proposal_id || data?.main_proposal_id}`);
    }
  };

  useEffect(() => {
    fetchDetail();
  }, [fetchDetail]);

  return (
    <>
      <div style={{ marginBottom: '15px' }}>
        <BreadCrumb level={4} breadCrumbList={BREAD_CRUMB} />
      </div>
      <GlobalAlertMessage style={{ marginBottom: '20px' }} />
      {isShowTopButtons && (
        <MobilityTopButtonControls
          idMeeting={idMeeting}
          mobilityId={mobilityId}
          mobilityDetails={mobilityDetails}
          fetchMobility={fetchMobilityDetails}
          isAdditionalViewAccess={isAdditionalViewAccess}
          isHRPartners={isHRPartners}
          isChairMainOrCommiteeOrAdvocator={isChairMainOrCommiteeOrAdvocator}
          meetingDetail={meetingDetail}
          getMainProposal={fetchDetail}
        />
      )}
      {isShownMobilityTabBar ? (
        <MobilityFinalizeBar mobilityDetails={mobilityDetails} />
      ) : (
        <PdcMobilityProcessBar currentStep={details.step || 1} mobilityDetails={details} isEnableMobilityTab={isEnableMobilityTab} />
      )}

      <Spin spinning={loading}>
        <div className={styles.firstForm}>
          <div className={styles.title}>Proposal Session Details</div>
          <div>
            <BasicLabel required>Title</BasicLabel>
            <BasicInput
              placeholder="Enter Mobility Proposal Session Title"
              defaultValue={formik.values.title}
              onBlur={(e) => onChangeSelectData(formik, e.target.value, 'title')}
              error={isCheckError(formik, 'title')}
              disabled={isOnlyViewAgenda || isHRPartners}
              style={isOnlyViewAgenda || isHRPartners ? FIELD_STYLE_DISABLED : null}
            />
            {showError(formik, 'title')}
          </div>
          <div>
            <BasicLabel required>Advocators</BasicLabel>
            <AsyncMultipleSelect
              value={formik.values.advocators}
              placeholder="Enter Advocators Name"
              loadOptions={(e) => getAsyncOptionAdvocatorsHrPartners(e, roleId)}
              onChange={(e) => onChangeSelectData(formik, e, 'advocators')}
              status={isCheckError(formik, 'advocators') ? 'error' : ''}
              isDisabled={isOnlyViewAgenda || isHRPartners}
              pageName={'PDC_MOBILITY'}
            />
            {showError(formik, 'advocators')}
          </div>
          <div>
            <BasicLabel required>HR Partners</BasicLabel>
            <AsyncMultipleSelect
              value={formik.values.hr_partners}
              placeholder="Enter HR Partners Name"
              loadOptions={(e) => getAsyncOptionAdvocatorsHrPartners(e, roleId)}
              onChange={(e) => onChangeSelectData(formik, e, 'hr_partners')}
              status={isCheckError(formik, 'hr_partners') ? 'error' : ''}
              isDisabled={isOnlyViewAgenda || isHRPartners}
              pageName={'PDC_MOBILITY'}
            />
            {showError(formik, 'hr_partners')}
          </div>
        </div>

        <FirstStepMobilitySection
          proposal={selectedProposal}
          setProposal={setSelectedProposal}
          details={details}
          setDetails={setDetails}
          saveDetails={handleSaveAsDraft}
          isEditingProposal={isEditingProposal}
          setIsEditingProposal={setIsEditingProposal}
          isOnlyViewAgenda={isOnlyViewAgenda || isHRPartners}
        />

        {(isShowBotButtons || !mobilityId) && (
          <MobilityBotButtonControls
            idMeeting={idMeeting}
            mobilityId={mobilityId}
            isDisabled={false}
            handleSaveAsDraft={handleSaveAsDraft}
            mobilityDetails={mobilityDetails}
            fetchMobility={fetchMobilityDetails}
            handleClickNext={handleClickNext}
            isOnlyViewAgenda={isOnlyViewAgenda}
            isHRPartners={isHRPartners}
            isAdditionalViewAccess={isAdditionalViewAccess}
            isChairMainOrCommiteeOrAdvocator={isChairMainOrCommiteeOrAdvocator}
            meetingDetail={meetingDetail}
            getMainProposal={fetchDetail}
          />
        )}
      </Spin>

      <ModelTC
        info={{
          type: 'withoutSaving',
          visible: isShowPopupSaveAgenda,
          setVisible: setIsShowPopupSaveAgenda,
          onClose: handleKeepNavigate,
          handleSubmit: handleSaveBeforeExit,
        }}
      />
    </>
  );
};

export default CreateEditPdcMobility;

const checkChangesUsers = (currentUsers, fetchUsers) => {
  if (currentUsers.length !== fetchUsers.length) return true;
  const currentUsersIds = currentUsers.map((u) => u.value).sort();
  const fetchUsersIds = fetchUsers.map((u) => u.id).sort();
  return currentUsersIds.toString() !== fetchUsersIds.toString();
};

const mapBodyProposals = (proposals) => {
  return proposals.map((proposal) => {
    return {
      ...proposal,
      id: isNaN(proposal.id) ? null : proposal.id,
      cards: proposal.cards.map((card, index) => ({
        id: card.id || null,
        staff_id: card.staff_id,
        position_code: card.position_code,
        order: index + 1,
        type: card.type,
        successors: !isEmpty(card?.successors) ? card.successors.join(',') : '',
        additional_input: card.additional_input,
      })),
    };
  });
};
