import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { avatar, close_modal, deleteIcon, green_plus_icon } from '../../assets/img';
import { handleShowCTForProposal } from '../../utils/helper';
import Spinner from '../Loading/Spinner';
import styles from './successor-comparison.module.scss';
import SearchSuccessorComparison from '../SearchSuccessorComparison/SearchSuccessorComparison';
import { cloneDeep, isArray, isEmpty, size } from 'lodash';
import styled from 'styled-components';
import UserCareerTable from '../../pages/PDCMeeting/PdcProposalList/components/UserCareerTable/UserCareerTable';
import { Checkbox, Input, Modal, Pagination } from 'antd';
import { BasicButton } from '../../assets/common';
import { pushMessage } from '../../store/alertMessageSlice';
import GlobalAlertMessage from '../GlobalAlertMessage/GlobalAlertMessage';
import { CustomNumber, Text } from '../ComplementaryExperience/complementaryExperienceStyled';
import { pdcMeetingApi } from '../../services/pdcMeetingApi';
import AddNewExperience from './AddNewExperience/AddNewExperience';
import { ModelTC } from '../Model';
import RichTextEditor from '../RichTextEditor/RichTextEditor';

const SuccessorComparison = (props) => {
  const { isPopupTalentComparison, setIsPopupTalentComparison, currentTalent, proposalList, isOnlyViewAgenda, fetchProposalList } = props;
  const dispatch = useDispatch();
  const userInfos = useSelector((state) => state.user);
  const { roleId } = useSelector((state) => state.user.roleActive);
  const [data, setData] = useState({
    limit: 3,
    total: 0,
    page: 1,
  });
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(true);
  const [compareData, setCompareData] = useState({
    initData: [],
    currentData: [],
  });
  const [isShowPopupConfirm, setIsShowPopupConfirm] = useState(false);

  useEffect(() => {
    setCompareData(() => ({
      initData: !isEmpty(proposalList) ? cloneDeep(proposalList) : [],
      currentData: !isEmpty(proposalList) ? cloneDeep(proposalList) : [],
    }));
  }, [proposalList]);

  const isChangeSuccessorList = useMemo(() => {
    return JSON.stringify(compareData.initData) !== JSON.stringify(compareData.currentData);
  }, [compareData]);

  // Init current talent to compare with new talent
  const compareItem = useMemo(() => {
    return compareData.currentData.filter((item) => item.staff_id === currentTalent.staff_id)[0];
  }, [compareData.currentData]);

  // Update pagination
  useEffect(() => {
    const tempLength = compareItem?.proposal_compare_successors?.length;
    setData((prev) => ({
      ...prev,
      total: tempLength,
      limit: tempLength === 4 ? 2 : 3,
    }));
  }, [compareItem]);

  const fetchTalentDetails = async (record) => {
    const talents = !isEmpty(compareItem?.proposal_compare_successors) ? cloneDeep(compareItem?.proposal_compare_successors) : [];
    const listIdTalent = !isEmpty(talents) ? talents.map((talent) => talent.staffId) : [];
    if (listIdTalent.includes(record?.staffId)) {
      dispatch(
        pushMessage({
          message: `Talent has already been added`,
          type: 'error',
          timeShow: 5000,
          containerId: 'SUCCESSOR_COMPARISION',
        })
      );
      return;
    }
    try {
      setLoading(true);
      const response = await pdcMeetingApi.getStaffDetails(record?.staffId || record?.staff_id);
      if (response.status === 200) {
        const talentDetails = isArray(response?.data?.result) ? response?.data?.result : [];
        const newTalentList = !isEmpty(compareItem?.proposal_compare_successors)
          ? [...cloneDeep(compareItem?.proposal_compare_successors), ...talentDetails]
          : talentDetails;
        setCompareData((prev) => ({
          ...prev,
          currentData: prev.currentData.map((item) => {
            if (item?.staff_id === compareItem?.staff_id) {
              return {
                ...item,
                proposal_compare_successors: newTalentList,
              };
            } else {
              return item;
            }
          }),
        }));
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const addTalent = async (val) => {
    if (!val?.staffId) return;
    await fetchTalentDetails(val);
  };

  const onDeleteProfile = (item) => {
    const index = compareItem?.proposal_compare_successors.findIndex(
      (talent) => (talent?.staff_id || talent?.staffId) === (item?.staff_id || item?.staffId)
    );
    if (index > -1) {
      const talentList = cloneDeep(compareItem?.proposal_compare_successors) || [];
      const result = talentList.filter((talent) => (talent?.staff_id || talent?.staffId) !== (item?.staff_id || item?.staffId));
      setData((prev) => ({
        ...prev,
        page: result?.length !== 4 && result?.length % 3 === 0 && prev?.page > 1 ? prev?.page - 1 : prev?.page,
      }));
      setCompareData((prev) => ({
        ...prev,
        currentData: prev.currentData.map((item) => {
          if ((item?.staff_id || item?.staffId) === (compareItem?.staff_id || compareItem?.staffId)) {
            return {
              ...item,
              proposal_compare_successors: result,
            };
          } else {
            return item;
          }
        }),
      }));
    }
  };

  const fromResult = (data.page - 1) * data.limit + 1;
  const toResults = data.page * data.limit;

  const getExperiencesToUpdate = (items, checkedItems) => {
    const tempCheckedItems = !isEmpty(checkedItems) ? checkedItems.map((i) => i.value) : [];
    let tempItems = [];
    if (!isEmpty(items)) {
      tempItems = items.map((item) => {
        if (tempCheckedItems.includes(item?.value)) {
          return { ...item, checked: true };
        } else {
          return { ...item, checked: false };
        }
      });
    }
    return tempItems;
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const data = !isEmpty(compareItem?.proposal_compare_successors)
        ? compareItem?.proposal_compare_successors.map((item, index) => ({
            mobility_id: compareItem?.mobility_id,
            staff_id_compare: compareItem?.staff_id,
            staff_id: item?.staffId || item?.staff_id,
            experiences: getExperiencesToUpdate(item?.experiences, item?.experiences_checked),
            keystrength: item?.keystrength || '',
            dev_area: item?.dev_area || '',
            order: index + 1,
          }))
        : [];
      const res = await pdcMeetingApi.createOrUpdateSuccessorList({ data, roleId, userInfos });
      if (res.status === 200) {
        await fetchProposalList();
        dispatch(
          pushMessage({
            message: `Your changes has been saved successfully`,
            type: 'success',
            timeShow: 5000,
            containerId: 'SUCCESSOR_COMPARISION',
          })
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = async () => {
    if (isChangeSuccessorList) {
      setIsShowPopupConfirm(true);
      return;
    }

    setCompareData({
      initData: [],
      currentData: [],
    });
    setIsPopupTalentComparison(null);
  };

  const handleSaveAndExit = async () => {
    await handleSave();
    setIsPopupTalentComparison(null);
  };

  const handleSaveBeforeExit = async () => {
    setIsShowPopupConfirm(false);
    await handleSave();
    setCompareData({
      initData: [],
      currentData: [],
    });
    setIsPopupTalentComparison(null);
  };

  const handleCloseBeforeExit = () => {
    setCompareData({
      initData: [],
      currentData: [],
    });
    setIsShowPopupConfirm(false);
    setIsPopupTalentComparison(null);
  };

  return (
    <>
      <CustomModal open={isPopupTalentComparison} width={1250} footer={null} onCancel={handleCloseBeforeExit}>
        <div className={styles.modalWrapper}>
          <div className={styles.modal_header}>
            <h1 data-testid="view-talent-comparison-title">
              {`Successors for the position ${
                compareItem?.position_name ? `of ${compareItem?.position_name} ${compareItem?.opu_name ? `, ${compareItem?.opu_name}` : ''}` : ''
              }`}
            </h1>
          </div>

          <GlobalAlertMessage style={{ marginBottom: '20px' }} id={'SUCCESSOR_COMPARISION'} />

          {isEdit && !isOnlyViewAgenda && (
            <SearchWrapper>
              <div className="d-flex">
                <div className="col-6 p-0" style={{ maxWidth: '520px' }}>
                  <SearchSuccessorComparison placeholder="Search Talent" addTalent={addTalent} />
                </div>
              </div>
            </SearchWrapper>
          )}

          <CustomModalContent>
            {compareItem?.proposal_compare_successors?.length > 0 &&
              compareItem?.proposal_compare_successors
                .map((i, indx) => ({ ...i, recordNo: indx }))
                .slice(fromResult - 1, toResults)
                .map((talent) => (
                  <TalentItem
                    key={talent?.staffId || talent?.staff_id}
                    record={{ ...talent }}
                    onDeleteProfile={onDeleteProfile}
                    setIsEdit={setIsEdit}
                    isEdit={isEdit}
                    compareItem={compareItem}
                    isOnlyViewAgenda={isOnlyViewAgenda}
                    setCompareData={setCompareData}
                  />
                ))}
          </CustomModalContent>

          <CustomModalFooter flex="between">
            <Text>
              Showing <CustomNumber>{fromResult}</CustomNumber> to <CustomNumber>{toResults > data.total ? data.total : toResults}</CustomNumber> of{' '}
              <CustomNumber>{data.total}</CustomNumber> results
            </Text>
            <Pagination
              style={!isEdit ? { opacity: 0.5, cursor: 'no-drop' } : { opacity: 1 }}
              current={data.page}
              pageSize={data.limit}
              total={data.total}
              onChange={(page) => {
                if (!isEdit) return;
                setData((prev) => ({ ...prev, page }));
              }}
              data-testid="pagination"
            />
          </CustomModalFooter>
          <CustomModalFooter>
            {!isEdit && (
              <BasicButton width="100px" mode="teal" onClick={() => setIsEdit(true)}>
                Edit
              </BasicButton>
            )}
            {isEdit && !isOnlyViewAgenda && (
              <>
                <BasicButton onClick={handleCancel}>Cancel</BasicButton>
                <BasicButton width="100px" mode="teal" onClick={handleSave}>
                  Save
                </BasicButton>
                <BasicButton width="110px" mode="teal" onClick={handleSaveAndExit}>
                  Save & Exit
                </BasicButton>
              </>
            )}
          </CustomModalFooter>
        </div>
        {loading && (
          <LoadingWrapper>
            <Spinner />
          </LoadingWrapper>
        )}
      </CustomModal>
      <ModelTC
        info={{
          type: 'withoutSaving',
          visible: isShowPopupConfirm,
          setVisible: setIsShowPopupConfirm,
          onClose: handleCloseBeforeExit,
          handleSubmit: handleSaveBeforeExit,
        }}
      />
    </>
  );
};

const TalentItem = (props) => {
  const { record, onDeleteProfile, isEdit, compareItem, isOnlyViewAgenda, setCompareData } = props;
  const [showExperienceModal, setShowExperienceModal] = useState(false);

  const cloneListExperience = useMemo(() => {
    return !isEmpty(record?.experiences) ? record.experiences : [];
  }, [record]);

  const cloneListExperienceChecked = useMemo(() => {
    return !isEmpty(record?.experiences_checked) ? record.experiences_checked : [];
  }, [record]);

  const onChangeExperience = (checkedValues) => {
    setCompareData((prev) => ({
      ...prev,
      currentData: prev.currentData.map((i) => {
        if (i?.staff_id === compareItem.staff_id) {
          return {
            ...i,
            proposal_compare_successors: i?.proposal_compare_successors.map((successor) => {
              if (successor?.staffId === record?.staffId) {
                return {
                  ...successor,
                  experiences_checked: checkedValues,
                };
              } else {
                return successor;
              }
            }),
          };
        } else {
          return i;
        }
      }),
    }));
  };

  return (
    <TalentItemWrap
      style={{
        width: '100%',
        flex: '1 1 0%',
      }}
    >
      <div className="topHead">
        <span className="title">Successor #{record?.recordNo + 1} Talent Information</span>
        {isEdit && compareItem?.proposal_compare_successors?.length > 1 && !isOnlyViewAgenda && (
          <img src={close_modal} className={`deleteIcon`} onKeyDown={() => {}} onClick={() => onDeleteProfile(record)} alt="delete" />
        )}
      </div>

      <div className="contentWrap">
        <div className="talentInfo">
          <div className="sectionItem firstSection">
            <div className="avatar">
              <img src={record?.image ? record?.image : avatar} alt="avatar_profile" width="137" height="137" style={{ borderRadius: '100px' }} />
            </div>

            <div className="name">
              <div className="talentName">{`${record?.birthName || ''} ${
                record?.talentCategory ? `(${handleShowCTForProposal(record?.talentCategory)})` : ''
              }`}</div>
              <div className="positionName">{`${record?.currentPosition?.position_name} ${
                record?.currentPosition?.business_unit ? `, ${record?.currentPosition?.business_unit}` : ''
              }`}</div>
            </div>
          </div>

          <div className="sectionItem talentDetail">
            <div className="subTitle">Talent Details</div>
            <div className="fieldItem">
              <span className={'greyLabel'}>{'Age'}</span>
              <span className={'normalText'}>{record?.age || '-'}</span>
            </div>
            <div className="fieldItem">
              <span className={'greyLabel'}>{'Retirement Date'}</span>
              <span className={'normalText'}>{record?.retirementDate || '-'} </span>
            </div>
            <div className="fieldItem">
              <span className={'greyLabel'}>{'JG (YIP)'}</span>
              <span className={'normalText'}>{`${record?.jg} (${record?.years_in_position})`}</span>
            </div>
            <div className="fieldItem">
              <span className={'greyLabel'}>{'SG (YiSG)'}</span>
              <span className={'normalText'}>{`${record?.sg} (${record?.yearsInSg})`}</span>
            </div>
            <div className="fieldItem">
              <span className={'greyLabel'}>{`BePCB ${!isEmpty(record?.rating) ? `'${record.rating[0].year.slice(2)}` : ''}`}</span>
              <span className={'normalText'}>{!isEmpty(record?.rating) ? record.rating[0].description : '-'}</span>
            </div>
          </div>

          <div className="sectionItem">
            <UserCareerTable data={record?.rating || []} isSearchTalent={true} type={'SUCCESSOR'} />
          </div>
        </div>
        <div className="sectionItem">
          <div className="subTitle">Experience</div>
          {!isEmpty(cloneListExperience) && (
            <CustomCheckboxGroup key={record?.staff_id} onChange={onChangeExperience} value={cloneListExperienceChecked}>
              {cloneListExperience.map((item) => (
                <CustomCheckbox key={item?.value} value={item} disabled={!isEdit || isOnlyViewAgenda}>
                  <LabelWraper>
                    <CustomInput
                      key={item?.value}
                      disabled={!isEdit || isOnlyViewAgenda}
                      defaultValue={item.label}
                      placeholder="Enter experience"
                      onChange={(event) => {
                        const tempVal = event?.target?.value;
                        setCompareData((prev) => ({
                          ...prev,
                          currentData: prev.currentData.map((i) => {
                            if (i?.staff_id === compareItem.staff_id) {
                              return {
                                ...i,
                                proposal_compare_successors: i?.proposal_compare_successors.map((successor) => {
                                  if (successor?.staffId === record?.staffId) {
                                    return {
                                      ...successor,
                                      experiences: !isEmpty(successor?.experiences)
                                        ? successor?.experiences.map((ex) => {
                                            if (ex.value === item.value) {
                                              return { value: ex.value, label: tempVal };
                                            } else {
                                              return ex;
                                            }
                                          })
                                        : [],
                                      experiences_checked: !isEmpty(successor?.experiences_checked)
                                        ? successor?.experiences_checked.map((ex1) => {
                                            if (ex1.value === item.value) {
                                              return { value: ex1.value, label: tempVal };
                                            } else {
                                              return ex1;
                                            }
                                          })
                                        : [],
                                    };
                                  } else {
                                    return successor;
                                  }
                                }),
                              };
                            } else {
                              return i;
                            }
                          }),
                        }));
                      }}
                    />
                    {size(cloneListExperience) > 0 && isEdit && !isOnlyViewAgenda && (
                      <img
                        style={{ cursor: 'pointer' }}
                        src={deleteIcon}
                        alt=""
                        onClick={() => {
                          setCompareData((prev) => ({
                            ...prev,
                            currentData: prev.currentData.map((i) => {
                              if (i?.staff_id === compareItem.staff_id) {
                                return {
                                  ...i,
                                  proposal_compare_successors: i?.proposal_compare_successors.map((successor) => {
                                    if (successor?.staffId === record?.staffId) {
                                      return {
                                        ...successor,
                                        experiences: !isEmpty(successor?.experiences)
                                          ? successor?.experiences.filter((ex) => ex.value !== item.value)
                                          : [],
                                        experiences_checked: !isEmpty(successor?.experiences_checked)
                                          ? successor?.experiences_checked.filter((ex1) => ex1.value !== item.value)
                                          : [],
                                      };
                                    } else {
                                      return successor;
                                    }
                                  }),
                                };
                              } else {
                                return i;
                              }
                            }),
                          }));
                        }}
                      />
                    )}
                  </LabelWraper>
                </CustomCheckbox>
              ))}
            </CustomCheckboxGroup>
          )}
          {isEdit && !isOnlyViewAgenda && (
            <CustomBtn
              onClick={() => {
                setShowExperienceModal(true);
              }}
            >
              <img src={green_plus_icon} alt="" />
              Add Experience
            </CustomBtn>
          )}
        </div>
        <AddNewExperience setIsVisible={setShowExperienceModal} isVisible={showExperienceModal} setCompareData={setCompareData} />
        <div className="sectionItem">
          <div className="subTitle">Leadership Insights</div>
          <div className="fieldItem mb-12">
            <div className={'smallTitle mb-8'}>
              <span>{'Strengths'}</span>
            </div>
            <RichTextEditor
              setFieldVal={(value) => {
                if (!isEdit || isOnlyViewAgenda) return;
                setCompareData((prev) => ({
                  ...prev,
                  currentData: prev.currentData.map((i) => {
                    if (i?.staff_id === compareItem.staff_id) {
                      return {
                        ...i,
                        proposal_compare_successors: i?.proposal_compare_successors.map((successor) => {
                          if (successor?.staffId === record?.staffId) {
                            return {
                              ...successor,
                              keystrength: value,
                            };
                          } else {
                            return successor;
                          }
                        }),
                      };
                    } else {
                      return i;
                    }
                  }),
                }));
              }}
              fieldVal={record?.keystrength || ''}
              newStyles={{ minHeight: '120px', maxHeight: '165px' }}
              placeholder="Enter Strengths"
              maxLength={false}
              readOnly={!isEdit || isOnlyViewAgenda}
            />
          </div>

          <div className="fieldItem">
            <div className={'smallTitle mb-8'}>
              <span>{'Development Areas'}</span>
            </div>
            <RichTextEditor
              setFieldVal={(value) => {
                if (!isEdit || isOnlyViewAgenda) return;
                setCompareData((prev) => ({
                  ...prev,
                  currentData: prev.currentData.map((i) => {
                    if (i?.staff_id === compareItem.staff_id) {
                      return {
                        ...i,
                        proposal_compare_successors: i?.proposal_compare_successors.map((successor) => {
                          if (successor?.staffId === record?.staffId) {
                            return {
                              ...successor,
                              dev_area: value,
                            };
                          } else {
                            return successor;
                          }
                        }),
                      };
                    } else {
                      return i;
                    }
                  }),
                }));
              }}
              fieldVal={record?.dev_area || ''}
              newStyles={{ minHeight: '120px', maxHeight: '165px' }}
              placeholder="Enter Development Areas"
              maxLength={false}
              readOnly={!isEdit || isOnlyViewAgenda}
            />
          </div>
        </div>
      </div>
    </TalentItemWrap>
  );
};

const CustomModal = styled(Modal)`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0 !important;
  }
`;

const CustomModalContent = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  position: relative;
  min-height: 450px;
  gap: 16px;

  .smallTitle {
    color: #394655;
    font-family: var(--font-type-default, Inter);
    font-size: var(--font-size-body-sm, 14px);
    font-style: normal;
    font-weight: var(--font-weight-medium, 500);
    line-height: var(--font-line-height-body-sm, 20px); /* 142.857% */
    letter-spacing: var(--font-letter-spacing-body-sm, 0.25px);
  }
`;

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
`;

const CustomModalFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${(props) => (props.flex === 'between' ? 'space-between' : 'flex-end')};
  padding-top: 30px;

  button {
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const TalentItemWrap = styled.div`
  width: 50%;
  border: solid 1px #d3dee8;
  border-radius: 4px;

  .deleteIcon {
    cursor: pointer;
  }

  .topHead {
    position: relative;
    border-radius: 4px 4px 0px 0px;
    background: #d9f2f7;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;

    img {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 24px;
      height: 24px;
      padding: 5px;
    }
  }

  .contentWrap {
    padding: 24px;
  }

  .talentInfo {
    .avatar {
      margin-bottom: 10px;

      img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
      }
    }

    .talentName {
      color: var(--Emphasis-Body-N800, #3f3c4c);
      text-align: center;
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.3px;
      margin-bottom: 10px;
    }

    .positionName {
      color: var(--Emphasis-Body-N800, #3f3c4c);
      text-align: center;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.3px;
    }
  }

  .firstSection {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .talentDetail {
    .fieldItem {
      display: flex;
      justify-content: space-between;
    }
    .greyLabel {
      min-width: 120px;
    }
    .normalText {
      width: calc(100% -120px);
      text-align: right;
    }
  }

  .sectionItem {
    margin-bottom: 30px;
  }

  .fieldItem {
    margin-bottom: 5px;
  }

  .title {
    color: var(--Other-Colors-Typography, #000);
    text-align: center;
    font-family: var(--font-type-default, Inter);
    font-size: var(--font-size-body-md, 16px);
    font-style: normal;
    font-weight: var(--font-weight-bold, 700);
    line-height: var(--font-line-height-body-md, 24px); /* 150% */
    letter-spacing: var(--font-letter-spacing-body-md, 0.3px);
  }

  .subTitle {
    color: var(--Emphasis-Body-N800, #3f3c4c);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.3px;
    margin-bottom: 16px;
  }

  .greyLabel {
    color: #7d8c9f;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 171.429% */
  }

  .normalText {
    color: var(--Emphasis-Body-N800, #3f3c4c);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
  }

  .mb-8 {
    margin-bottom: 8px;
  }

  .mb-12 {
    margin-bottom: 12px;
  }
`;

const LabelWraper = styled.div`
  display: flex;
  align-items: center;
  transition: all 2s;

  img {
    visibility: hidden;
    width: 0;
  }

  &:hover {
    img {
      margin-left: 10px;
      visibility: visible;
      width: 16px;
    }
  }
`;

const CustomCheckboxGroup = styled(Checkbox.Group)`
  width: 100%;
  display: flex;
  flex-direction: column;

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0 !important;
  }
`;

const CustomCheckbox = styled(Checkbox)`
  margin-bottom: 15px;

  span {
    width: 100%;

    &.ant-checkbox {
      width: 16px;
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #00a19c;
    border-color: #00a19c;
  }
`;

const CustomInput = styled(Input)`
  &:disabled {
    border: none !important;
    background: transparent !important;
  }
  input {
    border-radius: 4px;
    border: 1px solid var(--Blue-Grays-100, #d3dee8);
    background: var(--General-White, #fff);

    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */

    &::placeholder {
      color: var(--Grays-300, #8d8d8d);
    }
  }
`;

const CustomBtn = styled.button`
  border: none;
  outline: none;
  color: #00615e;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
  letter-spacing: 0.173px;
  text-decoration-line: underline;
  background: transparent;

  img {
    margin-right: 10px;
  }
`;

const SearchWrapper = styled.div`
  margin-bottom: 20px;
`;

export default SuccessorComparison;
