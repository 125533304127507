import styled from 'styled-components';
import { font } from '../../../../assets/common';

export const Container = styled.div`
  padding: 24px 31px 24px 31px;
  gap: 32px;
  border-radius: 6px 0px 0px 0px;
  opacity: 0px;
  background-color: #fff;
`;

export const Title = styled.div`
  font-family: ${font.inter};
  font-size: 18px;
  font-weight: 700;
  line-height: 21.78px;
  letter-spacing: 0.30000001192092896px;
  text-align: left;
`;
