import { Table, Tooltip } from 'antd';
import { isArray, isDate, isEmpty, isPlainObject } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import {
  AUDIT_LOG_DIFFERENCE_VALUES,
  AUDIT_LOG_KEY_REPLACE,
  AUDIT_LOG_MODULE,
  AUDIT_LOG_REPLACE_VALUE,
  AUDIT_LOG_USER_ACCESS_VALUES,
  AUDIT_LOG_VALUES,
  AUDIT_TRAIL_ACTION,
} from '../../../../../utils/constants';
import { convertRoleAccessLevels } from '../../../../../utils/helper';
import * as styles from './data-table.module.scss';
import * as SC from './styled';

export default function DataTable({ data, mode, page, limit, onPageChange, totalData }) {
  const [convertedData, setConvertedData] = useState(data || []);
  const maxLengthForPerRow = 20;

  const convertDataMarkDifferent = (data) => {
    if (!isEmpty(data)) {
      const converted = data.map((item) => {
        if (item?.new_value && isJSON(item?.new_value) && item?.action === AUDIT_TRAIL_ACTION.CONCLUDE_MEETING) {
          const newValue = JSON.parse(item?.new_value);
          item.new_value = JSON.stringify({
            name: newValue?.name,
            start_at: newValue?.start_at,
            meeting_id: newValue?.meeting_id,
          });
        }
        if (item?.module === AUDIT_LOG_MODULE.UNSEARCHABLE_POSITION && (isJSON(item?.new_value) || isJSON(item?.old_value))) {
          if (item && item.new_value) {
            const newValue = JSON.parse(item?.new_value);
            item.new_value = JSON.stringify({
              position_code: newValue?.position_code,
              unsearchable_position_name: newValue?.position_name,
            });
          }

          if (item && item.old_value) {
            const oldValue = JSON.parse(item?.old_value);
            item.old_value = JSON.stringify({
              position_code: oldValue?.position_code,
              unsearchable_position_name: oldValue?.position_name,
            });
          }
        }
        if (
          item?.new_value &&
          isJSON(item?.new_value) &&
          item?.action === AUDIT_TRAIL_ACTION.ADD &&
          item?.module === AUDIT_LOG_MODULE.UNSEARCHABLE_TALENT
        ) {
          const newValue = JSON.parse(item?.new_value);
          item.new_value = JSON.stringify({
            staff_name: newValue?.staff_name,
            staff_id: newValue?.staff_id,
          });
        }

        if (
          item?.new_value &&
          isJSON(item?.new_value) &&
          item?.action === AUDIT_TRAIL_ACTION.DELETE &&
          item?.module === AUDIT_LOG_MODULE.UNSEARCHABLE_TALENT
        ) {
          const oldValue = JSON.parse(item?.old_value);
          item.new_value = JSON.stringify({
            staff_name: oldValue?.staff_name,
            staff_id: oldValue?.staff_id,
          });
        }

        if (item?.module === AUDIT_LOG_MODULE.SP_MANAGEMENT.SP_READINESS_RULE) {
          let newValue = null;
          let oldValue = null;
          if (item?.new_value) {
            newValue = JSON.parse(item?.new_value);
            if (newValue?.position_jg_min1) newValue.position_jg_min1 = 'P' + newValue.position_jg_min1;
            if (newValue?.position_jg_min2) newValue.position_jg_min2 = 'P' + newValue.position_jg_min2;
            if (newValue?.position_jg_min3) newValue.position_jg_min3 = 'P' + newValue.position_jg_min3;
            if (newValue?.position_jg_max1) newValue.position_jg_max1 = 'P' + newValue.position_jg_max1;
            if (newValue?.position_jg_max2) newValue.position_jg_max2 = 'P' + newValue.position_jg_max2;
            if (newValue?.position_jg_max3) newValue.position_jg_max3 = 'P' + newValue.position_jg_max3;
          }
          if (item?.old_value) {
            oldValue = JSON.parse(item?.old_value);
            if (oldValue?.position_jg_min1) oldValue.position_jg_min1 = 'P' + oldValue.position_jg_min1;
            if (oldValue?.position_jg_min2) oldValue.position_jg_min2 = 'P' + oldValue.position_jg_min2;
            if (oldValue?.position_jg_min3) oldValue.position_jg_min3 = 'P' + oldValue.position_jg_min3;
            if (oldValue?.position_jg_max1) oldValue.position_jg_max1 = 'P' + oldValue.position_jg_max1;
            if (oldValue?.position_jg_max2) oldValue.position_jg_max2 = 'P' + oldValue.position_jg_max2;
            if (oldValue?.position_jg_max3) oldValue.position_jg_max3 = 'P' + oldValue.position_jg_max3;
          }

          item.new_value = newValue ? JSON.stringify(newValue) : null;
          item.old_value = oldValue ? JSON.stringify(oldValue) : null;
        }

        if (
          item?.action === AUDIT_TRAIL_ACTION.EDIT &&
          item?.module === AUDIT_LOG_MODULE.SP_MANAGEMENT.SP_READINESS_RULE &&
          isJSON(item?.new_value) &&
          isJSON(item?.old_value)
        ) {
          let newValue = JSON.parse(item?.new_value);
          let oldValue = JSON.parse(item?.old_value);

          const changedValues = Object.keys(newValue).filter((key) => newValue[key] !== oldValue[key]);

          Object.keys(newValue).forEach(function (key, index) {
            if (!changedValues.includes(key) && !['role_code1', 'readiness1', 'readiness2', 'readiness3'].includes(key)) delete newValue[key];
          });
          Object.keys(oldValue).forEach(function (key, index) {
            if (!changedValues.includes(key) && !['role_code1', 'readiness1', 'readiness2', 'readiness3'].includes(key)) delete oldValue[key];
          });

          item.new_value = JSON.stringify(newValue);
          item.old_value = JSON.stringify(oldValue);
        }

        if (
          item?.action === AUDIT_TRAIL_ACTION.EDIT &&
          item?.module === AUDIT_LOG_MODULE.SP_MANAGEMENT.SP_READINESS_RULE &&
          isJSON(item?.new_value) &&
          isJSON(item?.old_value)
        ) {
          let newValue = JSON.parse(item?.new_value);
          let oldValue = JSON.parse(item?.old_value);

          const changedValues = Object.keys(newValue).filter((key) => newValue[key] !== oldValue[key]);
          const readinesExist = changedValues.map((item) => item.slice(-1));
          if (!readinesExist.includes('1')) {
            delete newValue.readiness1;
            delete oldValue.readiness1;
          }
          if (!readinesExist.includes('2')) {
            delete newValue.readiness2;
            delete oldValue.readiness2;
          }
          if (!readinesExist.includes('3')) {
            delete newValue.readiness3;
            delete oldValue.readiness3;
          }
          item.new_value = JSON.stringify(newValue);
          item.old_value = JSON.stringify(oldValue);
        }

        if (item?.new_value && item?.old_value && isJSON(item?.new_value) && isJSON(item?.old_value)) {
          let newObj = JSON.parse(item?.new_value);
          let oldObj = JSON.parse(item?.old_value);
          const changedValues = Object.keys(newObj).filter((key) => JSON.stringify(newObj[key]) !== JSON.stringify(oldObj[key]));
          if (isPlainObject(newObj)) newObj.changed_values = changedValues;
          if (isPlainObject(oldObj)) oldObj.changed_values = changedValues;
          return {
            ...item,
            old_value: JSON.stringify(oldObj),
            new_value: JSON.stringify(newObj),
          };
        }

        return item;
      });
      setConvertedData(converted);
    }
  };

  useEffect(() => {
    if (data) {
      convertDataMarkDifferent(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleGetValue = (data) => {
    const isCheck = isJSON(data);
    if (!isCheck) return;

    const formatData = JSON.parse(data);
    if (isEmpty(formatData)) return;

    const changedValues = formatData?.changed_values || [];
    let tempArr = [];
    Object.keys(formatData).forEach((key) => {
      Object.keys(AUDIT_LOG_VALUES).forEach((auditKey) => {
        if (key === auditKey) {
          if (key === AUDIT_LOG_KEY_REPLACE.VERBATIM_FEEDBACK_HIDE) {
            formatData[key] = Number(formatData[key])
              ? AUDIT_LOG_REPLACE_VALUE.VERBATIM_FEEDBACK_HIDE
              : AUDIT_LOG_REPLACE_VALUE.VERBATIM_FEEDBACK_UNHIDE;
          }
          if (
            (key === AUDIT_LOG_DIFFERENCE_VALUES.CONTENT && !changedValues.includes(AUDIT_LOG_DIFFERENCE_VALUES.CONTENT)) ||
            (key === AUDIT_LOG_DIFFERENCE_VALUES.SPIKE_VALUE && !changedValues.includes(AUDIT_LOG_DIFFERENCE_VALUES.SPIKE_VALUE)) ||
            (key === AUDIT_LOG_DIFFERENCE_VALUES.ROLE_ACCESS_LEVEL && !changedValues.includes(AUDIT_LOG_DIFFERENCE_VALUES.ROLE_ACCESS_LEVEL))
          ) {
            // Set value for Content attribute of Managing Criteria module
            if (key === AUDIT_LOG_DIFFERENCE_VALUES.CONTENT && isArray(formatData[key])) {
              const tempContentList = handleGetListContent(formatData[key], null, false, true);
              tempArr = [...tempArr, ...tempContentList];
            }
            // Set value for Spike attribute of Managing Criteria module
            if (key === AUDIT_LOG_DIFFERENCE_VALUES.SPIKE_VALUE && isArray(formatData[key])) {
              formatData[key].forEach((item) => {
                tempArr.push(handleReturnHTML(`${item} (Content)`, null, false, true));
              });
            }

            // Set value for Role Access Level of Role Management module
            if (key === AUDIT_LOG_DIFFERENCE_VALUES.ROLE_ACCESS_LEVEL && isArray(formatData[key]?.role)) {
              const tempRoleAccessLevel = convertRoleAccessLevels(formatData[key].role);
              tempArr.push(handleReturnHTML(`${tempRoleAccessLevel} (Role Access Level)`, null, false, true));
            }
          } else {
            let tempValue = '';
            if (isDate(formatData[key]) || (moment(formatData[key], [moment.ISO_8601], true).isValid() && isNaN(formatData[key]))) {
              tempValue = moment(formatData[key]).format('DD-MMM-YYYY');
              if (isNaN(tempValue)) {
                tempValue = moment(new Date(formatData[key])).format('DD-MMM-YYYY');
              }
            } else {
              tempValue = formatData[key];
            }
            // Set value for Changed key
            if (changedValues.includes(key)) {
              if (
                key === AUDIT_LOG_DIFFERENCE_VALUES.CONTENT ||
                key === AUDIT_LOG_DIFFERENCE_VALUES.SPIKE_VALUE ||
                key === AUDIT_LOG_DIFFERENCE_VALUES.ROLE_ACCESS_LEVEL
              ) {
                // Set value for Content attribute of Managing Criteria module
                if (key === AUDIT_LOG_DIFFERENCE_VALUES.CONTENT && isArray(formatData[key])) {
                  const tempContentList = handleGetListContent(formatData[key], null, true, true);
                  tempArr = [...tempArr, ...tempContentList];
                }
                // Set value for Spike attribute of Managing Criteria module
                if (key === AUDIT_LOG_DIFFERENCE_VALUES.SPIKE_VALUE && isArray(formatData[key])) {
                  formatData[key].forEach((item) => {
                    tempArr.push(handleReturnHTML(`${item} (Content)`, null, true, true));
                  });
                }
                // Set value for Role Access Level of Role Management module
                if (key === AUDIT_LOG_DIFFERENCE_VALUES.ROLE_ACCESS_LEVEL && isArray(formatData[key]?.role)) {
                  const tempRoleAccessLevel = convertRoleAccessLevels(formatData[key].role);
                  tempArr.push(handleReturnHTML(`${tempRoleAccessLevel} (Role Access Level)`, null, true, true));
                }
              } else {
                tempArr.push(handleReturnHTML(tempValue, auditKey, true, false));
              }
            } else {
              tempArr.push(handleReturnHTML(tempValue, auditKey, false, false));
            }
          }
        }
      });
      if (key === AUDIT_LOG_USER_ACCESS_VALUES.ACCESS_LEVELS) {
        const accessLevels = formatData[key];
        accessLevels.forEach((item) => {
          if (changedValues.includes(key)) {
            tempArr.push(handleReturnHTML(item.access_level, 'access_level', true, false));
            tempArr.push(handleReturnHTML(item.business_access, 'business_access', true, false));
          } else {
            tempArr.push(handleReturnHTML(item.access_level, 'access_level', false, false));
            tempArr.push(handleReturnHTML(item.business_access, 'business_access', false, false));
          }
        });
      }
    });
    return tempArr;
  };

  const handleGetListContent = (data, auditKey, isChanged, hasContent) => {
    // Set value for Content attribute of Managing Criteria module
    let tempContent = [];
    data.forEach((item) => {
      if (item?.content_label) {
        tempContent.push(handleReturnHTML(`${item?.content_label} (Content)`, auditKey, isChanged, hasContent));
      }
      if (item?.content_type) {
        tempContent.push(handleReturnHTML(`${item?.content_type} (Type)`, auditKey, isChanged, hasContent));
      }
      if (item?.content_value) {
        tempContent.push(handleReturnHTML(`${item?.content_value} (Value)`, auditKey, isChanged, hasContent));
      }
    });
    return tempContent;
  };

  const handleReturnHTML = (value, auditKey, isChanged, hasContent) => {
    const tempString = !hasContent ? `${value} (${AUDIT_LOG_VALUES[auditKey]})` : value;
    return tempString?.length > maxLengthForPerRow ? (
      <Tooltip
        placement={'bottom'}
        className={styles.customTooltip}
        overlayStyle={{ minWidth: '237px' }}
        title={
          <div className={styles.tooltipContent}>
            <span style={{ fontWeight: 600 }}>{!hasContent ? `${value} (${AUDIT_LOG_VALUES[auditKey]})` : value}</span>
          </div>
        }
        overlayInnerStyle={{ borderRadius: '6px' }}
      >
        <div
          style={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            maxWidth: '180px',
            color: !isChanged ? `rgb(153, 154, 156)` : `rgb(94, 108, 132)`,
            display: 'block',
            marginBottom: '5px',
            fontWeight: !isChanged ? 400 : 700,
            fontStyle: !hasContent ? 'initial' : 'italic',
          }}
        >
          {!hasContent ? `${value} (${AUDIT_LOG_VALUES[auditKey]})` : value}
        </div>
      </Tooltip>
    ) : (
      <div
        style={{
          fontWeight: !isChanged ? 400 : 700,
          fontStyle: !hasContent ? 'initial' : 'italic',
          color: !isChanged ? `rgb(153, 154, 156)` : `rgb(94, 108, 132)`,
          marginBottom: '5px',
        }}
      >
        {!hasContent ? `${value} (${AUDIT_LOG_VALUES[auditKey]})` : value}
      </div>
    );
  };

  const isJSON = (value) => {
    if (typeof value != 'string') {
      value = JSON.stringify(value);
    }

    try {
      JSON.parse(value);
      return true;
    } catch (e) {
      return false;
    }
  };

  const columns = [
    {
      title: 'No.',
      dataIndex: 'no',
      align: 'center',
      render: (text) => <span style={{ color: '#999A9C' }}>{(page - 1) * limit + text}</span>,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      align: 'left',
      render: (text) => <span style={{ color: '#3F3C4C', fontWeight: 700, display: 'block', maxWidth: '288px' }}>{text}</span>,
    },
    {
      title: 'Module',
      dataIndex: 'module',
      align: 'left',
      render: (text) => <span style={{ color: '#999A9C', display: 'block', maxWidth: '300px', minWidth: '200px' }}>{text}</span>,
    },
    {
      title: 'Old Value',
      dataIndex: 'old_value',
      align: 'left',
      render: (text) => {
        const newData = handleGetValue(text);
        return !isEmpty(newData)
          ? newData.map((item, index) => {
              return <div key={index}>{item}</div>;
            })
          : '';
      },
    },
    {
      title: 'New Value',
      dataIndex: 'new_value',
      align: 'left',
      render: (text) => {
        const newData = handleGetValue(text);
        return !isEmpty(newData)
          ? newData.map((item, index) => {
              return <div key={index}>{item}</div>;
            })
          : '';
      },
    },
    {
      title: 'Action At',
      dataIndex: 'action_at',
      align: 'left',
      render: (text) => (
        <span style={{ color: '#999A9C', minWidth: '140px', display: 'inline-block' }}>
          {!isEmpty(text) ? moment(text).format('DD-MMMM-YYYY HH:mm:ss') : ''}
        </span>
      ),
    },
    {
      title: 'Action By',
      dataIndex: 'action_by',
      align: 'center',
      render: (text) => <span style={{ color: '#999A9C' }}>{text}</span>,
    },
  ];

  const rowSelection = {
    hideSelectAll: true,
  };

  const paginationConfig = {
    total: totalData,
    current: page,
    showSizeChanger: false,
    defaultPageSize: limit,
    onChange: (pageNum) => pageNum !== page && onPageChange(pageNum),
    showTotal: (total, range) => handleShowPaging(total, range),
  };

  const handleShowPaging = (total, range) => {
    return (
      <>
        Showing <b>{range[0]}</b> to <b>{range[1]}</b> of <b>{total}</b> results
      </>
    );
  };

  return (
    <div style={{ paddingTop: 24 }}>
      <SC.TableAuditLog>
        <Table
          rowKey="no"
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          columns={columns}
          dataSource={convertedData}
          pagination={{ position: ['bottomRight'], ...paginationConfig }}
        />
      </SC.TableAuditLog>
    </div>
  );
}
