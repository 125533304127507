import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import * as styles from './assessment-leadership-filter.module.scss';
import { arrow_down_filter_2 } from '../../../../assets/img';
import { get5LatestYear } from '../../../../utils/helper';
import { BasicSelect } from '../../../../assets/common';
import { PDC_EDGE_OPTIONS } from '../../constants';

const yearOptions = ['All', ...get5LatestYear()];

const AssessmentLeadershipFilter = (props) => {
  const { type, setType, year, setYear, isDisabled } = props;

  const typeSelectedDisplay = useMemo(() => {
    return (
      <>
        <span>{type}</span>
        <img src={arrow_down_filter_2} alt="filter" />
      </>
    );
  }, [type]);

  const yearSelectedDisplay = useMemo(() => {
    return (
      <>
        <span>{year}</span>
        <img src={arrow_down_filter_2} alt="filter" />
      </>
    );
  }, [year]);
  return (
    <div className={styles.wrapper}>
      <div>
        <BasicSelect
          disabled={isDisabled}
          pageName={'PDC_ASSESSMENT'}
          height={32}
          options={PDC_EDGE_OPTIONS.map((option) => ({ label: option, value: option }))}
          value={null}
          onChange={(value) => setType(value)}
          placeholder={typeSelectedDisplay}
          suffixIcon={null}
          getPopupContainer={(trigger) => trigger}
        />
      </div>
      <div>
        <BasicSelect
          disabled={isDisabled}
          pageName={'PDC_ASSESSMENT'}
          height={32}
          options={yearOptions.map((option) => ({ label: option, value: option }))}
          value={null}
          onChange={(value) => setYear(value)}
          placeholder={yearSelectedDisplay}
          suffixIcon={null}
          getPopupContainer={(trigger) => trigger}
        />
      </div>
    </div>
  );
};

export default AssessmentLeadershipFilter;

AssessmentLeadershipFilter.propTypes = {
  type: PropTypes.string,
  setType: PropTypes.func,
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setYear: PropTypes.func,
};
