import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as styles from './print-supporting-documents.module.scss';
import PreReadLabel from '../PreReadLabel/PreReadLabel';
import { Spin } from 'antd';
import styled from 'styled-components';
import { pdcMeetingApi } from '../../../../../services/pdcMeetingApi';
import { useSelector } from 'react-redux';
import {
  DocumentIcon,
  FilePreviewContainer,
  FileViewWrapper,
  ProgressContainer,
  ProgressContentLoaded,
} from '../../../../Meeting/components/FileUpload/Styled';
import { isEmpty } from 'lodash';
import { document_icon } from '../../../../../assets/img';
import AutoResizeTextarea from '../../../../../components/AutoResizeTextArea/AutoResizeTextArea';
import { formatTextStringJson } from '../../../../../utils/helper';

const PrintSupportingDocuments = ({ isPrint, preRead, handleFetchComplete, idMeeting, mobilityId }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const roleId = useSelector((state) => state.user.roleActive.roleId);

  useEffect(() => {
    const fetchReportingDocuments = async () => {
      try {
        setLoading(true);
        const res = await pdcMeetingApi.getReportingDocuments(idMeeting, mobilityId, roleId);
        if (res.status === 200) {
          setData(res.data.result);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
        handleFetchComplete('supporting_documents');
      }
    };

    if (!isPrint) handleFetchComplete('supporting_documents');
    if (!idMeeting || !mobilityId || !roleId) return;
    fetchReportingDocuments();
  }, [idMeeting, mobilityId, roleId]);

  return (
    <>
      {isPrint && (
        <div className={styles.wrapper}>
          <div className={styles.title}>
            Uploaded Supporting Documents
            {preRead && <PreReadLabel />}
          </div>
          <Spin spinning={loading}>
            <div>
              <FileViewWrapper>
                {!isEmpty(data.attachments) &&
                  data.attachments.map((item, index) => (
                    <FilePreviewContainer style={{ marginBottom: '16px', width: '100%' }} key={index}>
                      <ProgressContainer>
                        <DocumentIcon src={document_icon} alt=""></DocumentIcon>
                        <ProgressContentLoaded>
                          <div className={'file-name'}>{item?.file_name || `Minute of Meeting.pdf`}</div>
                          <div className={'file-size'}>{item?.file_size ? `${Math.floor(item?.file_size / 1024)} kb` : `0 kb`}</div>
                        </ProgressContentLoaded>
                      </ProgressContainer>
                    </FilePreviewContainer>
                  ))}
              </FileViewWrapper>
              <ItemWrapper>
                <CustomItemTitle>Remarks</CustomItemTitle>
                <CustomTextarea disabled placeholder="Enter Remarks" style={{ minHeight: '204px' }} value={formatTextStringJson(data.remarks)} />
              </ItemWrapper>
            </div>
          </Spin>
        </div>
      )}
    </>
  );
};

export default PrintSupportingDocuments;

PrintSupportingDocuments.propTypes = {
  isPrint: PropTypes.bool,
  preRead: PropTypes.bool,
  handleFetchComplete: PropTypes.func,
  mobilityId: PropTypes.number,
  idMeeting: PropTypes.string,
};

const CustomTextarea = styled(AutoResizeTextarea)`
  resize: none;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  background: #f0f5fa !important;
  color: #15191e !important;
  word-break: break-word;
`;

const CustomItemTitle = styled.div`
  color: var(--Grays-500, #525252);
  font-family: var(--font-type-default, Inter);
  font-size: 12px;
  font-style: normal;
  font-weight: var(--font-weight-medium, 500);
  line-height: var(--font-line-height-body-sm, 20px); /* 166.667% */
  margin-bottom: 5px;
`;

const ItemWrapper = styled.div`
  margin-bottom: 32px;
`;
