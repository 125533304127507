import { Spin } from 'antd';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import { avatar } from '../../../assets/img';
import { BreadCrumb, ModelTC } from '../../../components';
import GlobalAlertMessage from '../../../components/GlobalAlertMessage/GlobalAlertMessage';
import { useShowPopupSaveAgenda } from '../../../hooks/useShowPopupSaveAgenda';
import { useUpdatePdcMobilityStepNumber } from '../../../hooks/useUpdatePdcMobilityStepNumber';
import { pdcMeetingApi } from '../../../services/pdcMeetingApi';
import { NOTIFICATION_TYPE, PDC_PROPOSAL_TYPES, USER_ACCESS_MESSAGE } from '../../../utils/constants';
import { getBreadCrumbList } from '../../../utils/helper';
import { showNotification } from '../../../utils/notification';
import MobilityBotButtonControls from '../components/MobilityButtonControls/MobilityBotButtonControls';
import MobilityTopButtonControls from '../components/MobilityButtonControls/MobilityTopButtonControls';
import MobilityFinalizeBar from '../components/MobilityFinalizeBar/MobilityFinalizeBar';
import PdcMobilityProcessBar from '../components/PdcMobilityProcessBar/PdcMobilityProcessBar';
import * as styles from './pdc-proposal-talent-list.module.scss';

const BREAD_CRUMB = getBreadCrumbList(['Homepage', 'PDC Meeting', 'Mobility Agenda', 'Proposal']);

const PdcProposalTalentList = (props) => {
  const history = useHistory();
  const { idMeeting, mobilityId, proposalId } = useParams();

  const [loading, setLoading] = useState(false);
  const [proposalList, setProposalList] = useState({
    positions: [],
    talents: [],
  });
  const [isBack, setIsBack] = useState(false);
  const [compareData, setCompareData] = useState({
    initData: [],
    currentData: [],
  });

  // Get the condition to edit mobility agenda
  const {
    isOnlyViewAgenda,
    isShowTopButtons,
    isShowBotButtons,
    isAdditionalViewAccess,
    meetingDetail,
    mobilityDetails,
    isEnableMobilityTab,
    proposalMainStep,
    fetchMobilityDetails,
    isShownMobilityTabBar,
    isHRPartners,
    isChairMainOrCommiteeOrAdvocator,
  } = props;
  const { updateStepNumber } = useUpdatePdcMobilityStepNumber();

  // Update current data to compare value
  useEffect(() => {
    setCompareData((prev) => ({
      ...prev,
      currentData: proposalList,
    }));
  }, [proposalList]);

  const isChangeProposalList = useMemo(() => {
    return JSON.stringify(compareData.initData) !== JSON.stringify(compareData.currentData);
  }, [compareData]);

  const { isShowPopupSaveAgenda, handleKeepNavigate, setIsShowPopupSaveAgenda } = useShowPopupSaveAgenda(isChangeProposalList && !isBack);

  const fetchMobility = useCallback(async () => {
    if (!mobilityId) return;
    try {
      setLoading(true);
      const res = await pdcMeetingApi.getProposalMultipleTalentOrPosition(mobilityId, PDC_PROPOSAL_TYPES.MULTIPLE_TALENT);
      if (res.status === 200) {
        setProposalList(res?.data?.result);
        setCompareData((prev) => ({
          ...prev,
          initData: res?.data?.result,
          currentData: res?.data?.result,
        }));
      }
    } catch (error) {
      console.error(error);
      showNotification(USER_ACCESS_MESSAGE.AN_UNEXPECTED_ERROR, NOTIFICATION_TYPE.FAILED);
    } finally {
      setLoading(false);
    }
  }, [mobilityId]);

  // Fetch mobility in the first time
  useEffect(() => {
    fetchMobility();
  }, [fetchMobility]);

  const handleClickBack = async () => {
    history.push(`/pdc-meeting/${idMeeting}/mobility/${mobilityId}`);
  };

  const handleSaveBeforeExit = async () => {
    setIsBack(true);
    setIsShowPopupSaveAgenda(false);
    await handleSaveAsDraft();
    handleKeepNavigate();
  };

  const handleClickNext = async () => {
    if (proposalMainStep?.step < 2) {
      await updateStepNumber(mobilityId, 2);
    }
    history.push(`/pdc-meeting/${idMeeting}/mobility/${mobilityId}/assessment/${proposalId}?mobilityType=talents`);
  };

  const handleSaveAsDraft = async () => {
    if (proposalMainStep?.step < 2) {
      await updateStepNumber(mobilityId, 2);
    }
  };

  return (
    <>
      <div style={{ marginBottom: '15px' }}>
        <BreadCrumb level={4} breadCrumbList={BREAD_CRUMB} />
      </div>
      <GlobalAlertMessage style={{ marginBottom: '20px' }} />
      {isShowTopButtons && (
        <MobilityTopButtonControls
          idMeeting={idMeeting}
          mobilityId={mobilityId}
          mobilityDetails={mobilityDetails}
          fetchMobility={fetchMobilityDetails}
          isAdditionalViewAccess={isAdditionalViewAccess}
          isHRPartners={isHRPartners}
          isChairMainOrCommiteeOrAdvocator={isChairMainOrCommiteeOrAdvocator}
          meetingDetail={meetingDetail}
        />
      )}

      {isShownMobilityTabBar ? (
        <MobilityFinalizeBar mobilityDetails={mobilityDetails} />
      ) : (
        <PdcMobilityProcessBar currentStep={2} mobilityDetails={mobilityDetails} isEnableMobilityTab={isEnableMobilityTab} />
      )}
      <Spin spinning={loading}>
        <div className={styles.proposalWrap}>
          <div className={styles.proposalPositionList}>
            {!isEmpty(proposalList.positions) &&
              proposalList.positions.map((item, index) => (
                <div key={`${item.position_code}.${index}`} className={styles.proposalItem}>
                  <div className={`${styles.proposalItemContent}`}>
                    <div className={styles.infoLeft}>
                      {![item?.position_code, item?.birth_name].includes('VACANT') && (
                        <div className={styles.avatar}>
                          <img src={item.image_url || avatar} alt="" />
                        </div>
                      )}
                      <div className={styles.name} style={{ width: '535px' }}>
                        {![item?.position_code, item?.birth_name].includes('VACANT') ? (
                          <Link to={`/profile/${item?.staff_id}`} target="_blank" className={styles.fieldNameLink}>
                            <div className={styles.fieldName}>
                              {item.birth_name || '-'} ({item?.top_talent_status || '-'}, {item?.age || '-'})
                            </div>
                          </Link>
                        ) : (
                          <div className={styles.fieldNameDiff}>
                            <span>{item.birth_name || '-'}</span>
                          </div>
                        )}
                        <div className={styles.fieldDesc}>
                          {item?.position_name}, {item?.business_unit}
                        </div>
                        <div className={styles.normalText}>
                          {item?.division || '-'} | {item?.opu_name || '-'}
                        </div>
                      </div>
                    </div>
                    <div className={styles.fieldItem}>
                      <span className={styles.greyLabel}>JG</span>
                      <span className={styles.boldValue}>{item?.job_grade || '-'}</span>
                    </div>
                    <div className={styles.fieldItem}>
                      <span className={styles.greyLabel}>SG</span>
                      <span className={styles.boldValue}>{item?.sg || '-'}</span>
                    </div>
                    <div className={styles.fieldItem}>
                      <span className={styles.greyLabel}>Retirement:</span>
                      <span className={styles.boldValue}>{item?.retirement_or_contract || '-'}</span>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className={styles.proposalList}>
            {!isEmpty(proposalList.talents) &&
              proposalList.talents.map((item, index) => (
                <div key={`${item.staff_id}.${index}`} className={styles.proposalItem}>
                  <div className={styles.proposalItemHead}>
                    <h3>Talent {index + 1}</h3>
                  </div>
                  <div className={`${styles.proposalItemContent}`}>
                    <div className={styles.infoLeft}>
                      <div className={styles.avatar}>
                        <img src={item.image_url || avatar} alt="" />
                      </div>
                      <div className={styles.name} style={{ width: '535px' }}>
                        <Link to={`/profile/${item?.staff_id}`} target="_blank" className={styles.fieldNameLink}>
                          <div className={styles.fieldName}>
                            {item.birth_name || '-'} ({item?.top_talent_status || '-'}, {item?.age || '-'})
                          </div>
                        </Link>
                        <div className={styles.normalText}>
                          {item?.position_name || '-'}, {item?.business_unit || '-'}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        {isShowBotButtons && (
          <MobilityBotButtonControls
            idMeeting={idMeeting}
            mobilityId={mobilityId}
            isDisabled={false}
            handleSaveAsDraft={handleSaveAsDraft}
            mobilityDetails={mobilityDetails}
            fetchMobility={fetchMobilityDetails}
            handleClickNext={handleClickNext}
            handleClickBack={handleClickBack}
            isOnlyViewAgenda={isOnlyViewAgenda}
            agendaStatus={mobilityDetails?.status}
            isHRPartners={isHRPartners}
            isAdditionalViewAccess={isAdditionalViewAccess}
            isChairMainOrCommiteeOrAdvocator={isChairMainOrCommiteeOrAdvocator}
            meetingDetail={meetingDetail}
          />
        )}
      </Spin>

      <ModelTC
        info={{
          type: 'withoutSaving',
          visible: isShowPopupSaveAgenda,
          setVisible: setIsShowPopupSaveAgenda,
          onClose: handleKeepNavigate,
          handleSubmit: handleSaveBeforeExit,
        }}
      />
    </>
  );
};

export default PdcProposalTalentList;
