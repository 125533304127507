import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import {
  DocumentIcon,
  FilePreviewContainer,
  GroupButon,
  ProgressContainer,
  ProgressContentLoaded,
} from '../../../../Meeting/components/FileUpload/Styled';
import { deleteIcon, document_icon, sp_trash } from '../../../../../assets/img';
import { AiButton } from '../../../../../assets/common';
import { ModelTC } from '../../../../../components';
import { pdcMeetingApi } from '../../../../../services/pdcMeetingApi';
import { Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PAGE_NAME } from '../../../../../utils/constants';

const FileUploaded = ({ fileItem, setFileUpload, setFileUploadStatus, isUploading, pageName }) => {
  const { idMeeting } = useParams();
  const { externalToken } = useSelector((state) => state.user.tokenImage);
  const [visibleDeleteFile, setVisibleDeleteFile] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleDeleteFiles = async () => {
    try {
      setLoading(true);
      setFileUpload((prev) => {
        if (!isEmpty(prev)) {
          return prev.filter((item) => item.id !== fileItem.id);
        }
      });
      setFileUploadStatus((prev) => ({ ...prev, isDeletedMoM: true }));
      const res =
        pageName === PAGE_NAME.PDC_MEETING
          ? await pdcMeetingApi.deleteFilePdcMeeting(idMeeting, fileItem?.id)
          : await pdcMeetingApi.deleteFileReportingDocument(fileItem?.id);
      if (res.status === 200) {
        return true;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setVisibleDeleteFile(false);
    }
  };

  const handleDownloadFile = (file) => {
    if (pageName === PAGE_NAME.PDC_MEETING) {
      window.open(`${file?.url}?${externalToken}`, '_blank', 'noopener, noreferrer');
    } else {
      fetch(file?.url)
        .then((res) => {
          return res.blob();
        })
        .then((blob) => {
          const href = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.setAttribute('download', file?.file_name);
          link.href = href;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  return (
    <>
      <FilePreviewContainer style={{ marginBottom: '16px' }}>
        <ProgressContainer>
          <DocumentIcon src={document_icon} alt=""></DocumentIcon>
          <ProgressContentLoaded>
            <div className={'file-name'} onClick={() => handleDownloadFile(fileItem)}>
              {pageName !== PAGE_NAME.PDC_MEETING ? fileItem?.file_name : fileItem?.original_file_name || `Minute of Meeting.pdf`}
            </div>
            <div className={'file-size'}>{fileItem?.file_size ? `${Math.floor(fileItem?.file_size / 1024)} kb` : `0 kb`}</div>
          </ProgressContentLoaded>
          <GroupButon>
            <Spin spinning={loading}>
              <AiButton
                style={{
                  ...{ padding: 0, width: `30px` },
                  ...(isUploading && { opacity: '0.3', cursor: 'no-drop' }),
                }}
                onKeyDown={() => {}}
                onClick={() => !isUploading && setVisibleDeleteFile(true)}
              >
                {pageName === PAGE_NAME.PDC_MEETING ? (
                  <img src={deleteIcon} alt="deleteIcon" style={{ width: 16 }} />
                ) : (
                  <img src={sp_trash} alt="sp_trash" style={{ width: 20 }} />
                )}
              </AiButton>
            </Spin>
          </GroupButon>
        </ProgressContainer>
      </FilePreviewContainer>

      {/* ModelTC */}
      <ModelTC
        info={{
          type: pageName === PAGE_NAME.PDC_MEETING ? 'deleteMoM' : 'deleteFileOthersAgenda',
          visible: visibleDeleteFile,
          setVisible: setVisibleDeleteFile,
          handleSubmit: handleDeleteFiles,
          onClose: () => {},
          loading: false,
        }}
      />
    </>
  );
};

export default FileUploaded;
