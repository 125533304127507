import React, { useEffect, useState } from 'react';
import CustomAntModal from '../../../../components/CustomAntModal/CustomAntModal';
import { FirstSection, Footer, Item } from './AddExistingPDCMeetingStyled';
import { AiCheckBox, BasicButton } from '../../../../assets/common';
import { Pagination, Spin } from 'antd';
import { pdcMeetingApi } from '../../../../services/pdcMeetingApi';
import { useSelector } from 'react-redux';
import { get, isEmpty, xor } from 'lodash';

const AddExistingPDCMeeting = (props) => {
  const { open, setOpen, idMeeting, fetchMobilities, fetchBuSortList } = props;
  const roleActive = useSelector((state) => state.user.roleActive);
  const [query, setQuery] = useState({
    page: 1,
    total: 0,
    search_text: '',
    data: [],
    selected: [],
    loading: false,
  });

  function handleChangePage(page) {
    setQuery((prev) => ({ ...prev, page, selected: [] }));
  }

  useEffect(() => {
    (async function getPDCMobilityExist() {
      try {
        setQuery((prev) => ({ ...prev, loading: true }));
        const res = await pdcMeetingApi.fetchPDCMobilityExist({ page: query.page, search_text: query.search_text }, roleActive?.roleId);
        console.log(res);
        setQuery((prev) => ({ ...prev, data: get(res, 'data.result.data') || [], total: get(res, 'data.result.total') || 0 }));
      } catch (error) {
        console.log(error);
        setQuery((prev) => ({ ...prev, data: [], total: 0, page: 1 }));
      } finally {
        setQuery((prev) => ({ ...prev, loading: false }));
      }
    })();
  }, [query.page, query.search_text, roleActive?.roleId]);

  async function handleAddExistingAgenda() {
    try {
      const res = await pdcMeetingApi.fetchAddMobilityFromMobilityExist({ meeting_id: idMeeting, mobility_ids: query.selected }, roleActive?.roleId);
      console.log(res);
      setOpen(null);
      fetchMobilities(1);
      fetchBuSortList && fetchBuSortList();
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <CustomAntModal
      open={open}
      setOpen={setOpen}
      title={'Add Existing Mobility'}
      onCancel={() => setOpen(null)}
      width={1286}
      hideDefaultBtn={true}
      destroyOnClose
      closeType={1}
      styles={{
        containerPaddingTopBottom: 50,
        closePosition: [40, 56],
        headerPaddingBottom: 40,
        titleFontSize: 20,
        titleFontWeight: 700,
      }}
    >
      <Spin spinning={query.loading}>
        <FirstSection>
          <div>
            <input placeholder="Search by Position Name, Staff Name and Staff ID" id="search-add-mobility" />
            <BasicButton
              mode="teal"
              height="44px"
              width="100px"
              onClick={() => {
                setQuery((prev) => ({ ...prev, search_text: document.getElementById('search-add-mobility').value, page: 1 }));
              }}
            >
              Search
            </BasicButton>
          </div>
          <div className={`confirm ${!(query.selected.length > 0) ? 'disabled' : ''}`} onKeyDown={() => {}} onClick={handleAddExistingAgenda}>
            <span className="text" style={{ color: '#ffffff' }}>
              Confirm Add Existing Mobility
            </span>
          </div>
        </FirstSection>
        {!isEmpty(query.data) &&
          query.total !== 0 &&
          query.data.map((m) => (
            <Item key={m.mobility_id}>
              <div className="check-box">
                <AiCheckBox
                  onChange={() =>
                    setQuery((prev) => ({
                      ...prev,
                      selected: xor(prev.selected, [m.mobility_id]),
                    }))
                  }
                ></AiCheckBox>
              </div>
              <div className="advocator">
                <div className="weight-700">Advocator Name</div>
                {!isEmpty(m.advocators) && m.advocators.map((a) => <div key={a.id}>{a.name}</div>)}
              </div>
              <div className="name weight-600">{m.title}</div>
              <div className="advocator">
                <div className="weight-700">{m.meeting_name}</div>
                <div>{m.meeting_date}</div>
              </div>
            </Item>
          ))}

        {!isEmpty(query.data) && query.total !== 0 && (
          <Footer>
            <Pagination
              total={query.total}
              showTotal={(total, range) => (
                <span>
                  Showing <span className="weight-700">{range[0]}</span> to <span className="weight-700">{range[1]}</span> of{' '}
                  <span className="weight-700">{total}</span> results
                </span>
              )}
              current={query.page}
              defaultPageSize={6}
              showSizeChanger={false}
              onChange={handleChangePage}
            />
          </Footer>
        )}
      </Spin>
    </CustomAntModal>
  );
};

export default AddExistingPDCMeeting;
