import React, { useEffect, useMemo, useState } from 'react';
import { Collapse, Spin, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import { OverlayTrigger, Tooltip as BSToolTip } from 'react-bootstrap';
import moment from 'moment';

import {
  asc_order,
  avatar,
  sp_chevron_down,
} from '../../../../../../../assets/img';
import styles from './direct-report.module.scss';
import { meetingApi } from '../../../../../../../services/tcMeeting';
import {
  decodeBase64ToImage,
  handleShowRetirementDate,
  handleShowTextTooltip,
  handleShowTooltip,
  handleShowYIPAndYISG,
} from '../../../../../../../utils/helper';
import { Draggable } from '../../wrappers/Draggable';
import { SP_CARD_DEFAULT } from '../../../../../../../utils/constants';
import { AiAlert, AiSpin } from '../../../../../../../assets/common';
import { isEmpty } from 'lodash';
import { SingleSelect } from '../../../../../../../components';

const { Panel } = Collapse;

const DirectReport = ({
  text,
  setTotalFromDirectReport,
}) => {
  const positions = useSelector(
    (state) => state.app.meetingModule.agenda.agendaIncumbent.positions
  );

  const dropDownList = useMemo(() => {
    return positions.map((p) => ({
      label: p?.position_name,
      value: p?.position_code,
      staff_id: p?.incumbent?.staff_id,
    }));
  }, [positions]);

  const [activeKey, setActiveKey] = useState([]);
  const [currentKey, setCurrentKey] = useState(null);
  const [directReport, setDirectReport] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [detailsCache, setDetailsCache] = useState({});
  const [sortOrder, setSortOrder] = useState({
    line_1: 'asc',
    line_2: 'asc',
  });
  const [selectedOption, setSelectedOption] = useState(null);

  const handleSortByMatchingPercentage = (lineKey) => {
    const sortedLine = [...(directReport[lineKey] || [])].sort((a, b) => {
      const parsePercentage = (val) => {
        if (val === null || val === '' || val === '0%') {
          return -1;
        }
        return parseFloat(val.replace('%', ''));
      };

      const aValue = parsePercentage(a.matching_percentage);
      const bValue = parsePercentage(b.matching_percentage);

      if (aValue === -1 && bValue === -1) return 0;
      if (aValue === -1) return sortOrder[lineKey] === 'asc' ? 1 : 1;
      if (bValue === -1) return sortOrder[lineKey] === 'asc' ? -1 : -1;

      return sortOrder[lineKey] === 'asc' ? aValue - bValue : bValue - aValue;
    });

    setDirectReport((prev) => ({
      ...prev,
      [lineKey]: sortedLine,
    }));

    setSortOrder((prev) => ({
      ...prev,
      [lineKey]: prev[lineKey] === 'asc' ? 'desc' : 'asc',
    }));
  };

  const handleCollapseChange = async (key) => {
    setActiveKey(key);
    setCurrentKey(key[key.length - 1]);
    const staffId = key[key.length - 1];
    if (!detailsCache[staffId] && !isEmpty(selectedOption)) {
      const resDetail = await getDetails(staffId, selectedOption);
      if (resDetail) {
        setDetailsCache((prevCache) => ({
          ...prevCache,
          [staffId]: resDetail,
        }));
      }
    }
  };

  useEffect(() => {
    const line1Count = directReport?.line_1?.length || 0;
    const line2Count = directReport?.line_2?.length || 0;
    const total = line1Count + line2Count;
    setTotalFromDirectReport(total);
  }, [directReport]);

  const getFromDirectReport = async (selectedOption) => {
    if (isEmpty(selectedOption)) return;
    setLoading(true);
    try {
      const res = await meetingApi.getFromDirectReport(
        selectedOption?.staff_id,
        selectedOption?.value
      );
      if (res.status === 200) {
        setDirectReport(res.data.result);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getFromDirectReport(selectedOption);
  }, [selectedOption]);

  const getDetails = async (staff_id, selectedOption) => {
    if (detailsCache[staff_id]) {
      return detailsCache[staff_id];
    }
    if (isEmpty(selectedOption)) return;
    try {
      setLoadingDetail(true);
      const rs = await meetingApi.getTalentDetailsFromAi(
        staff_id,
        selectedOption?.value,
        true
      );
      if (rs?.data?.result) {
        return rs.data.result;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDetail(false);
    }
  };

  const handleShowRetirement = (item) => {
    let total =
      Number(moment(item?.retirement_date).format('YYYY')) -
      Number(moment(item?.birth_date, 'DD-MM-YYYY').format('YYYY'));
    if (!isNaN(total)) {
      return '(' + total + ')';
    }

    return '';
  };

  const Table = ({ data, name }) => {
    return (
      <div className={styles.wrapper}>
        <div
          style={{
            backgroundColor: '#50A4B9',
            color: '#FFFFFF',
            borderRadius: '4px 4px 0px 0px',
            display: 'grid',
            gridTemplateColumns: '10% 50% 10% 30%',
            fontFamily: 'Inter, san-serif',
            fontWeight: '500',
            fontSize: '12px',
            height: '30px',
            alignItems: 'center',
          }}
        >
          <div></div>
          <div>{'Name'}</div>
          <div>{'SG'}</div>
          <div
            style={{ display: 'flex', gap: '6px', cursor: 'pointer' }}
            onClick={() => handleSortByMatchingPercentage(name)}
          >
            <img src={asc_order} />
            {'Matching(%)'}
          </div>
        </div>
        <div
          style={{
            backgroundColor: '#FFFFFF',
            borderWidth: '0px 1px 1px 1px',
            borderRadius: '0px 0px 6px 6px',
            borderStyle: 'solid',
            borderColor: '#EBEBEB',
            height: data?.length ? 'fit-content' : '82px',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Collapse activeKey={activeKey} onChange={handleCollapseChange}>
            {data?.length > 0 ? (
              data?.map((item, index) => {
                const resDetail = detailsCache[item?.staff_id];

                // const vpCount = resDetail?.positions.filter((position) => position.role == 'VP');
                // const gmCount = resDetail?.positions.filter((position) => position.role == 'GM');
                // const sgmCount = resDetail?.positions.filter((position) => position.role == 'SGM');

                return (
                  <Panel
                    key={item?.staff_id}
                    showArrow={false}
                    forceRender={false}
                    header={
                      <div
                        style={{
                          display: 'grid',
                          gridTemplateColumns: '10% 50% 10% 30%',
                          backgroundColor: 'white',
                          borderTop: '1px solid #EBEBEB',
                          padding: '7px',
                        }}
                      >
                        <div className={styles.datafield500}>{index + 1}</div>
                        <div draggable="false">
                          <span
                            onClick={() =>
                              window.open(
                                `/profile/${item?.staff_id}`,
                                '_blank',
                                'noopener, noreferrer'
                              )
                            }
                            className={styles.datafield}
                            style={{ color: '#00A19C', textDecoration: 'none' }}
                            data-cardsettings={SP_CARD_DEFAULT.STAFF_NAME}
                          >
                            {item?.birth_name} &#160;
                          </span>
                          <span
                            className={styles.datafield}
                            style={{ color: '#00A19C', textDecoration: 'none' }}
                          >
                            {`(${item?.top_talent_status})`}
                          </span>
                        </div>
                        <div className={styles.datafield500}>{item?.sg}</div>
                        <div
                          className={styles.datafield500}
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginLeft: '20px',
                            marginRight: '20px',
                          }}
                        >
                          <div
                            style={{
                              textAlign: 'center',
                              width: '20px',
                              color: '#00A19C',
                            }}
                          >
                            {item?.matching_percentage || '-'}
                          </div>
                          <div className={styles.chevronWrapper}>
                            <img
                              src={sp_chevron_down}
                              alt=""
                              className={`${styles.chevron} ${
                                currentKey == item?.staff_id
                                  ? styles.animate
                                  : ``
                              }`}
                            />
                          </div>
                        </div>
                      </div>
                    }
                  >
                    <Spin
                      spinning={currentKey == item?.staff_id && loadingDetail}
                    >
                      <Draggable
                        id={`${text}-drap-${item?.staff_id}`}
                        className={`collapseBody__lineRecommendation`}
                        data={{
                          ...{
                            ...item,
                            matching_percentage:
                              item?.matching_percentage?.replace('%', '') ||
                              item?.matching_percentage,
                            salary_grade: item?.sg,
                            ...resDetail,
                          },
                          index,
                        }}
                      >
                        <div
                          style={{
                            padding: '0px 10px',
                            borderWidth: '0x 1px 1px',
                            borderStyle: 'solid',
                            borderColor:
                              '#BDB6EA rgb(231, 231, 240) rgb(231, 231, 240)',
                            display: 'grid',
                            gridTemplateColumns: '15% 85%',
                            gridTemplateRows: 'auto auto',
                            gridTemplateAreas: `
                                  "col1 col2"
                                  "fullwidth fullwidth"
                                `,
                            paddingBottom: '10px',
                          }}
                        >
                          <div
                            className="d-flex align-items-center h-100"
                            style={{ gap: '15px', margin: '10px 0' }}
                          >
                            <div
                              style={{
                                fontWeight: '900',
                                fontSize: '14px',
                                color: '#3f3c4c',
                                lineHeight: '20px',
                                marginBottom: '10px',
                                marginTop: '10px',
                              }}
                            >
                              {index + 1}
                            </div>
                            <img
                              style={{
                                width: '48px',
                                height: '48px',
                                borderRadius: '50%',
                              }}
                              src={
                                decodeBase64ToImage(resDetail?.image) || avatar
                              }
                            />
                          </div>
                          <div
                            style={{
                              gridArea: 'col2',
                              display: 'grid',
                              marginTop: '10px',
                              alignItems: 'center',
                              marginLeft: '20px',
                            }}
                          >
                            <OverlayTrigger
                              delay={{ show: 250, hide: 400 }}
                              placement="top"
                              overlay={
                                <BSToolTip>
                                  <div
                                    style={{
                                      fontSize: '12px',
                                      fontWeight: '600',
                                      lineHeight: '20px',
                                      letterSpacing: '0.173px',
                                      color: '#fff',
                                      textAlign: 'left',
                                      padding: '5px',
                                    }}
                                  >
                                    {resDetail?.position_name}
                                    <ul
                                      style={{ margin: 0, paddingLeft: '15px' }}
                                    >
                                      <li
                                        style={{ color: '#999999' }}
                                      >{`Division: ${resDetail?.division}`}</li>
                                      <li
                                        style={{ color: '#999999' }}
                                      >{`OPU: ${resDetail?.opu}`}</li>
                                      <li
                                        style={{ color: '#999999' }}
                                      >{`Business: ${resDetail?.business_unit}`}</li>
                                    </ul>
                                  </div>
                                </BSToolTip>
                              }
                            >
                              <div
                                style={{
                                  fontWeight: '500',
                                  fontSize: '14px',
                                  lineHeight: '14px',
                                  color: '#3D3D3D',
                                  width: 'fit-content',
                                  cursor: 'pointer',
                                }}
                              >
                                {resDetail?.position_name
                                  ? resDetail.position_name
                                  : 'N/A'}
                                , {resDetail?.business_unit}
                              </div>
                            </OverlayTrigger>
                            <div
                              style={{
                                display: 'flex',
                                gap: '10px',
                                fontSize: '13px',
                              }}
                            >
                              <div style={{ fontWeight: '400' }}>
                                {'ID: '}
                                <span style={{ fontWeight: 'bold' }}>
                                  {item?.staff_id}
                                </span>
                              </div>
                              {/* <OverlayTrigger
                                delay={{ show: 250, hide: 400 }}
                                placement="bottom"
                                overlay={
                                  <Tooltip>
                                    <div
                                      style={{
                                        fontSize: '12px',
                                        fontWeight: '600',
                                        lineHeight: '20px',
                                        letterSpacing: '0.173px',
                                        color: '#fff',
                                        textAlign: 'left',
                                        padding: '5px',
                                      }}
                                    >
                                      {vpCount?.length > 0 && (
                                        <>
                                          <div style={{ fontWeight: '700', fontSize: '13px' }}>{'VP'}</div>
                                          <ul style={{ margin: 0, paddingLeft: '15px' }}>
                                            {vpCount?.map((item) => {
                                              return (
                                                <li
                                                  key={item.position_code}
                                                  style={{ fontWeight: '400', fontSize: '11px' }}
                                                >{`${item.position_name} - ${item.readiness}`}</li>
                                              );
                                            })}
                                          </ul>
                                        </>
                                      )}
                                      {gmCount?.length > 0 && (
                                        <>
                                          <div style={{ fontWeight: '700', fontSize: '13px' }}>{'GM'}</div>
                                          <ul style={{ margin: 0, paddingLeft: '15px' }}>
                                            {gmCount?.map((item) => {
                                              return (
                                                <li
                                                  key={item.position_code}
                                                  style={{ fontWeight: '400', fontSize: '11px' }}
                                                >{`${item.position_name} - ${item.readiness}`}</li>
                                              );
                                            })}
                                          </ul>
                                        </>
                                      )}
                                      {sgmCount?.length > 0 && (
                                        <>
                                          <div style={{ fontWeight: '700', fontSize: '13px' }}>{'SGM'}</div>
                                          <ul style={{ margin: 0, paddingLeft: '15px' }}>
                                            {sgmCount?.map((item) => {
                                              return (
                                                <li
                                                  key={item.position_code}
                                                  style={{ fontWeight: '400', fontSize: '11px' }}
                                                >{`${item.position_name} - ${item.readiness}`}</li>
                                              );
                                            })}
                                          </ul>
                                        </>
                                      )}
                                    </div>
                                  </Tooltip>
                                }
                              >
                                <div style={{ cursor: 'pointer', display: 'flex', gap: '5px', fontSize: '12px' }}>
                                  {vpCount?.length > 0 && (
                                    <div style={{ fontWeight: '400' }}>
                                      {'VP: '}
                                      <span style={{ fontWeight: 'bold' }}>{vpCount?.length}</span>
                                    </div>
                                  )}
                                  {gmCount?.length > 0 && (
                                    <div style={{ fontWeight: '400' }}>
                                      {'GM: '}
                                      <span style={{ fontWeight: 'bold' }}>{gmCount?.length}</span>
                                    </div>
                                  )}
                                  {sgmCount?.length > 0 && (
                                    <div style={{ fontWeight: '400' }}>
                                      {'SGM: '}
                                      <span style={{ fontWeight: 'bold' }}>{sgmCount?.length}</span>
                                    </div>
                                  )}
                                </div>
                              </OverlayTrigger> */}
                              <Tooltip
                                placement="bottom"
                                title={
                                  resDetail?.positions &&
                                  handleShowTextTooltip(resDetail, positions)
                                }
                                color={'black'}
                                key={'black'}
                                overlayInnerStyle={{
                                  borderRadius: '6px',
                                  minWidth: 'max-content',
                                  padding: '12px',
                                  width: '100%',
                                }}
                                className={styles.sp_counter}
                              >
                                {resDetail?.positions &&
                                  handleShowTooltip(resDetail, positions)}
                              </Tooltip>
                            </div>
                          </div>
                          <div
                            style={{
                              gridArea: 'fullwidth',
                              border: '1px solid rgb(203, 214, 226)',
                              backgroundColor: 'rgb(242, 248, 253)',
                              borderRadius: '6px',
                              fontSize: '12px',
                              padding: '10px',
                              marginTop: '10px',
                              display: 'grid',
                              gridTemplateColumns: '32% 32% 35%',
                              gridTemplateRows: 'auto auto',
                              gridTemplateAreas: `
                                            "col1 col2 col2"
                                            "col3 col4 col5"
                                            `,
                              rowGap: '10px',
                            }}
                          >
                            <div style={{ gridArea: 'col1' }}>
                              {'YIP: '}
                              <div style={{ fontWeight: 'bold' }}>
                                {handleShowYIPAndYISG(
                                  resDetail?.year_in_position,
                                  resDetail?.date_in_position
                                )}
                              </div>
                            </div>
                            <div style={{ gridArea: 'col2' }}>
                              {'YISG: '}
                              <div style={{ fontWeight: 'bold' }}>
                                {item?.sg} (
                                {handleShowYIPAndYISG(
                                  resDetail?.years_in_sg ??
                                    resDetail?.year_in_sg,
                                  resDetail?.date_in_sg
                                )}
                                )
                              </div>
                            </div>
                            <div style={{ gridArea: 'col3' }}>
                              {'Age: '}
                              <div style={{ fontWeight: 'bold' }}>
                                {resDetail?.age
                                  ? `${resDetail?.age} Years`
                                  : 'N/A'}
                              </div>
                            </div>
                            <div style={{ gridArea: 'col4' }}>
                              {'Retirement: '}
                              <div style={{ fontWeight: 'bold' }}>
                                {handleShowRetirementDate(
                                  resDetail?.retirement_date
                                )}
                                {handleShowRetirement(resDetail)}
                              </div>
                            </div>
                            <div style={{ gridArea: 'col5' }}>
                              {'Performance Rating'}
                              <div style={{ fontWeight: 'bold' }}>
                                {resDetail?.performance_rating
                                  ? `${resDetail?.performance_rating}`
                                  : 'N/A'}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Draggable>
                    </Spin>
                  </Panel>
                );
              })
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '80px',
                }}
              >
                {'No Data Available'}
              </div>
            )}
          </Collapse>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (dropDownList.length > 0) {
      setSelectedOption(dropDownList[0]);
    }
  }, [dropDownList]);

  const handleChangePositionCode = (selected) => {
    setSelectedOption(selected);
  };

  return (
    <>
      <div style={{ display: 'grid', gap: '12px' }}>
        <div className={styles.positionCodeTitle}>{'Select Position'}</div>
        <SingleSelect
          placeholder={'Select Position'}
          value={selectedOption}
          options={dropDownList}
          onChange={(e) => {
            handleChangePositionCode(e);
          }}
        />
        {loading ? (
          <AiSpin tip="Loading...">
            <AiAlert />
          </AiSpin>
        ) : (
          <>
            <div className={styles.tableTitle}>{'Reporting Line (n-1)'}</div>
            <Table data={directReport?.line_1} name={'line_1'} />
            <div className={styles.tableTitle}>{'Reporting Line (n-2)'}</div>
            <Table data={directReport?.line_2} name={'line_2'} />
          </>
        )}
      </div>
    </>
  );
};
export default DirectReport;
