import { Spin } from 'antd';
import { isArray, isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import styled from 'styled-components';
import { Arrow_Down_2, Arrow_Up_2, avatar, Compare_2 } from '../../../assets/img';
import { BreadCrumb, ModelTC } from '../../../components';
import GlobalAlertMessage from '../../../components/GlobalAlertMessage/GlobalAlertMessage';
import ProposalSetting from '../../../components/ProposalSetting/ProposalSetting';
import SuccessorComparison from '../../../components/SuccessorComparison/SuccessorComparison';
import { useShowPopupSaveAgenda } from '../../../hooks/useShowPopupSaveAgenda';
import { useUpdatePdcMobilityStepNumber } from '../../../hooks/useUpdatePdcMobilityStepNumber';
import { pdcMeetingApi } from '../../../services/pdcMeetingApi';
import { NOTIFICATION_TYPE, PROPOSAL_LABEL, PROPOSAL_SELECT_OPTIONS, SELECT_ALL_LABEL, USER_ACCESS_MESSAGE } from '../../../utils/constants';
import { getBreadCrumbList } from '../../../utils/helper';
import { showNotification } from '../../../utils/notification';
import MobilityBotButtonControls from '../components/MobilityButtonControls/MobilityBotButtonControls';
import MobilityTopButtonControls from '../components/MobilityButtonControls/MobilityTopButtonControls';
import MobilityFinalizeBar from '../components/MobilityFinalizeBar/MobilityFinalizeBar';
import PdcMobilityProcessBar from '../components/PdcMobilityProcessBar/PdcMobilityProcessBar';
import PdcProposalDetail from './components/PdcProposalItem/PdcProposalDetail';
import * as styles from './pdc-proposal-list.module.scss';

const CustomBtn = styled.button`
  border: none;
  background: transparent;
  padding: 5px 7px;

  img {
    width: 24px;
    height: 24px;
  }
`;

const BREAD_CRUMB = getBreadCrumbList(['Homepage', 'PDC Meeting', 'Mobility Agenda', 'Proposal']);

const PdcProposalList = (props) => {
  const history = useHistory();
  const { idMeeting, mobilityId, proposalId } = useParams();
  const userInfos = useSelector((state) => state.user);
  const { roleId } = useSelector((state) => state.user.roleActive);
  const [loading, setLoading] = useState(false);
  const [proposalList, setProposalList] = useState([]);
  const [itemExpand, setItemExpand] = useState(null);
  const [compareItem, setCompareItem] = useState(null);
  const [initProposalOptions, setInitProposalOptions] = useState([]);
  const [proposalType, setProposalType] = useState(null);
  const [proposalItemList, setProposalItemList] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isBack, setIsBack] = useState(false);
  const [compareData, setCompareData] = useState({
    initData: [],
    currentData: [],
  });
  const [isOpenSettingHasType, setIsOpenSettingHasType] = useState(false);
  const dispatch = useDispatch();

  // Get the condition to edit mobility agenda
  const {
    isOnlyViewAgenda,
    isShowTopButtons,
    isShowBotButtons,
    isAdditionalViewAccess,
    meetingDetail,
    mobilityDetails,
    isEnableMobilityTab,
    proposalMainStep,
    fetchMobilityDetails,
    isShownMobilityTabBar,
    isHRPartners,
    isChairMainOrCommiteeOrAdvocator,
  } = props;
  const { updateStepNumber } = useUpdatePdcMobilityStepNumber();

  // Update current data to compare value
  useEffect(() => {
    setCompareData((prev) => ({
      ...prev,
      currentData: proposalList,
    }));
  }, [proposalList]);

  const isChangeProposalList = useMemo(() => {
    return JSON.stringify(compareData.initData) !== JSON.stringify(compareData.currentData);
  }, [compareData]);

  const { isShowPopupSaveAgenda, handleKeepNavigate, setIsShowPopupSaveAgenda } = useShowPopupSaveAgenda(isChangeProposalList && !isBack);

  const fetchProposalList = useCallback(async () => {
    if (!mobilityId) return;
    try {
      setLoading(true);
      const res = await pdcMeetingApi.getProposalList(mobilityId, proposalId);
      if (res.status === 200) {
        const tempData = !isEmpty(res?.data?.result) ? res.data.result.map((i) => ({ ...i, proposal_item_list: i?.proposal_settings || [] })) : [];
        const tempDataHassuccessors = isArray(tempData)
          ? tempData.map((item) => ({
              ...item,
              proposal_compare_successors: isArray(item?.proposal_compare_successors)
                ? item?.proposal_compare_successors.map((successor) => ({
                    ...successor,
                    ...(successor?.staffInfo || {}),
                    experiences_checked: !isEmpty(successor?.experiences) ? successor?.experiences.filter((ex) => ex?.checked) : [],
                  }))
                : [],
            }))
          : [];
        setProposalList(tempDataHassuccessors);
        setCompareData((prev) => ({
          ...prev,
          initData: tempDataHassuccessors,
          currentData: tempDataHassuccessors,
        }));
      }
    } catch (error) {
      console.error(error);
      showNotification(USER_ACCESS_MESSAGE.AN_UNEXPECTED_ERROR, NOTIFICATION_TYPE.FAILED);
    } finally {
      setLoading(false);
    }
  }, [mobilityId]);

  // Fetch mobility in the first time
  useEffect(() => {
    fetchProposalList();
  }, [fetchProposalList]);

  // Fetch proposal type
  useEffect(() => {
    const fetchProposalTypes = async () => {
      try {
        const resProposalType = await pdcMeetingApi.getListProposalType();
        if (resProposalType.status === 200) {
          const tempData = resProposalType?.data?.result;
          setInitProposalOptions(
            !isEmpty(tempData)
              ? tempData.map((item) => ({
                  label: <span>{item.category}</span>,
                  title: item.category,
                  options: !isEmpty(item.proposal_types)
                    ? item.proposal_types.map((option) => ({
                        label: <span>{option.name}</span>,
                        value: option.name,
                      }))
                    : [],
                }))
              : []
          );
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchProposalTypes();
  }, []);

  // Fetch proposal item list
  useEffect(() => {
    if (!proposalType || isOpenSettingHasType) return;

    const fetchProposalItemList = async () => {
      try {
        setLoading(true);
        const res = await pdcMeetingApi.getProposalItemList(proposalType);
        if (res.status === 200) {
          const arr = [
            { label: SELECT_ALL_LABEL, value: PROPOSAL_LABEL.SELECT_ALL, checked: false },
            ...(res.data?.result.flatMap(({ children, ...item }) => {
              if (children.length > 0) return [{ ...item }, ...children]; // if the object is empty or has one one object then return the object
              return children;
            }) || []),
          ];
          // eslint-disable-next-line no-unused-vars
          setProposalItemList(arr.filter((i) => i.checked).map(({ checked, ...m }) => m));
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchProposalItemList();
  }, [proposalType, isOpenSettingHasType]);

  const handleReturnProposalItemList = (dataList) => {
    const isValid = !isEmpty(dataList) ? isEmpty(dataList[0]?.children) : false;
    return isValid
      ? PROPOSAL_SELECT_OPTIONS.slice(1).map((m) => {
          if (!isEmpty(m.children)) {
            const childrenUpdate = m.children.map((child) => ({
              ...child,
              checked: dataList.find((f) => f.value === child.value) ? true : false,
            }));
            return {
              ...m,
              checked: dataList.find((f) => f.value === m.value) ? true : false,
              children: childrenUpdate,
            };
          }
          return { ...m, checked: dataList.find((f) => f.value === m.value) ? true : false };
        })
      : dataList;
  };

  // Fetch template content
  const fetchTemplateContent = async (iExpandItem, settings, isApplied) => {
    if (isEmpty(settings) || !iExpandItem || !isApplied) return;

    try {
      setLoading(true);
      const res = await pdcMeetingApi.getTemplateContent({
        staff_id: iExpandItem?.staff_id,
        position_code: iExpandItem?.position_code,
        mobility_id: iExpandItem?.mobility_id,
        mobility_proposal_id: proposalId,
        data: handleReturnProposalItemList(settings),
        roleId,
      });
      if (res.status === 200) {
        setProposalList((prev) => {
          return !isEmpty(prev)
            ? prev.map((item) => {
                return item?.staff_id === iExpandItem?.staff_id ? { ...item, proposal_settings: res?.data?.result } : item;
              })
            : [];
        });
        setCompareData((prev) => ({
          ...prev,
          initData: isArray(prev?.initData)
            ? prev.initData.map((i) => {
                return i?.staff_id === iExpandItem?.staff_id ? { ...i, proposal_settings: res?.data?.result } : i;
              })
            : [],
        }));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleClickBack = async () => {
    setIsBack(true);
    if (isChangeProposalList) {
      await handleSaveAsDraft();
    }
    history.push(`/pdc-meeting/${idMeeting}/mobility/${mobilityId}`);
  };

  const handleSaveBeforeExit = async () => {
    setIsBack(true);
    setIsShowPopupSaveAgenda(false);
    await handleSaveAsDraft();
    handleKeepNavigate();
  };

  const handleSaveAsDraft = async () => {
    if (!roleId) return;

    try {
      setLoading(true);
      const payload = !isEmpty(proposalList)
        ? proposalList.map((item, index) => {
            const tempSuccessor = item?.proposal_settings?.positionInfo?.identifiedSuccessors?.successorList;
            const tempReplacement = item?.proposal_settings?.staffInfo?.replacement?.replacement_staff_ids;
            const tempFirstLineSuccessor = item?.proposal_settings?.staffInfo?.successor_to_1st_line;
            return {
              order: index + 1,
              staff_id: item?.staff_id,
              mobility_id: item?.mobility_id,
              mobility_proposal_id: proposalId,
              position_code: item?.position_code,
              proposal_type: item?.proposal_type,
              replacement_staff_lists: !isEmpty(tempReplacement) ? tempReplacement.map((i) => i.staffId || i.staff_id) : [],
              replacement_save_status: item?.proposal_settings?.staffInfo?.replacement?.save,
              replacement_input_value: item?.proposal_settings?.staffInfo?.replacement?.replacement_input_value,
              previous_contract_details: item?.proposal_settings?.staffInfo?.previous_contract_details,
              previous_secondment: item?.proposal_settings?.staffInfo?.previous_secondment,
              identified_successor_staff_lists: !isEmpty(tempSuccessor) ? tempSuccessor.map((s) => s.staffId || s.staff_id) : [],
              successor_first_line_position_code_lists: !isEmpty(tempFirstLineSuccessor)
                ? tempFirstLineSuccessor.map((s) => s.position_code || s.position_code)
                : [],
              identified_successor_save_status: item?.proposal_settings?.positionInfo?.identifiedSuccessors?.save,
              identified_successor_input_value: item?.proposal_settings?.positionInfo?.identifiedSuccessors?.identified_successor_input_value,
              justification: item?.proposal_settings?.justificationInfo?.justification,
              decision_remarks: item?.proposal_settings?.decisionInfo?.decision_remarks,
              effective_start_date: item?.proposal_settings?.decisionInfo?.effectiveStartDate,
              effective_end_date: item?.proposal_settings?.decisionInfo?.effectiveEndDate,
              contract_start_date: item?.proposal_settings?.decisionInfo?.contractStartDate,
              contract_end_date: item?.proposal_settings?.decisionInfo?.contractEndDate,
              decision: item?.proposal_settings?.decisionInfo?.decision?.selectedDecision,
              remarks: item?.proposal_settings?.decisionInfo?.remarks,
              proposal_compare_successors: item?.proposal_compare_successors,
              proposal_settings: !isEmpty(item?.proposal_item_list) ? handleReturnProposalItemList(item?.proposal_item_list) : [],
              note: item?.proposal_settings?.staffInfo?.note,
              current_incumbent_input_value: item?.proposal_settings?.positionInfo?.current_incumbent_input_value,
            };
          })
        : [];
      const res = await pdcMeetingApi.updateProposalList(payload, roleId, userInfos);
      if (res.status === 200) {
        if (proposalMainStep?.step < 2) {
          await updateStepNumber(mobilityId, 2);
        }
        setCompareData((prev) => ({
          ...prev,
          initData: prev.currentData,
        }));
        return true;
      }
    } catch (error) {
      console.error(error);
      showNotification(USER_ACCESS_MESSAGE.AN_UNEXPECTED_ERROR, NOTIFICATION_TYPE.FAILED);
    } finally {
      setLoading(false);
    }
  };

  const handleClickNext = async () => {
    if (proposalMainStep?.step < 2) {
      await updateStepNumber(mobilityId, 2);
    }
    history.push(`/pdc-meeting/${idMeeting}/mobility/${mobilityId}/assessment/${proposalId}?mobilityType=single`);
  };

  const handleReturnExpandCollapseClass = (itemExpand, currentItem) => {
    return itemExpand?.staff_id === currentItem?.staff_id ? styles.expanded : styles.collapsed;
  };

  const fetchTalentDetails = async (record) => {
    try {
      setLoading(true);
      const res = await pdcMeetingApi.getStaffDetails(record?.staffId || record?.staff_id);
      if (res.status === 200) {
        const talentDetails = isArray(res?.data?.result) ? res?.data?.result : [];
        const tempData = !isEmpty(proposalList)
          ? proposalList.map((item) => {
              if (item?.staff_id === record?.staff_id) {
                return {
                  ...item,
                  proposal_compare_successors: !isEmpty(talentDetails)
                    ? talentDetails.map((successor) => {
                        if (successor?.staff_id === record?.staff_id) {
                          return {
                            ...successor,
                            experiences_checked: successor?.experiences || [],
                          };
                        } else {
                          return successor;
                        }
                      })
                    : [],
                };
              } else {
                return item;
              }
            })
          : [];
        setCompareData({
          initData: tempData,
          currentData: tempData,
        });
        setProposalList(tempData);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleCompareTalent = async (record) => {
    if (!isEmpty(record?.proposal_compare_successors)) {
      setProposalList((prev) => {
        if (!isEmpty(prev)) {
          return prev.map((item) => {
            if (item?.staff_id === record?.staff_id) {
              return {
                ...item,
                proposal_compare_successors: record.proposal_compare_successors.map((i) => ({
                  ...i,
                  ...(i?.staffInfo || {}),
                  experiences_checked: !isEmpty(i?.experiences) ? i?.experiences.filter((ex) => ex?.checked) : [],
                })),
              };
            } else {
              return item;
            }
          });
        }
      });
    } else {
      await fetchTalentDetails(record);
    }
    setCompareItem(record);
  };

  const handleExpandCollapseItem = async (record) => {
    setItemExpand(record);

    if (!record?.proposal_type) {
      setProposalItemList([]);
      setProposalType(null);
      return;
    }

    const isCheck = !isEmpty(record?.proposal_item_list) ? isEmpty(record?.proposal_item_list[0]?.children) : false;
    const tempSettings = isCheck
      ? record?.proposal_item_list
      : record?.proposal_item_list
          .map((m) => {
            if (!isEmpty(m.children)) {
              return {
                ...m,
                children: m.children.filter((child) => child?.checked),
              };
            }
            return { ...m };
          })
          .flatMap(({ children, ...item }) => {
            if (children.length > 0) return [{ ...item }, ...children];
            return children;
          }) || [];
    setProposalItemList(tempSettings);
    setProposalType(record?.proposal_type);

    await fetchTemplateContent(record, tempSettings, true);
  };

  const handleOpenSettingProposal = (record) => {
    if (!record?.proposal_type) {
      setProposalItemList([]);
      setProposalType(null);
      return;
    }

    const isCheck = !isEmpty(record?.proposal_item_list) ? isEmpty(record?.proposal_item_list[0]?.children) : false;
    setIsOpenSettingHasType(true);
    setProposalItemList(
      isCheck
        ? record?.proposal_item_list
        : record?.proposal_item_list
            .map((m) => {
              if (!isEmpty(m.children)) {
                return {
                  ...m,
                  children: m.children.filter((child) => child?.checked),
                };
              }
              return { ...m };
            })
            .flatMap(({ children, ...item }) => {
              if (children.length > 0) return [{ ...item }, ...children];
              return children;
            }) || []
    );
    setProposalType(record?.proposal_type);
  };

  const getProposalPrevValue = (record) => {
    let tempData;
    if (!isEmpty(compareData.initData)) {
      compareData.initData.forEach((i) => {
        if (i?.staff_id === record?.staff_id) {
          tempData = i;
        }
      });
    }
    return tempData;
  };

  return (
    <>
      <div style={{ marginBottom: '15px' }}>
        <BreadCrumb level={4} breadCrumbList={BREAD_CRUMB} />
      </div>
      <GlobalAlertMessage style={{ marginBottom: '20px' }} />
      {isShowTopButtons && (
        <MobilityTopButtonControls
          idMeeting={idMeeting}
          mobilityId={mobilityId}
          mobilityDetails={mobilityDetails}
          fetchMobility={fetchMobilityDetails}
          isAdditionalViewAccess={isAdditionalViewAccess}
          isHRPartners={isHRPartners}
          isChairMainOrCommiteeOrAdvocator={isChairMainOrCommiteeOrAdvocator}
          meetingDetail={meetingDetail}
        />
      )}
      {isShownMobilityTabBar ? (
        <MobilityFinalizeBar mobilityDetails={mobilityDetails} />
      ) : (
        <PdcMobilityProcessBar currentStep={2} mobilityDetails={mobilityDetails} isEnableMobilityTab={isEnableMobilityTab} />
      )}

      <Spin spinning={loading}>
        <div className={styles.proposalWrap}>
          <div className={styles.mainTitle}>Proposal List</div>
          <div className={styles.proposalList}>
            {!isEmpty(proposalList) &&
              proposalList.map((item, index) => (
                <div key={`${item.staff_id}.${index}`} className={styles.proposalItem}>
                  <div className={styles.proposalItemHead}>
                    <h3>Proposal {index + 1}</h3>
                  </div>
                  <div className={`${styles.proposalItemContent} ${handleReturnExpandCollapseClass(itemExpand, item)}`}>
                    <div className={styles.infoLeft}>
                      <div className={styles.avatar}>
                        <img src={item.image || avatar} alt="" />
                      </div>
                      <div className={styles.name} style={{ width: '230px' }}>
                        <span className={styles.fieldLabel}>Name</span>
                        <span className={styles.fieldValue}>{item.birth_name || '-'}</span>
                      </div>
                    </div>
                    <div className={styles.position} style={{ width: '320px' }}>
                      <span className={styles.fieldLabel}>Position</span>
                      <span className={styles.fieldValue}>{item.position_name}</span>
                    </div>
                    <div className={styles.type} style={{ width: '320px' }}>
                      {item.proposal_type}
                    </div>
                    <div className={styles.listAction}>
                      <CustomBtn>
                        <img src={Compare_2} alt="" onClick={() => handleCompareTalent(item)} />
                      </CustomBtn>
                      {!(isOnlyViewAgenda || isHRPartners) && (
                        <CustomBtn>
                          <ProposalSetting
                            defaultValues={!isEmpty(proposalItemList) ? proposalItemList : []}
                            initProposalOptions={initProposalOptions}
                            setInitProposalOptions={setInitProposalOptions}
                            selectedOptions={selectedOptions}
                            setSelectedOptions={setSelectedOptions}
                            setProposalType={setProposalType}
                            proposalType={proposalType}
                            currentItem={item}
                            setItemExpand={setItemExpand}
                            setProposalList={setProposalList}
                            fetchTemplateContent={fetchTemplateContent}
                            handleOpenSettingProposal={handleOpenSettingProposal}
                            setIsOpenSettingHasType={setIsOpenSettingHasType}
                          />
                        </CustomBtn>
                      )}
                      <CustomBtn>
                        {(!itemExpand || itemExpand?.staff_id !== item.staff_id) && (
                          <img src={Arrow_Down_2} alt="" onClick={() => handleExpandCollapseItem(item)} />
                        )}
                        {itemExpand?.staff_id === item.staff_id && <img src={Arrow_Up_2} alt="" onClick={() => setItemExpand(null)} />}
                      </CustomBtn>
                    </div>
                  </div>
                  <div key={item.staff_id} className={`${styles.proposalItemDetail} ${handleReturnExpandCollapseClass(itemExpand, item)}`}>
                    <PdcProposalDetail
                      key={item.staff_id}
                      data={item}
                      setProposalList={setProposalList}
                      isOnlyViewAgenda={isOnlyViewAgenda || isHRPartners}
                      prevData={getProposalPrevValue(item)}
                    />
                  </div>
                  {compareItem?.staff_id === item?.staff_id && (
                    <SuccessorComparison
                      isPopupTalentComparison={!isEmpty(compareItem)}
                      setIsPopupTalentComparison={setCompareItem}
                      currentTalent={compareItem}
                      setProposalList={setProposalList}
                      proposalList={proposalList}
                      isOnlyViewAgenda={isOnlyViewAgenda || isHRPartners}
                      fetchProposalList={fetchProposalList}
                    />
                  )}
                </div>
              ))}
          </div>
        </div>

        {isShowBotButtons && (
          <MobilityBotButtonControls
            idMeeting={idMeeting}
            mobilityId={mobilityId}
            isDisabled={false}
            handleSaveAsDraft={handleSaveAsDraft}
            mobilityDetails={mobilityDetails}
            fetchMobility={fetchMobilityDetails}
            handleClickNext={handleClickNext}
            handleClickBack={handleClickBack}
            isOnlyViewAgenda={isOnlyViewAgenda}
            setIsBack={setIsBack}
            isHRPartners={isHRPartners}
            isAdditionalViewAccess={isAdditionalViewAccess}
            isChairMainOrCommiteeOrAdvocator={isChairMainOrCommiteeOrAdvocator}
            meetingDetail={meetingDetail}
          />
        )}
      </Spin>

      <ModelTC
        info={{
          type: 'withoutSaving',
          visible: isShowPopupSaveAgenda,
          setVisible: setIsShowPopupSaveAgenda,
          onClose: handleKeepNavigate,
          handleSubmit: handleSaveBeforeExit,
        }}
      />
    </>
  );
};

export default PdcProposalList;
