import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep, isArray } from 'lodash';

const INITIAL_STATE = {
  temporaryFiles: [],
};

const pdcSupportingDocumentSlice = createSlice({
  name: 'pdcSupportingDocuments',
  initialState: { ...INITIAL_STATE },
  reducers: {
    addNewFile: (state, action) => {
      let files = cloneDeep(state.temporaryFiles);
      if (isArray(action.payload)) {
        action.payload.forEach((f) => {
          let tempIndex = files.findIndex((item) => item?.tempId === f?.tempId);
          if (tempIndex !== -1) {
            files[tempIndex] = { ...f };
          } else {
            files = [...files, f];
          }
        });
        state.temporaryFiles = files;
      }
    },
    removeTempFileUploadingFail: (state) => {
      let files = cloneDeep(state.temporaryFiles);
      files.splice(-1);
      state.temporaryFiles = files;
    },
    updateTempFile: (state, action) => {
      let files = cloneDeep(state.temporaryFiles);
      state.temporaryFiles = files.map((i) => {
        if (i?.tempId === action?.payload?.tempId) {
          return action.payload;
        } else {
          return i;
        }
      });
    },
    removeTempFile: (state, action) => {
      let files = cloneDeep(state.temporaryFiles);
      let tempData = files.filter((item) => item.tempId !== action.payload.tempId);
      state.temporaryFiles = tempData;
    },
    resetFile: (state) => {
      state.temporaryFiles = [];
    },
  },
});
const { actions, reducer: pdcSupportingDocumentsReducer } = pdcSupportingDocumentSlice;
export const {
  addNewFile,
  removeTempFileUploadingFail,
  removeTempFile,
  resetFile,
  updateTempFile,
} = actions;
export default pdcSupportingDocumentsReducer;
