import { Col, Collapse, Input, Row } from 'antd';
import { cloneDeep, get, isArray, isEmpty, size, sortBy } from 'lodash';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';

import {
  AiAlert,
  AiBtn,
  AiCheckBox,
  AiError,
  AiHeader,
  AiPanel,
  AiSpan,
  AiSpin,
  AiTitle,
  Container,
  Header,
  Navigate,
} from '../../../../assets/common';
import { chevron_down_arrow, chevron_up_arrow, edit_icon_role, pets_chevron_right } from '../../../../assets/img';
import { adminApi } from '../../../../services/admin';
import { NEW_ACCESS_LEVEL_OPTIONS, NOTIFICATION_TYPE, PERMISSION, PERMISSION_ORDER, USER_ID } from '../../../../utils/constants';
import { showNotification } from '../../../../utils/notification';
import { roleIncumbentSelector } from '../../../selector';
import DropdownAccessRole from '../../components/DropdownAccessRole/DropdownAccessRole';
import {
  getPermissions,
  getRoleLevels,
  getSettingRole,
  updateBuAccessPermission,
  updateDisablePermission,
  updateStatusPermission,
} from '../RoleList/rolesSlice';
import * as styles from './role-detail.module.scss';

const RoleDetail = () => {
  const { roleId, type } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading2, setLoading2] = useState(false);
  const [loadingRoleName, setLoadingRoleName] = useState(false);
  const [groupSPM, setGroupSPM] = useState(false);

  const [permissionSetting, setPermissionSetting] = useState([]);
  const [nameRoleIncumbent, setNameRoleIncumbent] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [hasError, setHasError] = useState(false);
  const setSizeOpt = useState(0)[1];

  const roleInCumbent = useSelector(roleIncumbentSelector);
  const user = useSelector((state) => state.user);
  const userPermissions = user?.permissions;
  const roleActive = useSelector((state) => state.user.roleActive);
  const { permissions, loading } = useSelector((state) => state.app.adminModule.roleManagement);
  const [tempChecked, setTempChecked] = useState([]);
  const [isCheckedNewOption, setIsCheckedNewOption] = useState(false);
  const [noDisclaimer] = useState([PERMISSION.SP_READINESS_RULE]);
  const [accessLevelOptions, setAccessLevelOptions] = useState([]);

  // const mock_accessLevel = [
  //   {
  //     id: 1,
  //     name: 'PETRONAS',
  //   },
  //   {
  //     id: 2,
  //     name: 'Business',
  //   },
  //   {
  //     id: 3,
  //     name: 'OPU/Division',
  //   },
  //   {
  //     id: 4,
  //     name: 'Job Family',
  //   },
  // ];

  useEffect(() => {
    const fetchFilterAccessLevel = async () => {
      try {
        setLoading2(true);
        const res = await adminApi.getFilterAccessLevel(roleId);
        if (res.status === 200) {
          setAccessLevelOptions(!isEmpty(res?.data?.result) ? sortBy(res?.data?.result, ['id']) : []);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading2(false);
      }
    };
    fetchFilterAccessLevel();
  }, [roleId, setAccessLevelOptions]);

  useEffect(() => {
    if (permissions && !isCheckedNewOption) {
      let tempCheckedArr = [];
      permissions.forEach((item) => {
        item.forEach((ele) => {
          if (typeof ele === 'string') return;

          ele.forEach((subEle) => {
            if (subEle.permission_checked) {
              tempCheckedArr.push(`${subEle?.namePermission}_${subEle?.idPermission}`);
            }

            if (subEle?.permissions) {
              let tempSubPer = [];
              subEle.permissions.forEach((item) => {
                if (item.permission_checked) {
                  tempSubPer.push(item.namePermission);
                }
              });

              if (!isEmpty(tempSubPer)) {
                tempCheckedArr.push(subEle?.groupName);
                setGroupSPM(true);
              }

              tempCheckedArr.push(...tempSubPer);
            }
          });
        });
      });
      setTempChecked(tempCheckedArr);
    }
  }, [permissions, isCheckedNewOption, setTempChecked]);

  /*
   * Function: Got name role that was selected
   * Goal : Got the name of role
   * Input: id: number, role: object
   * Return none
   */
  const getFullNameOfRole = async () => {
    setLoadingRoleName(true);
    try {
      const res = await adminApi.getNameRoleIncumbent({ role: roleActive, roleId });
      if (res.status === 200) {
        setNameRoleIncumbent(res.data.result[0].fullname);
      }
      setLoadingRoleName(false);
    } catch (error) {
      setLoadingRoleName(false);
    }
  };

  const getListPermisison = async () => {
    await getFullNameOfRole();
    let resGetPermissions = await getListPermissions({ role: roleActive });
    if (resGetPermissions?.payload.error) {
      showNotification(`${resGetPermissions.payload.message}`, NOTIFICATION_TYPE.FAILED);
      return;
    }
    let resGetRoleLevels = await getListRoleLevels({ id: USER_ID, role: roleActive });
    if (!resGetRoleLevels?.payload.error) {
      setSizeOpt(resGetRoleLevels?.payload.size);
    }
    if (resGetRoleLevels?.payload.error) {
      showNotification(`${resGetRoleLevels?.payload.message}`, NOTIFICATION_TYPE.FAILED);
    }
  };
  const hanleCheckSelectAll = (data) => {
    let cloneData = cloneDeep(data);
    if (size(data) === 6) {
      cloneData.push(0);
    }
    return cloneData;
  };
  /*
   * Function: Got role that was selected
   * Goal : Got the value of role that was selected
   * Input: id: number, role: object
   * Return none
   */
  const getRolesIncumbent = async () => {
    try {
      let res = await adminApi.getRoleIncumbent({ role: roleActive, roleId });

      if (res.status === 200 && size(res.data.result) > 0) {
        const convertResCheckRole = res.data.result.map((ele) => {
          return {
            ...ele,
            is_changed: false,
            role_access: {
              type: ele.role_access.type,
              role: get(ele.role_access, 'role', []),
            },
          };
        });
        const convertRoleAccess = convertResCheckRole.map((ele) => {
          if (size(ele.role_access.role) === 0) return ele;
          return {
            ...ele,
            role_access: {
              type: ele.role_access.type,
              role: ele.role_access.role.map((eleR) => {
                if (eleR === 'VP') {
                  return 1;
                }
                if (eleR === 'SGM') {
                  return 2;
                }
                if (eleR === 'GM') {
                  return 3;
                }
                if (eleR === 'SM') {
                  return 4;
                }
                if (eleR === 'Executive') {
                  return 5;
                }
                if (eleR === 'M') {
                  return 6;
                }
                return null;
              }),
            },
          };
        });
        const roleAccessAll = convertRoleAccess.map((ele) => {
          if (size(ele.role_access.role) === 0) return ele;
          return {
            ...ele,
            role_access: {
              type: ele.role_access.type,
              role: hanleCheckSelectAll(ele.role_access.role),
            },
          };
        });
        const convertRes = convertResCheckRole.map((item) => ({
          permission_id: item.permission_id,
          // bu_access: item.bu_access,
          access_level_name: item?.access_level_name,
          access_level_id: item?.access_level_id,
          permission_checked: item?.permission_checked ? true : false,
          is_changed: item?.is_changed,
          role_access: {
            ...item.role_access,
            role: item.role_access.role.map((eleR) => {
              if (eleR === 'VP') {
                return 1;
              }
              if (eleR === 'SGM') {
                return 2;
              }
              if (eleR === 'GM') {
                return 3;
              }
              if (eleR === 'SM') {
                return 4;
              }
              if (eleR === 'Executive') {
                return 5;
              }
              if (eleR === 'M') {
                return 6;
              }
              return null;
            }),
          },
        }));
        setPermissionSetting(convertRes);
        dispatch(getSettingRole(roleAccessAll));
      }
    } catch (error) {
      setPermissionSetting([]);
    }
  };

  /*
   * Function: Get list role levels
   * Goal : Get list role access levels for role detail page
   * Input: id: number, role: object
   * Return object {error : boolean , message : string}
   */
  const getListRoleLevels = async (userPermission) => {
    let result = dispatch(getRoleLevels(userPermission));
    return result;
  };

  /*
   * Function: Get list role permissions
   * Goal : Get list role permissions for role detail page
   * Input: id: number, role: object
   * Return object {error : boolean , message : string}
   */
  const getListPermissions = async (userPermission) => {
    let result = dispatch(getPermissions(userPermission));
    return result;
  };

  // Get Role Details
  const getRoleDetai = async () => {
    await getListPermisison();
    await getRolesIncumbent();
  };
  useEffect(() => {
    getRoleDetai();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleId, roleInCumbent]);

  const handleGetPerSetting = (item, roles, roleType, is_changed, bu_access) => {
    return {
      ...item,
      permission_id: item?.idPermission,
      permission_checked: item?.permission_checked ? true : false,
      // bu_access: !bu_access ? item?.id : bu_access,
      access_level_name: item?.name,
      access_level_id: item?.id || bu_access,
      role_access: { type: roleType, role: roles },
      is_changed,
    };
  };

  /*
   * Function: Update permission state according to dropdown value
   * Goal : Update permission field bu_access of permission state and store
   * Input: id : number , idPermission : number, name(selected) : string, namePermission : string,permission : string
   * Return null
   */
  const onChangeDropdown = (option) => {
    if (size(permissionSetting) === 0) {
      const perSetting = handleGetPerSetting(option, [], 'role', true);
      setPermissionSetting((perState) => [...perState, perSetting]);
    }
    if (size(permissionSetting) > 0) {
      let idxPer = permissionSetting.findIndex((ele) => ele.permission_id === option.idPermission);
      /// The permission setting doesn't exist
      if (idxPer === -1) {
        const perSetting = handleGetPerSetting(option, [], 'role', true);
        setPermissionSetting((perState) => [...perState, perSetting]);
      } else {
        /// The permission setting exsit
        /// change value of bu access
        setPermissionSetting((preState) => {
          return preState.map((el, idxEl) => {
            if (idxEl === idxPer) {
              return {
                ...el,
                // bu_access: option.id,
                access_level_name: option?.name,
                access_level_id: option?.id,
                permission_checked: el?.permission_checked ? true : false,
                is_changed: true,
              };
            }
            return el;
          });
        });
      }
    }
    dispatch(updateBuAccessPermission(option));
  };

  /*
   * Function: Update permission state according to checkbox value
   * Goal : Update permission field role in role_access
   * Input: Object include idPermission :number, namePermission :string, optId :Number, optList :Array Object ,permission :String
   * Return null
   * Note: option.optId = 0 <=> selected 'All'
   */
  const onChangeCheckbox = (e, option) => {
    setIsCheckedNewOption(true);
    let idSettingsFilter = (!isEmpty(option?.optList) && option.optList.filter((item) => item.id !== 0)) || [];
    let idSettings = (!isEmpty(idSettingsFilter) && idSettingsFilter.map((ele) => ele.id)) || [];
    if (size(permissionSetting) === 0) {
      if (option.optId === 0) {
        const perSetting = handleGetPerSetting(option, idSettings, 'role', true, 1);
        setPermissionSetting((perState) => [...perState, perSetting]);
      } else {
        const perSetting = handleGetPerSetting(option, [option.optId], 'role', true, 1);
        setPermissionSetting((perState) => [...perState, perSetting]);
      }
    }
    if (size(permissionSetting) > 0) {
      let idxPer = permissionSetting.findIndex((ele) => ele.permission_id === option.idPermission);

      /// The permission setting doesn't exist
      if (idxPer === -1) {
        if (option.optId === 0) {
          const perSetting = handleGetPerSetting(option, idSettings, 'role', true, 1);
          setPermissionSetting((perState) => [...perState, perSetting]);
        } else {
          const perSetting = handleGetPerSetting(option, [option.optId], 'role', true, 1);
          setPermissionSetting((perState) => [...perState, perSetting]);
        }
      } else {
        /// The permission setting exsit
        /// Selected All
        if (option.optId === 0) {
          if (size(permissionSetting[idxPer].role_access.role) === size(idSettings)) {
            setPermissionSetting((preState) => {
              return preState.map((el, idxEl) => {
                if (idxEl === idxPer) {
                  return {
                    ...el,
                    role_access: {
                      ...el.role_access,
                      role: [],
                    },
                    is_changed: true,
                  };
                }
                return el;
              });
            });
          } else {
            setPermissionSetting((preState) => {
              return preState.map((el, idxEl) => {
                if (idxEl === idxPer) {
                  return {
                    ...el,
                    role_access: {
                      ...el.role_access,
                      role: idSettings,
                    },
                    is_changed: true,
                  };
                }
                return el;
              });
            });
          }
        } else {
          /// Checked element of role_access
          /// Selected One Element

          let idxEleRole = permissionSetting[idxPer].role_access.role.findIndex((eleR) => option.optId === eleR);

          /// element of role_access doesn't exist
          if (idxEleRole === -1) {
            setPermissionSetting((preState) => {
              return preState.map((el, idxEl) => {
                if (idxEl === idxPer) {
                  return {
                    ...el,
                    role_access: {
                      ...el.role_access,
                      role: [...el.role_access.role, option.optId],
                    },
                    is_changed: true,
                  };
                }
                return el;
              });
            });
          } else {
            /// element of role_access exist
            let deRoleAccess = permissionSetting[idxPer].role_access.role.filter((ele, index) => index !== idxEleRole);
            setPermissionSetting((preState) => {
              return preState.map((el, idxEl) => {
                if (idxEl === idxPer) {
                  return {
                    ...el,
                    role_access: {
                      ...el.role_access,
                      role: deRoleAccess,
                    },
                    is_changed: true,
                  };
                }
                return el;
              });
            });
          }
        }
      }
    }

    dispatch(updateStatusPermission(option));
  };

  const changeDisableCheckbox = (e, option) => {
    setIsCheckedNewOption(true);
    const { optList, idPermission } = option;
    const isEnable = e.target.checked;
    let idSettingsFilter = (!isEmpty(optList) && optList.filter((item) => item?.id !== 0)) || [];
    let idSettings = (!isEmpty(idSettingsFilter) && idSettingsFilter.map((ele) => ele?.id)) || [];
    if (isEnable) {
      let tempArr = [];
      const itemIdx = tempChecked.findIndex((item) => item === (option?.groupName || `${option?.namePermission}_${option?.idPermission}`));
      if (itemIdx === -1) {
        tempArr.push(option?.groupName || `${option?.namePermission}_${option?.idPermission}`);
      }
      if (!option?.groupName && option?.accLv?.groupName) {
        const itemGroupIdx = tempChecked.findIndex((item) => item === option?.accLv?.groupName);
        if (itemGroupIdx === -1) {
          tempArr.push(option?.accLv?.groupName);
        }
      }
      setTempChecked((prevState) => {
        return [...prevState, ...tempArr];
      });

      if (isArray(idPermission)) {
        let tempPermissions = [];
        let tempPermissionSettings = [];
        option.accLv.permissions.forEach((per) => {
          const itemIndx = tempChecked.findIndex((item) => item.namePermission === `${per?.namePermission}_${per?.idPermission}`);
          if (itemIndx === -1) {
            tempPermissions.push(`${per?.namePermission}_${per?.idPermission}`);
          }
          if (per.is_applicable && !tempChecked.includes(`${per?.namePermission}_${per?.idPermission}`)) {
            changeDisableCheckbox(e, {
              accLv: per,
              optId: 0,
              namePermission: per?.namePermission,
              permission: option.accLv.nameMoudule,
              idPermission: per.idPermission,
              optList: option.accLv.option,
              permission_checked: isEnable,
            });
          }
          tempPermissionSettings.push(returnNewPermissionSetting(per.idPermission, idSettings, true));
        });

        if (!isEmpty(tempPermissionSettings)) {
          let newPermissionSetting = cloneDeep(permissionSetting);
          tempPermissionSettings.forEach((item) => {
            newPermissionSetting = newPermissionSetting.filter((per) => per.permission_id !== item.permission_id);
          });
          setPermissionSetting(() => {
            return [...newPermissionSetting, ...tempPermissionSettings];
          });
        }

        setTempChecked((prevState) => {
          return !isEmpty(prevState) ? [...prevState, ...tempPermissions] : [...tempPermissions];
        });
      } else {
        const perSetting = returnNewPermissionSetting(idPermission, idSettings, true);
        const itemIndx = permissionSetting.findIndex((item) => item.permission_id === idPermission);
        if (itemIndx === -1) {
          setPermissionSetting((perState) => [...perState, perSetting]);
        } else {
          setPermissionSetting(() => {
            permissionSetting[itemIndx] = perSetting;
            return permissionSetting;
          });
        }
      }
      // Show/hide SPM Group
      if (option?.groupName) {
        setGroupSPM(true);
      }
    } else {
      let tempPermissionSettings = [];
      let tempArr = cloneDeep(tempChecked);
      setTempChecked(() => {
        if (!isEmpty(tempArr) && tempArr.includes(option?.groupName || `${option?.namePermission}_${option?.idPermission}`)) {
          tempArr = tempArr.filter((item) => item !== (option?.groupName || `${option?.namePermission}_${option?.idPermission}`));
          return tempArr;
        }
      });

      if (isArray(idPermission)) {
        option.accLv.permissions.forEach((per) => {
          if (!isEmpty(tempArr) && tempArr.includes(`${per?.namePermission}_${per?.idPermission}`)) {
            tempArr = tempArr.filter((item) => item !== `${per?.namePermission}_${per?.idPermission}`);
          }
          if (per.is_applicable && !tempArr.includes(`${per?.namePermission}_${per?.idPermission}`)) {
            changeDisableCheckbox(e, {
              accLv: per,
              optId: 0,
              namePermission: per?.namePermission,
              permission: option.accLv.nameMoudule,
              idPermission: per.idPermission,
              optList: option.accLv.option,
              permission_checked: isEnable,
            });
          }
          tempPermissionSettings.push(returnNewPermissionSetting(per.idPermission, [], false));
        });

        if (!isEmpty(tempPermissionSettings)) {
          let newPermissionSetting = cloneDeep(permissionSetting);
          tempPermissionSettings.forEach((item) => {
            newPermissionSetting = newPermissionSetting.filter((per) => per.permission_id !== item.permission_id);
          });
          setPermissionSetting(() => {
            return [...newPermissionSetting, ...tempPermissionSettings];
          });
        }

        if (!isEmpty(tempChecked) && !isEmpty(tempArr)) {
          setTempChecked(() => {
            return tempArr;
          });
        }
      } else {
        const perSetting = returnNewPermissionSetting(idPermission, [], false);
        const itemIndx = permissionSetting.findIndex((item) => item.permission_id === idPermission);
        if (itemIndx === -1) {
          setPermissionSetting((perState) => [...perState, perSetting]);
        } else {
          setPermissionSetting((perState) => {
            let tempArr = perState.filter((item) => item.permission_id !== idPermission);
            return tempArr;
          });
        }
      }
    }
    dispatch(updateDisablePermission({ ...option, isEnable }));
  };

  const returnNewPermissionSetting = (idPermission, idSettings, isChecked) => {
    return {
      permission_id: idPermission,
      // bu_access: 1,
      access_level_id: NEW_ACCESS_LEVEL_OPTIONS[0].id,
      access_level_name: NEW_ACCESS_LEVEL_OPTIONS[0].name,
      permission_checked: isChecked ? 1 : 0,
      role_access: { type: isChecked ? 'all' : 'role', role: idSettings },
      is_changed: true,
    };
  };

  /*
   * Function: Check the value of permission
   * Goal : Check the value of field is empty or not
   * Input: none
   * Return boolean
   */
  const handleCheckBuAccess = () => {
    const result = true;
    const synthesisPer = [];
    permissionSetting.forEach((item) => {
      synthesisPer.push(...item.role_access.role);
    });
    if (size(synthesisPer) === 0) {
      return result;
    }
    return !result;
  };

  /*
   * Function: Check type role access
   * Goal : if size permission > 5 => type of role_access : all. If not , type of role_access: role
   * Input: array of object
   * Return array of object after checking
   */
  const handleCheckTypeRoleAccess = (permissionSetting) => {
    let convertNameAccessRole = permissionSetting.map((ele) => {
      if (size(ele.role_access.role) === 0) return ele;
      let tempPerChecked;
      if (isArray(ele?.permission_id) && !isEmpty(ele?.permission_id)) {
        tempPerChecked = 1;
      } else {
        tempPerChecked = ele?.permission_checked ? 1 : 0;
      }

      return {
        ...ele,
        permission_checked: tempPerChecked,
        role_access: {
          ...ele.role_access,
          role: ele.role_access.role.map((eleR) => {
            if (eleR === 1) {
              return 'VP';
            }
            if (eleR === 2) {
              return 'SGM';
            }
            if (eleR === 3) {
              return 'GM';
            }
            if (eleR === 4) {
              return 'SM';
            }
            if (eleR === 5) {
              return 'Executive';
            }
            if (eleR === 6) {
              return 'M';
            }
            return eleR;
          }),
        },
      };
    });
    let newPermisstion = convertNameAccessRole.map((item) => {
      if (item.role_access.role.length === 6) {
        return {
          ...item,
          role_access: {
            type: 'all',
            role: item.role_access.role,
          },
        };
      }
      return {
        ...item,
        role_access: {
          type: 'role',
          role: item.role_access.role,
        },
      };
    });
    return newPermisstion;
  };

  /*
   * Function: Create Role Setting
   * Goal : Create Role Setting for new permission
   * Input: none
   * Return none
   */
  const createRoleSetting = async () => {
    const params = {
      id: USER_ID,
      role: roleActive,
      roleId,
      body: handleCheckTypeRoleAccess(permissionSetting),
    };
    try {
      setLoading2(true);
      const res = await adminApi.createRoleSetting(params);
      if (res.status === 200) {
        getRoleDetai();
      }
      setLoading2(false);
      showNotification('Add new role successful', NOTIFICATION_TYPE.SUCCESSFUL);
      history.push(`/admin/role-management`);
    } catch (error) {
      setLoading2(false);
      showNotification('Add new role failed', NOTIFICATION_TYPE.FAILED);
    }
  };

  /*
   * Function: Update Role Setting
   * Goal : Update Role Setting for new permission
   * Input: none
   * Return none
   */
  const updateRoleSetting = async () => {
    const params = {
      id: USER_ID,
      role: roleActive,
      roleId,
      body: handleCheckTypeRoleAccess(permissionSetting),
    };
    const paramEditName = {
      id: USER_ID,
      role: roleActive,
      roleId,
      body: {
        fullname: nameRoleIncumbent,
      },
    };

    try {
      setLoading2(true);
      /// should promise all
      const resUpdateRoleName = await adminApi.updateRoleName(paramEditName);
      const resUpdateRoleSetting = await adminApi.updateRoleSetting(params);
      if (resUpdateRoleSetting.status === 200 && resUpdateRoleName.status === 200) {
        getRoleDetai();
        setLoading2(false);
        showNotification('Update new role successful', NOTIFICATION_TYPE.SUCCESSFUL);
      }
    } catch (error) {
      setLoading2(false);
      showNotification('Update new role failed', NOTIFICATION_TYPE.FAILED);
    }
  };

  const handleEditNameRole = () => {
    let isError = handleCheckValidate();
    if (isError) {
      return;
    }
    setIsEdit((preState) => !preState);
  };
  const handleChangeFullName = (e) => {
    setNameRoleIncumbent(e.target.value);
  };
  const handleCheckValidate = () => {
    if (nameRoleIncumbent.length === 0) {
      setHasError(true);
      return true;
    }
    setHasError(false);
    return false;
  };

  const createOrUpdateRoleSetting = () => {
    let isError = handleCheckValidate();
    if (isError) return;
    let isNullBuAccess = handleCheckBuAccess();
    if (isNullBuAccess) {
      showNotification('Please select at least one role access level', NOTIFICATION_TYPE.FAILED);
      return;
    }
    if (type === 'create') {
      createRoleSetting();
    }
    if (type === 'update') {
      updateRoleSetting();
    }
  };

  const handleDisplayPermission = () => {
    const permissionToSort = [...permissions];

    const sortedPermissions = permissionToSort.sort((a, b) => {
      const indexA = PERMISSION_ORDER.indexOf(a[0]);
      const indexB = PERMISSION_ORDER.indexOf(b[0]);
      return (indexA === -1 ? Infinity : indexA) - (indexB === -1 ? Infinity : indexB);
    });
    if (size(permissions) === 0) return;
    return sortedPermissions.map((per, idx) => {
      return (
        <AiPanel
          header={
            <Row gutter={16}>
              <Col
                offset={1}
                span={2}
                style={{
                  paddingLeft: 0,
                  paddingRight: '16px',
                }}
              >
                <AiSpan title={'r'}>{idx + 1}</AiSpan>
              </Col>
              <Col span={4}>
                <AiSpan title={'r'}>{per[0]}</AiSpan>
              </Col>
            </Row>
          }
          key={idx + 1}
        >
          {typeof per[1] === 'object' &&
            per[1]?.map((accLv, idxLv) => {
              return (
                <div key={idxLv}>
                  <Row className="mb-2">
                    <Col span={2} style={{ justifyContent: 'center', display: 'flex' }}></Col>
                    <Col offset={1} span={5}>
                      {!accLv.is_applicable && accLv?.groupName ? (
                        <AiCheckBox
                          checked={!isEmpty(tempChecked) && tempChecked.includes(accLv?.groupName)}
                          onChange={(e) =>
                            changeDisableCheckbox(e, {
                              optId: 0,
                              groupName: accLv.groupName,
                              namePermission: accLv.groupName,
                              permission: per[0],
                              idPermission: accLv.permissions.map((x) => x.idPermission),
                              optList: accLv.option,
                              permission_checked: e.target.checked,
                              accLv,
                            })
                          }
                        >
                          {accLv.groupName}
                        </AiCheckBox>
                      ) : (
                        <AiCheckBox
                          checked={!isEmpty(tempChecked) && tempChecked.includes(`${accLv?.namePermission}_${accLv?.idPermission}`)}
                          onChange={(e) =>
                            changeDisableCheckbox(e, {
                              optId: 0,
                              namePermission: accLv.namePermission || accLv.permissions.map((x) => x.namePermission),
                              permission: per[0],
                              idPermission: accLv.idPermission,
                              optList: accLv.option,
                              permission_checked: e.target.checked,
                              accLv,
                            })
                          }
                        >
                          {accLv.namePermission}
                        </AiCheckBox>
                      )}
                    </Col>
                    <Col offset={2} span={9}>
                      {accLv?.is_applicable ? (
                        <Row>
                          {!isEmpty(accLv?.option) &&
                            accLv.option.map((opt, idxOpt) => (
                              <Col span={12} key={idxOpt} className="mb-1">
                                <AiCheckBox
                                  disabled={!accLv.permission_checked}
                                  checked={opt.checked}
                                  value={opt.id}
                                  onChange={(e) =>
                                    onChangeCheckbox(e, {
                                      accLv,
                                      optId: opt.id,
                                      namePermission: accLv.namePermission,
                                      permission: per[0],
                                      idPermission: accLv.idPermission,
                                      optList: accLv.option,
                                    })
                                  }
                                >
                                  {opt.name}
                                </AiCheckBox>
                              </Col>
                            ))}
                        </Row>
                      ) : (
                        <AiSpan>Not Applicable</AiSpan>
                      )}
                    </Col>
                    <Col span={3} style={{ justifyContent: 'center', display: 'flex', paddingLeft: '30px' }}>
                      {accLv?.is_applicable ? (
                        <DropdownAccessRole
                          disabled={!accLv.permission_checked}
                          selected={accLv.accessLevel}
                          setSelected={(e) =>
                            onChangeDropdown({
                              namePermission: accLv.namePermission,
                              permission: per[0],
                              idPermission: accLv.idPermission,
                              permission_checked: accLv?.permission_checked ? true : false,
                              ...e,
                            })
                          }
                          options={accessLevelOptions}
                        />
                      ) : (
                        <AiSpan>Not Applicable</AiSpan>
                      )}
                    </Col>
                    <Col span={2} style={{ textAlign: 'end' }}>
                      {accLv.group_id ? (
                        <AiSpan
                          style={{ cursor: 'pointer' }}
                          onKeyDown={() => {}}
                          onClick={() => (groupSPM ? setGroupSPM(false) : setGroupSPM(true))}
                        >
                          <img src={groupSPM ? chevron_up_arrow : chevron_down_arrow} alt={groupSPM ? 'chevron_up_arrow' : 'chevron_down_arrow'} />
                        </AiSpan>
                      ) : (
                        ''
                      )}
                    </Col>
                    {accLv.group_id && groupSPM
                      ? size(accLv.permissions) > 0 &&
                        accLv.permissions.map((perm, idxPerm) => (
                          <Row style={{ width: '91%', marginTop: '10px' }} key={idxPerm}>
                            <Col span={2} style={{ justifyContent: 'center', display: 'flex' }}></Col>
                            <Col offset={2} span={5} style={{ marginBottom: '5px' }} key={idxPerm}>
                              <AiCheckBox
                                checked={!isEmpty(tempChecked) && tempChecked.includes(`${perm?.namePermission}_${perm?.idPermission}`)}
                                onChange={(e) =>
                                  changeDisableCheckbox(e, {
                                    accLv: perm,
                                    optId: 0,
                                    namePermission: perm?.namePermission,
                                    permission: per[0],
                                    idPermission: perm.idPermission,
                                    optList: accLv.option,
                                    permission_checked: e.target.checked,
                                  })
                                }
                              >
                                {perm.namePermission.replace(/\u00A0/g, ' ')}
                              </AiCheckBox>
                            </Col>
                            <Col offset={2} span={9} style={perm.is_applicable ? { marginLeft: '80px' } : { marginLeft: '95px' }}>
                              {perm?.is_applicable ? (
                                <Row>
                                  {!isEmpty(perm?.option) &&
                                    perm.option.map((opt, idxOpt) => (
                                      <Col span={12} key={idxOpt} className="mb-1">
                                        <AiCheckBox
                                          disabled={!perm.permission_checked}
                                          checked={opt.checked}
                                          value={opt.id}
                                          onChange={(e) =>
                                            onChangeCheckbox(e, {
                                              accLv,
                                              optId: opt.id,
                                              groupName: perm?.groupName,
                                              namePermission: perm?.namePermission,
                                              permission: per[0],
                                              idPermission: perm.idPermission,
                                              optList: perm?.option,
                                            })
                                          }
                                        >
                                          {opt.name}
                                        </AiCheckBox>
                                      </Col>
                                    ))}
                                </Row>
                              ) : (
                                <AiSpan>Not Applicable</AiSpan>
                              )}
                            </Col>
                            <Col
                              span={3}
                              style={
                                perm.is_applicable
                                  ? {
                                      justifyContent: 'center',
                                      display: 'flex',
                                      paddingLeft: '33px',
                                      marginLeft: '57px',
                                    }
                                  : {
                                      justifyContent: 'center',
                                      display: 'flex',
                                      paddingLeft: '33px',
                                      marginLeft: '47px',
                                    }
                              }
                            >
                              {perm.is_applicable ? (
                                <DropdownAccessRole
                                  disabled={!perm.permission_checked}
                                  selected={perm.accessLevel}
                                  setSelected={(e) =>
                                    onChangeDropdown({
                                      namePermission: perm.namePermission,
                                      permission: per[0],
                                      idPermission: perm.idPermission,
                                      permission_checked: perm?.permission_checked ? true : false,
                                      ...e,
                                    })
                                  }
                                  options={accessLevelOptions}
                                />
                              ) : (
                                <AiSpan>Not Applicable</AiSpan>
                              )}
                            </Col>
                            <Col span={3}></Col>
                            {!isEmpty(tempChecked) &&
                              tempChecked.includes(`${perm?.namePermission}_${perm?.idPermission}`) &&
                              !noDisclaimer.includes(perm?.idPermission) &&
                              !perm.is_applicable && (
                                <Col className={styles.disclaimer} style={{ padding: '0 0 24px 72px' }}>
                                  <span>DISCLAIMER:</span> Position in Unsearchable Position List is not searchable except for role assigned with
                                  Profile Management.
                                </Col>
                              )}
                          </Row>
                        ))
                      : ''}
                  </Row>
                  {per[0] === 'Admin' && accLv.permission_checked && (
                    <Row>
                      <Col span={3}></Col>
                      <Col className={styles.disclaimer}>
                        <span>DISCLAIMER:</span> The module unsearchable list for talent or position will be applicable.
                      </Col>
                    </Row>
                  )}
                </div>
              );
            })}
        </AiPanel>
      );
    });
  };

  return (
    <>
      {!isEmpty(userPermissions) && userPermissions.includes(PERMISSION.AD_ROLE_MANAGEMENT) && (
        <Container getByTestId="role-detail">
          <Navigate>
            <Link to="/admin">
              <p>Admin</p>
            </Link>
            <img src={pets_chevron_right} alt="pets_chevron_right" />
            <Link to="/admin/role-management">
              <p>Role Management</p>
            </Link>
            <img src={pets_chevron_right} alt="pets_chevron_right" />
            <p
              style={{
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '24px',
                color: '#212121',
                textTransform: 'capitalize',
              }}
            >
              Role Management Setting
            </p>
          </Navigate>

          <Header>
            <div className="left">
              <AiTitle>
                {!isEdit ? (
                  <p> {nameRoleIncumbent}</p>
                ) : (
                  <>
                    <Input
                      value={nameRoleIncumbent}
                      onChange={(e) => handleChangeFullName(e)}
                      style={{ marginRight: '20px' }}
                      onBlur={() => handleEditNameRole()}
                      status={hasError ? 'error' : ''}
                    />
                  </>
                )}
                {!loadingRoleName && type === 'update' && (
                  <div onKeyDown={() => {}} onClick={() => handleEditNameRole()} style={{ cursor: 'pointer' }}>
                    <img style={{ cursor: 'pointer' }} src={edit_icon_role} alt="" />
                  </div>
                )}
              </AiTitle>
              {hasError && <AiError>Role Name is required field</AiError>}
              <div className="sub-title" style={{ marginTop: 0 }}>
                Role Management setting
              </div>
            </div>
            <div className="right">
              <AiBtn
                onKeyDown={() => {}}
                onClick={() => {
                  history.push(`/admin/role-management`);
                  setTempChecked(null);
                }}
              >
                Back
              </AiBtn>
              <AiBtn form1 onKeyDown={() => {}} onClick={() => createOrUpdateRoleSetting()}>
                Save Setting
              </AiBtn>
            </div>
          </Header>
          <AiHeader>
            <Col offset={1} span={1}>
              No.
            </Col>
            <Col span={4} style={{ justifyContent: 'center', display: 'flex' }}>
              Permissions
            </Col>
            <Col span={12} style={{ justifyContent: 'center', display: 'flex' }}>
              Role Access Level
            </Col>
            <Col span={6} style={{ justifyContent: 'center', display: 'flex' }}>
              Access Level
            </Col>
          </AiHeader>
          {(loading2 || loading.getPermission) && (
            <AiSpin tip="Loading...">
              <AiAlert />
            </AiSpin>
          )}
          {!loading2 && !loading.getPermission && (
            <Collapse defaultActiveKey={[]} expandIconPosition="end">
              {handleDisplayPermission()}
            </Collapse>
          )}
        </Container>
      )}
    </>
  );
};
export default memo(RoleDetail);
