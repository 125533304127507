import { Alert, Button, DatePicker, Input, Modal, Row, TimePicker } from 'antd';
import TimeField from 'react-simple-timefield';
import styled, { css } from 'styled-components';

import { color, font, fontSize } from '../../../assets/common';
import { MEETING_STATUS } from '../../../utils/constants';
import { rootStyles } from '../../../utils/stylesHelper';

const { TextArea } = Input;

export const Hidden = styled.div`
  display: none;
`;

export const FieldRow = styled(Row)`
  width: 100%;
  gap: 16px;
`;

export const AiDropdown = styled.div`
  background: ${(props) => (props.disabled ? '#e0e0e0' : 'white')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 4px;
  padding: 10px 16px;
  min-height: 43px;
  border: ${(props) => (props.status === 'error' ? '1px solid red' : `1px solid ${color.geyser}`)};
  img {
    color: #bababa;
  }
`;

export const AiTextarea = styled(TextArea)`
  border-radius: 4px;
  padding: 10px 16px;
  font-family: ${font.inter};
  &:hover,
  &:focus {
    border-color: ${color.teal};
    box-shadow: 0 0 0 2px rgb(0 161 156 / 20%);
  }
  &:disabled {
    color: #787587;
    background: #e0e0e0;
    border-color: #e0e0e0;
  }
`;

export const AiInput = styled(Input)`
  border-radius: 4px;
  padding: 10px 16px;
  font-family: ${font.inter};
  &:hover,
  &:focus {
    border-color: ${color.teal};
    box-shadow: 0 0 0 2px rgb(0 161 156 / 20%);
  }
`;
export const AiDatePicker = styled(DatePicker)`
  width: 100%;
  padding: 10px 16px;
  border-radius: 4px;
  height: 44px;

  .ant-picker-input > input[disabled] {
    color: ${(props) => (props?.pageName === 'PDC_MOBILITY' ? '#15191e' : `#787587`)};
  }

  &:not(.ant-picker-disabled) {
    &:hover,
    &:focus {
      border-color: ${color.teal} !important;
    }
  }
`;
export const AiTimeField = styled(TimeField)`
  height: 44px;
  padding: 10px 16px;
  border: ${(props) => (props.status === 'error' ? '1px solid red' : `1px solid ${color.geyser}`)};
  border-radius: 4px;
`;
export const AiTimePickerField = styled(TimePicker)`
  height: 44px;
  padding: 10px 16px;
  border: ${(props) => (props.status === 'error' ? '1px solid red' : `1px solid ${color.geyser}`)};
  border-radius: 4px;

  &:not(.ant-picker-disabled) {
    &:hover,
    &:focus {
      border-color: ${color.teal} !important;
    }
  }
`;
export const AiLabel = styled.p`
  font-family: ${font.inter};
  font-weight: 500;
  font-size: ${fontSize.small};
  color: ${color.brightGray2};
  margin-bottom: 4px;
  position: relative;
  &::after {
    ${(props) => (!props?.noRequire ? `position: absolute; content: '*';  color: red;` : `content: ''`)};
  }
`;
export const AiContainerForm = styled.div`
  background-color: white;
  padding: 26px 33px 53px 33px;
  border-radius: 6px;
  margin-bottom: 32px;
`;
export const AiContainerRemark = styled.div`
  background-color: #fff;
  padding: 37px 33px 45px 33px;
  border-radius: 6px;
  margin-bottom: 32px;
`;
export const AiContainerGrBtn = styled.div`
  padding: 25px 0 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const AiContainerGrBtnModel = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
export const AiH2 = styled.h2`
  font-family: ${font.inter};
  font-weight: 700;
  font-size: ${fontSize.large};
  color: ${color.brightGray2};
  margin-bottom: 0;
`;
export const AiH3 = styled.h3`
  font-family: ${font.inter};
  font-weight: 600;
  font-size: ${fontSize.sizable};
  color: ${color.brightGray2};
  margin-bottom: 0;
`;
export const MeetingStatus = styled.div`
  padding: 5.5px 8px;
  border: 1px solid ${color.geyser};
  border-radius: 6px;
  margin-left: 10px;
  p {
    font-size: ${fontSize.small};
    color: ${color.darkBlue};
    font-family: ${font.rubik};
    font-weight: 700;
    position: relative;
    margin-bottom: 0;
    margin-left: 20px;
    &::before {
      position: absolute;
      content: '';
      width: 12px;
      height: 12px;
      border-radius: 4px;
      background-color: ${(props) => (props.status === MEETING_STATUS.PUBLISHED ? `${color.darkGreen}` : `${color.mySin}`)};
      left: -13px;
      top: 49%;
      transform: translate(-50%, -50%);
    }
  }
`;
export const MeetingTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 19px;
`;
export const AiButton = styled(Button)`
  border-radius: 6px;
  padding: 0px 20px;
  font-weight: 500;
  font-size: ${fontSize.small};
  font-family: ${font.inter};
  border: ${(props) => (props?.border ? `1px solid ${props?.borderColor || color.gallery} !important` : '')};
  ${(props) => {
    switch (props.mode) {
      case 'danger':
        return css`
          background-color: ${color.froly};
          border-color: ${color.froly};
          color: white;
        `;
      case 'teal':
        return css`
          background-color: ${color.teal};
          border-color: ${color.teal};
          color: white;
        `;
      default:
        return css`
          background-color: ${color.white};
          border-color: ${color.white};
          color: ${color.teal};
        `;
    }
  }}

  &:hover {
    ${(props) => {
      switch (props.mode) {
        case 'danger':
          return css`
            background-color: ${color.froly};
            border-color: ${color.froly};
            color: white;
          `;
        case 'teal':
          return css`
            background-color: ${color.teal};
            border-color: ${color.teal};
            color: white;
          `;
        default:
          return css`
            background-color: ${color.white};
            border-color: ${color.white};
            color: ${color.teal};
          `;
      }
    }}
  }
  &:focus {
    ${(props) => {
      switch (props.mode) {
        case 'danger':
          return css`
            background-color: ${color.froly};
            border-color: ${color.froly};
            color: white;
          `;
        case 'teal':
          return css`
            background-color: ${color.teal};
            border-color: ${color.teal};
            color: white;
          `;
        default:
          return css`
            background-color: ${color.white};
            border-color: ${color.white};
            color: ${color.teal};
          `;
      }
    }}
  }
  &[disabled] {
    background-color: ${color.brightGray} !important;
    color: ${color.white} !important;
    border: none !important;
    cursor: default !important;
  }
`;
export const AiAlert = styled(Alert)`
  padding: 18px 23.33px 18px 13.67px;
  .ant-alert-message {
    color: ${color.monza};
    font-family: ${font.inter};
    font-weight: 500;
    font-size: ${fontSize.normal};
  }
  .ant-alert-close-icon {
    .anticon-close {
      color: ${color.monza};
    }
  }
`;
export const AiIconAlert = styled.img`
  .ant-alert-icon {
    width: 16.67px;
    height: 16.67px;
    margin-right: 13.76px;
  }
`;

export const customStyles = {
  control: (provided, _state) => ({
    ...provided,
    minHeight: 44,
    border: '1px solid hsl(0, 0%, 80%)',
    width: _state.selectProps.hasError,
    '&:hover': {
      borderColor: `${color.teal}`,
      boxShadow: `0 0 0 1px ${color.teal}`,
    },
  }),
  ...rootStyles,
};
export const theme = (theme) => ({
  ...theme,
  borderRadius: '4px',
  colors: {
    ...theme.colors,
    primary: '#00a19c',
  },
});
export const AiModel = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
  }
  .ant-modal-body {
    padding: 84px 57px 48px;
  }
`;
export const AiTitle = styled.p`
  font-family: ${font.inter};
  font-weight: 700;
  font-size: ${fontSize.superLarge};
  color: ${color.brightGray2};
  line-height: 38.73px;
  width: 100%;
`;
export const AiDesc = styled.p`
  font-family: ${font.inter};
  font-weight: 500;
  font-size: 13;
  color: ${color.santasGray};
  line-height: 20px;
  padding-bottom: 30px;
`;
export const AiFormWrap = styled.div`
  border-bottom: 1px solid ${color.geyser};
  padding-bottom: 25px;
  margin-bottom: 25px;
`;
export const AiAgendaContainer = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 6px;
  padding: 23px 32px;
  margin-bottom: 20px;
`;
export const AiAgendaLineHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 26px;
`;
export const AiAgendaTitle = styled.div`
  font-family: ${font.inter};
  font-weight: 700;
  font-size: 18px;
`;
export const AiTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const AiGroupButton = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 19px;
`;

export const RemarkTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 19px;
`;

export const AiAgendaForm = styled.div`
  background-color: white;
  padding: 26px 39px 44px 33px;
  margin-bottom: 24px;
  border-radius: 6px;
`;

export const TopBtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 15px;
`;

export const CutomBtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 32px;
`;
