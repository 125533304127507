import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { Spin } from 'antd';
import { useDispatch } from 'react-redux';

import styles from './compare-observation.module.scss';
import { avatar } from '../../../../../assets/img';
import { BasicButton } from '../../../../../assets/common';
import { AlertMessage } from '../../../../../components';
import { pdcMeetingApi } from '../../../../../services/pdcMeetingApi';
import { pushMessage } from '../../../../../store/alertMessageSlice';
import { ASPIRATION_RISKS_SUCCESS_MESSAGE, MESSAGE_TYPES } from '../../../../../utils/constants';
import RichTextEditor from '../../../../../components/RichTextEditor/RichTextEditor';

const CustomModal = styled(Modal)`
  width: 100%;
  display: flex;
  flex-direction: column;
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0 !important;
  }
`;

const CompareObservation = ({ compareItem, setCompareItem, isOnlyViewAgenda, setCombinedData }) => {
  const [isSaveSuccess, setSaveSuccess] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [compareData, setCompareData] = useState({
    initData: [],
    currentData: [],
  });

  const toggleEdit = () => {
    setIsEditable(!isEditable);
  };

  const onSaveClick = () => {
    handleSaveAsDraft(() => {
      setSaveSuccess(true);
      toggleEdit();
    });
  };

  const onSaveAndExitClick = () => {
    handleSaveAsDraft(() => {
      dispatch(
        pushMessage({
          type: MESSAGE_TYPES.SUCCESS,
          message: ASPIRATION_RISKS_SUCCESS_MESSAGE,
          timeShow: 0,
        })
      );
      setCompareItem(null);
    });
  };

  const fetchAspirationRisksSuccessor = async () => {
    if (!compareItem) return;
    try {
      setLoading(true);
      const res = await pdcMeetingApi.getAspirationRiskSuccessor(compareItem?.staff_id, compareItem?.mobility_id, compareItem?.proposal_mobility_id);
      if (res.status === 200) {
        const tempData = !isEmpty(res?.data?.result) ? res?.data?.result : [];
        setCompareData((prev) => ({
          ...prev,
          initData: tempData,
          currentData: tempData,
        }));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAspirationRisksSuccessor();
  }, []);

  const handleSaveAsDraft = async (onSuccessCallback) => {
    try {
      setLoading(true);
      const payload =
        !isEmpty(compareData?.currentData) && !isEmpty(compareItem)
          ? compareData.currentData.map((item) => {
              return {
                staff_id: item?.staff_id,
                aspirations: item?.aspiration,
                risks: item?.risks,
                other_observation: item?.other_observation,
                mobility_id: compareItem?.mobility_id,
                mobility_proposal_id: compareItem?.proposal_mobility_id,
                proposal_staff_id: compareItem?.staff_id,
              };
            })
          : [];
      const res = await pdcMeetingApi.updateAspirationRisk(payload);
      if (res.status === 200) {
        if (!isEmpty(compareData?.currentData)) {
          compareData.currentData.map((item) => {
            setCombinedData((prev) => {
              if (!isEmpty(prev)) {
                return prev.map((each) => {
                  if (each?.staff_id == item?.staff_id) {
                    return {
                      ...item,
                      aspirations: item?.aspiration,
                      risks: item?.risks,
                      other_observation: item?.other_observation,
                    };
                  } else {
                    return item;
                  }
                });
              }
            });
          });
        }
        if (typeof onSuccessCallback === 'function') {
          onSuccessCallback();
        } else setSaveSuccess(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <CustomModal
        open={compareItem}
        width={1250}
        footer={null}
        onCancel={() => {
          setCompareItem(null);
        }}
      >
        <Spin spinning={loading}>
          <div className={styles.modalWrapper}>
            <div className={styles.modal_header}>
              <h6>{`Successor's Aspirations, Observations & Risks`}</h6>
              {isSaveSuccess && (
                <div style={{ marginBottom: '20px' }}>
                  <AlertMessage message="Aspirations & Risks has been successfully saved." type="success" />
                </div>
              )}
            </div>
            <div className={styles.talent_wrapper}>
              {!isEmpty(compareData) &&
                compareData.currentData.map((item, index) => {
                  return (
                    <div key={index} className={styles.each_talent}>
                      <div className={styles.item1}>
                        <div className={styles.avatar}>
                          <img src={item?.image || avatar} alt="" />
                        </div>
                        <div className={styles.birth_name}>{item?.birth_name || '-'}</div>
                        <div className={styles.jg}>{`JG: ${item?.jg || '-'}`}</div>
                      </div>
                      <div className={styles.item2}>Aspirations</div>
                      <div className={styles.item3}>Other Observations</div>
                      <div className={styles.item4}>Risks</div>
                      <div className={styles.item5}>
                        <RichTextEditor
                          setFieldVal={(value) => {
                            if (!isEditable || isOnlyViewAgenda) return;
                            setCompareData((prev) => {
                              const updatedCurrentData = prev?.currentData?.map((each) => {
                                if (each?.staff_id === item?.staff_id) {
                                  return {
                                    ...each,
                                    aspiration: value,
                                  };
                                }
                                return each;
                              });

                              return {
                                ...prev,
                                currentData: updatedCurrentData,
                              };
                            });
                          }}
                          fieldVal={item?.aspiration || ''}
                          newStyles={{ minHeight: '202px', maxHeight: '210px' }}
                          placeholder="Enter Talent Aspirations"
                          maxLength={false}
                          readOnly={!isEditable || isOnlyViewAgenda}
                        />
                      </div>
                      <div className={styles.item6}>
                        <RichTextEditor
                          setFieldVal={(value) => {
                            if (!isEditable || isOnlyViewAgenda) return;
                            setCompareData((prev) => {
                              const updatedCurrentData = prev?.currentData?.map((each) => {
                                if (each?.staff_id === item?.staff_id) {
                                  return {
                                    ...each,
                                    other_observation: value,
                                  };
                                }
                                return each;
                              });

                              return {
                                ...prev,
                                currentData: updatedCurrentData,
                              };
                            });
                          }}
                          fieldVal={item?.other_observation || ''}
                          newStyles={{ minHeight: '202px', maxHeight: '210px' }}
                          placeholder="Enter Other Observations"
                          maxLength={false}
                          readOnly={!isEditable || isOnlyViewAgenda}
                        />
                      </div>
                      <div className={styles.item7}>
                        <RichTextEditor
                          setFieldVal={(value) => {
                            if (!isEditable || isOnlyViewAgenda) return;
                            setCompareData((prev) => {
                              const updatedCurrentData = prev?.currentData?.map((each) => {
                                if (each?.staff_id === item?.staff_id) {
                                  return {
                                    ...each,
                                    risks: value,
                                  };
                                }
                                return each;
                              });

                              return {
                                ...prev,
                                currentData: updatedCurrentData,
                              };
                            });
                          }}
                          fieldVal={item?.risks || ''}
                          newStyles={{ minHeight: '202px', maxHeight: '210px' }}
                          placeholder="Enter Talent Risks"
                          maxLength={false}
                          readOnly={!isEditable || isOnlyViewAgenda}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
            {!isOnlyViewAgenda && (
              <div className={styles.btnRow}>
                <BasicButton
                  width="100px"
                  onClick={() => {
                    setCompareItem(null);
                  }}
                >
                  Cancel
                </BasicButton>
                {!isEditable && (
                  <BasicButton width="100px" mode="teal" disabled={compareData?.currentData?.length <= 0} onClick={toggleEdit}>
                    Edit
                  </BasicButton>
                )}
                {isEditable && (
                  <>
                    <BasicButton width="100px" mode="teal" onClick={onSaveClick}>
                      Save
                    </BasicButton>
                    <BasicButton width="100px" mode="teal" onClick={onSaveAndExitClick}>
                      Save & Exit
                    </BasicButton>
                  </>
                )}
              </div>
            )}
          </div>
        </Spin>
      </CustomModal>
    </div>
  );
};

export default CompareObservation;
