import React, { useEffect, useState } from 'react';
import { Container, Title } from './ContainerSummaryStyled';
import SummaryMeeting from '../SummaryMeeting/SummaryMeeting';
import { pdcMeetingApi } from '../../../../services/pdcMeetingApi';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { Spin } from 'antd';

const ContainerSummary = () => {
  const { idMeeting } = useParams();
  const roleActive = useSelector((state) => state.user.roleActive);
  const [data, setData] = useState({
    by_proposal_type: [],
    by_effective_date: [],
    loading: false,
  });

  useEffect(() => {
    if (!idMeeting || !roleActive.roleId) return;
    (async function getSummaryProposal() {
      try {
        setData((prev) => ({ ...prev, loading: true }));
        const res = await pdcMeetingApi.fetchSummaryProposal(idMeeting, roleActive.roleId);
        setData({
          by_proposal_type: get(res, 'data.result.by_proposal_type') || [],
          by_effective_date: get(res, 'data.result.by_effective_date') || [],
          loading: false,
        });
      } catch (error) {
        console.log(error);
        setData({
          by_proposal_type: [],
          by_effective_date: [],
          loading: false,
        });
      }
    })();
  }, [idMeeting, roleActive.roleId]);

  return (
    <Spin spinning={data.loading}>
      <Container>
        <Title>Summary of PDC Proposal</Title>
        {!isEmpty(data.by_proposal_type) && <SummaryMeeting data={data.by_proposal_type} />}

        <Title style={{ marginTop: '30px' }}>Summary of PDC Proposal By Effective Date</Title>
        {!isEmpty(data.by_effective_date) && <SummaryMeeting data={data.by_effective_date} isByDate />}
      </Container>
    </Spin>
  );
};

export default ContainerSummary;
