import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BasicButton } from '../../../../assets/common';
import { circle_checked, paperInProgress } from '../../../../assets/img';
import { ModelTC } from '../../../../components';
import { pdcMeetingApi } from '../../../../services/pdcMeetingApi';
import { pushMessage } from '../../../../store/alertMessageSlice';
import { AGENDA_STATUS, MEETING_DETAIL_STATUS, MESSAGE_TYPES } from '../../../../utils/constants';
import styles from './mobility-button-controls.module.scss';

const MobilityTopButtonControls = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { roleId } = useSelector((state) => state.user.roleActive);
  const {
    idMeeting,
    mobilityId,
    mobilityDetails,
    fetchMobility,
    isAdditionalViewAccess,
    isHRPartners,
    isChairMainOrCommiteeOrAdvocator,
    meetingDetail,
    getMainProposal,
  } = props;
  const [visibleChangeStatusModal, setVisibleChangeStatusModal] = useState({ edit_paper: false, paper_unfinalize: false });

  const handleClickBack = async () => {
    if (meetingDetail.status === MEETING_DETAIL_STATUS.IN_PLANNING) return history.push(`/pdc-meeting/${idMeeting}/mobilities`);
    if (meetingDetail.status === MEETING_DETAIL_STATUS.IN_REVIEW) return history.push(`/pdc-meeting/${idMeeting}/in-review`);
    if (meetingDetail.status === MEETING_DETAIL_STATUS.CONCLUDED) return history.push(`/pdc-meeting/${idMeeting}/concluded`);
    history.push(`/pdc-meeting/${idMeeting}`);
  };

  const handleChangeAgendaStatus = async (status) => {
    try {
      const payload = {
        meeting_id: idMeeting,
        mobility_id: mobilityId,
        status,
      };
      let res = await pdcMeetingApi.updateMobilityAgendaStatus(payload, roleId);
      if (res.status === 200) {
        if (status === AGENDA_STATUS.PAPER_IN_PROGRESS) {
          dispatch(
            pushMessage({
              message: `${mobilityDetails?.title} is ready to be edited.`,
              type: MESSAGE_TYPES.SUCCESS,
            })
          );
        }
        if (status === AGENDA_STATUS.PAPER_READY) {
          dispatch(
            pushMessage({
              message: `${mobilityDetails?.title} has been successfully unfinalized`,
              type: MESSAGE_TYPES.SUCCESS,
            })
          );
        }
        await fetchMobility();
        if (getMainProposal) getMainProposal();
      }
    } catch (error) {
      return console.error(error);
    }
  };

  return (
    <>
      <div className={styles.topControls}>
        <div className={styles.rowItem}>
          <div className={styles.title}>{mobilityDetails?.title}</div>
          {![MEETING_DETAIL_STATUS.IN_REVIEW, MEETING_DETAIL_STATUS.CONCLUDED].includes(meetingDetail?.status) && (
            <div
              className={styles.status}
              style={{
                color: [mobilityDetails?.status].includes(AGENDA_STATUS.PAPER_IN_PROGRESS) ? '#f08800' : '#007724',
                background: [mobilityDetails?.status].includes(AGENDA_STATUS.PAPER_IN_PROGRESS) ? '#fdf7b0' : '#e5f4e7',
              }}
            >
              <img src={[mobilityDetails?.status].includes(AGENDA_STATUS.PAPER_IN_PROGRESS) ? paperInProgress : circle_checked} alt="icon_status" />
              {mobilityDetails?.status}
            </div>
          )}
        </div>
        <div className={styles.rowItem}>
          <BasicButton width="100px" onClick={handleClickBack}>
            Back
          </BasicButton>
          {[MEETING_DETAIL_STATUS.IN_PLANNING, MEETING_DETAIL_STATUS.PUBLISHED].includes(meetingDetail?.status) &&
            !(isAdditionalViewAccess || isHRPartners || isChairMainOrCommiteeOrAdvocator) && (
              <BasicButton width="120px" mode="teal" onClick={() => setVisibleChangeStatusModal({ edit_paper: true, paper_unfinalize: false })}>
                Edit Paper
              </BasicButton>
            )}
          {[AGENDA_STATUS.PAPER_FINALIZED].includes(mobilityDetails?.status) &&
            [MEETING_DETAIL_STATUS.IN_PROGRESS].includes(meetingDetail?.status) &&
            !(isAdditionalViewAccess || isHRPartners || isChairMainOrCommiteeOrAdvocator) && (
              <BasicButton width="100px" mode="teal" onClick={() => setVisibleChangeStatusModal({ edit_paper: false, paper_unfinalize: true })}>
                Unfinalize
              </BasicButton>
            )}
        </div>
      </div>

      {/* Modal */}
      {visibleChangeStatusModal.edit_paper && (
        <ModelTC
          info={{
            type: 'changeToPaperInProgressMobilityStatus',
            visible: visibleChangeStatusModal.edit_paper,
            setVisible: (val) => setVisibleChangeStatusModal((prev) => ({ ...prev, edit_paper: val })),
            handleSubmit: async () => {
              handleChangeAgendaStatus(AGENDA_STATUS.PAPER_IN_PROGRESS);
              setVisibleChangeStatusModal((prev) => ({ ...prev, edit_paper: false }));
            },
            onClose: () => setVisibleChangeStatusModal((prev) => ({ ...prev, paper_finalize: false })),
          }}
        />
      )}
      {visibleChangeStatusModal.paper_unfinalize && (
        <ModelTC
          info={{
            type: 'paperUnFinalizePDCMeeting',
            visible: visibleChangeStatusModal.paper_unfinalize,
            setVisible: (val) => setVisibleChangeStatusModal((prev) => ({ ...prev, paper_unfinalize: val })),
            handleSubmit: async () => {
              handleChangeAgendaStatus(AGENDA_STATUS.PAPER_READY);
              setVisibleChangeStatusModal((prev) => ({ ...prev, paper_unfinalize: false }));
            },
            onClose: () => setVisibleChangeStatusModal((prev) => ({ ...prev, paper_unfinalize: false })),
          }}
        />
      )}
    </>
  );
};
export default MobilityTopButtonControls;
