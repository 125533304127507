import { Spin } from 'antd';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import styled from 'styled-components';
import { document_icon } from '../../../assets/img';
import { BreadCrumb, ModelTC } from '../../../components';
import GlobalAlertMessage from '../../../components/GlobalAlertMessage/GlobalAlertMessage';
import RichTextEditor from '../../../components/RichTextEditor/RichTextEditor';
import { useShowPopupSaveAgenda } from '../../../hooks/useShowPopupSaveAgenda';
import { useUpdatePdcMobilityStepNumber } from '../../../hooks/useUpdatePdcMobilityStepNumber';
import { pdcMeetingApi } from '../../../services/pdcMeetingApi';
import { pushMessage } from '../../../store/alertMessageSlice';
import { MESSAGE_TYPES, UPLOAD_DOC_STATEMENT } from '../../../utils/constants';
import { getBreadCrumbList } from '../../../utils/helper';
import { AiTitleWrapper } from '../../Meeting/MeetingDetails/Styled';
import {
  DocumentIcon,
  FilePreviewContainer,
  FileViewWrapper,
  GroupButon,
  ProgressContainer,
  ProgressContentLoaded,
} from '../../Meeting/components/FileUpload/Styled';
import MobilityBotButtonControls from '../components/MobilityButtonControls/MobilityBotButtonControls';
import MobilityTopButtonControls from '../components/MobilityButtonControls/MobilityTopButtonControls';
import MobilityFinalizeBar from '../components/MobilityFinalizeBar/MobilityFinalizeBar';
import PdcMobilityProcessBar from '../components/PdcMobilityProcessBar/PdcMobilityProcessBar';
import UploadMultipleFileForSupporting from './components/UploadMultipleFile/UploadMultipleFileForSupporting';
import * as styles from './pdc-supporting-documents.module.scss';

const BREAD_CRUMB = getBreadCrumbList(['Homepage', 'PDC Meeting', 'Mobility Agenda', 'Supporting Documents']);

const PdcSupportingDocuments = (props) => {
  const { isDisableAll } = props;
  const dispatch = useDispatch();
  const { idMeeting, mobilityId, proposalId } = useParams();
  const { roleId } = useSelector((state) => state.user.roleActive);
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [isBack, setIsBack] = useState(false);
  const [compareData, setCompareData] = useState({
    initData: [],
    currentData: [],
  });
  // Upload file state
  const [fileSizeUpload, setFileSizeUpload] = useState(0);
  const [fileUpload, setFileUpload] = useState([]);
  const [fileUploadStatus, setFileUploadStatus] = useState({
    isSuccess: false,
    hasError: false,
    notPdfFile: false,
    limitFileSize: false,
    isDeletedMoM: false,
    isExistFile: false,
    maximumOf5Files: false,
  });
  const [isReplaceFile, setIsReplaceFile] = useState(false);
  const [isClickedSaveBtn, setIsClickedSaveBtn] = useState(false);
  const [remarkVal, setRemarkVal] = useState('');

  // Update current data
  useEffect(() => {
    setCompareData((prev) => ({
      ...prev,
      currentData: { ...prev.currentData, attachments: fileUpload, remarks: remarkVal },
    }));
  }, [fileUpload, remarkVal]);

  // Get the condition to edit mobility agenda
  const {
    isOnlyViewAgenda,
    isShowTopButtons,
    isShowBotButtons,
    isAdditionalViewAccess,
    meetingDetail,
    mobilityDetails,
    isEnableMobilityTab,
    proposalMainStep,
    fetchMobilityDetails,
    isShownMobilityTabBar,
    isHRPartners,
    isChairMainOrCommiteeOrAdvocator,
  } = props;
  const { updateStepNumber } = useUpdatePdcMobilityStepNumber();

  const fetchReportingDocuments = useCallback(async () => {
    try {
      setLoading(true);
      const res = await pdcMeetingApi.getReportingDocuments(idMeeting, mobilityId, roleId);
      if (res.status === 200) {
        setCompareData({
          initData: res?.data?.result,
          currentData: res?.data?.result,
        });
        setFileUpload(res?.data?.result?.attachments);
        setRemarkVal(res?.data?.result?.remarks);
        await fetchMobilityDetails();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [mobilityId, roleId, setCompareData]);

  // Get reporting documents
  useEffect(() => {
    if (!idMeeting || !mobilityId) return;

    fetchReportingDocuments();
  }, [idMeeting, mobilityId, fetchReportingDocuments]);

  const onReplaceFileUpload = (value) => {
    setIsReplaceFile(value);
  };

  const UPLOAD_FILE_ERROR_MESSAGE = useMemo(
    () => ({
      notPdfFile: 'Only PDF file can be uploaded.',
      limitFileSize: `Error uploading file. File is too big (${Math.round(fileSizeUpload / 1024 / 1024)}MB). Max file size: 200MB`,
      isSuccess: 'Attachment uploaded successfully',
      hasError: 'Attachment has not been able to upload. Please retry',
      isDeletedMoM: 'Attachment deleted successfully',
      isExistFile: 'You have one file already uploaded. Do you want to replace the existing file?',
      maximumOf5Files: 'The uploaded file exceeds the limit. You can only upload a maximum of 5 attachments.',
    }),
    [fileSizeUpload]
  );

  const showMessageUploadFile = useCallback(
    (state, name, type) => {
      if (state[name]) {
        dispatch(
          pushMessage({
            message: UPLOAD_FILE_ERROR_MESSAGE[name],
            type,
          })
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [UPLOAD_FILE_ERROR_MESSAGE]
  );

  useEffect(
    () => {
      const { notPdfFile, limitFileSize, hasError, isExistFile, isDeletedMoM, isSuccess, maximumOf5Files } = fileUploadStatus;
      if (!(notPdfFile || limitFileSize || hasError || isExistFile || isDeletedMoM || isSuccess || maximumOf5Files)) return;

      Object.keys(fileUploadStatus).forEach((key) => {
        let messageType = MESSAGE_TYPES.ERROR;
        if (['isSuccess', 'isDeletedMoM'].includes(key)) messageType = MESSAGE_TYPES.SUCCESS;
        if (key === 'isExistFile') messageType = MESSAGE_TYPES.WARNING;
        showMessageUploadFile(fileUploadStatus, key, messageType);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fileUploadStatus]
  );

  const isChangeSupportingDocuments = useMemo(() => {
    return JSON.stringify(compareData.initData) !== JSON.stringify(compareData.currentData);
  }, [compareData]);

  const { isShowPopupSaveAgenda, handleKeepNavigate, setIsShowPopupSaveAgenda } = useShowPopupSaveAgenda(isChangeSupportingDocuments && !isBack);

  const handleClickBack = async () => {
    setIsBack(true);
    history.push(`/pdc-meeting/${idMeeting}/mobility/${mobilityId}/aspiration-risks/${proposalId}`);
  };

  const handleSaveBeforeExit = async () => {
    setIsBack(true);
    setIsShowPopupSaveAgenda(false);
    await handleSaveAsDraft();
    if (proposalMainStep?.step < 5) {
      await updateStepNumber(mobilityId, 5);
    }
    handleKeepNavigate();
  };

  const handleSaveAsDraft = async () => {
    if (isEmpty(fileUpload) && isEmpty(remarkVal)) return;

    setIsClickedSaveBtn(true);
    try {
      setLoading(true);
      const payload = {
        meeting_id: idMeeting,
        mobility_id: mobilityId,
        attachment_ids: !isEmpty(fileUpload) ? fileUpload.filter((f) => !f?.isError).map((i) => i?.id) : [],
        remarks: remarkVal,
      };
      const res = await pdcMeetingApi.createReportingDocuments(payload, roleId);
      if (res.status === 200) {
        if (proposalMainStep?.step < 5) {
          await updateStepNumber(mobilityId, 5);
        }
        fetchReportingDocuments();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadFile = (file) => {
    fetch(file?.url)
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('download', file?.file_name);
        link.href = href;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      <div style={{ marginBottom: '15px' }}>
        <BreadCrumb level={4} breadCrumbList={BREAD_CRUMB} />
      </div>
      <GlobalAlertMessage style={{ marginBottom: '36px' }} />
      {isShowTopButtons && (
        <MobilityTopButtonControls
          idMeeting={idMeeting}
          mobilityId={mobilityId}
          mobilityDetails={mobilityDetails}
          fetchMobility={fetchMobilityDetails}
          isAdditionalViewAccess={isAdditionalViewAccess}
          isHRPartners={isHRPartners}
          isChairMainOrCommiteeOrAdvocator={isChairMainOrCommiteeOrAdvocator}
          meetingDetail={meetingDetail}
        />
      )}

      {isShownMobilityTabBar ? (
        <MobilityFinalizeBar mobilityDetails={mobilityDetails} />
      ) : (
        <PdcMobilityProcessBar currentStep={5} mobilityDetails={mobilityDetails} isEnableMobilityTab={isEnableMobilityTab} />
      )}

      <Spin spinning={loading}>
        <div className={styles.contentWrapper}>
          <ItemWrapper>
            <div className="w-100">
              <AiTitleWrapper style={{ marginBottom: '10px' }}>
                <CustomTitle required>Upload Supporting Documents</CustomTitle>
              </AiTitleWrapper>
              <DocStatementTitle>Documents to Upload:</DocStatementTitle>
              <CustomOL>
                {UPLOAD_DOC_STATEMENT.map((line, index) => (
                  <DocStatementDesc key={index}>{line}</DocStatementDesc>
                ))}
              </CustomOL>
              {!isDisableAll && !isOnlyViewAgenda ? (
                <UploadMultipleFileForSupporting
                  accept=".pdf,.doc,.docx,.pptx,.xlsx,.jpeg,.jpg,.png,.mp4"
                  setFileUpload={setFileUpload}
                  fileUpload={fileUpload}
                  setFileUploadStatus={setFileUploadStatus}
                  fileUploadStatus={fileUploadStatus}
                  isReplaceFile={isReplaceFile}
                  onReplace={onReplaceFileUpload}
                  setFileSizeUpload={setFileSizeUpload}
                  maxFileSizeInBytes={1024 * 1024 * 200}
                  dataFileUploaded={fileUpload}
                  isClickedSave={isClickedSaveBtn}
                  pageContent={{
                    papeName: 'Supporting Documents',
                    uploadContent: 'Limit 200MB per file e.g. : .pdf, .docx, .pptx, .xlsx etc.',
                  }}
                />
              ) : (
                <FileViewWrapper>
                  {!isEmpty(fileUpload) &&
                    fileUpload.map((item, index) => (
                      <FilePreviewContainer style={{ marginBottom: '16px', width: '100%' }} key={index}>
                        <ProgressContainer>
                          <DocumentIcon src={document_icon} alt=""></DocumentIcon>
                          <ProgressContentLoaded>
                            <div className={'file-name'} onClick={() => handleDownloadFile(item)}>
                              {item?.file_name || `Minute of Meeting.pdf`}
                            </div>
                            <div className={'file-size'}>{item?.file_size ? `${Math.floor(item?.file_size / 1024)} kb` : `0 kb`}</div>
                          </ProgressContentLoaded>
                          <GroupButon></GroupButon>
                        </ProgressContainer>
                      </FilePreviewContainer>
                    ))}
                </FileViewWrapper>
              )}
            </div>
          </ItemWrapper>
          <ItemWrapper>
            <CustomItemTitle>Remarks</CustomItemTitle>
            <RichTextEditor
              setFieldVal={setRemarkVal}
              fieldVal={remarkVal}
              newStyles={{ minHeight: '204px' }}
              placeholder="Enter Remarks"
              maxLength={2500}
              readOnly={isOnlyViewAgenda}
            />
          </ItemWrapper>
        </div>

        {isShowBotButtons && (
          <MobilityBotButtonControls
            idMeeting={idMeeting}
            mobilityId={mobilityId}
            handleSaveAsDraft={handleSaveAsDraft}
            mobilityDetails={mobilityDetails}
            fetchMobility={fetchMobilityDetails}
            handleClickBack={handleClickBack}
            isOnlyViewAgenda={isOnlyViewAgenda}
            setIsBack={setIsBack}
            isHRPartners={isHRPartners}
            isSupportDocument
            isAdditionalViewAccess={isAdditionalViewAccess}
            isChairMainOrCommiteeOrAdvocator={isChairMainOrCommiteeOrAdvocator}
            meetingDetail={meetingDetail}
          />
        )}
      </Spin>

      <ModelTC
        info={{
          type: 'withoutSaving',
          visible: isShowPopupSaveAgenda,
          setVisible: setIsShowPopupSaveAgenda,
          onClose: handleKeepNavigate,
          handleSubmit: handleSaveBeforeExit,
        }}
      />
    </>
  );
};

const CustomTitle = styled.div`
  color: var(--Emphasis-Body-N800, #3f3c4c);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.3px;
  margin-bottom: 32px;
`;

const CustomItemTitle = styled.div`
  color: var(--Grays-500, #525252);
  font-family: var(--font-type-default, Inter);
  font-size: 12px;
  font-style: normal;
  font-weight: var(--font-weight-medium, 500);
  line-height: var(--font-line-height-body-sm, 20px); /* 166.667% */
  margin-bottom: 5px;
`;

const ItemWrapper = styled.div`
  margin-bottom: 32px;
`;

const DocStatementTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-family: Inter;
  color: #7a7a7a;
  margin-bottom: 10px;
`;

const DocStatementDesc = styled.li`
  font-size: 14px;
  font-weight: 500;
  font-family: Inter;
  color: #7a7a7a;
  white-space: pre-wrap;
`;

const CustomOL = styled.ol`
  padding-left: 20px;
`;

export default PdcSupportingDocuments;
