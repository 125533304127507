import { useState } from 'react';
import { pdcMeetingApi } from '../services/pdcMeetingApi';

export const useUpdatePdcMobilityStepNumber = () => {
  const [loading, setLoading] = useState(false);

  const updateStepNumber = async (mobilityId, stepNumber) => {
    if (!mobilityId) return false;

    try {
      setLoading(true);
      const payload = {
        step: stepNumber,
      };
      const res = await pdcMeetingApi.updateStepMobilityAgenda(mobilityId, payload);
      if (res.status === 200) {
        return true;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  return { updateStepNumber, loading };
};
