import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AiButton } from '../../../../assets/common';
import { homepage_icon, illustration_contribution } from '../../../../assets/img';
import { BreadCrumb } from '../../../../components';
import { useSearchParams } from '../../../../hooks/useSearchQuery';
import { AiDesc, AiTitle, Container, Row } from './Styled';

const itemIncumbent = {
  title: 'Great! You have finished the Meeting!',
  desc: 'Would you like to generate MoM now? ',
  btnCancel: 'Back to Home',
  borderCancel: true,
  modeCancel: '',
  img: illustration_contribution,
  btnGenerate: 'Generate MoM',
  modeGenerate: 'teal',
};
const InReviewPDCMeeting = () => {
  const history = useHistory();
  const meeting = useSelector((state) => state.app.meetingModule.preMeeting.meeting);
  const [breadCrumbList, setBreadCrumbList] = useState([{ name: 'PDC Meeting', icon: homepage_icon }, { name: 'Board Management Meeting' }]);
  const { meeting_name, meeting_id } = useSearchParams();

  useEffect(() => {
    setBreadCrumbList([{ name: 'Homepage', icon: homepage_icon, url: '/' }, { name: 'PDC Meeting', url: '/pdc' }, { name: meeting_name || '' }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meeting && meeting_name]);

  return (
    <>
      <div style={{ marginLeft: '20px' }}>
        <BreadCrumb level={3} breadCrumbList={breadCrumbList} />
      </div>
      <Container>
        <Row style={{ gap: '70px' }}>
          <div>
            <AiTitle style={{ width: '460px' }}>{itemIncumbent.title}</AiTitle>
            <AiDesc style={{ width: '290px' }}>
              {itemIncumbent.desc}
              <br />
            </AiDesc>
            <AiButton border={itemIncumbent?.borderCancel} mode={itemIncumbent.modeCancel} onClick={() => history.push('/homepage')}>
              {itemIncumbent.btnCancel}
            </AiButton>
            <AiButton mode={itemIncumbent.modeGenerate} onClick={() => history.push(`/pdc-meeting/${meeting_id}/in-review`)}>
              {itemIncumbent.btnGenerate}
            </AiButton>
          </div>
          <div>
            <img src={itemIncumbent.img} alt="" />
          </div>
        </Row>
      </Container>
    </>
  );
};
export default InReviewPDCMeeting;
