import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import * as styles from './print-compare-assessment.module.scss';
import PreReadLabel from '../PreReadLabel/PreReadLabel';
import { useSelector } from 'react-redux';
import { pdcMeetingApi } from '../../../../../services/pdcMeetingApi';
import { isEmpty } from 'lodash';
import { sortAndMapData } from '../../../components/AssessmentCompareLeadershipModal/AssessmentCompareLeadershipModal';
import { Spin } from 'antd';
import { PDC_LEADERSHIP_DOT_COLOR, PDC_LEADERSHIP_ROW_NAME } from '../../../constants';
import { avatar } from '../../../../../assets/img';
import { getNoOf2NestedArr } from '../../../../../utils/helper';

const EFFECTIVE_LEVEL_COLOR = ['#FFEFD0', '#D5DFFF', '#FDDAFF', '#EEFABC'];
const VP_LEVEL = 'VP & Above';
const CELL_WIDTH = 40;
const CELL_HEIGHT = 40;
const STYLE_CELL = { height: CELL_HEIGHT, width: CELL_WIDTH };
const DIFFERENT_BETWEEN_LADDER = 3;
const MAX_PROFICIENCY = 5;

const PrintCompareAssessment = ({ preRead, mobilityId, staffId, proposal, handleFetchComplete, typeAssessment, yearAssessment }) => {
  const [rawData, setRawData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isFetchFirstTime, setIsFetchFirstTime] = useState(true);
  const roleId = useSelector((state) => state.user.roleActive.roleId);

  useEffect(() => {
    const fetchData = async () => {
      if (isEmpty(proposal) && !staffId) return handleFetchComplete('Compare Assessment');
      if (isEmpty(proposal)) return;
      try {
        setLoading(true);
        const res = await pdcMeetingApi.getCompareAssessmentEdgeChart(
          mobilityId,
          typeAssessment,
          yearAssessment,
          roleId,
          proposal.staff_id,
          proposal.position_code
        );
        if (res.status === 200) {
          setRawData(res.data.result);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
        if (isFetchFirstTime) {
          handleFetchComplete('Compare Assessment');
          setIsFetchFirstTime(false);
        }
      }
    };
    fetchData();
  }, [typeAssessment, yearAssessment, proposal.staff_id, proposal.position_code]);

  const displayData = useMemo(() => {
    if (isEmpty(rawData)) return { data: [], effectiveLevels: [] };
    return sortAndMapData(rawData);
  }, [rawData]);

  const renderedLadderAndValue = useMemo(() => {
    const { data, effectiveLevels } = displayData;
    const ladderRows = Array.from({ length: effectiveLevels.length }, (_, rowIndex) => {
      const totalNumbers = MAX_PROFICIENCY + rowIndex * DIFFERENT_BETWEEN_LADDER;
      return Array.from({ length: totalNumbers }, (_, i) => i + 1);
    });

    const styleBorderLastLader = (num) => {
      const noBottomBorderNum = [7, 8];
      const noBottomBorderRightNum = 6;
      if (noBottomBorderNum.includes(num)) return { boxShadow: '0 -1px 0 #cbd6e2' };
      if (num === noBottomBorderRightNum) return { boxShadow: '-1px -1px 0 #cbd6e2' };
      return {};
    };

    const revertOrderIndex = (length, index) => {
      return length - 1 - index;
    };

    return (
      <>
        <div className={styles.ladders}>
          {ladderRows.map((row, rowIndex) => (
            <div key={effectiveLevels[rowIndex]} className={styles.row}>
              {row.map((num, index) => (
                <div
                  key={num}
                  className={`${styles.cell} ${num > MAX_PROFICIENCY ? styles.hide : ''}`}
                  style={{ ...STYLE_CELL, ...styleBorderLastLader(num) }}
                >
                  {num <= MAX_PROFICIENCY ? num : ''}
                  {index === 0 && <div className={styles.nameLevel}>{effectiveLevels[revertOrderIndex(ladderRows.length, rowIndex)]}</div>}
                </div>
              ))}
            </div>
          ))}
        </div>

        <div className={styles.values}>
          {data.map((talent) => (
            <div key={talent.staffId}>
              {talent.edges.map((edge, index) => (
                <div key={index} className={styles.row}>
                  {edge.displayCol.map((col, idx) => (
                    <div key={idx} className={styles.cell} style={{ ...STYLE_CELL, backgroundColor: col.backgroundColor }}>
                      {col.isSMA && <span className={styles.sma} />}
                      {col.isExternal && <span className={styles.external} />}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          ))}
        </div>
      </>
    );
  }, [displayData]);
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        Compare Assessment
        {preRead && <PreReadLabel />}
      </div>
      <Spin spinning={loading}>
        <div className={styles.desc}>
          {typeAssessment === 'All' ? (
            <>
              {Object.keys(PDC_LEADERSHIP_DOT_COLOR).map((showType) => (
                <div className={styles.name} key={showType}>
                  <div className={styles.dot} style={{ background: PDC_LEADERSHIP_DOT_COLOR[showType].color }} />
                  <div>{PDC_LEADERSHIP_DOT_COLOR[showType].text}</div>
                </div>
              ))}
            </>
          ) : (
            <div className={styles.name}>
              <div className={styles.dot} style={{ background: PDC_LEADERSHIP_DOT_COLOR[typeAssessment.toLowerCase()].color }} />
              <div>{PDC_LEADERSHIP_DOT_COLOR[typeAssessment.toLowerCase()].text}</div>
            </div>
          )}
          {displayData.effectiveLevels.map((level, index) => (
            <div className={styles.name} key={level}>
              <span className={styles.effective} style={{ background: EFFECTIVE_LEVEL_COLOR[index] ?? '#EEFABC' }} />
              {level !== VP_LEVEL ? `Effective Level ${level || ''}` : VP_LEVEL}
            </div>
          ))}
        </div>
        <div className={styles.table}>
          <div className={styles.descColumn} style={{ paddingTop: `${displayData.effectiveLevels.length * CELL_HEIGHT}px` }}>
            {displayData.data.map((info) => (
              <div key={info.staffId}>
                <div className={styles.talentInfo}>
                  <img src={info.image || avatar} alt="avatar" />
                  <div className={styles.name}>
                    <div>{info.staffName}</div>
                    <div>{info.staffId}</div>
                  </div>
                  <div className={styles.jg}>JG: {info.jg}</div>
                </div>

                <div className={styles.rowName}>
                  {PDC_LEADERSHIP_ROW_NAME.map((item, parentIndex) => (
                    <div key={item.name}>
                      <div className={styles.parent} style={{ background: item.color }}>
                        {item.name}
                      </div>

                      <div className={styles.childWrapper} style={{ height: CELL_HEIGHT * 2 }}>
                        {item.childNames.map((childName, childIndex) => (
                          <div className={styles.child} key={childName} style={{ height: CELL_HEIGHT }}>
                            <div className={styles.childName}>
                              <div className={styles.childNo} style={{ background: item.childColor }}>
                                {getNoOf2NestedArr(parentIndex, childIndex)}
                              </div>
                              <div className={styles.childTitle}>{childName}</div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div className={styles.dataColumn}>{renderedLadderAndValue}</div>
        </div>
      </Spin>
    </div>
  );
};

export default PrintCompareAssessment;

PrintCompareAssessment.propTypes = {
  preRead: PropTypes.bool,
  mobilityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  staffId: PropTypes.string,
  positionCode: PropTypes.string,
  handleFetchComplete: PropTypes.func,
};
