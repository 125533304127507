import { SP_CARD_DEFAULT, SP_CARD_DEFAULT_SELECT_OPTIONS } from '../utils/constants';

const { createSlice } = require('@reduxjs/toolkit');

const initialValue = {
  statusLeftMenu: true,
  isError: false,
};

const pageSlice = createSlice({
  name: 'page',
  initialState: {
    statusLeftMenu: initialValue.statusLeftMenu,
    cardSettingsDSP: {
      first_line: SP_CARD_DEFAULT_SELECT_OPTIONS.filter(
        (f) => ![SP_CARD_DEFAULT.ADDITIONAL_INPUT, SP_CARD_DEFAULT.MATCHING_PERCENTAGE].includes(f.value)
      ),
      second_line: SP_CARD_DEFAULT_SELECT_OPTIONS.filter(
        (f) => ![SP_CARD_DEFAULT.ADDITIONAL_INPUT, SP_CARD_DEFAULT.MATCHING_PERCENTAGE].includes(f.value)
      ),
      third_line: SP_CARD_DEFAULT_SELECT_OPTIONS.filter(
        (f) => ![SP_CARD_DEFAULT.ADDITIONAL_INPUT, SP_CARD_DEFAULT.MATCHING_PERCENTAGE].includes(f.value)
      ),
    },
  },
  reducers: {
    saveStatusLeftMenu: (state, action) => {
      state.statusLeftMenu = action.payload;
    },
    updateIsError: (state, action) => {
      state.isError = action.payload;
    },

    setCardSettingsDSP: (state, action) => {
      const { line, data } = action.payload;
      state.cardSettingsDSP[line] = data;
    },
    resetCardSettingDSP: (state) => {
      state.cardSettingsDSP = {
        first_line: SP_CARD_DEFAULT_SELECT_OPTIONS.filter(
          (f) => ![SP_CARD_DEFAULT.ADDITIONAL_INPUT, SP_CARD_DEFAULT.MATCHING_PERCENTAGE].includes(f.value)
        ),
        second_line: SP_CARD_DEFAULT_SELECT_OPTIONS.filter(
          (f) => ![SP_CARD_DEFAULT.ADDITIONAL_INPUT, SP_CARD_DEFAULT.MATCHING_PERCENTAGE].includes(f.value)
        ),
        third_line: SP_CARD_DEFAULT_SELECT_OPTIONS.filter(
          (f) => ![SP_CARD_DEFAULT.ADDITIONAL_INPUT, SP_CARD_DEFAULT.MATCHING_PERCENTAGE].includes(f.value)
        ),
      };
    },
    setTokenTalentProfile: (state, action) => {
      state.tokenTalentProfile = action.payload;
    },
  },
});

const { reducer, actions } = pageSlice;
export const { saveStatusLeftMenu, updateIsError, setCardSettings, setCardSettingsDSP, resetCardSettingDSP, setTokenTalentProfile } = actions;
export default reducer;
