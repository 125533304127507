import { isArray, isEmpty, size } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
  CustomNormalText,
  DragDropText,
  FileUploadContainer,
  FormField,
  UploadFileBtn,
  UploadIcon,
} from '../../../../Meeting/components/FileUpload/Styled';
import FileLoading from './FileLoading';
import FileUploaded from './FileUploaded';
import { upload_icon } from '../../../../../assets/img';

const LIMIT_FILE_SIZE = 200;
const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 1024 * 1024 * LIMIT_FILE_SIZE;

let tempPrevFileLoading = [];

const UploadMultipleFileForSupporting = ({
  setFileUpload,
  setFileUploadStatus,
  fileUploadStatus,
  setFileSizeUpload,
  fileUpload,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  isClickedSave,
  dataFileUploaded,
  ...otherProps
}) => {
  const fileInputField = useRef(null);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [fileUploaded, setFileUploaded] = useState([]);

  const handleUploadBtnClick = () => {
    fileInputField.current.click();
  };

  const handleCheckFile = (file) => {
    if (file && file.size > maxFileSizeInBytes) {
      setFileSizeUpload(file.size);
      setFileUploadStatus((prev) => ({ ...prev, limitFileSize: true }));
      return true;
    }
    // if (file?.name && !file.name.toLowerCase().includes('.pdf')) {
    //   setFileUploadStatus((prev) => ({ ...prev, notPdfFile: true }));
    //   return true;
    // }
    return false;
  };

  const checkAllFileValid = (files) => {
    let isFileValid = false;
    for (let i = 0; i < files.length; i++) {
      const fileElem = files[i];
      isFileValid = handleCheckFile(fileElem);
      if (isFileValid) {
        return true;
      }
    }
    return isFileValid;
  };

  const handleAddNewFiles = (e) => {
    setFileUploaded([]);
    const { files: newFiles } = e.target;
    if (newFiles.length <= 0) {
      return;
    }

    // if (size([...newFiles, ...fileUpload]) > 5) {
    //   setFileUploadStatus((prev) => ({ ...prev, maximumOf5Files: true }));
    //   return;
    // }

    const isCheck = checkAllFileValid(newFiles);
    if (!isCheck) {
      let tempData = [];
      for (let i = 0; i < newFiles.length; i++) {
        const fileElem = newFiles[i];
        tempData.push({ fileData: fileElem, tempId: uuidv4() });
      }
      if (isEmpty(tempPrevFileLoading)) {
        tempPrevFileLoading = tempData.map((item) => ({ ...item, isLoading: true }));
      } else {
        tempPrevFileLoading = [
          ...tempPrevFileLoading.map((i) => ({ ...i, isLoading: false })),
          ...tempData.map((f) => {
            let tempIndex = tempPrevFileLoading.findIndex((item) => item?.tempId === f?.tempId);
            if (tempIndex !== -1) {
              return { ...f, isLoading: false };
            } else {
              return { ...f, isLoading: true };
            }
          }),
        ];
      }
    }
  };

  const handleSetFileUploaded = (record) => {
    tempPrevFileLoading = tempPrevFileLoading
      .filter((i) => i?.tempData !== record?.tempId && !record?.isError)
      .map((prevItem) => {
        if (prevItem?.tempId === record?.tempId) {
          return record;
        } else {
          return prevItem;
        }
      });
  };

  const getContentFileUploading = (files) => {
    return files
      .filter((i) => !i?.loaded)
      .map((item) => (
        <FileLoading
          fileItem={item}
          key={item?.tempId || item?.id}
          setFileUploadStatus={setFileUploadStatus}
          setIsFileLoading={setIsFileLoading}
          setFileUploaded={setFileUploaded}
          fileUploadStatus={fileUploadStatus}
          isFileLoading={isFileLoading}
          handleSetFileUploaded={handleSetFileUploaded}
        />
      ));
  };

  useEffect(() => {
    if (!isEmpty(fileUploaded)) {
      setFileUpload((prev) => {
        const tempFiles = [];
        if (!isEmpty(fileUploaded)) {
          fileUploaded.forEach((f) => {
            const isCheck = !isEmpty(prev) ? fileUpload.filter((i) => i?.id === f?.id).length > 0 : false;
            if (!isCheck) {
              tempFiles.push(f);
            }
          });
        }
        return isArray(prev) ? [...prev, ...tempFiles] : [...tempFiles];
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUploaded, setFileUpload]);

  // Handle reset temporary files
  useEffect(() => {
    if (!isEmpty(fileUploaded) && !isEmpty(tempPrevFileLoading) && size(fileUploaded) === size(tempPrevFileLoading)) {
      tempPrevFileLoading = [];
    }
  }, [fileUploaded]);

  // const isCheckUploadFileStatus = useMemo(() => {
  //   return fileUploadStatus.hasError || fileUploadStatus.limitFileSize || fileUploadStatus.notPdfFile || fileUploadStatus.maximumOf5Files;
  // }, [fileUploadStatus.hasError, fileUploadStatus.limitFileSize, fileUploadStatus.maximumOf5Files, fileUploadStatus.notPdfFile]);

  return (
    <>
      <FileUploadContainer error={isClickedSave && isEmpty(fileUpload)} style={{ borderColor: '#26afab', marginBottom: '32px' }}>
        <UploadIcon src={upload_icon} alt=""></UploadIcon>
        {otherProps?.pageContent && (
          <>
            <CustomNormalText>{otherProps?.pageContent?.uploadContent}</CustomNormalText>
            <DragDropText>
              Drag and drop your files here
              <span style={{ display: 'block', marginTop: '10px' }}>OR</span>
            </DragDropText>
          </>
        )}
        <UploadFileBtn type="button" onClick={handleUploadBtnClick} data-testid="fileUploadBtn">
          <span>Browse File</span>
        </UploadFileBtn>
        <FormField type="file" name="files" ref={fileInputField} onChange={handleAddNewFiles} title="" value="" multiple {...otherProps} />
      </FileUploadContainer>
      {tempPrevFileLoading.length > 0 && <>{getContentFileUploading(tempPrevFileLoading)}</>}
      {isArray(dataFileUploaded) &&
        dataFileUploaded.map((fileItem, index) => (
          <FileUploaded
            key={index}
            fileItem={fileItem}
            setFileUploadStatus={setFileUploadStatus}
            setFileUpload={setFileUpload}
            isUploading={isFileLoading}
            fileUploadStatus={fileUploadStatus}
          />
        ))}
    </>
  );
};

export default UploadMultipleFileForSupporting;
