import { Spin } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import styled from 'styled-components';

import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { Arrow_Down_2, Arrow_Up_2, Compare_2, avatar, homepage_icon } from '../../../assets/img';
import { BreadCrumb, ModelTC } from '../../../components';
import GlobalAlertMessage from '../../../components/GlobalAlertMessage/GlobalAlertMessage';
import { useShowPopupSaveAgenda } from '../../../hooks/useShowPopupSaveAgenda';
import { useUpdatePdcMobilityStepNumber } from '../../../hooks/useUpdatePdcMobilityStepNumber';
import { pdcMeetingApi } from '../../../services/pdcMeetingApi';
import { pushMessage } from '../../../store/alertMessageSlice';
import { ASPIRATION_RISKS_SUCCESS_MESSAGE, MESSAGE_TYPES, NOTIFICATION_TYPE, USER_ACCESS_MESSAGE } from '../../../utils/constants';
import { showNotification } from '../../../utils/notification';
import MobilityBotButtonControls from '../components/MobilityButtonControls/MobilityBotButtonControls';
import MobilityTopButtonControls from '../components/MobilityButtonControls/MobilityTopButtonControls';
import MobilityFinalizeBar from '../components/MobilityFinalizeBar/MobilityFinalizeBar';
import PdcMobilityProcessBar from '../components/PdcMobilityProcessBar/PdcMobilityProcessBar';
import * as styles from './aspiration-risks.module.scss';
import AspirationRisksDetail from './components/AspirationRisksDetail/AspirationRisksDetail';
import CompareObservation from './components/CompareObservation/CompareObservation';

const CustomBtn = styled.button`
  border: none;
  background: transparent;
  padding: 5px 7px;

  img {
    width: 24px;
    height: 24px;
  }
`;

function AspirationRisks(props) {
  const history = useHistory();
  const { idMeeting, mobilityId, proposalId } = useParams();
  const [loading, setLoading] = useState(false);
  const [itemExpand, setItemExpand] = useState(null);
  const [compareItem, setCompareItem] = useState(null);
  const [proposalList, setProposalList] = useState([]);
  const [compareData, setCompareData] = useState({
    initData: [],
    currentData: [],
  });
  const [combinedData, setCombinedData] = useState([]);
  const [isBack, setIsBack] = useState(false);
  const [isSaveSuccess, setSaveSuccess] = useState(false);
  const dispatch = useDispatch();

  const BREAD_CRUMB = [
    { name: 'Homepage', icon: homepage_icon, url: '/homepage' },
    { name: 'PDC Meeting', url: '/pdc' },
    { name: 'Mobility Agenda', url: `/pdc-meeting/${idMeeting}/mobilities` },
    { name: 'Aspirations & Risks' },
  ];

  // Get the condition to edit mobility agenda
  const {
    isOnlyViewAgenda,
    isShowTopButtons,
    isShowBotButtons,
    isAdditionalViewAccess,
    meetingDetail,
    mobilityDetails,
    isEnableMobilityTab,
    proposalMainStep,
    fetchMobilityDetails,
    isShownMobilityTabBar,
    isHRPartners,
    isChairMainOrCommiteeOrAdvocator,
  } = props;

  const { updateStepNumber } = useUpdatePdcMobilityStepNumber();

  const handleReturnExpandCollapseClass = (itemExpand, currentItem) => {
    return itemExpand?.staff_id === currentItem?.staff_id ? styles.expanded : styles.collapsed;
  };

  const isMultiple = useMemo(() => {
    if (!mobilityDetails) return 0;
    return mobilityDetails.proposals.filter((m) => m.is_main && m.type === 'multiple').length;
  }, [mobilityDetails]);

  const fetchMobility = useCallback(async () => {
    if (!mobilityId) return;
    try {
      setLoading(true);
      const res = await pdcMeetingApi.getProposalList(mobilityId, proposalId);
      if (res.status === 200) {
        const tempData = !isEmpty(res?.data?.result)
          ? res.data.result.map((i) => ({
              ...i,
              proposal_item_list: i?.proposal_settings || [],
            }))
          : [];
        setProposalList(tempData);
      }
    } catch (error) {
      console.error(error);
      showNotification(USER_ACCESS_MESSAGE.AN_UNEXPECTED_ERROR, NOTIFICATION_TYPE.FAILED);
    } finally {
      setLoading(false);
    }
  }, [mobilityId]);

  const fetchAspirationRisks = async () => {
    const staff_ids = proposalList
      ?.map((obj) => obj?.staff_id)
      .filter((id) => id != null)
      .join(',');

    if (!mobilityId || staff_ids?.length <= 0) return;
    try {
      setLoading(true);
      const res = await pdcMeetingApi.getAspirationRisk(staff_ids, mobilityId, proposalId);
      if (res.status === 200) {
        const tempData = !isEmpty(res?.data?.result) ? res?.data?.result : [];
        const tempCombinedData = proposalList.map((proposal) => {
          const matchingRisk = tempData?.find((each) => each.staff_id === proposal.staff_id);

          const { staff_id: _, ...restOfMatchingRisk } = matchingRisk || {
            aspiration: '',
            other_observation: '',
            risks: '',
          };

          return {
            ...proposal,
            ...restOfMatchingRisk,
          };
        });
        setCombinedData(tempCombinedData);
        setCompareData((prev) => ({
          ...prev,
          initData: tempCombinedData,
          currentData: tempCombinedData,
        }));
      }
    } catch (error) {
      console.error(error);
      showNotification(USER_ACCESS_MESSAGE.AN_UNEXPECTED_ERROR, NOTIFICATION_TYPE.FAILED);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMobility();
  }, [fetchMobility]);

  useEffect(() => {
    if (proposalList?.length > 0 || (proposalList?.length > 0 && isSaveSuccess === true)) {
      fetchAspirationRisks();
    }
  }, [proposalList, isSaveSuccess]);

  const handleClickBack = async () => {
    setIsBack(true);
    if (isChangeAspirationRisks) {
      await handleSaveAsDraft();
    }
    history.push(`/pdc-meeting/${idMeeting}/mobility/${mobilityId}/assessment/${proposalId}`);
  };

  const handleSaveAsDraft = async (onSuccessCallback) => {
    try {
      setLoading(true);
      const payload = !isEmpty(combinedData)
        ? combinedData.map((item) => {
            return {
              staff_id: item?.staff_id,
              aspirations: item?.aspiration,
              risks: item?.risks,
              other_observation: item?.other_observation,
              mobility_id: mobilityId,
              mobility_proposal_id: proposalId,
              proposal_staff_id: '',
            };
          })
        : [];
      const res = await pdcMeetingApi.updateAspirationRisk(payload);
      if (res.status === 200) {
        setCompareData((prev) => ({
          ...prev,
          initData: combinedData,
          currentData: combinedData,
        }));
        if (proposalMainStep?.step < 4) {
          await updateStepNumber(mobilityId, 4);
        }

        if (typeof onSuccessCallback === 'function') {
          onSuccessCallback();
        } else {
          setSaveSuccess(true);
          dispatch(
            pushMessage({
              type: MESSAGE_TYPES.SUCCESS,
              message: ASPIRATION_RISKS_SUCCESS_MESSAGE,
              timeShow: 0,
            })
          );
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleClickNext = async () => {
    if (proposalMainStep?.step < 4) {
      await updateStepNumber(mobilityId, 4);
    }

    history.push(`/pdc-meeting/${idMeeting}/mobility/${mobilityId}/supporting-documents/${proposalId}`);
  };

  const isChangeAspirationRisks = useMemo(() => {
    return JSON.stringify(compareData.initData) !== JSON.stringify(compareData.currentData);
  }, [compareData.initData, compareData.currentData]);

  useEffect(() => {
    setCompareData((prev) => ({
      ...prev,
      currentData: combinedData,
    }));
  }, [combinedData]);

  const { isShowPopupSaveAgenda, handleKeepNavigate, setIsShowPopupSaveAgenda } = useShowPopupSaveAgenda(isChangeAspirationRisks && !isBack);

  const handleSaveBeforeExit = async () => {
    setIsBack(true);
    setIsShowPopupSaveAgenda(false);
    await handleSaveAsDraft();
    handleKeepNavigate();
  };

  return (
    <>
      <div style={{ marginBottom: '15px' }}>
        <BreadCrumb level={7} breadCrumbList={BREAD_CRUMB} />
      </div>
      <GlobalAlertMessage style={{ marginBottom: '20px' }} />
      {isShowTopButtons && (
        <MobilityTopButtonControls
          idMeeting={idMeeting}
          mobilityId={mobilityId}
          mobilityDetails={mobilityDetails}
          fetchMobility={fetchMobilityDetails}
          isAdditionalViewAccess={isAdditionalViewAccess}
          isHRPartners={isHRPartners}
          isChairMainOrCommiteeOrAdvocator={isChairMainOrCommiteeOrAdvocator}
          meetingDetail={meetingDetail}
        />
      )}
      {isShownMobilityTabBar ? (
        <MobilityFinalizeBar mobilityDetails={mobilityDetails} />
      ) : (
        <PdcMobilityProcessBar currentStep={4} mobilityDetails={mobilityDetails} isEnableMobilityTab={isEnableMobilityTab} />
      )}

      <Spin spinning={loading}>
        <div className={styles.proposalWrap}>
          <div className={styles.mainTitle}>Proposal List</div>
          <div className={styles.proposalList}>
            {!isEmpty(proposalList) &&
              proposalList.map((item, index) => (
                <div key={item?.staff_id} className={styles.proposalItem}>
                  <div className={styles.proposalItemHead}>
                    <h3>Proposal {index + 1}</h3>
                  </div>
                  <div className={`${styles.proposalItemContent} ${handleReturnExpandCollapseClass(itemExpand, item)}`}>
                    <div className={styles.infoLeft}>
                      <div className={styles.avatar}>
                        <img src={item?.image || avatar} alt="" />
                      </div>
                      <div className={styles.name} style={{ width: '230px' }}>
                        <span className={styles.fieldLabel}>Name</span>
                        <span className={styles.fieldValue}>{item?.birth_name || '-'}</span>
                      </div>
                    </div>
                    <div className={styles.position} style={{ width: '350px' }}>
                      <span className={styles.fieldLabel}>Position</span>
                      <span className={styles.fieldValue}>{item?.position_name}</span>
                    </div>
                    <div className={styles.type} style={{ width: '230px' }}>
                      {item?.proposal_type}
                    </div>
                    <div className={styles.listAction}>
                      {!isMultiple && (
                        <CustomBtn>
                          <img
                            src={Compare_2}
                            alt=""
                            onClick={() => {
                              setCompareItem(item);
                            }}
                          />
                        </CustomBtn>
                      )}
                      <CustomBtn>
                        {(!itemExpand || itemExpand?.staff_id !== item?.staff_id) && (
                          <img
                            src={Arrow_Down_2}
                            alt=""
                            onClick={() => {
                              setItemExpand(item);
                            }}
                          />
                        )}
                        {itemExpand?.staff_id === item?.staff_id && <img src={Arrow_Up_2} alt="" onClick={() => setItemExpand(null)} />}
                      </CustomBtn>
                    </div>
                  </div>
                  <div className={`${styles.proposalItemDetail} ${handleReturnExpandCollapseClass(itemExpand, item)}`}>
                    <AspirationRisksDetail
                      data={combinedData?.find((each) => each?.staff_id === item?.staff_id)}
                      setCombinedData={setCombinedData}
                      isOnlyViewAgenda={isOnlyViewAgenda || isHRPartners}
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
        {(isShowBotButtons || !mobilityId) && (
          <MobilityBotButtonControls
            idMeeting={idMeeting}
            mobilityId={mobilityId}
            isDisabled={false}
            mobilityDetails={mobilityDetails}
            fetchMobility={fetchMobilityDetails}
            handleClickBack={handleClickBack}
            handleSaveAsDraft={handleSaveAsDraft}
            handleClickNext={handleClickNext}
            isOnlyViewAgenda={isOnlyViewAgenda}
            isHRPartners={isHRPartners}
            isAdditionalViewAccess={isAdditionalViewAccess}
            isChairMainOrCommiteeOrAdvocator={isChairMainOrCommiteeOrAdvocator}
            meetingDetail={meetingDetail}
          />
        )}
      </Spin>
      {compareItem && (
        <CompareObservation
          compareItem={compareItem}
          setCompareItem={setCompareItem}
          isOnlyViewAgenda={isOnlyViewAgenda || isHRPartners}
          setCombinedData={setCombinedData}
        />
      )}
      <ModelTC
        info={{
          type: 'withoutSaving',
          visible: isShowPopupSaveAgenda,
          setVisible: setIsShowPopupSaveAgenda,
          onClose: handleKeepNavigate,
          handleSubmit: handleSaveBeforeExit,
        }}
      />
    </>
  );
}

export default AspirationRisks;
