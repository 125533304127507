import React from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';

import * as styles from './aspiration-risks-detail.module.scss';
import RichTextEditor from '../../../../../components/RichTextEditor/RichTextEditor';

const ProposalWrapper = styled.div`
  margin-bottom: 20px;
  border-radius: 0px 0px 8px 8px;
  border-right: 1px solid var(--color-border-weak, #cbd6e2);
  border-bottom: 1px solid var(--color-border-weak, #cbd6e2);
  border-left: 1px solid var(--color-border-weak, #cbd6e2);
  background-color: #f4f5f8;
  padding: 24px;
  display: flex;
  flex-direction: column;

  .noContent {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
  }
`;

const AspirationRisksDetail = ({ data, setCombinedData, isOnlyViewAgenda }) => {
  return (
    <ProposalWrapper>
      {
        <>
          <div className={styles.cardTitle}>{`Talent's Career Aspirations & Potential Derailers based on External Assessment`}</div>
          <div className={styles.flexContainer}>
            <div className={`${styles.tableHeading} ${styles.heading1}`}>{'Aspirations'}</div>
            <div className={`${styles.tableHeading} ${styles.heading2}`}>{'Risks'}</div>
          </div>
          <div className={styles.flexContainer}>
            <div className={styles.tableCell}>
              <RichTextEditor
                setFieldVal={(value) => {
                  if (isOnlyViewAgenda) return;
                  setCombinedData((prev) => {
                    if (!isEmpty(prev)) {
                      return prev.map((item) => {
                        if (item?.staff_id == data?.staff_id) {
                          return {
                            ...item,
                            aspiration: value,
                          };
                        } else {
                          return item;
                        }
                      });
                    }
                  });
                }}
                fieldVal={data?.aspiration || ''}
                newStyles={{ minHeight: '202px', maxHeight: '210px', width: '543px' }}
                placeholder="Enter Talent Aspirations"
                maxLength={false}
                readOnly={isOnlyViewAgenda}
              />
            </div>
            <div className={styles.tableCell}>
              <RichTextEditor
                setFieldVal={(value) => {
                  if (isOnlyViewAgenda) return;
                  setCombinedData((prev) => {
                    if (!isEmpty(prev)) {
                      return prev.map((item) => {
                        if (item?.staff_id === data?.staff_id) {
                          return {
                            ...item,
                            risks: value,
                          };
                        } else {
                          return item;
                        }
                      });
                    }
                  });
                }}
                fieldVal={data?.risks || ''}
                newStyles={{ minHeight: '202px', maxHeight: '210px', width: '543px' }}
                placeholder="Enter Talent Risks"
                maxLength={false}
                readOnly={isOnlyViewAgenda}
              />
            </div>
          </div>
          <div className={styles.flexContainer}>
            <div className={`${styles.tableHeading} ${styles.heading1}`}>{'Other Observations'}</div>
          </div>
          <div className={styles.flexContainer}>
            <div className={styles.tableCell}>
              <RichTextEditor
                setFieldVal={(value) => {
                  if (isOnlyViewAgenda) return;
                  setCombinedData((prev) => {
                    if (!isEmpty(prev)) {
                      return prev.map((item) => {
                        if (item?.staff_id === data?.staff_id) {
                          return {
                            ...item,
                            other_observation: value,
                          };
                        } else {
                          return item;
                        }
                      });
                    }
                  });
                }}
                fieldVal={data?.other_observation || ''}
                newStyles={{ minHeight: '202px', maxHeight: '210px', width: '1138px' }}
                placeholder="Enter Other Observations"
                maxLength={false}
                readOnly={isOnlyViewAgenda}
              />
            </div>
          </div>
        </>
      }
    </ProposalWrapper>
  );
};

export default AspirationRisksDetail;
