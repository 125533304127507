import React from 'react';
import * as styles from './print-sketon.module.scss';

const PrintSkeleton = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.section}>
        <div className={styles.type1} />
        <div className={styles.type2} />
        <div className={styles.row}>
          <div className={styles.type2} />
          <div className={styles.type2} />
        </div>
        <div className={styles.type2} />
      </div>

      <div className={styles.section}>
        <div className={styles.type1} />
        <div className={styles.type3} />
        <div className={styles.type3} />
      </div>

      <div className={styles.section}>
        <div className={styles.type1} />
        <div className={styles.row}>
          <div className={styles.type2} />
          <div className={styles.type4} />
          <div className={styles.type4} />
        </div>
        <div className={styles.type2} />
        <div className={styles.type3} />
      </div>
    </div>
  );
};

export default PrintSkeleton;
