import { get, isArray } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useShowAlertUploadDownloadWhenNavigate } from '../../../../../hooks/useShowAlertUploadDownloadWhenNavigate';
import { useShowAlertWhenRefresh } from '../../../../../hooks/useShowAlertWhenRefresh';
import axiosClient from '../../../../../services/axiosClient';
import {
  DocumentIcon,
  FilePreviewContainer,
  FileUploadTitle,
  GroupButon,
  ProgressContainer,
  ProgressContent,
  UploadFileProgress,
} from '../../../../Meeting/components/FileUpload/Styled';
import { deleteIcon, document_icon, reload_icon, sp_trash } from '../../../../../assets/img';
import { AiButton } from '../../../../../assets/common';
import { ModelTC } from '../../../../../components';
import { Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { PAGE_NAME } from '../../../../../utils/constants';

const FileLoading = ({ fileItem, setFileUploadStatus, setIsFileLoading, setFileUploaded, isFileLoading, handleSetFileUploaded, pageName }) => {
  const { roleId } = useSelector((state) => state.user.roleActive);
  const { idMeeting } = useParams();
  const [hasError, setHasError] = useState({
    value: false,
    type: '',
  });
  const [progress, setProgress] = useState(0);
  const [timer, setTimer] = useState(0);
  const { setIsUploadingOrDownloading } = useShowAlertUploadDownloadWhenNavigate('upload');
  const { setShowAlertRefresh } = useShowAlertWhenRefresh();
  const [visibleDeleteFile, setVisibleDeleteFile] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchFileUpload = async () => {
    if (!fileItem || fileItem?.loaded) return;

    const formData = new FormData();
    formData.append('file', fileItem?.fileData);
    try {
      setIsFileLoading(true);
      setIsUploadingOrDownloading(true);
      setShowAlertRefresh(true);
      setTimer(150);
      if (pageName === PAGE_NAME.PDC_MEETING) {
        formData.append('meeting_id', idMeeting);
        formData.append('original_file_name', fileItem?.fileData?.name || `Minute of Meeting`);
      }
      let apiName = pageName === PAGE_NAME.PDC_MEETING ? '/meetings-pdc/upload-MoM' : '/pdc/reporting-documents/attachment';
      const response = await axiosClient.post(`${apiName}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          role: roleId,
        },
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 50;
          setTimer((prev) => {
            if (prev > 0) {
              return prev - 1;
            } else {
              return 0;
            }
          });
          setProgress(progress);
        },
        onDownloadProgress: (progressEvent) => {
          const progress = 50 + (progressEvent.loaded / progressEvent.total) * 50;
          setTimer((prev) => {
            if (prev > 0) {
              return prev - 1;
            } else {
              return 0;
            }
          });
          setProgress(progress);
        },
      });
      if (response.status === 200) {
        const result = response?.data?.result;
        setProgress(100);
        setFileUploaded((prev) => {
          return isArray(prev) ? [...prev, result] : [result];
        });
        handleSetFileUploaded({ ...fileItem, isLoading: false, loaded: true });
        if (pageName === PAGE_NAME.PDC_MEETING) {
          setFileUploadStatus((prev) => ({ ...prev, isSuccess: true }));
        }
      }
    } catch (error) {
      const errType = get(error, 'response.data.errors');
      setHasError({
        value: true,
        type: errType,
      });
    } finally {
      setTimer(0);
      setIsFileLoading(false);
      setIsUploadingOrDownloading(false);
      setShowAlertRefresh(false);
    }
  };

  useEffect(() => {
    if (!fileItem || fileItem?.isError) return;

    fetchFileUpload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileItem]);

  useEffect(() => {
    if (hasError?.value) {
      setFileUploadStatus((prev) => ({ ...prev, hasError: true }));
      handleSetFileUploaded({ ...fileItem, isError: true, errType: hasError.type, isLoading: false, loaded: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasError, setFileUploadStatus]);

  const handleDeleteFiles = async () => {
    try {
      setLoading(true);
      setFileUploadStatus((prev) => ({ ...prev, isDeletedMoM: true, hasError: false }));
      handleSetFileUploaded({ ...fileItem, isDeletedMoM: true, isLoading: false, loaded: true, isError: false });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setVisibleDeleteFile(false);
    }
  };

  return (
    <>
      {progress <= 100 && (
        <FilePreviewContainer style={{ marginBottom: '10px' }}>
          <ProgressContainer>
            <DocumentIcon src={document_icon} alt=""></DocumentIcon>
            <ProgressContent style={{ paddingRight: 0 }}>
              <FileUploadTitle>
                <div className={'progress-file-name'}>
                  {fileItem?.fileData?.name || `Minute of Meeting.pdf`}
                  {fileItem?.isError && (
                    <img src={reload_icon} alt="reload_icon" style={{ width: 20, marginLeft: '10px', cursor: 'pointer' }} onClick={fetchFileUpload} />
                  )}
                </div>
                <span className={'progress-time'} style={fileItem?.isError ? { color: '#DA2228' } : {}}>
                  {!fileItem?.isError && (
                    <>
                      Time remaining: <strong>{!fileItem?.file_url ? Math.round(timer) : 0} sec</strong>
                    </>
                  )}
                  {fileItem?.isError && `Upload failed: ${fileItem?.errType || 'undefined'}`}
                </span>
              </FileUploadTitle>
              <UploadFileProgress hasError={fileItem?.isError}>
                <span style={{ width: `${!fileItem?.file_url ? progress : 100}%` }}></span>
              </UploadFileProgress>
            </ProgressContent>
            <GroupButon>
              <Spin spinning={loading}>
                <AiButton
                  style={{
                    ...{ padding: 0, width: `30px` },
                    ...(isFileLoading && { opacity: '0.3', cursor: 'no-drop' }),
                  }}
                  onKeyDown={() => {}}
                  onClick={() => !isFileLoading && setVisibleDeleteFile(true)}
                >
                  {pageName === PAGE_NAME.PDC_MEETING ? (
                    <img src={deleteIcon} alt="deleteIcon" style={{ width: 16 }} />
                  ) : (
                    <img src={sp_trash} alt="sp_trash" style={{ width: 20 }} />
                  )}
                </AiButton>
              </Spin>
            </GroupButon>
          </ProgressContainer>
        </FilePreviewContainer>
      )}

      {/* ModelTC */}
      <ModelTC
        info={{
          type: pageName === PAGE_NAME.PDC_MEETING ? 'deleteMoM' : 'deleteFileOthersAgenda',
          visible: visibleDeleteFile,
          setVisible: setVisibleDeleteFile,
          handleSubmit: handleDeleteFiles,
          onClose: () => {},
          loading: false,
        }}
      />
    </>
  );
};

export default FileLoading;
