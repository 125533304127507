import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import styles from './add-new-experience.module.scss';
import { BasicButton } from '../../../assets/common';
import { v4 as uuidv4 } from 'uuid';

const AddNewExperience = (props) => {
  const { isVisible, setIsVisible, setCompareData } = props;
  const [experience, setExperience] = useState('');

  const handleCancel = () => {
    setIsVisible(false);
    setExperience('');
  };

  const handleClickAdd = async () => {
    if (!experience) return;
    setCompareData((prev) => ({
      ...prev,
      currentData: prev.currentData.map((i) => ({
        ...i,
        proposal_compare_successors: i?.proposal_compare_successors.map((successor) => {
          return {
            ...successor,
            experiences: !isEmpty(successor?.experiences)
              ? [...successor.experiences, { value: uuidv4(), label: experience }]
              : [{ value: uuidv4(), label: experience }],
          };
        }),
      })),
    }));
    setIsVisible(false);
    setExperience('');
  };

  return (
    <Modal
      show={isVisible}
      onHide={() => handleCancel()}
      dialogClassName={`${styles.modal} modal_add_experience`}
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header className={styles.modal_header} closeButton>
        <h3>Add new Experience</h3>
      </Modal.Header>

      <Modal.Body className={styles.table}>
        <div className={`${styles.label}`}>
          <span>Experience</span>
        </div>
        <input
          onChange={(e) => {
            if (isEmpty(e.target.value)) setExperience('');
            setExperience(e.target.value);
          }}
          value={experience}
          placeholder="Input Experience "
        />
        <div className={styles.listBtn}>
          <BasicButton width="100px" onClick={handleCancel}>
            Cancel
          </BasicButton>
          <BasicButton disabled={!experience} width="120px" mode="teal" onClick={handleClickAdd}>
            Save Changes
          </BasicButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddNewExperience;
