import { List, Spin, Tooltip } from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { v4 as uuidv4 } from 'uuid';

import { close, close_icon_light, Rectangle } from '../../assets/img';
import useCallbackClickOutside from '../../hooks/useCallbackClickOutside';
import { getLabelNameForSecretariatOfTcMeeting } from '../../utils/helper';
import {
  InputStyleCustom,
  ListAntd,
  SearchInputWrapper,
  SelectedItem,
  TitleDiv,
  Wrapper,
  WrapperPopUp,
} from './SearchBySecretariatForTcMeetingStyled';
import { MEETING_CATEGORY_VALUES, ROLE } from '../../utils/constants';
import { meetingApi } from '../../services/tcMeeting';
import { useSelector } from 'react-redux';

const titlePetronasHeaderList = [
  {
    id: '1',
    name: 'Role',
  },
];

const titleHeaderList = [
  {
    id: '1',
    name: 'Role',
  },
  {
    id: '2',
    name: 'Business',
  },
  {
    id: '3',
    name: 'Sector',
  },
  {
    id: '4',
    name: 'OPU',
  },
  {
    id: '5',
    name: 'Division',
  },
];

const titleSKGHeaderList = [
  {
    id: '1',
    name: 'Role',
  },
  {
    id: '2',
    name: 'SKG',
  },
];

const titleJobFamilyHeaderList = [
  {
    id: '1',
    name: 'Role',
  },
  {
    id: '2',
    name: 'Job Family',
  },
];

const SearchBySecretariatForTcMeeting = (props) => {
  const { setValue, searchName, fieldValues, setMeetingSecretariatValue, isDisabled, status } = props;
  const { roleId } = useSelector((state) => state.user.roleActive);
  const [loading, setLoading] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [query, setQuery] = useState({ search: '', page: 1, data: [] });
  const [inputValue, setInputValue] = useState(null);
  const [debouncedInputValue, setDebouncedInputValue] = useState('');
  const [isMount, setIsMount] = useState(false);
  const [hasMore, setHasmore] = useState(true);

  const { ref, isComponentVisible, setIsComponentVisible } = useCallbackClickOutside({
    initialIsVisible: openDropdown,
    cbFunction: () => {
      setOpenDropdown(false);
      setInputValue(null);
    },
  });

  const handleClearData = () => {
    setMeetingSecretariatValue([]);
  };

  const getColumnNumber = useMemo(() => {
    let tempNumber = 0;
    switch (searchName) {
      case MEETING_CATEGORY_VALUES.PETRONAS:
        tempNumber = 1;
        break;
      case MEETING_CATEGORY_VALUES.BUSINESS:
      case MEETING_CATEGORY_VALUES.OPU:
      case MEETING_CATEGORY_VALUES.DIVISION:
      case MEETING_CATEGORY_VALUES.SECTOR:
        tempNumber = 5;
        break;
      case MEETING_CATEGORY_VALUES.SKG:
      case MEETING_CATEGORY_VALUES.JOB_FAMILY:
        tempNumber = 2;
        break;
      default:
        tempNumber = 1;
        break;
    }
    return tempNumber;
  }, [query, searchName]);

  useEffect(() => {
    setInputValue(null);
    setQuery((prev) => ({ ...prev, search: '', page: 1, limit: 15 }));
  }, [searchName]);

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      setDebouncedInputValue(inputValue);
    }, 1000);
    return () => clearTimeout(delayInputTimeoutId);
  }, [inputValue]);

  useEffect(() => {
    const element = document.getElementById('scrollableDiv');
    if (element) {
      element.scrollTo({
        top: 0,
      });
      setQuery(() => ({ page: 1, search: debouncedInputValue, data: [] }));
    }
  }, [debouncedInputValue]);

  const loadMoreData = async (page, keyword) => {
    if (loading) {
      return;
    }

    try {
      setLoading(true);
      const params = {
        is_include_roles: true,
        is_full_category_dropdown: true,
        search_text: keyword,
        meeting_category: searchName,
        page,
        limit: 15,
      };
      const res = await meetingApi.getMeetingSearchByCategory(params, roleId);
      const result = res.data.result?.data;
      if (page > 1) {
        setHasmore(true);
        setQuery((prev) => ({ ...prev, data: [...prev.data, ...result] }));
      } else {
        setQuery(() => ({ page: 1, search: keyword, data: !isEmpty(result) ? result : [] }));
      }
    } catch (error) {
      console.error(error);
      setQuery(() => ({ page: 1, search: '', data: [] }));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isComponentVisible && isMount) {
      loadMoreData(query.page, query.search);
    }
  }, [query.page, query.search, isComponentVisible]);

  useEffect(() => {
    if (isComponentVisible && !isMount) {
      loadMoreData(1, '');
      setIsMount(true);
    }
  }, [isMount, isComponentVisible]);

  useEffect(() => {
    if (isComponentVisible && !isMount) {
      setIsMount(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMount, isComponentVisible]);

  const getClassActiveColumn = (value) => {
    return searchName === value ? 'function-name' : '';
  };

  const handleRemoveItem = (e, item) => {
    e.preventDefault();
    setMeetingSecretariatValue(fieldValues.filter((option) => option?.label_name !== item?.label_name));
  };

  const returnTitleList = (data) => {
    if (isEmpty(data)) return;

    return data.map((item, index) => (
      <div key={index} style={item.name === searchName ? { backgroundColor: '#C9D5E3' } : {}}>
        {item.name}
      </div>
    ));
  };

  const handleReturnTableHeader = () => {
    let tempHTML;
    switch (searchName) {
      case MEETING_CATEGORY_VALUES.PETRONAS:
        tempHTML = returnTitleList(titlePetronasHeaderList);
        break;
      case MEETING_CATEGORY_VALUES.BUSINESS:
      case MEETING_CATEGORY_VALUES.OPU:
      case MEETING_CATEGORY_VALUES.DIVISION:
      case MEETING_CATEGORY_VALUES.SECTOR:
        tempHTML = returnTitleList(titleHeaderList);
        break;

      case MEETING_CATEGORY_VALUES.SKG:
        tempHTML = returnTitleList(titleSKGHeaderList);
        break;
      case MEETING_CATEGORY_VALUES.JOB_FAMILY:
        tempHTML = returnTitleList(titleJobFamilyHeaderList);
        break;
      default:
        break;
    }
    return tempHTML;
  };

  const handleReturnTableContent = (record) => {
    let tempHTML;
    switch (searchName) {
      case MEETING_CATEGORY_VALUES.PETRONAS:
        tempHTML = <div className={getClassActiveColumn('Role')}>{record.role || '-'}</div>;
        break;
      case MEETING_CATEGORY_VALUES.BUSINESS:
      case MEETING_CATEGORY_VALUES.OPU:
      case MEETING_CATEGORY_VALUES.DIVISION:
      case MEETING_CATEGORY_VALUES.SECTOR:
        tempHTML = (
          <>
            <div className={getClassActiveColumn('Role')}>{record.role || '-'}</div>
            <div className={getClassActiveColumn('Business')}>{record.business || record?.business_unit || '-'}</div>
            <div className={getClassActiveColumn('Sector')}>{record.sector || '-'}</div>
            <div className={getClassActiveColumn('OPU')}>{record.opu || record?.opu_name || '-'}</div>
            <div className={getClassActiveColumn('Division')}>{record.division || '-'}</div>
          </>
        );
        break;
      case MEETING_CATEGORY_VALUES.SKG:
        tempHTML = (
          <>
            <div className={getClassActiveColumn('Role')}>{record.role || '-'}</div>
            <div className={getClassActiveColumn('SKG')}>{record.name || '-'}</div>
          </>
        );
        break;
      case MEETING_CATEGORY_VALUES.JOB_FAMILY:
        tempHTML = (
          <>
            <div className={getClassActiveColumn('Role')}>{record.role || '-'}</div>
            <div className={getClassActiveColumn('Job Family')}>{record.name || '-'}</div>
          </>
        );
        break;
      default:
        break;
    }
    return tempHTML;
  };

  const handleOpenSearchDropdown = () => {
    setInputValue(null);
    setIsComponentVisible(true);
    setQuery(() => ({ page: 1, search: '', data: [] }));
  };

  const handleShowContentTooltip = (record) => {
    let tempHTML;
    switch (record?.search_type) {
      case MEETING_CATEGORY_VALUES.PETRONAS:
        tempHTML = <div>Role: {record.role || '-'}</div>;
        break;
      case MEETING_CATEGORY_VALUES.SECTOR:
        tempHTML = (
          <div>
            <div>Business: {record?.business || record?.business_unit || '-'}</div>
          </div>
        );
        break;
      case MEETING_CATEGORY_VALUES.OPU:
        tempHTML = (
          <div>
            <div>Business: {record?.business || record?.business_unit || '-'}</div>
            <div>Sector: {record.sector || '-'}</div>
          </div>
        );
        break;
      case MEETING_CATEGORY_VALUES.DIVISION:
        tempHTML = (
          <div>
            <div>Business: {record?.business || record?.business_unit || '-'}</div>
            <div>Sector: {record.sector || '-'}</div>
            <div>OPU: {record?.opu || record?.opu_name || '-'}</div>
          </div>
        );
        break;
      default:
        break;
    }
    return tempHTML;
  };

  const tempDataSource = useMemo(() => {
    return !isEmpty(query.data)
      ? query.data.map((item) => {
          if (searchName === MEETING_CATEGORY_VALUES.SKG) {
            return {
              ...item,
              skg: item?.id,
              skg_text: item?.name,
            };
          } else if (searchName === MEETING_CATEGORY_VALUES.JOB_FAMILY) {
            return {
              ...item,
              dev_job_family_id: item?.id,
              job_family: item?.name,
            };
          } else {
            return item;
          }
        })
      : [];
  }, [query.data]);

  const searchInputDom = document.getElementById('search-input-wrapper');
  const popupDom = document.getElementById('secretariat-search-popup');

  useEffect(() => {
    const search = searchInputDom?.getBoundingClientRect();
    if (search && popupDom) {
      popupDom.style.top = `0px`;
      popupDom.style.top = search?.height + 5 + 'px';
    }
  }, [searchInputDom?.getBoundingClientRect(), popupDom?.getBoundingClientRect()]);

  return (
    <Wrapper ref={ref}>
      <div style={{ width: '100%' }}>
        {!isComponentVisible && (
          <SelectedItem
            onClick={() => {
              !isDisabled && handleOpenSearchDropdown();
            }}
            className={`${isDisabled && 'isDisabled'}`}
            status={isEmpty(fieldValues) && status}
            disabled={isDisabled}
          >
            {!isEmpty(fieldValues) ? (
              <div className={`optionList`}>
                {fieldValues.map((item, index) => (
                  <>
                    {![
                      MEETING_CATEGORY_VALUES.PETRONAS,
                      MEETING_CATEGORY_VALUES.BUSINESS,
                      MEETING_CATEGORY_VALUES.SKG,
                      MEETING_CATEGORY_VALUES.JOB_FAMILY,
                    ].includes(searchName) && ![item?.role, item?.name, item?.role_name].includes(ROLE.HR_COE) ? (
                      <Tooltip
                        key={index}
                        title={handleShowContentTooltip(item)}
                        {...{
                          overlayInnerStyle: {
                            width: '200px',
                            fontSize: '12px',
                            lineHeight: '18px',
                            fontWeight: 400,
                            fontFamily: '"Inter", sans-serif',
                            padding: '12px',
                            borderRadius: '4px',
                            backgroundColor: '#000',
                          },
                        }}
                      >
                        <div className="optionItem" key={index}>
                          <img
                            style={{ position: 'relative' }}
                            src={close_icon_light}
                            alt=""
                            onClick={(e) => {
                              if (isDisabled) return;
                              handleRemoveItem(e, item);
                            }}
                          />
                          <span>{item?.label_name || 'N/A'}</span>
                        </div>
                      </Tooltip>
                    ) : (
                      <div className="optionItem" key={index}>
                        <img
                          style={{ position: 'relative' }}
                          src={close_icon_light}
                          alt=""
                          onClick={(e) => {
                            if (isDisabled) return;
                            handleRemoveItem(e, item);
                          }}
                        />
                        <span>{item?.label_name || 'N/A'}</span>
                      </div>
                    )}
                  </>
                ))}
              </div>
            ) : (
              <div className="textPlacebolder">Search by Role, Business, Sector, OPU and Division</div>
            )}
            {!isDisabled && (
              <div className={'clearBtnWrap'}>
                <img
                  className={!isEmpty(fieldValues) && 'clearBtn'}
                  src={!isEmpty(fieldValues) ? close : Rectangle}
                  alt=""
                  style={{ padding: '10px', cursor: 'pointer' }}
                  onClick={() => {
                    if (searchName === MEETING_CATEGORY_VALUES.PETRONAS) return;
                    !isEmpty(fieldValues) ? handleClearData() : handleOpenSearchDropdown();
                  }}
                  onKeyDown={() => {}}
                />
              </div>
            )}
          </SelectedItem>
        )}
        {isComponentVisible && (
          <SearchInputWrapper id="search-input-wrapper">
            <div className={`optionList`}>
              {!isEmpty(fieldValues) && (
                <>
                  {fieldValues.map((item, index) => (
                    <>
                      {![
                        MEETING_CATEGORY_VALUES.PETRONAS,
                        MEETING_CATEGORY_VALUES.BUSINESS,
                        MEETING_CATEGORY_VALUES.SKG,
                        MEETING_CATEGORY_VALUES.JOB_FAMILY,
                      ].includes(searchName) && ![item?.role, item?.name, item?.role_name].includes(ROLE.HR_COE) ? (
                        <Tooltip
                          key={index}
                          title={handleShowContentTooltip(item)}
                          {...{
                            overlayInnerStyle: {
                              width: '200px',
                              fontSize: '12px',
                              lineHeight: '18px',
                              fontWeight: 400,
                              fontFamily: '"Inter", sans-serif',
                              padding: '12px',
                              borderRadius: '4px',
                              backgroundColor: '#000',
                            },
                          }}
                        >
                          <div className="optionItem" key={index}>
                            <img
                              style={{ position: 'relative' }}
                              src={close_icon_light}
                              alt=""
                              onClick={(e) => {
                                if (isDisabled) return;
                                handleRemoveItem(e, item);
                              }}
                            />
                            <span>{item?.label_name || 'N/A'}</span>
                          </div>
                        </Tooltip>
                      ) : (
                        <div className="optionItem" key={index}>
                          <img
                            style={{ position: 'relative' }}
                            src={close_icon_light}
                            alt=""
                            onClick={(e) => {
                              if (isDisabled) return;
                              handleRemoveItem(e, item);
                            }}
                          />
                          <span>{item?.label_name || 'N/A'}</span>
                        </div>
                      )}
                    </>
                  ))}
                </>
              )}
              <InputStyleCustom
                placeholder={isEmpty(fieldValues) ? `Search by Role, Business, Sector, OPU and Division` : ''}
                onChange={(e) => setInputValue(e.target.value)}
                value={inputValue}
                autofocus
              />
            </div>
            {!isDisabled && (
              <div className={'clearBtnWrap'}>
                <img
                  className={!isEmpty(fieldValues) && 'clearBtn'}
                  src={!isEmpty(fieldValues) ? close : Rectangle}
                  alt=""
                  style={{ padding: '10px', cursor: 'pointer' }}
                  onClick={() => {
                    if (searchName === MEETING_CATEGORY_VALUES.PETRONAS) return;
                    !isEmpty(fieldValues) ? handleClearData() : handleOpenSearchDropdown();
                  }}
                  onKeyDown={() => {}}
                />
              </div>
            )}
          </SearchInputWrapper>
        )}
      </div>
      {isComponentVisible && (
        <WrapperPopUp id="secretariat-search-popup">
          <TitleDiv columnNumber={getColumnNumber}>{handleReturnTableHeader()}</TitleDiv>
          <div
            id="scrollableDiv"
            style={{
              maxHeight: '264px',
              overflow: 'auto',
              width: '100%',
              backgroundColor: 'white',
            }}
          >
            <InfiniteScroll
              dataLength={tempDataSource.length}
              next={() => {
                setQuery((prev) => ({ ...prev, page: prev.page + 1 }));
              }}
              hasMore={hasMore}
              loader={
                loading && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '50px',
                    }}
                  >
                    <Spin loading={loading} />
                  </div>
                )
              }
              scrollableTarget="scrollableDiv"
            >
              <ListAntd
                columnNumber={getColumnNumber}
                dataSource={tempDataSource}
                split={false}
                renderItem={(item) => (
                  <List.Item
                    key={uuidv4()}
                    onClick={() => {
                      setIsComponentVisible(false);
                      setValue({
                        val: {
                          ...item,
                          label_name: getLabelNameForSecretariatOfTcMeeting(searchName, item),
                          search_type: searchName,
                        },
                      });
                      setInputValue(null);
                    }}
                  >
                    {handleReturnTableContent(item)}
                  </List.Item>
                )}
              />
            </InfiniteScroll>
          </div>
        </WrapperPopUp>
      )}
    </Wrapper>
  );
};
export default SearchBySecretariatForTcMeeting;
