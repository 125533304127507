import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as styles from './search-position-talent-modal.module.scss';
import CustomAntModal from '../../../../components/CustomAntModal/CustomAntModal';
import { BasicButton, BasicSelect } from '../../../../assets/common';
import { Divider, Spin } from 'antd';
import { debounce } from 'lodash';
import { avatar_not_found } from '../../../../assets/img';
import { pdcMeetingApi } from '../../../../services/pdcMeetingApi';

const SearchPositionTalentModal = ({ open, setOpen, onAdd, cards }) => {
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchData = async (keyword) => {
    setLoading(true);
    try {
      const selectedCards = cards.map((card) => ({
        staff_id: card.staff_id,
        position_code: card.position_code,
      }));
      const response = await pdcMeetingApi.searchTalentPosition(keyword, selectedCards);
      setOptions(response.data.result);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchData = debounce(fetchData, 500);

  const handleSearch = (value) => {
    if (value) {
      debouncedFetchData(value);
    }
  };

  const handleClickAdd = async () => {
    onAdd(selected);
    handleCancel();
  };

  const handleCancel = async () => {
    setSelected(null);
    setOptions([]);
  };

  const handleSelect = (value) => {
    const item = options.find((item) => `${item.staff_id}-${item.position_code}` === value);
    setSelected(item); // Save the selected item details in state
  };

  useEffect(() => {
    const input = document.getElementById('pdc-add-mobility');
    if (!input) return;
    loading ? (input.readOnly = true) : (input.readOnly = false);
  }, [loading]);

  return (
    <CustomAntModal hideDefaultBtn open={open} setOpen={setOpen} closeType={1} width={550} styles={{ closePosition: 30 }} onCancel={handleCancel}>
      <Spin spinning={loading}>
        <div className={styles.wrapper}>
          <div className={styles.title}>Add Position or Talent to Mobility</div>
          <BasicSelect
            showSearch
            placeholder={
              selected ? (
                <div>
                  <span>{!selected.has_incumbent ? selected.position_name : selected.birth_name || selected.staff_id || selected.position_name}</span>
                </div>
              ) : (
                'Search By Position name, Position ID, Staff Name or Staff ID'
              )
            }
            value={null}
            filterOption={false}
            onSearch={handleSearch}
            onSelect={handleSelect}
            getPopupContainer={(trigger) => trigger}
            id="pdc-add-mobility"
          >
            {options.map((item) => (
              <BasicSelect.Option key={`${item.staff_id}-${item.position_code}`} value={`${item.staff_id}-${item.position_code}`}>
                <div className={styles.individual}>
                  <div className={styles.avatar}>
                    <img alt="avatar" src={!item.has_incumbent ? avatar_not_found : item.image_url || avatar_not_found} />
                  </div>
                  <div className={styles.info}>
                    <p className={styles.name}>{!item.has_incumbent ? 'N/A' : item.birth_name || 'N/A'}</p>
                    <p>{!item.has_incumbent ? 'N/A' : item.staff_id || 'N/A'}</p>
                    <p>{item.position_name || 'N/A'}</p>
                    <p>
                      {item.division || 'N/A'}, {item.business_unit || 'N/A'}
                    </p>
                    <p>{item.opu_name || 'N/A'}</p>
                  </div>
                </div>
              </BasicSelect.Option>
            ))}
          </BasicSelect>
          <Divider />
          <BasicButton mode="teal" width="100%" onClick={handleClickAdd}>
            Add
          </BasicButton>
        </div>
      </Spin>
    </CustomAntModal>
  );
};

export default SearchPositionTalentModal;

SearchPositionTalentModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  onAdd: PropTypes.func,
  cards: PropTypes.array,
};
