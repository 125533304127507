import { Col, Row, Space, Spin } from 'antd';
import { useFormik } from 'formik';
import __, { find, get, isArray, isEmpty, isNil, map, size } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { AiButton, BasicButton } from '../../../assets/common';
import { homepage_icon } from '../../../assets/img';
import { AlertMessage, AsyncMultipleSelect, BreadCrumb, FieldError, ModelTC } from '../../../components';
import SearchByCategoryForTcMeeting from '../../../components/SearchByCategoryForTcMeeting/SearchByCategoryForTcMeeting';
import SearchBySecretariatForTcMeeting from '../../../components/SearchBySecretariatForTcMeeting/SearchBySecretariatForTcMeeting';
import { isCheckError } from '../../../hooks/useFormik';
import { useSecretarialOptions } from '../../../hooks/useSecretarialOptions';
import { meetingApi } from '../../../services/tcMeeting';
import {
  AN_UNEXPECTED_ERROR,
  DATE_TIME_FORMAT,
  FILL_OUT_REQUIRE_MESSAGE,
  MEETING_CATEGORY_OPTIONS,
  MEETING_CATEGORY_VALUES,
  MEETING_DETAIL_STATUS,
  MESSAGE_TYPES,
  NOTIFICATION_TYPE,
  ROLE,
} from '../../../utils/constants';
import {
  getAsyncOptionAdvocatorsHrPartners,
  getBusinessIdToPopulateForAdditionalView,
  getFieldValueForTCMeetingInFirstTime,
  getLabelNameForSecretariatOfTcMeeting,
  isDifferentFieldPdcMeeting,
} from '../../../utils/helper';
import { showNotification } from '../../../utils/notification';
import MeetingProgressBar from '../components/MeetingProgressBar/MeetingProgressBar';
import { addOrUpdateMeeting, clearPDCMeeting, initialStateMeeting, switchEditMode } from '../redux/meetingDetailSlice';
import { addOrUpdateStepper } from '../redux/meetingProgressBarSlice';
import styles from './meeting-details.module.scss';
import { validationSchema } from './meeting-validations';
import {
  AiContainerForm,
  AiContainerGrBtn,
  AiDatePicker,
  AiH3,
  AiInput,
  AiLabel,
  AiTimePickerField,
  MeetingTitle,
} from '../../Meeting/MeetingDetails/Styled';
import SearchByAdditionalViewAccessForTcMeeting from '../../../components/SearchByAdditionalViewAccessForTcMeeting/SearchByAdditionalViewAccessForTcMeeting';
import MeetingCategory from '../../Meeting/MeetingDetails/components/MeetingCategory';
import { useCreatePDCMeeting } from '../../../hooks/useCreatePDCMeeting';
import { useUpdatePDCMeeting } from '../../../hooks/useUpdatePDCMeeting';
import { useGetPDCMeeting } from '../../../hooks/useGetPDCMeeting';
import { pdcMeetingApi } from '../../../services/pdcMeetingApi';
import { useShowPopupSaveAgenda } from '../../../hooks/useShowPopupSaveAgenda';
import GlobalAlertMessage from '../../../components/GlobalAlertMessage/GlobalAlertMessage';
import { pushMessage } from '../../../store/alertMessageSlice';
import PDCMeetingMobilities from '../PDCMeetingMobilities/PDCMeetingMobilities';
import { useGetRoleInPdcMeeting } from '../../../hooks/useGetRoleInPdcMeeting';
import ContainerSummary from '../components/ContainerSummary/ContainerSummary';
import PDCMeetingStatus from '../components/PDCMeetingStatus/PDCMeetingStatus';
import { useUpdatePdcMeetingStepNumber } from '../../../hooks/useUpdatePdcMeetingStepNumber';
import PrintSelectButton from '../PDCPrintPage/components/PrintSelectButton/PrintSelectButton';
import PDCRemarks from '../components/PDCRemarks/PDCRemarks';

const PDCMeetingDetails = () => {
  const [visibleRemoveMeeting, setVisibleRemoveMeeting] = useState(false);
  const { meeting, isEditMode } = useSelector((state) => state.app.pdcMeetingModule.prePDCMeeting);
  const roleActive = useSelector((state) => state.user.roleActive);
  const currentUser = useSelector((state) => state.user);

  const [isSubmit, setIsSubmit] = useState(false);
  const [loadingDeleteMeeting, setLoadingDeleteMeeting] = useState(false);
  const [isOpenCategoryDropdown, setIsOpenCategoryDropdown] = useState();
  const [meetingSecretariatValue, setMeetingSecretariatValue] = useState([]);
  const [additionalViewAccessValue, setAdditionalViewAccessValue] = useState([]);
  // Category Value
  const [isLoadAdditionalDataInFirstTime, setIsLoadAdditionalDataInFirstTime] = useState(true);
  const [visibleErrorMsg, setVisibleErrorMsg] = useState(false);

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showStartMeetingModal, setShowStartMeetingModal] = useState(false);
  const [showCancelMeetingModal, setShowCancelMeetingModal] = useState(false);
  const [showCompleteMeeting, setShowCompleteMeeting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDiscardChanges, setIsDiscardChanges] = useState(false);
  const [isExistingPaperReady, setIsExistingPaperReady] = useState({
    isHighLight: false,
    data: [],
  });
  const [isDislayEditCancelPopup, setIsDislayEditCancelPopup] = useState(false);

  // Fetch api to update step number
  const { updateMeetingStepNumber } = useUpdatePdcMeetingStepNumber();

  // Init state to conpare data changes
  const [compareData, setCompareData] = useState({
    initData: {},
    currentData: {},
  });

  const { isShowPopupSaveAgenda, handleKeepNavigate, setIsShowPopupSaveAgenda } = useShowPopupSaveAgenda(
    !isEmpty(isDifferentFieldPdcMeeting(compareData))
  );

  const handleSaveBeforeExit = async () => {
    setIsShowPopupSaveAgenda(false);
    const values = formik.values;
    const result = await formik.validateForm(values);

    if (isEmpty(result)) {
      await handleSubmit(values);
      handleKeepNavigate();
    } else {
      dispatch(addOrUpdateMeeting(formik.values));
    }
  };

  const handleConfirmExit = () => {
    handleKeepNavigate();
  };

  const isAdminRole = useMemo(() => {
    return roleActive?.roleName ? roleActive?.roleName.includes(ROLE.ADMIN) : false;
  }, [roleActive]);

  // Set Meeting Secretariats
  useEffect(() => {
    formik.setFieldValue('meeting_secretariats', meetingSecretariatValue || []);
  }, [meetingSecretariatValue]);

  // Set Meeting Additional View Access
  useEffect(() => {
    formik.setFieldValue('meeting_additional_view_accesses', additionalViewAccessValue || []);
  }, [additionalViewAccessValue]);

  const [breadCrumbList] = useState([
    { name: 'Homepage', icon: homepage_icon, url: '/homepage' },
    { name: 'PDC Meeting', url: '/pdc' },
    { name: 'Meeting Details' },
  ]);
  const dispatch = useDispatch();
  const history = useHistory();

  let { idMeeting } = useParams();

  const { data: meetingDetail, fetch: fetchMeeting, loading: loadingGetMeeting } = useGetPDCMeeting({ idMeeting });

  const { secretarialOptions } = useSecretarialOptions('add_meeting');

  const { data: meetingRole } = useGetRoleInPdcMeeting({ idMeeting });

  const isOnlyViewMeeting = useMemo(() => {
    if (!idMeeting) return false;
    
    return (
      (!meetingRole?.is_secretariat_or_facilitator &&
        !isAdminRole &&
        meetingRole?.is_additional_view_access &&
        meetingDetail.status === MEETING_DETAIL_STATUS.IN_PLANNING) ||
      (!meetingRole?.is_secretariat_or_facilitator &&
        !meetingRole?.is_chairman &&
        !meetingRole?.is_commitee &&
        !isAdminRole &&
        meetingRole?.is_additional_view_access &&
        meetingDetail.status !== MEETING_DETAIL_STATUS.IN_PLANNING) ||
      (meetingDetail.status === MEETING_DETAIL_STATUS.PUBLISHED && !isEditMode) ||
      meetingDetail.status === MEETING_DETAIL_STATUS.IN_PROGRESS ||
      meetingDetail.status === MEETING_DETAIL_STATUS.CANCELED ||
      (!meetingRole?.is_secretariat_or_facilitator &&
        !meetingRole?.is_chairman &&
        !meetingRole?.is_commitee &&
        !isAdminRole &&
        meetingRole?.is_hr_partners) ||
      (!meetingRole?.is_secretariat_or_facilitator &&
        !meetingRole?.is_additional_view_access &&
        !meetingRole?.is_hr_partners &&
        !isAdminRole &&
        (meetingRole?.is_chairman || meetingRole?.is_advocator || meetingRole?.is_commitee))
    );
  }, [meetingRole, idMeeting, meetingDetail.status, isEditMode]);

  const resetMeetingDetails = () => {
    if (!idMeeting) return;

    formik.setValues({
      ...initialStateMeeting,
      meeting_pdc_id: idMeeting || null,
    });
    dispatch(clearPDCMeeting());
    setMeetingSecretariatValue([]);
    setAdditionalViewAccessValue([]);
  };

  useEffect(() => {
    if (meetingDetail?.meeting_pdc_id) {
      dispatch(
        addOrUpdateMeeting({
          ...meetingDetail,
          category: find(MEETING_CATEGORY_OPTIONS, {
            name: meetingDetail.category,
          }),
          category_value: {
            name: meetingDetail.category_value,
            category_label: meetingDetail.category_label,
          },
          meeting_secretariats: meetingDetail?.meeting_secretariats,
          meeting_additional_view_accesses: meetingDetail?.meeting_additional_view_accesses,
        })
      );
      setMeetingSecretariatValue(getFieldValueForTCMeetingInFirstTime(meetingDetail?.meeting_secretariats));
      setMeetingSecretariatValue(getFieldValueForTCMeetingInFirstTime(meetingDetail?.meeting_additional_view_accesses));
      setCompareData((prev) => ({
        ...prev,
        initData: {
          ...prev.initData,
          meeting_secretariats: getFieldValueForTCMeetingInFirstTime(meetingDetail?.meeting_secretariats),
          meeting_additional_view_accesses: getFieldValueForTCMeetingInFirstTime(meetingDetail?.meeting_additional_view_accesses),
        },
        currentData: {
          ...prev.currentData,
          meeting_secretariats: getFieldValueForTCMeetingInFirstTime(meetingDetail?.meeting_secretariats),
          meeting_additional_view_accesses: getFieldValueForTCMeetingInFirstTime(meetingDetail?.meeting_additional_view_accesses),
        },
      }));
    } else {
      resetMeetingDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingDetail, secretarialOptions, dispatch]);

  const convertMeetingFromStore = useCallback((meeting) => {
    const {
      category,
      chairman,
      meeting_committee,
      meeting_facilitator,
      meeting_secretariats,
      meeting_additional_view_accesses,
      end_time,
      start_time,
      date,
      category_value,
      ...rest
    } = meeting;
    const dateWithFormat = moment(date).format('YYYY-MM-DD');
    return {
      ...rest,
      meeting_category: category.name,
      chairman_id: __(chairman).castArray().head()?.value,
      meeting_committee_ids: map(meeting_committee, 'value'),
      meeting_facilitator_ids: map(meeting_facilitator, 'value'),
      meeting_secretariats: meeting_secretariats,
      meeting_additional_view_accesses,
      end_time: moment(`${dateWithFormat} ${end_time}`, DATE_TIME_FORMAT.DATE_TIME).toDate(),
      start_time: moment(`${dateWithFormat} ${start_time}`, DATE_TIME_FORMAT.DATE_TIME).toDate(),
      on_step: 1,
      category_value: category_value?.name || null,
      category_label: category_value?.category_label || formik.values.category_value?.category_label || null,
    };
  }, []);

  const {
    createMeeting,
    isSuccess: isCreateMeetingSuccess,
    error: createMeetingError,
  } = useCreatePDCMeeting({
    onSuccess: (data) => {
      history.push(`/pdc-meeting/${data.result?.meeting_pdc_id}`);
    },
  });

  const {
    updateMeeting,
    isSuccess: isUpdateMeetingSuccess,
    error: updateMeetingError,
  } = useUpdatePDCMeeting({
    onSuccess: fetchMeeting,
  });

  const handleCreateOrEditPdcMeeting = useCallback(
    (meeting) => {
      const data = convertMeetingFromStore(meeting);
      dispatch(
        addOrUpdateStepper({
          currentTabActive: 2,
          currentTabFinished: [1],
          tabsActive: [1, 2],
        })
      );
      if (isNaN(Number(idMeeting))) {
        return createMeeting(data);
      } else {
        return updateMeeting({ idMeeting, ...data });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [idMeeting]
  );

  const handleSubmit = async (values) => {
    dispatch(addOrUpdateMeeting(values));
    const response = await handleCreateOrEditPdcMeeting(values);
    if (!response?.result?.is_secretariat_or_facilitator && !isAdminRole) {
      return resetMeetingDetails();
    }
    if (response) {
      handleAddOrUpdateMeetingToStore(response.result);
      if (Number(meetingDetail?.on_step) < 1) {
        await updateMeetingStepNumber(idMeeting, 1);
      }
    }
    return response;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: meeting,
    validationSchema,
    onSubmit: handleSubmit,
  });

  // Init data for meeting facilitator in the first time
  const [isAddValueFacilitatorFirstTime, setIsAddValueFacilitatorFirstTime] = useState(true);
  useEffect(() => {
    if (isEmpty(formik.values.meeting_facilitator) && currentUser?.data?.userId && !idMeeting && isAddValueFacilitatorFirstTime) {
      formik.setFieldValue('meeting_facilitator', [
        {
          value: currentUser?.data?.userId,
          label: currentUser?.data?.userName,
          type: 'facilitator',
        },
      ]);
    }
  }, [formik.values.meeting_facilitator, currentUser, idMeeting]);

  // Update compare data
  useEffect(() => {
    if (isDiscardChanges) {
      setCompareData((prev) => ({
        ...prev,
        currentData: prev.initData,
      }));
      setAdditionalViewAccessValue(compareData.initData.meeting_additional_view_accesses);
      setMeetingSecretariatValue(compareData.initData.meeting_secretariats);
      setIsDiscardChanges(false);
    } else {
      setCompareData((prev) => ({
        ...prev,
        currentData: {
          ...prev.currentData,
          ...formik.values,
        },
      }));
    }
  }, [formik.values]);

  useEffect(() => {
    formik.setValues(meeting);
    formik.setFieldValue(
      'meeting_secretariats',
      getFieldValueForTCMeetingInFirstTime(meeting?.meeting_secretariats || meeting?.roles?.meeting_secretariats || meeting?.roles?.secretariat)
    );
    formik.setFieldValue(
      'meeting_additional_view_accesses',
      getFieldValueForTCMeetingInFirstTime(
        meeting?.meeting_additional_view_accesses || meeting?.roles?.meeting_additional_view_accesses || meeting?.roles?.additional_view_access
      )
    );
    setMeetingSecretariatValue(
      getFieldValueForTCMeetingInFirstTime(meeting?.meeting_secretariats || meeting?.roles?.meeting_secretariats || meeting?.roles?.secretariat)
    );
    setAdditionalViewAccessValue(
      getFieldValueForTCMeetingInFirstTime(
        meeting?.meeting_additional_view_accesses || meeting?.roles?.meeting_additional_view_accesses || meeting?.roles?.additional_view_access
      )
    );
    setCompareData((prev) => ({
      ...prev,
      initData: {
        ...prev.initData,
        ...meeting,
        meeting_secretariats: getFieldValueForTCMeetingInFirstTime(
          meeting?.meeting_secretariats || meeting?.roles?.meeting_secretariats || meeting?.roles?.secretariat
        ),
        meeting_additional_view_accesses: getFieldValueForTCMeetingInFirstTime(
          meeting?.meeting_additional_view_accesses || meeting?.roles?.meeting_additional_view_accesses || meeting?.roles?.additional_view_access
        ),
      },
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meeting]);

  const onChangeInputData = (value, key) => {
    formik.setFieldValue(key, value);
  };

  const onChange = (date, dateString) => {
    formik.setFieldValue('date', dateString);
  };

  const onChangeSelectData = (event, key) => {
    formik.setFieldValue(key, event);
  };

  const handleCheckValidateForm = async () => {
    setIsSubmit(true);
    if (size(formik.errors) > 0) {
      setIsSubmit(false);
    } else {
      dispatch(addOrUpdateMeeting(formik.values));
    }
  };

  const exitCreateOrEditMeeting = () => {
    dispatch(clearPDCMeeting());
    history.push('/pdc');
  };
  const handleExit = () => {
    history.push('/pdc');
    // if (!formik.dirty) return history.push('/pdc');
    // setVisibleSaveAsDraft(true);
  };

  const handleNext = async () => {
    const errRes = await formik.validateForm();
    dispatch(addOrUpdateMeeting(formik.values));
    if (!isEmpty(errRes)) {
      setVisibleErrorMsg(true);
      return;
    }

    if (!isOnlyViewMeeting) {
      const response = await handleCreateOrEditPdcMeeting(formik.values);
      if (response) {
        handleAddOrUpdateMeetingToStore(response.result);
        idMeeting = get(response, ['result', 'meeting_pdc_id']);
      }
    }
    if (!idMeeting) return;

    if (Number(meetingDetail?.on_step) < 1) {
      await updateMeetingStepNumber(idMeeting, 1);
    }
    history.push(`/pdc-meeting/${idMeeting}/mobilities`);
  };

  const handleAddOrUpdateMeetingToStore = (data) => {
    const newData = handleConvertDataToSaveStore(data);
    dispatch(
      addOrUpdateMeeting({
        ...newData,
        category: find(MEETING_CATEGORY_OPTIONS, {
          name: newData.category,
        }),
        category_value: {
          name: newData.category_value,
          category_label: newData.category_label,
        },
        meeting_secretariats: newData.meeting_secretariats,
        meeting_additional_view_accesses: newData.meeting_secretariats,
        is_secretariat_or_facilitator: newData?.is_secretariat_or_facilitator,
      })
    );
  };

  // Convert the post response to store
  const handleConvertDataToSaveStore = (data) => {
    const tempChairman = size(data.roles.chairman) > 0 ? data.roles.chairman : [];
    const tempCommittee = size(data.roles.committee) > 0 ? data.roles.committee : [];
    const tempFacilitator = size(data.roles.facilitator) > 0 ? data.roles.facilitator : [];
    const { name, start_at, end_at, is_secretariat_or_facilitator, ...rest } = data;

    return {
      ...rest,
      meeting_name: name,
      date: moment(new Date(start_at)).format(DATE_TIME_FORMAT.DATE_SPACE),
      start_time: getTime(start_at),
      end_time: getTime(end_at),
      chairman: map(tempChairman, (it) => ({ label: it.name, value: it.id })),
      meeting_committee: map(tempCommittee, ({ name, id }) => ({
        label: name,
        value: id,
      })),
      meeting_facilitator: map(tempFacilitator, ({ name, id }) => ({
        label: name,
        value: id,
      })),
      is_secretariat_or_facilitator: is_secretariat_or_facilitator || false,
    };
  };

  const getTime = (dateInput) => {
    if (!dateInput) return '';
    const date = moment(dateInput).local().toDate();
    let hour = date.getHours();
    let minutes = date.getMinutes();

    hour = `0${hour}`.slice(-2);
    minutes = `0${minutes}`.slice(-2);

    return `${hour}:${minutes}`;
  };

  const handleSetMeetingSecretariatValue = (val) => {
    const isCheck = meetingSecretariatValue.filter((item) => item?.label_name === val?.label_name).length > 0;
    if (!isCheck) {
      setMeetingSecretariatValue((prev) => {
        return [...prev, val];
      });
    }
    setIsLoadAdditionalDataInFirstTime(false);
  };

  const handleSetAdditionalViewAccess = (val) => {
    const isCheck = additionalViewAccessValue.filter((item) => item?.label_name === val?.label_name).length > 0;
    if (!isCheck) {
      setAdditionalViewAccessValue((prev) => {
        return [...prev, val];
      });
    }
    setIsLoadAdditionalDataInFirstTime(true);
  };

  useEffect(() => {
    if (!roleActive?.roleId || isEmpty(formik.values.category?.name) || (isLoadAdditionalDataInFirstTime && !isEmpty(meetingSecretariatValue))) {
      return;
    }

    if (
      isEmpty(meetingSecretariatValue) &&
      ![MEETING_CATEGORY_VALUES.SKG, MEETING_CATEGORY_VALUES.JOB_FAMILY].includes(formik.values.category?.name)
    ) {
      setAdditionalViewAccessValue([]);
      formik.setFieldValue('meeting_additional_view_accesses', []);
      return;
    }

    const fetchDataToPopulateForAdditionalView = async (secretariats) => {
      try {
        const params = {
          meeting_category: formik.values.category?.name,
        };
        const body = !isEmpty(secretariats)
          ? secretariats.map((i) => ({
              role_id: i?.role_id,
              business_id: getBusinessIdToPopulateForAdditionalView(formik.values.category?.name, i),
            }))
          : [];
        const res = await meetingApi.getDataToPopulateForAdditionalView(params, body, roleActive?.roleId);
        if (res.status === 200 && res.data.result.length > 0 && isArray(res.data.result)) {
          res.data.result.forEach((item) => {
            const tempVal = {
              ...item,
              label_name: getLabelNameForSecretariatOfTcMeeting(formik.values.category?.name, item),
              search_type: formik.values.category?.name,
            };
            handleSetAdditionalViewAccess(tempVal);
          });
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (
      ![MEETING_CATEGORY_OPTIONS.PETRONAS, MEETING_CATEGORY_VALUES.SKG, MEETING_CATEGORY_VALUES.JOB_FAMILY].includes(formik.values.category?.name)
    ) {
      fetchDataToPopulateForAdditionalView(meetingSecretariatValue);
    }
  }, [meetingSecretariatValue, isLoadAdditionalDataInFirstTime]);

  const isDisabledMeetingSecretariatField = useMemo(() => {
    return [MEETING_CATEGORY_VALUES.PETRONAS].includes(formik.values.category?.name);
  }, [formik.values.category]);

  const handleSelectMeetingCategory = (val) => {
    formik.setFieldValue('category', val);
    formik.setFieldValue('category_value', null);
    setIsLoadAdditionalDataInFirstTime(false);

    const tempVal = {
      role: ROLE.HR_COE,
      role_id: 7,
      label_name: getLabelNameForSecretariatOfTcMeeting(MEETING_CATEGORY_VALUES.PETRONAS, { role: ROLE.HR_COE, role_id: 7 }),
      search_type: MEETING_CATEGORY_VALUES.PETRONAS,
    };
    if (val.name === MEETING_CATEGORY_VALUES.PETRONAS) {
      setMeetingSecretariatValue([tempVal]);
      setAdditionalViewAccessValue([tempVal]);
    } else if ([MEETING_CATEGORY_VALUES.SKG, MEETING_CATEGORY_VALUES.JOB_FAMILY].includes(val.name)) {
      setAdditionalViewAccessValue([tempVal]);
    } else {
      setMeetingSecretariatValue([]);
      setAdditionalViewAccessValue([]);
    }
  };

  const handleDiscardChanges = () => {
    setIsDiscardChanges(true);
    dispatch(switchEditMode(false));
    setTimeout(() => {
      formik.resetForm();
    }, 100);
  };

  const handleClickEditMeeting = () => {
    dispatch(switchEditMode(true));
  };

  const handleClickUpdateMeeting = () => {
    const isErrors = size(formik.errors) > 0;
    if (isErrors) {
      const touchedField = {};
      Object.keys(formik.errors).forEach((key) => {
        touchedField[key] = true;
      });
      formik.setTouched(touchedField);

      dispatch(
        pushMessage({
          type: MESSAGE_TYPES.ERROR,
          message: FILL_OUT_REQUIRE_MESSAGE,
          timeShow: 0,
        })
      );
      return;
    }
    setShowUpdateModal(true);
  };
  const [openResendInvite, setOpenResendInvite] = useState(false);
  const handleUpdateMeeting = async () => {
    try {
      setLoading(true);
      const data = convertMeetingFromStore({ idMeeting, ...formik.values });
      const res = await pdcMeetingApi.updateMeeting(data, roleActive);
      if (res.status === 200) {
        fetchMeeting();
        dispatch(switchEditMode(false));
        setOpenResendInvite(true);
      }
    } catch (error) {
      console.error(error);
      showNotification(AN_UNEXPECTED_ERROR, NOTIFICATION_TYPE.FAILED);
    } finally {
      setLoading(false);
      setShowUpdateModal(false);
    }
  };

  const handleStartMeeting = async () => {
    try {
      setLoading(true);
      const res = await pdcMeetingApi.updateMeetingStatus(idMeeting, MEETING_DETAIL_STATUS.IN_PROGRESS, roleActive?.roleId);
      if (res.status === 200) {
        await fetchMeeting();
        dispatch(
          pushMessage({
            type: MESSAGE_TYPES.SUCCESS,
            message: `${meetingDetail.meeting_name} is started!`,
            timeShow: 0,
          })
        );
      }
    } catch (error) {
      console.error(error);
      showNotification(AN_UNEXPECTED_ERROR, NOTIFICATION_TYPE.FAILED);
    } finally {
      setLoading(false);
      setShowStartMeetingModal(false);
    }
  };

  const handleCancelMeeting = async () => {
    try {
      setLoading(true);
      const res = await pdcMeetingApi.updateMeetingStatus(idMeeting, MEETING_DETAIL_STATUS.CANCELED, roleActive?.roleId);
      if (res.status === 200) {
        await fetchMeeting();
        dispatch(switchEditMode(false));
        dispatch(
          pushMessage({
            type: MESSAGE_TYPES.SUCCESS,
            message: `${meetingDetail.meeting_name} has been successfully cancelled.`,
            timeShow: 0,
          })
        );
      }
    } catch (error) {
      console.error(error);
      showNotification(AN_UNEXPECTED_ERROR, NOTIFICATION_TYPE.FAILED);
    } finally {
      setLoading(false);
      setShowCancelMeetingModal(false);
    }
  };

  const handleCompleteMeeting = async () => {
    try {
      setShowCompleteMeeting(false);
      setLoading(true);
      await pdcMeetingApi.fetchCompletePDCMeeting(idMeeting, roleActive.roleId);
      history.push(`/pdc-meeting-in-review?meeting_name=${meetingDetail.meeting_name}&meeting_id=${idMeeting}`);
    } catch (error) {
      console.error(error);
      dispatch(
        pushMessage({
          type: MESSAGE_TYPES.ERROR,
          message: AN_UNEXPECTED_ERROR,
          timeShow: 0,
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const handleEditCancelMeeting = async () => {
    try {
      setLoading(true);
      let res = await pdcMeetingApi.editMeetingFromCancel(roleActive, idMeeting);
      if (res.status === 200) {
        await fetchMeeting();
        history.push(`/pdc-meeting/${idMeeting}`);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
    setIsDislayEditCancelPopup(false);
  };

  const resendInvitationsPDCMeetingId = async () => {
    try {
      await pdcMeetingApi.resendInvitationsPDCMeetingId(idMeeting, roleActive?.roleId);
      dispatch(
        pushMessage({
          type: MESSAGE_TYPES.SUCCESS,
          message: 'Your changes have been saved successfully and the new invite have been sent.',
          timeShow: 0,
        })
      );
    } catch (error) {
      return console.error(error);
    } finally {
      setOpenResendInvite(false);
    }
  };

  useEffect(() => {
    if (meetingDetail.status === MEETING_DETAIL_STATUS.IN_REVIEW) history.push(`/pdc-meeting/${meetingDetail.meeting_pdc_id}/in-review`);
    if (meetingDetail.status === MEETING_DETAIL_STATUS.CONCLUDED) history.push(`/pdc-meeting/${meetingDetail.meeting_pdc_id}/concluded`);
  }, [meetingDetail.status]);

  return (
    <div className={styles.wrapper}>
      <BreadCrumb level={6} breadCrumbList={breadCrumbList} />
      {(!idMeeting || meetingDetail.status === MEETING_DETAIL_STATUS.IN_PLANNING) && (
        <MeetingProgressBar isOnlyViewMeeting={!idMeeting} meetingDetail={meetingDetail} />
      )}
      <GlobalAlertMessage style={{ margin: '10px 0 30px 0' }} />
      <Spin spinning={loadingGetMeeting}>
        <form onSubmit={formik.handleSubmit}>
          {idMeeting && meetingDetail.status && (
            <div className={styles.titleRow}>
              {meetingDetail.status !== MEETING_DETAIL_STATUS.IN_PLANNING && (
                <div>
                  <div style={{ wordBreak: 'break-word', maxWidth: '800px' }}>{meetingDetail.meeting_name}</div>
                  <PDCMeetingStatus status={meeting.status} />
                </div>
              )}
              {meetingDetail.status === MEETING_DETAIL_STATUS.PUBLISHED && (
                <div className={styles.btnRow}>
                  {!isEditMode ? (
                    <>
                      <BasicButton border="none" type="button" onClick={() => history.push('/pdc')}>
                        Back
                      </BasicButton>
                      {(meetingRole?.is_secretariat_or_facilitator || isAdminRole) && (
                        <>
                          <PrintSelectButton />
                          <BasicButton border="none" type="button" onClick={handleClickEditMeeting}>
                            Edit Meeting
                          </BasicButton>
                          <BasicButton mode="teal" type="button" onClick={() => setShowStartMeetingModal(true)}>
                            Start Meeting
                          </BasicButton>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <BasicButton border="none" type="button" onClick={handleDiscardChanges}>
                        Cancel
                      </BasicButton>
                      <BasicButton mode="teal" type="button" onClick={handleClickUpdateMeeting}>
                        Update Meeting
                      </BasicButton>
                    </>
                  )}
                </div>
              )}
              {meetingDetail.status === MEETING_DETAIL_STATUS.CANCELED && (
                <div className={styles.btnRow}>
                  <BasicButton border="none" type="button" onClick={() => history.push('/pdc')}>
                    Back
                  </BasicButton>
                  {(meetingRole?.is_secretariat_or_facilitator || isAdminRole) && (
                    <BasicButton border="none" type="button" onClick={() => setIsDislayEditCancelPopup(true)}>
                      Edit Meeting
                    </BasicButton>
                  )}
                </div>
              )}
              {meetingDetail.status === MEETING_DETAIL_STATUS.IN_PROGRESS && (
                <div className={styles.btnRow}>
                  <BasicButton border="none" type="button" onClick={() => history.push('/pdc')}>
                    Back
                  </BasicButton>
                  {(meetingRole?.is_secretariat_or_facilitator || isAdminRole) && (
                    <>
                      <PrintSelectButton />{' '}
                      <BasicButton
                        mode="teal"
                        type="button"
                        onClick={() => {
                          if (isEditMode) {
                            dispatch(
                              pushMessage({
                                type: MESSAGE_TYPES.WARNING,
                                message: `Please complete your edit by clicking 'Done Edit Meeting' button before you can proceed to complete the meeting`,
                                timeShow: 0,
                              })
                            );
                            return;
                          }
                          if (!isEmpty(isExistingPaperReady?.data)) {
                            dispatch(
                              pushMessage({
                                type: MESSAGE_TYPES.WARNING,
                                message: `Please ensure all paper is finalized to proceed to the next step`,
                                timeShow: 0,
                              })
                            );
                            setIsExistingPaperReady((prev) => ({
                              ...prev,
                              isHighLight: true,
                            }));
                            return;
                          }
                          setShowCompleteMeeting(true);
                        }}
                      >
                        Complete Meeting
                      </BasicButton>
                    </>
                  )}
                </div>
              )}
            </div>
          )}
          <AiContainerForm>
            {(size(formik.errors) > 0 && isSubmit) ||
              (visibleErrorMsg && (
                <div style={{ marginBottom: '20px' }}>
                  <AlertMessage message="There are items that require your attention. Please fill out this field." type="error" />
                </div>
              ))}

            {isCreateMeetingSuccess && (
              <div style={{ marginBottom: '20px' }}>
                <AlertMessage message="Meeting Details has been successfully saved." type="success" />
              </div>
            )}

            {!isNil(createMeetingError) && !isEmpty(createMeetingError) && (
              <div style={{ marginBottom: '20px' }}>
                <AlertMessage message="Meeting hasn't been created." type="error" />
              </div>
            )}

            {isUpdateMeetingSuccess && (
              <div style={{ marginBottom: '20px' }}>
                <AlertMessage message="Meeting has been updated successfully." type="success" />
              </div>
            )}

            {!isNil(updateMeetingError) && !isEmpty(updateMeetingError) && (
              <div style={{ marginBottom: '20px' }}>
                <AlertMessage message="Meeting hasn't been updated." type="error" />
              </div>
            )}

            <MeetingTitle>
              <AiH3>Meeting Details</AiH3>
              {(!idMeeting || meetingDetail.status === MEETING_DETAIL_STATUS.IN_PLANNING) && <PDCMeetingStatus status={meeting.status} />}
            </MeetingTitle>
            <Row className="mb-3">
              <Col span={24}>
                <AiLabel>Meeting Name</AiLabel>
                <AiInput
                  maxLength={100}
                  placeholder="Enter Meeting Name"
                  name="meeting_name"
                  value={formik.values.meeting_name}
                  onChange={formik.handleChange}
                  status={isCheckError(formik, 'meeting_name') ? 'error' : ''}
                  disabled={isOnlyViewMeeting}
                />
                <FieldError name="meeting_name" {...formik} />
              </Col>
            </Row>
            <div className={styles.rowInput}>
              <div className={styles.inputItem}>
                <AiLabel>Date</AiLabel>
                <Space direction="vertical" style={{ width: '100%' }}>
                  <AiDatePicker
                    onChange={onChange}
                    format={DATE_TIME_FORMAT.DATE_SPACE}
                    status={isCheckError(formik, 'date') ? 'error' : ''}
                    value={formik.values.date && moment(formik.values.date, DATE_TIME_FORMAT.DATE_SPACE)}
                    placeholder="Select Date"
                    getPopupContainer={(trigger) => trigger.parentElement}
                    disabled={isOnlyViewMeeting}
                  />
                  <FieldError name="date" {...formik} />
                </Space>
              </div>
              <div className={styles.inputItem}>
                <AiLabel>Meeting Category</AiLabel>
                <MeetingCategory
                  selected={formik.values.category}
                  setSelected={(event) => {
                    handleSelectMeetingCategory(event);
                  }}
                  options={MEETING_CATEGORY_OPTIONS}
                  placeholder="Select Meeting Category"
                  status={isCheckError(formik, 'category') ? 'error' : ''}
                  disabled={isOnlyViewMeeting}
                />
                <FieldError name="category.id" {...formik} />
              </div>
              {!isEmpty(formik.values.category) &&
                ['Business', 'OPU', 'Sector', 'Division', 'SKG', 'Job Family'].includes(formik.values.category?.name) && (
                  <div className={styles.inputItem}>
                    <AiLabel>{formik.values.category?.name}</AiLabel>
                    <SearchByCategoryForTcMeeting
                      openDropdown={isOpenCategoryDropdown}
                      setOpenDropdown={setIsOpenCategoryDropdown}
                      setValue={({ val }) => formik.setFieldValue('category_value', val)}
                      searchName={formik.values.category?.name}
                      fieldValue={formik.values.category_value}
                      status={isCheckError(formik, 'category_value') ? 'error' : ''}
                      disabled={isOnlyViewMeeting}
                    />
                    <FieldError name="category_value" {...formik} />
                  </div>
                )}
              <div className={styles.inputItem}>
                <AiLabel>Start time</AiLabel>
                <AiTimePickerField
                  placeholder={'Select Time'}
                  value={formik.values.start_time ? moment(formik.values.start_time, DATE_TIME_FORMAT.TIME).local() : null}
                  style={{ width: '100%' }}
                  onChange={(_event, value) => onChangeInputData(value, 'start_time')}
                  status={isCheckError(formik, 'start_time') ? 'error' : ''}
                  showTime={{ format: DATE_TIME_FORMAT.TIME, use12Hours: true }}
                  format={DATE_TIME_FORMAT.TIME}
                  showNow={false}
                  inputReadOnly={true}
                  allowClear={false}
                  getPopupContainer={(trigger) => trigger.parentElement}
                  disabled={isOnlyViewMeeting}
                />
                <FieldError name="start_time" {...formik} />
              </div>
              <div className={styles.inputItem}>
                <AiLabel>End time</AiLabel>
                <AiTimePickerField
                  placeholder={'Select Time'}
                  value={formik.values.end_time ? moment(formik.values.end_time, DATE_TIME_FORMAT.TIME).local() : null}
                  style={{ width: '100%' }}
                  onChange={(_event, value) => onChangeInputData(value, 'end_time')}
                  status={isCheckError(formik, 'end_time') ? 'error' : ''}
                  showTime={{ format: DATE_TIME_FORMAT.TIME, use12Hours: true }}
                  format={DATE_TIME_FORMAT.TIME}
                  showNow={false}
                  inputReadOnly={true}
                  allowClear={false}
                  getPopupContainer={(trigger) => trigger.parentElement}
                  disabled={isOnlyViewMeeting}
                />
                <FieldError name="end_time" {...formik} />
              </div>
            </div>

            <Row className="mb-3">
              <Col span={24}>
                <AiLabel>Location</AiLabel>
                <AiInput
                  maxLength={100}
                  placeholder="Enter Location"
                  name="location"
                  value={formik.values.location}
                  onChange={formik.handleChange}
                  status={isCheckError(formik, 'location') ? 'error' : ''}
                  disabled={isOnlyViewMeeting}
                />
                <FieldError name="location" {...formik} />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col span={24}>
                <AiLabel>Chairman</AiLabel>
                <AsyncMultipleSelect
                  value={formik.values.chairman}
                  placeholder="Enter Chairman Name"
                  loadOptions={(e) => getAsyncOptionAdvocatorsHrPartners(e, roleActive.roleId)}
                  onChange={(e) => onChangeSelectData(e, 'chairman')}
                  status={isCheckError(formik, 'chairman') ? 'error' : ''}
                  isDisabled={isOnlyViewMeeting}
                />
                <FieldError name="chairman" {...formik} />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col span={24}>
                <AiLabel>Committee Members</AiLabel>
                <AsyncMultipleSelect
                  placeholder={'Enter Committee Members Name'}
                  loadOptions={(e) => getAsyncOptionAdvocatorsHrPartners(e, roleActive.roleId)}
                  onChange={(e) => onChangeSelectData(e, 'meeting_committee')}
                  value={formik.values.meeting_committee}
                  status={isCheckError(formik, 'meeting_committee') ? 'error' : ''}
                  isDisabled={isOnlyViewMeeting}
                />
                <FieldError name="meeting_committee" {...formik} />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col span={24}>
                <AiLabel>Meeting Facilitators</AiLabel>
                <AsyncMultipleSelect
                  placeholder={'Enter Meeting Facilitators Name'}
                  onChange={(e) => {
                    setIsAddValueFacilitatorFirstTime(false);
                    onChangeSelectData(e, 'meeting_facilitator');
                  }}
                  loadOptions={(e) => getAsyncOptionAdvocatorsHrPartners(e, roleActive.roleId)}
                  value={formik.values.meeting_facilitator}
                  status={isCheckError(formik, 'meeting_facilitator') ? 'error' : ''}
                  isDisabled={isOnlyViewMeeting}
                />
                <FieldError name="meeting_facilitator" {...formik} />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col span={24}>
                <AiLabel>Meeting Secretariat</AiLabel>
                <SearchBySecretariatForTcMeeting
                  searchName={formik.values.category?.name}
                  setValue={({ val }) => {
                    handleSetMeetingSecretariatValue(val);
                  }}
                  fieldValues={meetingSecretariatValue}
                  setMeetingSecretariatValue={setMeetingSecretariatValue}
                  status={isCheckError(formik, 'meeting_secretariats') ? 'error' : ''}
                  isDisabled={isDisabledMeetingSecretariatField || isOnlyViewMeeting}
                />
                <FieldError name="meeting_secretariats" {...formik} />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col span={24}>
                <AiLabel noRequire={true}>Additional View Access</AiLabel>
                <SearchByAdditionalViewAccessForTcMeeting
                  searchName={formik.values.category?.name}
                  setValue={({ val }) => handleSetAdditionalViewAccess(val)}
                  fieldValues={additionalViewAccessValue}
                  setAdditionalViewAccessValue={setAdditionalViewAccessValue}
                  isDisabled={isOnlyViewMeeting}
                  // status={isCheckError(formik, 'meeting_additional_view_accesses') ? 'error' : ''}
                />
                {/* <FieldError name="meeting_additional_view_accesses" {...formik} /> */}
              </Col>
            </Row>
          </AiContainerForm>
          {(!idMeeting || meetingDetail.status === MEETING_DETAIL_STATUS.IN_PLANNING) && (
            <AiContainerGrBtn>
              <div>
                {!isOnlyViewMeeting && (
                  <AiButton mode="remove" onClick={() => setVisibleRemoveMeeting(true)}>
                    Delete Meeting
                  </AiButton>
                )}
              </div>
              <div>
                <AiButton className="ml-2" onClick={handleExit}>
                  Exit
                </AiButton>
                {!isOnlyViewMeeting && (
                  <AiButton htmlType="submit" onClick={handleCheckValidateForm}>
                    Save as Draft
                  </AiButton>
                )}
                <AiButton mode="teal" className="ml-2" onClick={handleNext}>
                  Next
                </AiButton>
              </div>
            </AiContainerGrBtn>
          )}
        </form>
      </Spin>
      {idMeeting && meetingDetail.status && meetingDetail.status !== MEETING_DETAIL_STATUS.IN_PLANNING && (
        <>
          {[MEETING_DETAIL_STATUS.IN_PROGRESS].includes(meetingDetail.status) && (
            <div className={styles.btnRow} style={{ marginBottom: 30 }}>
              {(meetingRole?.is_secretariat_or_facilitator || isAdminRole) && (
                <>
                  {!isEditMode ? (
                    <BasicButton border="none" type="button" onClick={handleClickEditMeeting}>
                      Edit Meeting
                    </BasicButton>
                  ) : (
                    <BasicButton border="none" type="button" onClick={() => dispatch(switchEditMode(false))}>
                      Done Edit Meeting
                    </BasicButton>
                  )}
                </>
              )}
            </div>
          )}
          <div style={{ marginBottom: 30 }}>
            <PDCMeetingMobilities
              isComponent
              meetingStatus={meetingDetail.status}
              isEditMode={isEditMode}
              isExistingPaperReady={isExistingPaperReady}
              setIsExistingPaperReady={setIsExistingPaperReady}
            />
          </div>
          <div style={{ marginBottom: 30 }}>
            <ContainerSummary />
          </div>
          {idMeeting &&
            meetingDetail?.status === MEETING_DETAIL_STATUS.IN_PROGRESS &&
            isEditMode &&
            (meetingRole?.is_secretariat_or_facilitator || isAdminRole) && (
              <div style={{ marginBottom: 30 }}>
                <PDCRemarks idMeeting={idMeeting} />
              </div>
            )}
          <div className={styles.btnRow} style={{ marginBottom: 30 }}>
            {meetingDetail.status !== MEETING_DETAIL_STATUS.CANCELED && (meetingRole?.is_secretariat_or_facilitator || isAdminRole) && (
              <BasicButton mode="remove" onClick={() => setShowCancelMeetingModal(true)}>
                Cancel Meeting
              </BasicButton>
            )}
            {meetingDetail.status === MEETING_DETAIL_STATUS.CANCELED && (meetingRole?.is_secretariat_or_facilitator || isAdminRole) && (
              <BasicButton mode="remove" style={{ marginBottom: 30 }} onClick={() => setVisibleRemoveMeeting(true)}>
                Delete Meeting
              </BasicButton>
            )}
          </div>
        </>
      )}
      {/*
       * IMPLEMENT MODEL
       */}
      <ModelTC
        info={{
          type: 'deletePDCMeeting',
          visible: visibleRemoveMeeting,
          setVisible: setVisibleRemoveMeeting,
          handleSubmit: async () => {
            setLoadingDeleteMeeting(true);
            if (!idMeeting) {
              exitCreateOrEditMeeting();
            } else {
              try {
                let res = await pdcMeetingApi.deleteMeeting({ idMeeting });
                if (res.status === 200) {
                  exitCreateOrEditMeeting();
                  setVisibleRemoveMeeting(false);
                  setLoadingDeleteMeeting(false);
                }
              } catch (error) {
                showNotification(`Delete Meeting failed`, NOTIFICATION_TYPE.FAILED);
                setVisibleRemoveMeeting(false);
                setLoadingDeleteMeeting(false);
              }
            }
          },
          onClose: () => {},
          loading: loadingDeleteMeeting,
        }}
      />
      <ModelTC
        info={{
          type: 'withoutSaving',
          visible: isShowPopupSaveAgenda,
          setVisible: setIsShowPopupSaveAgenda,
          onClose: handleConfirmExit,
          handleSubmit: handleSaveBeforeExit,
        }}
      />

      <ModelTC
        info={{
          type: 'pdcUpdateMeeting',
          visible: showUpdateModal,
          setVisible: setShowUpdateModal,
          handleSubmit: handleUpdateMeeting,
          loading: loading,
        }}
      />
      <ModelTC
        info={{
          type: 'pdcStartMeeting',
          visible: showStartMeetingModal,
          setVisible: setShowStartMeetingModal,
          handleSubmit: handleStartMeeting,
          loading: loading,
        }}
      />
      <ModelTC
        info={{
          type: 'pdcCancelMeeting',
          visible: showCancelMeetingModal,
          setVisible: setShowCancelMeetingModal,
          handleSubmit: handleCancelMeeting,
          loading: loading,
        }}
      />
      {showCompleteMeeting && (
        <ModelTC
          info={{
            type: 'completeMeeting',
            visible: showCompleteMeeting,
            setVisible: setShowCompleteMeeting,
            handleSubmit: handleCompleteMeeting,
            loading: loading,
          }}
        />
      )}
      <ModelTC
        info={{
          type: 'editFromCancel',
          visible: isDislayEditCancelPopup,
          setVisible: setIsDislayEditCancelPopup,
          onClose: () => setIsDislayEditCancelPopup(false),
          handleSubmit: handleEditCancelMeeting,
          loading: loading,
        }}
      />
      {openResendInvite && (
        <ModelTC
          info={{
            type: 'resendNewInvite',
            visible: openResendInvite,
            setVisible: () => {
              setOpenResendInvite(false);
              dispatch(
                pushMessage({
                  type: MESSAGE_TYPES.SUCCESS,
                  message: 'Your changes has been saved successfully.',
                  timeShow: 0,
                })
              );
            },
            onClose: () => {
              setOpenResendInvite(false);
              dispatch(
                pushMessage({
                  type: MESSAGE_TYPES.SUCCESS,
                  message: 'Your changes has been saved successfully.',
                  timeShow: 0,
                })
              );
            },
            handleSubmit: async () => {
              await resendInvitationsPDCMeetingId();
            },
            loading: loading,
          }}
        />
      )}
    </div>
  );
};
export default PDCMeetingDetails;
