import { isEmpty } from 'lodash';
import queryString from 'query-string';

import { PERMISSION } from '../utils/constants';
import { generateFilterUrl, getPermissionByRole } from '../utils/helper';
import axiosClient from './axiosClient';

export const adminApi = {
  createRole: ({ id, role, body }) => {
    const url = `/admin/add-role`;
    return axiosClient.post(url, body, {
      headers: {
        role: role.roleId,
      },
    });
  },

  deleteRoles: ({ id, role, body }) => {
    const url = `/admin/roles`;
    return axiosClient.delete(url, {
      headers: {
        role: role.roleId,
      },
      data: body,
    });
  },

  getRoles: ({ id, role, limit, page }) => {
    const permission = getPermissionByRole({ role, part: 'ROLE' });
    const url = '/roles';
    return axiosClient.get(url, {
      params: {
        limit,
        page,
      },
      headers: {
        role: role.roleId,
        permission,
      },
    });
  },
  getRoleIncumbent: ({ role, roleId }) => {
    const permission = getPermissionByRole({ role, part: 'ROLE' });
    const url = `/admin/get-role-details-edit/${roleId}`;
    return axiosClient.get(url, {
      headers: {
        role: role.roleId,
        permission,
      },
    });
  },
  getNameRoleIncumbent: ({ role, roleId }) => {
    const url = `/admin/role-name/${roleId}`;
    return axiosClient.get(url, {
      headers: {
        role: role.roleId,
      },
    });
  },
  getModulePermission: ({ id, role }) => {
    const url = '/admin/get-module-permission';
    return axiosClient.get(url, {
      headers: {
        role: role.roleId,
        permission: PERMISSION.AD_ROLE_MANAGEMENT,
      },
    });
  },
  getRoleLevels: ({ id, role }) => {
    const url = '/admin/get-role-access-level';
    return axiosClient.get(url, {
      headers: {
        role: role.roleId,
      },
    });
  },
  createRoleSetting: ({ id, role, roleId, body }) => {
    const url = `/admin/add-role-permission/${roleId}`;
    return axiosClient.post(url, body, {
      headers: {
        role: role.roleId,
      },
    });
  },
  updateRoleSetting: ({ id, role, roleId, body }) => {
    const url = `/admin/put-role-permission/${roleId}`;
    return axiosClient.put(url, body, {
      headers: {
        role: role.roleId,
      },
    });
  },
  updateRoleName: ({ id, role, roleId, body }) => {
    const url = `/admin/put-role-name/${roleId}`;
    return axiosClient.put(url, body, {
      headers: {
        role: role.roleId,
      },
    });
  },
  getAdminUsers: (roles, limit, page, filters) => {
    const { role } = roles;
    const queryFilters = [];
    Object.keys(filters).forEach((key) => {
      if (!filters[key]) return;

      queryFilters.push(`${key}=${encodeURIComponent(filters[key])}`);
    });
    let url = `/admin/get-user-details?limit=${limit}&page=${page}`;
    url = queryFilters.length > 0 ? `${url}&${queryFilters.join('&')}` : url;
    return axiosClient.get(url, {
      headers: {
        role: role.roleId,
        permission: PERMISSION.AD_USER_ACCESS_MANAGEMENT,
      },
    });
  },

  getUserByEmail: (roles, query) => {
    const { role } = roles;
    const url = `/employee-by-email?email=${query}`;
    return axiosClient.get(url, {
      headers: {
        role: role.roleId,
        permission: PERMISSION.AD_USER_ACCESS_MANAGEMENT,
      },
    });
  },

  getAllRoleForCreateUser: (roles) => {
    const { role } = roles;
    const url = `/all-roles`;
    return axiosClient.get(url, {
      headers: {
        role: role.roleId,
        permission: PERMISSION.AD_USER_ACCESS_MANAGEMENT,
      },
    });
  },

  getAllOpuAndDivsion: (roles, sourcePage = '') => {
    const { role, permission } = roles;
    const url = `/all-opu-divisions?source_page=${sourcePage}`;
    return axiosClient.get(url, {
      headers: {
        role: role.roleId,
        permission,
      },
    });
  },

  getJobFamilies: (roles) => {
    const { role, permission } = roles;
    const url = `/job-families`;
    return axiosClient.get(url, {
      headers: {
        role: role.roleId,
        permission,
      },
    });
  },

  getBusinessUnit: (roles) => {
    const { role, permission } = roles;
    const url = `/business-units`;
    return axiosClient.get(url, {
      headers: {
        role: role.roleId,
        permission,
      },
    });
  },

  getOpuDivisionDropdown: (roles) => {
    const { role } = roles;
    const url = `/meeting-bu-opu-div/dropdown?category=opu_division`;
    return axiosClient.get(url, {
      headers: {
        role: role.roleId,
      },
    });
  },

  saveUser: (roles, body) => {
    const { role } = roles;
    const url = `/user-access-management`;
    return axiosClient.post(url, body, {
      headers: {
        role: role.roleId,
        permission: PERMISSION.AD_USER_ACCESS_MANAGEMENT,
      },
    });
  },

  getUserEdit: (roles, userId) => {
    const { role, permission } = roles;
    const url = `/user-access-management/${userId}`;
    return axiosClient.get(url, {
      headers: {
        role: role.roleId,
        permission,
      },
    });
  },

  saveEditUser: (roles, body, userId) => {
    const { role, permission } = roles;
    const url = `/user-access-management/${userId}`;
    return axiosClient.put(url, body, {
      headers: {
        role: role.roleId,
        permission,
      },
    });
  },

  deleteUser: (roles, ids) => {
    const { role, permission } = roles;
    const url = `/admin/users`;
    return axiosClient.delete(
      url,
      { data: { user_ids: ids } },
      {
        headers: {
          role: role.roleId,
          permission,
        },
      }
    );
  },

  getFileUrlToExport: (ids, roles, filters) => {
    const { role } = roles;
    let url = `/user-access-management/users/download-list?user_ids=${
      !isEmpty(ids) ? `${String(ids)}` : ''
    }`;
    const queryFilters = [];
    Object.keys(filters).forEach((key) => {
      if (!filters[key]) return;

      queryFilters.push(`${key}=${encodeURIComponent(filters[key])}`);
    });
    url = queryFilters.length > 0 ? `${url}&${queryFilters.join('&')}` : url;
    return axiosClient.get(url, {
      headers: {
        role: role.roleId,
        permission: PERMISSION.AD_USER_ACCESS_MANAGEMENT,
      },
    });
  },

  getPermissionsByUserRole: (role) => {
    const url = `/permissions/${role}`;
    return axiosClient.get(url);
  },

  getKeyPositions: ({ userRole, permission, userInfos, ...query }) => {
    const url = '/key-positions';
    return axiosClient.get(url, {
      params: query,
      headers: {
        role: userRole.roleId,
        permission,
        userInfos: JSON.stringify(userInfos),
      },
    });
  },

  getSuccessorPositions: ({ userRole, permission, ...query }) => {
    const url = '/successor-positions';
    return axiosClient.get(url, {
      params: query,
      headers: {
        role: userRole.roleId,
        permission,
      },
    });
  },

  getConductedPositions: ({ userRole, permission, ...query }) => {
    const url = '/conducted-positions';
    return axiosClient.get(url, {
      params: query,
      headers: {
        role: userRole.roleId,
        permission,
      },
    });
  },

  getNotReviewedPositions: ({ userRole, permission, ...query }) => {
    const url = '/not-reviewed-positions';
    return axiosClient.get(url, {
      params: query,
      headers: {
        role: userRole.roleId,
        permission,
      },
    });
  },

  getAttritionPositions: ({ userRole, permission, ...query }) => {
    const url = '/attrition-positions';
    return axiosClient.get(url, {
      params: query,
      headers: {
        role: userRole.roleId,
        permission,
      },
    });
  },

  getAllPositions: ({ userRole, permission, ...query }) => {
    const url = '/get-all-position';
    return axiosClient.get(url, {
      params: query,
      headers: {
        role: userRole.roleId,
        permission,
      },
    });
  },

  getTalentReviewsListDetails: ({ userRole, userId, permission, ...query }) => {
    const url = '/talent-review/list-details-top-talents';
    return axiosClient.get(url, {
      params: query,
      headers: {
        role: userRole.roleId,
        permission,
      },
    });
  },

  getRoleLevel: (roles) => {
    const { role, permission } = roles;
    const url = '/get-role-level';
    return axiosClient.get(url, {
      headers: {
        role: role.roleId,
        permission,
      },
    });
  },

  getSalaryGrade: (roles) => {
    const { role, permission } = roles;
    const url = '/get-salary-grade';
    return axiosClient.get(url, {
      headers: {
        role: role.roleId,
        permission,
      },
    });
  },

  getOptionsCriticalPositions: () => {
    const url = '/critical-positions/filter-options';
    return axiosClient.get(url);
  },

  searchCriticalPositions: ({
    keyword = '',
    position_labels = [],
    businesses = [],
    _limit = 15,
    _page = 1,
    roleActive,
  }) => {
    const params = new URLSearchParams({
      keyword,
      position_labels: position_labels.join(','),
      businesses: businesses.join(','),
      _limit,
      _page,
    });
    const url = `/critical-positions/_search?${params}`;
    return axiosClient.get(url, {
      headers: {
        permission: PERMISSION.MANAGING_CRITICAL_POSITION,
        role: roleActive.roleId,
      },
    });
  },

  updateCriticalPosition: (
    {
      positionCode,
      position_label,
      business,
      included_in_ratio,
      role,
      manual,
      status,
      new_position_code,
      new_position_name,
      sg,
      jg,
    },
    roles
  ) => {
    const { role: role_ } = roles;
    const url = `/critical-positions/${positionCode}/update`;
    return axiosClient.put(
      url,
      {
        position_label,
        business,
        included_in_ratio,
        role,
        manual,
        status,
        new_position_code,
        new_position_name,
        sg,
        jg,
      },
      {
        headers: {
          role: role_.roleId,
        },
      }
    );
  },

  getApprovedSP: (params, roleId) => {
    const path = window.location.href.includes('edit-approved-succession-planning');
    const { keyword, business_unit, position_label, active_approved_successor } = params;
    const url = `/admin/approved-succession-planning?keyword=${keyword}&${business_unit
      ?.map((item) => `business_unit[]=${item.replace('&', '%26')}`)
      ?.join('&')}&${position_label
      ?.map((item) => `position_label[]=${item.replace('&', '%26')}`)
      ?.join('&')}&${active_approved_successor
      ?.map((item) => `active_approved_successor[]=${item}`)
      ?.join('&')}
    `;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
        permission: path
          ? PERMISSION.EDIT_APPROVED_SUCCESSION_PLANNING
          : PERMISSION.COPY_SUCCESSION_PLANNING_TO_OTHER_POSITION,
        action: path ? 'Edit Approved SP' : 'Copy SP To Other Position',
      },
    });
  },
  getPositionNameByPositionCode: ({ positionCode, role }) => {
    const params = new URLSearchParams({ positionCode });
    const url = `/critical-positions/name?${params}`;
    return axiosClient.get(url, {
      headers: {
        permission: PERMISSION.MANAGING_CRITICAL_POSITION,
        role: role.roleId,
      },
    });
  },
  addNewCriticalPosition: (
    {
      position_code,
      position_name,
      position_label,
      included_in_ratio,
      business,
      role,
      manual,
      status,
      sg,
      jg,
    },
    roles
  ) => {
    const { role: role_ } = roles;
    const url = '/critical-positions';
    return axiosClient.post(
      url,
      {
        position_code,
        position_name,
        position_label,
        included_in_ratio,
        business,
        role,
        manual,
        status,
        sg,
        jg,
      },
      {
        headers: {
          role: role_.roleId,
        },
      }
    );
  },

  // Add new approved SP
  getAllFilterAddNewSP: (roleId) => {
    const url = `/admin/get-fillter/add-sp`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
    });
  },
  getSPDetailByPositionId: (positionCode, roleId) => {
    const url = `/admin/get-details/position?position_code=${positionCode}`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
        permission: PERMISSION.EDIT_APPROVED_SUCCESSION_PLANNING,
      },
    });
  },
  getSuccessorDetailByStaffId: (staff_id, roleId) => {
    let url = `/admin/get-details/successor?staff_id=${staff_id}`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
    });
  },

  addNewApprovedSuccessionPlanning: (body, roleId) => {
    const url = `/admin/add-succession-planning`;
    return axiosClient.post(url, body, {
      headers: {
        role: roleId,
      },
    });
  },

  getDetailApprovedSpByPositionCode: (positionCode, approvedDate, roleId) => {
    let url = `/admin/get-details-update/position?position_code=${positionCode}&approved_date=${approvedDate}`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
    });
  },

  updateDetailApprovedSp: (body, roleId) => {
    let url = `/admin/update-details/position?position_code=${body?.position_code}`;
    return axiosClient.put(url, body, {
      headers: {
        role: roleId,
      },
    });
  },

  deleteApprovedSP: (position_code, approved_date, roleActive) => {
    const url = `/admin/approved-succession-planning/${position_code}/${approved_date}`;
    return axiosClient.delete(url, {
      headers: {
        role: roleActive.roleId,
      },
    });
  },

  getApprovedSPFilter: () => {
    const url = `/admin/approved-sp-filters`;
    return axiosClient.get(url);
  },
  getCopyApprovedSP: (params, role) => {
    const keyword = params;
    const url = `/admin/position-suggestions?keyword=${keyword}`;
    return axiosClient.get(url, {
      headers: {
        permission: PERMISSION.COPY_SUCCESSION_PLANNING_TO_OTHER_POSITION,
        role: role.roleId,
      },
    });
  },
  copySPToPosition: (params, roleActive) => {
    const url = '/admin/copy-sp-to-position';
    return axiosClient.post(url, params, {
      headers: {
        role: roleActive.roleId,
      },
    });
  },

  deleteCriticalPosition: (ids, roleActive) => {
    const url = `/critical-positions`;
    return axiosClient.delete(url, {
      headers: {
        role: roleActive.roleId,
      },
      data: { positionCodes: ids },
    });
  },

  getManagingCCP: (params, role) => {
    const { page, limit, keyword, business, position_label } = params;
    const request = `page=${page}&limit=${limit}&keyword=${keyword}&${business
      ?.map((item) => `business[]=${item.replace('&', '%26')}`)
      ?.join('&')}&${position_label
      ?.map((item) => `position_label[]=${item.replace('&', '%26')}`)
      ?.join('&')}
    `;
    const url = `/admin/critical-position?${request}`;
    return axiosClient.get(url, {
      headers: {
        permission: PERMISSION.MANAGING_CRITERIA_FOR_CRITICAL_POSITION,
        role: role.roleId,
      },
    });
  },
  getManualRunCriticalPosition: (params, role) => {
    const url = `/admin/critical-position-manual-run-status`;
    return axiosClient.get(url, {
      headers: {
        permission: PERMISSION.MANAGING_CRITERIA_FOR_CRITICAL_POSITION,
        role: role.roleId,
      },
      params,
    });
  },
  deleteManagingCCP: (params) => {
    const request = `position_code[]=${params?.join(',')}`;
    const url = `/admin/critical-position?${request}`;
    return axiosClient.delete(url);
  },
  getSelectedCriteria: (listSelectedCriteria) => {
    const url = `/critical-positions/matching-criteria-options`;
    return axiosClient.get(url, { params: { criteriaList: listSelectedCriteria.join(',') } });
  },

  getSelectedCriteriaWithPositionCode: (positionCode) => {
    const url = `/critical-positions/set-matching-criteria-options?position_code=${positionCode}`;
    return axiosClient.get(url);
  },

  getFunctionCriteriaContent: (functionCriteria) => {
    const url = `/critical-positions/criterias/content`;
    return axiosClient.get(url, { params: { functionCriteria } });
  },

  saveMatchingCriteria: (body) => {
    const url = `/critical-positions/criterias`;
    return axiosClient.post(url, body);
  },

  getEditMatchingCriteria: (positionCode) => {
    const url = `/critical-positions/criteria/${positionCode}/edit-form-options`;
    return axiosClient.get(url);
  },

  getEditRankingCriteria: (positionCode) => {
    const url = `/critical-positions/criterias/ranking?position_code=${positionCode}`;
    return axiosClient.get(url);
  },

  editMatchingCriteria: (body) => {
    const url = `/critical-positions/criterias/${body.position_code}`;
    return axiosClient.put(url, body);
  },
  deleteMatchingSetCriteria: (position_code, set_id) => {
    const url = `/critical-positions/delete-managing-criteria/${set_id}/${position_code}
  `;
    return axiosClient.delete(url);
  },
  runMatchingCriteria: (body) => {
    const url = `/critical-positions/run-criteria`;
    return axiosClient.post(url, body);
  },

  getPositionCriteria: (positionCode, role) => {
    const url = `/critical-positions/search`;
    return axiosClient.get(url, {
      headers: {
        permission: PERMISSION.MANAGING_CRITERIA_FOR_CRITICAL_POSITION,
        role: role.roleId,
      },
      params: { positionCode },
    });
  },

  getSGJGMappingFilter: (roleId, params) => {
    const { sg, job_grade } = params;
    const url = `/admin/sg-jg-mapping/filters`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
      params: {
        sg: sg || '',
        job_grade: job_grade || '',
      },
    });
  },

  getSGJGMappingSuggestions: (keyword, roleId) => {
    const url = `/admin/sg-jg-mapping/suggestions?keyword=${keyword}`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
    });
  },

  getSGJGMapping: (SGJGParams, roleId) => {
    const { page, limit, keyword, role_level, jg_in_p_series } = SGJGParams;
    const request = `page=${page}&limit=${limit}&keyword=${keyword}&${role_level
      ?.map((item) => `role_level[]=${item.replace('&', '%26')}`)
      ?.join('&')}&${jg_in_p_series
      ?.map((item) => `jg_in_p_series[]=${item.replace('&', '%26')}`)
      ?.join('&')}
    `;
    const url = `/admin/sg-jg-mapping?${request}`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
    });
  },

  deleteSGJGMapping: (ids, roleId) => {
    const request = `${ids?.map((id) => `id[]=${id}`)?.join('&')}`;
    const url = `/admin/sg-jg-mapping/delete?${request}`;
    return axiosClient.delete(url, {
      headers: {
        role: roleId,
      },
    });
  },

  createSGJGMapping: (body, roleId) => {
    const url = `/admin/sg-jg-mapping/create`;
    return axiosClient.post(url, body, {
      headers: {
        role: roleId,
      },
    });
  },

  updateSGJGMappingById: (body, roleId) => {
    const url = `/admin/sg-jg-mapping/${body.id}/update`;
    return axiosClient.put(url, body, {
      headers: {
        role: roleId,
      },
    });
  },

  deleteFromListProfiles: (ids) => {
    const url = `/admin/profile-management/profiles`;
    return axiosClient.delete(url, {
      data: { staff_ids: ids },
    });
  },

  searchTalentsListProfiles: (keyword) => {
    const url = `/admin/profile-management/profiles/search?keyword=${keyword}`;
    return axiosClient.get(url);
  },

  addToListProfiles: (staff_id) => {
    const url = `/admin/profile-management/profiles`;
    return axiosClient.post(url, { staff_id });
  },

  exportFromListOfProfiles: (params) => {
    const { keyword, role_level, business } = params;
    const request = `keyword=${keyword}&${role_level
      ?.map((item) => `role_level[]=${item.replace('&', '%26')}`)
      ?.join('&')}&${business?.map((item) => `business[]=${item.replace('&', '%26')}`)?.join('&')}
    `;
    const url = `/admin/profile-management/profiles/export?${request}`;
    return axiosClient.get(url);
  },

  addNewRoleLevel: (roles, body) => {
    const { role, userId } = roles;
    const url = `/admin/add-role-level`;
    return axiosClient.post(url, body, {
      headers: {
        role: role.roleId,
        id: userId,
      },
    });
  },

  getRoleLevelSearch: (query) => {
    const { keyword, limit, page } = query;
    const url = `/admin/get-list-role-level?role_level=${keyword}&limit=${limit}&page=${page}`;
    return axiosClient.get(url);
  },

  updateRoleLevel: (body, id, role) => {
    const url = `/admin/update-role-level/${id}`;
    return axiosClient.put(url, body, {
      headers: {
        role: role.roleId,
      },
    });
  },

  deleteRoleLevel: (ids, roleId) => {
    const request = `${ids
      ?.map((id) => {
        return `id[]=${id}`;
      })
      ?.join('&')}`;
    const url = `/admin/delete-role-level?${request}`;
    return axiosClient.delete(url, {
      headers: {
        role: roleId,
      },
    });
  },

  getRoleOptions: (roleId) => {
    const url = `/user-access-management/filter-options/roles`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
        permission: PERMISSION.AD_USER_ACCESS_MANAGEMENT,
      },
    });
  },

  getAccessLevelOptions: (roleId, query) => {
    const { roleIdSelected } = query;
    const url = `/user-access-management/filter-options/access-levels`;

    return axiosClient.get(url, {
      headers: {
        role: roleId,
        permission: PERMISSION.AD_USER_ACCESS_MANAGEMENT,
      },
      params: {
        roleId: roleIdSelected,
      },
    });
  },

  getBuAccessOptions: (roleId, query) => {
    const { roleIdSelected, accessLevelSelected } = query;
    const url = `/user-access-management/filter-options/business-access`;

    return axiosClient.get(url, {
      headers: {
        role: roleId,
        permission: PERMISSION.AD_USER_ACCESS_MANAGEMENT,
      },
      params: {
        roleId: roleIdSelected,
        accessLevel: accessLevelSelected,
      },
    });
  },

  getUnsearchablePosition: (query, roleId) => {
    const url = `/admin/get-list/unsearchable-position`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
      params: query,
    });
  },

  getUnsearchablePositionFilter: (query, roleId) => {
    const url = `/admin/unsearchable-position/filter`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
      params: query,
    });
  },

  addUnsearchablePosition: (body, roleId) => {
    const url = `/admin/add-unsearchable-position`;
    return axiosClient.post(url, body, {
      headers: {
        role: roleId,
      },
    });
  },

  deleteUnsearchablePosition: (id, role) => {
    const url = `/admin/delete/unsearchable-position/${id}`;
    return axiosClient.delete(url, {
      headers: {
        role: role,
      },
    });
  },

  searchTopTalentStatus: (searchParams, roleActive) => {
    const request = generateFilterUrl(searchParams);
    const url = `admin/get-top-talent-status${request}`;
    return axiosClient.get(url, {
      headers: {
        role: roleActive.roleId,
      },
    });
  },

  deleteTopTalentStatus: (ids, staffIds, userInfos, roleActive) => {
    const request = generateFilterUrl({ id: ids, staff_id: staffIds });
    const url = `/admin/delete-top-talent-status${request}`;
    return axiosClient.delete(url, {
      headers: {
        userInfos: JSON.stringify(userInfos),
        role: roleActive.roleId,
      },
    });
  },

  getUnsearchableTalentList: ({ keyword, page, limit }) => {
    const url = `/admin/profile-management/unsearchable-talents?keyword=${keyword}&page=${page}&limit=${limit}`;
    return axiosClient.get(url);
  },

  searchTalentUnsearchable: (keyword) => {
    const url = `/admin/profile-management/unsearchable-talents/search?keyword=${keyword}`;
    return axiosClient.get(url);
  },

  addUnsearchableTalentList: (staff_id, role) => {
    const url = `/admin/profile-management/unsearchable-talents`;
    return axiosClient.post(url, { staff_id }, { headers: { role } });
  },

  deleteUnsearchableTalent: (staff_id, role) => {
    const url = `/admin/profile-management/unsearchable-talents/${staff_id}`;
    return axiosClient.delete(url, { headers: { role } });
  },

  searchReadinessRules: (keyword, page, limit, roleId) => {
    const url = `/sp-readiness-rules?keyword=${keyword}&page=${page}&limit=${limit}`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
    });
  },

  deleteReadinessRules: (roles, roleId) => {
    const url = `/sp-readiness-rules/`;
    return axiosClient.delete(url, {
      data: {
        group_ids: roles,
      },
      headers: {
        role: roleId,
      },
    });
  },

  addReadinessRules: (body, roleActive) => {
    const url = `/sp-readiness-rule`;
    return axiosClient.post(url, body, {
      headers: {
        role: roleActive.roleId,
      },
    });
  },

  editReadinessRules: (body, roleActive) => {
    const url = `/sp-readiness-rule`;
    return axiosClient.put(url, body, {
      headers: {
        role: roleActive.roleId,
      },
    });
  },

  getDetailReadiness: (group_id, roleActive) => {
    const url = `/sp-readiness-rule/role-rules?group_id=${group_id}`;
    return axiosClient.get(url, {
      headers: {
        role: roleActive.roleId,
      },
    });
  },

  getOptionsTopTalentStatus: (roleActive) => {
    const url = `/top-talent-status/select-options`;
    return axiosClient.get(url, {
      headers: {
        role: roleActive.roleId,
      },
    });
  },

  getTopTalentStatusDetails: (staff_id) => {
    const url = `/admin/top-talent-status/${staff_id}`;
    return axiosClient.get(url);
  },

  addTopTalentStatus: (body, roleActive) => {
    const url = `/top-talent-status`;
    return axiosClient.post(url, body, {
      headers: {
        role: roleActive.roleId,
      },
    });
  },

  updateTopTalentStatus: (staffId, assessmentId, body, userInfos, roleActive) => {
    const url = `/admin/update-top-talent-status/${staffId}/${assessmentId}`;
    return axiosClient.put(url, body, {
      headers: {
        userInfos: JSON.stringify(userInfos),
        role: roleActive.roleId,
      },
    });
  },

  searchStaffIdTopTalentStatus: (query, roleActive) => {
    const stringified = queryString.stringify(query);
    const url = `/prod-employment-info/search?${stringified}`;
    return axiosClient.get(url, {
      headers: {
        role: roleActive.roleId,
      },
    });
  },

  getStaffNameTopTalentStatus: (staff_id, roleActive) => {
    const url = `/prod-employment-info/get-info/${staff_id}`;
    return axiosClient.get(url, {
      headers: {
        role: roleActive.roleId,
      },
    });
  },

  getExternalTalentFilter: () => {
    const url = `/admin/profile-management/external-talents/filters-options`;
    return axiosClient.get(url);
  },

  searchExternalTalents: (searchParams, roleId) => {
    const { keyword, page, limit, role, company } = searchParams;
    const url = `/admin/profile-management/external-talents?keyword=${keyword}&limit=${limit}&page=${page}&role=${role.join(
      ','
    )}&company=${company.join(',')}`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
    });
  },

  deleteExternalTalent: (talentIds, roleId) => {
    const url = `/admin/profile-management/external-talents`;
    return axiosClient.delete(url, {
      data: {
        talentIds,
      },
      headers: {
        role: roleId,
      },
    });
  },

  searchTopTalentCategory: (searchParams, roleId) => {
    const { keyword, page, limit } = searchParams;
    const url = `/admin/top-talent-category/list?type=${keyword}&limit=${limit}&page=${page}`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
    });
  },

  editTopTalentCategory: (category, roleId) => {
    const url = `/admin/top-talent-category/update/${category.id}`;
    return axiosClient.put(url, category, {
      headers: {
        role: roleId,
      },
    });
  },

  deleteTopTalentCategory: (ids, roleId) => {
    const request = `${ids
      ?.map((id) => {
        return `id[]=${id}`;
      })
      ?.join('&')}`;
    const url = `/admin/top-talent-category/delete?${request}`;
    return axiosClient.delete(url, {
      data: {
        ids,
      },
      headers: {
        role: roleId,
      },
    });
  },

  getHelpDocument: () => {
    const url = `/support/help-document`;
    return axiosClient.get(url);
  },

  getListByGrade: (query) => {
    const stringified = queryString.stringify(query);
    const url = `/get-list-by-grade?${stringified}`;
    return axiosClient.get(url);
  },

  getMatchingCriteriaList: (params) => {
    const {
      page,
      limit,
      keyword,
      isExport,
      run_id,
      roleId,
      role_level,
      sp_readiness,
      salary_grade,
      position_code,
      sort_matching_percentage,
    } = params;
    const url = `/critical-positions/get-managing-criteria`;
    return axiosClient.get(url, {
      params: {
        limit,
        page,
        keyword: keyword || '',
        isExport,
        run_id,
        roleId,
        role_level: (!isEmpty(role_level) && role_level.map((item) => `${item}`).join(',')) || '',
        sp_readiness:
          (!isEmpty(sp_readiness) && sp_readiness.map((item) => `${item}`).join(',')) || '',
        salary_grade:
          (!isEmpty(salary_grade) && salary_grade.map((item) => `${item}`).join(',')) || '',
        position_code,
        sort_matching_percentage,
      },
      headers: {
        role: roleId,
      },
    });
  },

  getFilterMatchingCriteria: (params) => {
    const { roleId } = params;
    const url = `/critical-positions/get-filter-managing-criteria`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
    });
  },

  getDetailMatchingPercentage: (params) => {
    const url = `/matching-criteria/group-list`;
    return axiosClient.get(url, {
      params,
    });
  },

  getPositionCategory: (searchParams, roleId) => {
    const { keyword, page, limit } = searchParams;
    const url = `/admin/position-category/list?category=${keyword}&limit=${limit}&page=${page}`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
    });
  },

  getDetailPositionCategory: (id, roleId) => {
    const url = `admin/position-category-detail/${id}`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
    });
  },

  addPositionCategory: (body, roleId) => {
    const url = `/admin/position-category/create`;
    return axiosClient.post(url, body, {
      headers: {
        role: roleId,
      },
    });
  },

  checkIsDashboardFull: (roleId) => {
    const url = `/admin/position-category/check-isdashboard`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
    });
  },

  editPositionCategory: (id, payload, roleId) => {
    const url = `/admin/position-category/${id}`;
    return axiosClient.put(url, payload, {
      headers: {
        role: roleId,
      },
    });
  },

  deletePositionCategory: (selectedRecords, roleId) => {
    const url = `/admin/position-category?ids=${selectedRecords.join(',')}`;
    return axiosClient.delete(url, {
      headers: {
        role: roleId,
      },
    });
  },

  getSetMatchingDetails: (roleId, run_id) => {
    const url = `critical-positions/manual-run-status/detail/${run_id}`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
    });
  },

  getRoleLevelAndBusiness: (roleId) => {
    const url = `/succession-planning/sp-dashboard-filter `;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
    });
  },

  getFilterAccessLevel: (roleId) => {
    const url = `/user-access-management/filter-access-levels`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
    });
  },

  getOpuDivisionDropdownUserRoles: (roles) => {
    const { role } = roles;
    const url = `/meeting-bu-opu-div/dropdown?is_full_category_dropdown=true&meeting_category=Business,OPU,Division,Sector,SKG,Job Family&is_full=true`;
    return axiosClient.get(url, {
      headers: {
        role: role.roleId,
      },
    });
  },

  addNewsAndAnnouncement: (body, roleId) => {
    const url = `/admin/add-announcement`;
    return axiosClient.post(url, body, {
      headers: {
        role: roleId,
      },
    });
  },

  getUploadUrlAnnouncement: (fileName) => {
    const url = `/admin/url-announcement`;
    return axiosClient.get(url, {
      params: {
        file_name: fileName,
      },
    });
  },
  getViewHomeSpotlight: (roleId) => {
    const url = `/admin/announcement-home/view-spotlight`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
    });
  },
  getViewNewsAnnouncement: (roleId) => {
    const url = `/admin/announcement-home/view-news`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
    });
  },

  getViewNewsAnnouncementList: (roleId) => {
    const url = `/admin/announcement-home/view-news-list`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
    });
  },

  getViewNewsAnnouncementDetail: (annId, roleId) => {
    const url = `/admin/announcement-home/${annId}/detail`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
    });
  },

  getNewsAnnouncement: (queries, roleId) => {
    const stringified = queryString.stringify(queries);
    const url = `/admin/announcement-home/views?${stringified}`;
    return axiosClient.get(url, {
      headers: {
        role: roleId,
      },
      params: {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    });
  },

  deleteNewsAnnouncement: (body, roleId) => {
    const url = `/admin/delete-announcement`;
    return axiosClient.delete(url, {
      headers: {
        role: roleId,
      },
      data: body,
    });
  },
};
