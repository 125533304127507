import { useCallback, useState } from 'react';
import { pdcMeetingApi } from '../services/pdcMeetingApi';

export const useUpdatePdcMeetingStepNumber = () => {
  const [loading, setLoading] = useState(false);

  const updateMeetingStepNumber = useCallback(
    async (idMeeting, stepNumber) => {
      try {
        setLoading(true);
        if (!idMeeting) return;

        await pdcMeetingApi.updateStepMeeting({
          body: {
            on_step: stepNumber,
          },
          idMeeting,
        });
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [setLoading]
  );

  return { updateMeetingStepNumber, loading };
};
