import { Col, Dropdown, List, Row } from 'antd';

import _, { filter, find, includes, isArray, isObject, map } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import ReactSelect, { components } from 'react-select';
import { useDispatch, useSelector } from 'react-redux';

import { AiSpin } from '../../../../../assets/common';
import {
  circle_checked,
  commentAllLines,
  comment_stack,
  editNote,
  sp_trash,
  shape,
} from '../../../../../assets/img';
import { ModelTC } from '../../../../../components';
import { AiH3 } from '../../Styled';
import styles from './view-remarks.module.scss';
import {
  EllipsisButton,
  FilterCol,
  MenuItemStyled,
  MenuWithStyle,
  NoRemarkCol,
  NoRemarkDescription,
  NoRemarkTitle,
  RemarkCategory,
  RemarkHeader,
  RemarkIcon,
  RemarkItemBody,
  RemarkItemCardDescription,
  RemarkItemCardTitle,
  RemarkItemStyled,
  RemarksBody,
  RemarkTalentName,
  ShapeIcon,
  Title,
} from './styled';
import {
  AGENDA_TYPES,
  DELETE_REMARK_SUCCESS_MESSAGE,
  MESSAGE_TYPES,
  REMARK_MODE,
  REMARK_TYPE_OPTIONS_PDC,
} from '../../../../../utils/constants';
import { decodeBase64ToImage } from '../../../../../utils/helper';
import { pdcMeetingApi } from '../../../../../services/pdcMeetingApi';
import { pushMessage } from '../../../../../store/alertMessageSlice';

const FilterSelect = ({
  closeMenuOnSelect,
  placeholder,
  value,
  options,
  onChange,
  isMulti,
  mode = 'remark',
}) => {
  const [longest, setLongest] = useState(0);

  useEffect(() => {
    if (!_.isEmpty(options)) {
      const longest = options.sort(function (a, b) {
        return b.label.length - a.label.length;
      })[0];
      setLongest(longest?.label?.length);
    }
  }, [options]);

  return (
    <ReactSelect
      maxMenuHeight={mode === 'agenda' ? 150 : ''}
      placeholder={placeholder}
      isClearable={false}
      isSearchable={false}
      closeMenuOnSelect={closeMenuOnSelect}
      isMulti={isMulti}
      options={options}
      value={value}
      onChange={onChange}
      hideSelectedOptions={false}
      styles={{
        menu: (provided) =>
          mode === 'agenda'
            ? {
                ...provided,
                width: `${longest * 6 + 200}px`,
                right: 0,
              }
            : { ...provided },
        control: (provided) => ({
          ...provided,
          background: '#FBFDFF',
          border: '1px solid #00A19C',
          boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.04)',
          borderRadius: '4px',
          '&:hover': {
            border: '1px solid #00A19C',
            cursor: 'pointer',
          },
        }),
        singleValue: (provided) => ({
          ...provided,
          fontFamily: "'Inter', sans-serif",
          fontStyle: 'normal',
          fontWeight: '500',
          fontSize: '12px',
          lineHeight: '15px',
          /* identical to box height */
          color: '#00A19C',
        }),
        placeholder: (provided) => ({
          ...provided,
          fontFamily: "'Inter', sans-serif",
          fontStyle: 'normal',
          fontWeight: '500',
          fontSize: '12px',
          lineHeight: '15px',
          /* identical to box height */
          color: '#00A19C',
        }),
        option: (provided, state) => ({
          ...provided,
          fontSize: '14px',
          fontFamily: "'Inter', sans-serif",
          fontWeight: 'bold',
          backgroundColor: 'white',
          overflow: 'ellipsis',
          color: '#344563',
          '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#e5e5e5',
            color: '#344563',
          },
          '&:not(:last-of-type)': {
            borderBottom: '1px solid #d3dee8',
          },
        }),
      }}
      components={
        mode === 'agenda'
          ? {
              ValueContainer: CustomValueContainer,
              Option: Option,
              IndicatorSeparator: () => <></>,
              DropdownIndicator: (props) => (
                <components.DropdownIndicator {...props}>
                  {!props.hasValue ? <ShapeIcon /> : <img src={shape} alt="" />}
                </components.DropdownIndicator>
              ),
            }
          : {
              IndicatorSeparator: () => <></>,
              DropdownIndicator: (props) => (
                <components.DropdownIndicator {...props}>
                  <ShapeIcon />
                </components.DropdownIndicator>
              ),
            }
      }
    />
  );
};

const CustomValueContainer = ({ children, label, ...props }) => {
  const { hasValue } = props;
  if (!hasValue) {
    return (
      <components.ValueContainer {...props}>
        {children}
      </components.ValueContainer>
    );
  }
  return (
    <components.ValueContainer {...props}>
      <Row align="middle" gutter={8} style={{ fontSize: 14, color: 'black' }}>
        <Col>
          <img height="20px" src={circle_checked} alt="" />
        </Col>
        <Col>{props.selectProps.placeholder}</Col>
      </Row>
      {children[1]}
    </components.ValueContainer>
  );
};
const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <Row align="top" gutter={2} justify="space-between">
          <Col span={2}>
            <input
              className={styles.checkedFilter}
              type="checkbox"
              checked={props.isSelected}
              onChange={() => null}
            />
          </Col>
          <Col span={20}>
            <label
              style={{
                color: '#344563',
                fontWeight: 'normal',
                textTransform: 'capitalize',
              }}
            >
              {props.label}
            </label>
          </Col>
        </Row>
      </components.Option>
    </div>
  );
};

const NoRemarks = React.memo(() => (
  <NoRemarkCol>
    <img src={comment_stack} alt="Comment Stack" />
    <NoRemarkTitle>No Remark, yet.</NoRemarkTitle>
    <NoRemarkDescription>
      No remarks in this section, yet. Post your remarks to start evaluation.
    </NoRemarkDescription>
  </NoRemarkCol>
));

const RemarkItemUI = React.memo(
  ({
    icon,
    headerName,
    subTitle,
    remarks,
    category,
    onDelete,
    onEdit,
    isHiddenAction,
    talentImage,
    mode,
  }) => {
    const handleMenuClick = useCallback(
      ({ key, domEvent }) => {
        if (key === 'edit') {
          onEdit && onEdit(domEvent);
        }

        if (key === 'delete') {
          onDelete && onDelete(domEvent);
        }
      },
      [onEdit, onDelete]
    );
    return (
      <RemarkItemStyled>
        <RemarkHeader>
          <RemarkTalentName>
            <RemarkIcon>
              {icon || <img src={commentAllLines} alt="Comment All Lines" />}
            </RemarkIcon>
            {mode === REMARK_MODE.PDC ? (
              <>
                <img
                  className={styles.talentImagePDC}
                  src={decodeBase64ToImage(talentImage)}
                  alt="avatar"
                />
                <div className={styles.headerTitlePDC}>{headerName}</div>
              </>
            ) : (
              <>
                <RemarkIcon>
                  {icon || (
                    <img src={commentAllLines} alt="Comment All Lines" />
                  )}
                </RemarkIcon>
                {headerName}
              </>
            )}
          </RemarkTalentName>
          <Row gutter={12}>
            {mode === REMARK_MODE.PDC
              ? category && (
                  <div className={styles.categoryPDC}>
                    Category : {isObject(category) ? category?.label : category}
                  </div>
                )
              : category && (
                  <RemarkCategory>
                    Category : {isObject(category) ? category?.label : category}
                  </RemarkCategory>
                )}
            <Col>
              {!isHiddenAction && (
                <Dropdown
                  trigger={['click']}
                  overlay={
                    <MenuWithStyle onClick={handleMenuClick}>
                      <MenuItemStyled
                        key="edit"
                        icon={<img src={editNote} alt="Edit Icon" />}
                      >
                        <span>Edit</span>
                      </MenuItemStyled>
                      <MenuItemStyled
                        key="delete"
                        icon={<img src={sp_trash} alt="Edit Icon" />}
                        $isDelete
                      >
                        <span>Delete</span>
                      </MenuItemStyled>
                    </MenuWithStyle>
                  }
                  placement="bottomRight"
                  getPopupContainer={(trigger) => trigger.parentElement}
                >
                  <EllipsisButton />
                </Dropdown>
              )}
            </Col>
          </Row>
        </RemarkHeader>
        <div style={{ marginTop: 20 }} />
        <RemarkItemBody>
          <RemarkItemCardTitle>{subTitle}</RemarkItemCardTitle>
          {mode === REMARK_MODE.PDC ? (
            <div className={styles.remarkPDC}>{remarks}</div>
          ) : (
            <RemarkItemCardDescription>{remarks}</RemarkItemCardDescription>
          )}
        </RemarkItemBody>
      </RemarkItemStyled>
    );
  }
);

RemarkItemUI.displayName = 'RemarkItemUI';

const DeleteConfirmation = ({
  visible,
  setVisible,
  onConfirmDelete,
  loading,
}) => (
  <ModelTC
    info={{
      type: 'confirmDeleteRemark',
      visible,
      setVisible,
      loading,
      handleSubmit: onConfirmDelete,
    }}
  />
);

const RemarkItem = React.memo(
  ({
    remarkType,
    talentName,
    agendaName,
    staffId,
    remark,
    remarks,
    category,
    remarkId,
    agendaId,
    onDelete,
    onEdit,
    isHiddenAction,
    agenda_name,
    talent_name,
    remark_type,
    agendaType,
    birthName,
    image,
    mode,
  }) => {
    const handleDelete = useCallback(() => {
      onDelete({ remarkId, agendaId, remarkType });
    }, [onDelete, remarkId, agendaId, remarkType]);

    const handleEdit = useCallback(() => {
      onEdit({
        remarkId,
        agendaId,
        agendaName,
        remarkType,
        staffId,
        talentName,
        remarks,
        category,
        agenda_name,
        talent_name,
        remark_type,
        birthName,
      });
    }, [
      onEdit,
      remarkId,
      agendaId,
      agendaName,
      remarkType,
      staffId,
      talentName,
      remarks,
      category,
      agenda_name,
      talent_name,
      remark_type,
      birthName,
    ]);

    if ((remarkType || remark_type) === 'Talent') {
      const firstCharacterNames =
        talentName || talent_name || birthName
          ? (talentName || talent_name || birthName)?.split(/\s+/)
          : null;
      return (
        <RemarkItemUI
          icon={
            isArray(firstCharacterNames)
              ? [
                  firstCharacterNames[0]?.charAt(0),
                  firstCharacterNames[1]?.charAt(0),
                ].join('')
              : null
          }
          headerName={`${talentName || talent_name || birthName || '-'}`}
          category={category}
          subTitle={`${agendaName || ''}`}
          remarks={remarks}
          onDelete={handleDelete}
          onEdit={handleEdit}
          isHiddenAction={isHiddenAction}
        />
      );
    } else if (mode === REMARK_MODE.PDC) {
      return (
        <RemarkItemUI
          headerName={birthName}
          category={category}
          remarks={remarks || remark}
          onDelete={handleDelete}
          onEdit={handleEdit}
          isHiddenAction={isHiddenAction}
          remark={remark}
          agendaType={agendaType}
          talentImage={image}
          mode={mode}
        />
      );
    }
    return (
      <RemarkItemUI
        headerName={
          <RemarkItemCardTitle as="span">
            {agendaType !== AGENDA_TYPES.OTHERS_AGENDA
              ? agendaName || agenda_name || birthName
              : `General Remarks`}
          </RemarkItemCardTitle>
        }
        remarks={remarks || remark}
        onDelete={handleDelete}
        onEdit={handleEdit}
        isHiddenAction={isHiddenAction}
      />
    );
  }
);

const viewRemarkReducer = (state, action) => {
  if (action.type === 'UPDATE') {
    return { ...state, ...action.payload };
  }
  return state;
};

const useRemarkFiltersReducer = (initialState) => {
  const [remarkFilters, dispatch] = useReducer(viewRemarkReducer, initialState);

  const updateFilters = useCallback((filterName, filterValue) => {
    dispatch({ type: 'UPDATE', payload: { [filterName]: filterValue } });
  }, []);

  return { remarkFilters, updateFilters };
};

const REMARK_TYPE_OPTIONS = [
  { label: 'All', value: null },
  { label: 'General', value: 'General' },
  { label: 'Talent', value: 'Talent' },
];

export default React.memo(
  ({
    className,
    isLoading,
    onFilters,
    onDeleteRemark,
    agendaOptions,
    remarks,
    isDeleteRemarkLoading,
    onEditRemark,
    isHiddenAction,
    agendaType,
    mode,
    getRemarksList,
    currentPage,
    setEditRemark,
    idMeeting,
  }) => {
    const [isConfirmationDelete, setIsConfirmationDelete] = useState(null);
    const userInfos = useSelector((state) => state.user);
    const { roleId } = useSelector((state) => state.user.roleActive);
    const dispatch = useDispatch();
    const paginationConfig = {
      defaultPageSize: 3,
      showTotal: (total, range) => (
        <>
          Showing <b>{range[0]}</b> to <b>{range[1]}</b> of <b>{total}</b>{' '}
          results
        </>
      ),
    };

    const { remarkFilters, updateFilters } = useRemarkFiltersReducer({
      remarkType: null,
      agendas: [],
    });

    useEffect(() => {
      onFilters && onFilters(remarkFilters);
    }, [onFilters, remarkFilters]);

    const deleteRemarkParamsRef = useRef(null);

    const openDeleteConfirmation = useCallback((params) => {
      setIsConfirmationDelete(true);
      deleteRemarkParamsRef.current = params;
    }, []);

    const handleDeleteRemark = useCallback(() => {
      onDeleteRemark(deleteRemarkParamsRef.current, {
        closeDeletePopup: () => setIsConfirmationDelete(false),
      });
    }, [onDeleteRemark]);

    const handleEditRemark = useCallback(
      (item) => {
        onEditRemark(item);
      },
      [onEditRemark]
    );

    const handleAgendaFiltersChange = useCallback(
      (options) => {
        updateFilters('agendas', map(options, 'value'));
      },
      [updateFilters]
    );

    const handleRemarkTypeFiltersChange = useCallback(
      (option) => {
        updateFilters('remarkType', option.value);
      },
      [updateFilters]
    );

    const [selectedOptions, setSelectedOptions] = useState(
      REMARK_TYPE_OPTIONS_PDC
    );

    useEffect(() => {
      if (selectedOptions) updateFilters('remarkType', selectedOptions);
    }, [selectedOptions]);

    const handleChangePDC = (selected) => {
      if (!selected) {
        setSelectedOptions([]);
        return;
      }

      const isSelectAllCurrentlySelected = selected.some(
        (item) => item.value === 'select-all'
      );
      const wasSelectAllPreviouslySelected = selectedOptions.some(
        (item) => item.value === 'select-all'
      );
      const otherOptions = selected.filter(
        (option) => option.value !== 'select-all'
      );

      if (isSelectAllCurrentlySelected && !wasSelectAllPreviouslySelected) {
        setSelectedOptions(REMARK_TYPE_OPTIONS_PDC);
      } else if (
        !isSelectAllCurrentlySelected &&
        wasSelectAllPreviouslySelected
      ) {
        setSelectedOptions([]);
      } else {
        if (otherOptions?.length < REMARK_TYPE_OPTIONS_PDC.length - 1) {
          setSelectedOptions(
            selected.filter((item) => item.value !== 'select-all')
          );
        } else if (otherOptions?.length === REMARK_TYPE_OPTIONS_PDC.length - 1)
          setSelectedOptions(REMARK_TYPE_OPTIONS_PDC);
      }
    };

    const paginationConfigPDC = {
      current: currentPage,
      defaultPageSize: 3,
      onChange: (page) => {
        getRemarksList(page);
      },
      total: remarks[0]?.total || 0,
      showTotal: (total, range) => (
        <>
          Showing <b>{range[0]}</b> to <b>{range[1]}</b> of <b>{total}</b>{' '}
          results
        </>
      ),
    };

    const handleDeleteRemarkPDC = async () => {
      try {
        if (deleteRemarkParamsRef?.current) {
          const res = await pdcMeetingApi.deletePDCRemark(
            idMeeting,
            deleteRemarkParamsRef?.current?.remarkId,
            roleId,
            userInfos
          );
          if (res.status === 200) {
            setIsConfirmationDelete(false);
            getRemarksList(1);
            dispatch(
              pushMessage({
                type: MESSAGE_TYPES.SUCCESS,
                message: DELETE_REMARK_SUCCESS_MESSAGE,
                timeShow: 0,
              })
            );
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    return (
      <>
        <Col className={className}>
          <Row justify="space-between" align="center">
            <Title as="div">
              <AiH3>All Remark</AiH3>
            </Title>
            {mode !== REMARK_MODE.PDC ? (
              <FilterCol>
                {agendaType !== AGENDA_TYPES.OTHERS_AGENDA && (
                  <FilterSelect
                    value={filter(agendaOptions, ({ value }) =>
                      includes(remarkFilters.agendas, value)
                    )}
                    onChange={handleAgendaFiltersChange}
                    isMulti
                    options={agendaOptions}
                    placeholder="Select Agenda"
                    closeMenuOnSelect={false}
                    mode="agenda"
                  />
                )}
                <FilterSelect
                  value={find(agendaOptions, {
                    value: remarkFilters.remarkType,
                  })}
                  onChange={handleRemarkTypeFiltersChange}
                  options={REMARK_TYPE_OPTIONS}
                  // placeholder="Select Remark type"
                  placeholder="All"
                />
              </FilterCol>
            ) : (
              <FilterSelect
                onChange={handleChangePDC}
                isMulti
                value={selectedOptions}
                options={REMARK_TYPE_OPTIONS_PDC}
                closeMenuOnSelect={false}
                placeholder={`Category${
                  selectedOptions?.some((item) => item.value === 'select-all')
                    ? ': All'
                    : ''
                }`}
                mode="agenda"
                sortType={mode}
              />
            )}
          </Row>

          <RemarksBody>
            <AiSpin
              wrapperClassName="w-100"
              spinning={isLoading}
              tip="Loading..."
            >
              {remarks.length ? (
                <List
                  pagination={
                    mode === REMARK_MODE.PDC
                      ? paginationConfigPDC
                      : paginationConfig
                  }
                  itemLayout="vertical"
                  dataSource={remarks}
                  grid={{ gutter: 28, column: 1 }}
                  renderItem={(item) => (
                    <List.Item className="w-100">
                      <RemarkItem
                        onDelete={() => openDeleteConfirmation(item)}
                        onEdit={() =>
                          mode === REMARK_MODE.PDC
                            ? setEditRemark(item)
                            : handleEditRemark(item)
                        }
                        {...item}
                        isHiddenAction={isHiddenAction}
                        agendaType={agendaType}
                        mode={mode}
                      />
                    </List.Item>
                  )}
                />
              ) : (
                <NoRemarks />
              )}
            </AiSpin>
          </RemarksBody>
        </Col>
        <DeleteConfirmation
          visible={isConfirmationDelete}
          setVisible={setIsConfirmationDelete}
          onConfirmDelete={
            mode === REMARK_MODE.PDC
              ? handleDeleteRemarkPDC
              : handleDeleteRemark
          }
          loading={isDeleteRemarkLoading}
        />
      </>
    );
  }
);
