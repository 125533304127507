import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as styles from './print-summary.module.scss';
import { Spin } from 'antd';
import { pdcMeetingApi } from '../../../../../services/pdcMeetingApi';
import { useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';
import SummaryMeeting from '../../../components/SummaryMeeting/SummaryMeeting';
import PreReadLabel from '../PreReadLabel/PreReadLabel';

const PrintSummary = ({ idMeeting, preRead }) => {
  const roleActive = useSelector((state) => state.user.roleActive);
  const [data, setData] = useState({
    by_proposal_type: [],
    by_effective_date: [],
    loading: false,
  });

  useEffect(() => {
    if (!idMeeting || !roleActive.roleId) return;
    (async function getSummaryProposal() {
      try {
        setData((prev) => ({ ...prev, loading: true }));
        const res = await pdcMeetingApi.fetchSummaryProposal(idMeeting, roleActive.roleId);
        setData({
          by_proposal_type: get(res, 'data.result.by_proposal_type') || [],
          by_effective_date: get(res, 'data.result.by_effective_date') || [],
          loading: false,
        });
      } catch (error) {
        console.error(error);
        setData({
          by_proposal_type: [],
          by_effective_date: [],
          loading: false,
        });
      }
    })();
  }, [idMeeting, roleActive.roleId]);
  return (
    <Spin spinning={data.loading}>
      <div className={styles.wrapper}>
        <div className={styles.title}>
          Summary
          {preRead && <PreReadLabel />}
        </div>
        <div className={styles.subTitle}>Summary of PDC Proposal</div>
        {!isEmpty(data.by_proposal_type) && <SummaryMeeting data={data.by_proposal_type} />}

        <div className={styles.subTitle}>Summary of PDC Proposal By Effective Date</div>
        {!isEmpty(data.by_effective_date) && <SummaryMeeting data={data.by_effective_date} isByDate />}
      </div>
    </Spin>
  );
};

export default PrintSummary;

PrintSummary.propTypes = {
  idMeeting: PropTypes.string,
  preRead: PropTypes.bool,
};
