import { Tooltip } from 'antd';
import { isEmpty } from 'lodash';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { notification_hover } from '../../assets/img/';
import { NOTIFICATION_MEETING_TYPE } from '../../utils/constants';
import { formatDate, formatTimeWithoutSecond } from '../../utils/helper';
import styles from './notification-detail.module.scss';

const NotificationDetail = ({ notifications, viewNotification }) => {
  const clickNotification = async (notification) => {
    await viewNotification(notification);
  };

  return (
    <div className={styles.container}>
      {!isEmpty(notifications) &&
        notifications.map((noti, i) => (
          <div
            onKeyDown={() => {}}
            onClick={() => clickNotification(noti)}
            className={`${styles.notification_detail} ${!noti.seen_at ? styles.unseen_noti : ''} `}
            style={{
              backgroundColor: `${!noti.seen_at ? 'rgba(0, 161, 156, 0.08)' : 'white'}`,
            }}
            key={i}
          >
            <div className={styles.notifcation_type}>
              {noti.notification_type}
              {(NOTIFICATION_MEETING_TYPE.PDC_MEETING_RE_INVITATION === noti.notification_type ||
                NOTIFICATION_MEETING_TYPE.MEETING_RE_INVITATION === noti.notification_type ||
                NOTIFICATION_MEETING_TYPE.MEETING_CANCELLED === noti.notification_type ||
                NOTIFICATION_MEETING_TYPE.PDC_MEETING_CANCELLED === noti.notification_type) && (
                <Tooltip
                  placement="top"
                  title={
                    [NOTIFICATION_MEETING_TYPE.MEETING_CANCELLED, NOTIFICATION_MEETING_TYPE.PDC_MEETING_CANCELLED].includes(noti.notification_type)
                      ? `${noti.title} has been Cancelled.`
                      : `The details for ${noti.title} has been changed.`
                  }
                  {...{
                    overlayInnerStyle: {
                      fontSize: '12px',
                      lineHeight: '18px',
                      gap: '24px',
                      padding: '12px',
                      borderRadius: '4px',
                      backgroundColor: '#000',
                    },
                  }}
                >
                  <img src={notification_hover} alt="" />
                </Tooltip>
              )}
            </div>
            <div className={styles.title}>
              {noti.notification_type !== NOTIFICATION_MEETING_TYPE.TALENT_FINDER_EXPORT
                ? noti.title
                : 'Your request to export Talent Finder is done, click here to download.'}
            </div>
            {noti.notification_type !== NOTIFICATION_MEETING_TYPE.TALENT_FINDER_EXPORT && noti.start_at && noti.end_at && (
              <div className={styles.description}>
                {formatDate(noti.start_at) +
                  ', ' +
                  formatTimeWithoutSecond(noti.start_at).toLocaleUpperCase() +
                  ' - ' +
                  formatTimeWithoutSecond(noti.end_at).toLocaleUpperCase()}
              </div>
            )}
            {noti.sent_at && (
              <div className={styles.sent_at}>
                <div>
                  Sent on {formatDate(noti.sent_at)} at {formatTimeWithoutSecond(noti.sent_at).toLocaleUpperCase()}
                </div>
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default withRouter(NotificationDetail);
