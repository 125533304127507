import React from 'react';
import styles from './footer.module.scss';
import { FOOTER_COPYRIGHT } from '../../utils/constants';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      {FOOTER_COPYRIGHT}
    </footer>
  );
};

export default Footer;
