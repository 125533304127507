import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './no-value.module.scss';
import { nothing_added } from '../../../../assets/img';

const NoValue = (props) => {
  const { text1, text2, text3 } = props;
  return (
    <div className={styles.noValueSection}>
      <img src={nothing_added} alt="no-mobility" />
      <h3>{text1}</h3>
      <span>
        {text2}
        <br />
        {text3}
      </span>
    </div>
  );
};
NoValue.propTypes = {
  text1: PropTypes.string,
  text2: PropTypes.string,
  text3: PropTypes.string,
};
export default NoValue;
