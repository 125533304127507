import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import * as styles from './print-aspiration.module.scss';
import PreReadLabel from '../PreReadLabel/PreReadLabel';
import { Spin } from 'antd';
import styled from 'styled-components';
import AutoResizeTextarea from '../../../../../components/AutoResizeTextArea/AutoResizeTextArea';
import { formatTextStringJson } from '../../../../../utils/helper';

const PrintAspiration = ({ preRead, staff_id, loadingAspiration, aspirations }) => {
  const data = useMemo(() => {
    return aspirations.find((item) => item.staff_id === staff_id);
  }, [aspirations.length, staff_id]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        Aspirations & Risks
        {preRead && <PreReadLabel />}
      </div>
      <Spin spinning={loadingAspiration}>
        <ProposalWrapper>
          <div className={styles.cardTitle}>{`Talent's Career Aspirations & Potential Derailers based on External Assessment`}</div>
          <div className={styles.flexContainer}>
            <div className={`${styles.tableHeading} ${styles.heading1}`}>{'Aspirations'}</div>
            <div className={`${styles.tableHeading} ${styles.heading2}`}>{'Risks'}</div>
          </div>
          <div className={styles.flexContainer}>
            <div className={styles.tableCell}>
              <CustomTextarea disabled placeholder="Enter Talent Aspirations" value={formatTextStringJson(data?.aspiration || '')} />
            </div>
            <div className={styles.tableCell}>
              <CustomTextarea disabled placeholder="Enter Talent Risks" value={formatTextStringJson(data?.risks || '')} />
            </div>
          </div>
          <div className={styles.flexContainer} style={{ marginTop: '16px' }}>
            <div className={`${styles.tableHeading} ${styles.heading1}`}>{'Other Observations'}</div>
          </div>
          <div className={styles.flexContainer}>
            <div className={styles.tableCell}>
              <CustomTextarea disabled placeholder="Enter Other Observations" value={formatTextStringJson(data?.other_observation || '')} />
            </div>
          </div>
        </ProposalWrapper>
      </Spin>
    </div>
  );
};

export default PrintAspiration;

PrintAspiration.propTypes = {
  preRead: PropTypes.bool,
  staff_id: PropTypes.string,
  aspirations: PropTypes.array,
  loadingAspiration: PropTypes.bool,
};

const ProposalWrapper = styled.div`
  border: 1px solid #cbd6e2;
  padding: 16px;
  display: flex;
  flex-direction: column;

  .noContent {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
  }
`;

const CustomTextarea = styled(AutoResizeTextarea)`
  resize: none;
  border-radius: 4px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  background-color: white !important;
  color: #3d3d3d !important;
  word-break: break-word;
`;
