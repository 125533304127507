import { invoke } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { pdcMeetingApi } from '../services/pdcMeetingApi';

export const useUpdatePDCMeeting = (options) => {
  const optionsRef = useRef(options);
  const [isSuccess, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const roleActive = useSelector((state) => state.user.roleActive);

  useEffect(() => {
    optionsRef.current = options;
  });

  const handleOnSuccess = useCallback((response) => {
    setSuccess(true);
    invoke(optionsRef.current, 'onSuccess', response.data);
  }, []);

  const handleOnError = useCallback((error) => {
    setSuccess(false);
    invoke(optionsRef.current, 'onError', error);
    setError(error);
  }, []);

  const updateMeeting = useCallback(
    async (meetingData) => {
      try {
        setSuccess(false);
        setError(null);
        const response = await pdcMeetingApi.updateMeeting(meetingData, roleActive);
        handleOnSuccess(response);
        return response.data;
      } catch (err) {
        handleOnError(err);
        return {};
      }
    },
    [handleOnError, handleOnSuccess, roleActive]
  );

  return { isSuccess, error, updateMeeting };
};
