import { DndContext, useDroppable } from '@dnd-kit/core';
import { Dropdown, Spin } from 'antd';
import { chunk, cloneDeep, isEmpty, size } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { BasicButton, BasicInput, BasicLabel, BasicRadio, BasicSelect, BasicSwitch } from '../../../../assets/common';
import {
  add_position,
  delete_position,
  edit_report_name,
  mobility_arrow_1,
  mobility_arrow_2,
  mobility_arrow_3,
  more_dot_card_vertical,
} from '../../../../assets/img';
import { ModelTC } from '../../../../components';
import CustomAntModal from '../../../../components/CustomAntModal/CustomAntModal';
import { pdcMeetingApi } from '../../../../services/pdcMeetingApi';
import { pushMessage } from '../../../../store/alertMessageSlice';
import { MESSAGE_TYPES, PDC_PROPOSAL_TYPES } from '../../../../utils/constants';
import { compareCard, getPdcMobilityProposalType } from '../../../../utils/helper';
import {
  INIT_PDC_MOBILITY_PROPOSAL,
  PDC_MOBILITY_CARD_TYPE,
  PDC_MOBILITY_PROPOSAL_TYPE,
  PDC_MOBILITY_TYPE_OPTIONS,
  PDC_SAVE_FOR,
} from '../../constants';
import NoValue from '../NoValue/NoValue';
import PDCTalentPositionCard from '../PDCTalentPositionCard/PDCTalentPositionCard';
import SearchPositionTalentModal from '../SearchPositionTalentModal/SearchPositionTalentModal';
import * as styles from './first-step-mobility-section.module.scss';

const MAX_CARD = 6;
const MAX_CARDS_PER_ROW = 3;
const MIN_MULTIPLE_CARD_FIRST_ROW = 1;
const TEMP_CARD = { staff_id: 0, position_code: 0 };
const TAG_COLOR_AI = 'FDB924';

const FirstStepMobilitySection = (props) => {
  const { proposal, setProposal, details, setDetails, saveDetails, isEditingProposal, setIsEditingProposal, isOnlyViewAgenda } = props;
  const [recommendations, setRecommendations] = useState({ fromAi: [], approved: [] });
  const [loadingRecommendation, setLoadingRecommendation] = useState(false);
  const [showEditName, setShowEditName] = useState(false);
  const [showAddCard, setShowAddCard] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [isCurrentPositionCard, setIsCurrentPositionCard] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const dispatch = useDispatch();

  const { mobilityId } = useParams();

  const selectMobilityOptions = useMemo(() => {
    return details.proposals.map((createdMobility) => (
      <BasicSelect.Option key={createdMobility.id} value={createdMobility.id}>
        <div className={styles.selectMobilityOption}>
          <BasicRadio checked={createdMobility.id === proposal.id} invert />
          <span>{createdMobility.name}</span>
          <MainTag isMain={createdMobility.is_main} />
        </div>
      </BasicSelect.Option>
    ));
  }, [details.proposals, proposal.id]);

  const handleClickEditName = () => {
    setShowEditName(true);
  };

  const handleEditName = (name) => {
    const newProposal = cloneDeep(proposal);
    newProposal.name = name;
    setProposal(newProposal);
    setShowEditName(false);
  };

  const handleSetAsMain = (checked) => {
    const newProposal = cloneDeep(proposal);
    newProposal.is_main = checked;
    setProposal(newProposal);
  };

  const handleDeleteProposal = async () => {
    const newDetails = cloneDeep(details);
    newDetails.proposals = newDetails.proposals.filter((item) => item.id !== proposal.id);
    setDetails(newDetails);
    let idUpdateMain = '';
    if (newDetails.proposals[0]) {
      const isMain = newDetails.proposals[0].is_main;
      newDetails.proposals[0].is_main = proposal.is_main ? true : isMain;
      setProposal(newDetails.proposals[0]);
      idUpdateMain = newDetails.proposals[0].id;
    } else {
      setProposal(INIT_PDC_MOBILITY_PROPOSAL);
    }
    setOpenDeleteConfirm(false);

    if (isNaN(proposal.id) || isNaN(idUpdateMain)) return;
    await saveDetails({ proposals: newDetails.proposals, saveFor: PDC_SAVE_FOR.DELETE });
  };

  const handleChangeType = (value) => {
    const newProposal = cloneDeep(proposal);
    newProposal.type = value;
    newProposal.cards = [];
    setProposal(newProposal);
    setSelectedCard(null);
  };

  const handleReset = () => {
    const newProposal = cloneDeep(proposal);
    newProposal.cards = [];
    setProposal(newProposal);
    setSelectedCard(null);
  };

  const handleSelectProposal = (proposalId) => {
    const selectedMobility = details.proposals.find((savedMobility) => savedMobility.id === proposalId);
    setProposal(selectedMobility);
  };

  const handleCreateProposal = () => {
    setProposal({
      ...INIT_PDC_MOBILITY_PROPOSAL,
      is_main: !details.proposals.find((p) => p.is_main),
      name: `Mobility Proposal ${details.proposals.length + 1}`,
    });
    setIsEditingProposal(true);
  };

  const handleSaveMobility = () => {
    const isEdit = !!proposal.id;
    const savedOnClientMobility = { ...proposal, id: isEdit ? proposal.id : uuidv4() };
    const newDetails = cloneDeep(details);
    if (savedOnClientMobility.is_main) {
      newDetails.proposals = newDetails.proposals.map((p) => ({ ...p, is_main: false }));
    }
    const updateIndex = newDetails.proposals.findIndex((p) => p.id === savedOnClientMobility.id);

    if (updateIndex !== -1) {
      newDetails.proposals[updateIndex] = savedOnClientMobility;
    } else {
      newDetails.proposals.push(savedOnClientMobility);
    }
    setIsEditingProposal(false);
    setSelectedCard(null);
    if (!mobilityId) {
      setDetails(newDetails);
      setProposal(savedOnClientMobility);
      return dispatch(
        pushMessage({
          type: MESSAGE_TYPES.SUCCESS,
          message: isEdit ? `Mobility Proposal has been successfully edited.` : `Mobility Proposal has been successfully saved.`,
          timeShow: 0,
        })
      );
    }
    saveDetails({ proposals: newDetails.proposals, saveFor: PDC_SAVE_FOR.SAVE_MOBILITY, isEdit });
  };

  const handleClickAddCard = (isPosition) => {
    setShowAddCard(true);
    setIsCurrentPositionCard(isPosition);
  };

  const handleAddCard = (card) => {
    const newProposal = cloneDeep(proposal);
    const type = !card.type && isCurrentPositionCard ? PDC_MOBILITY_CARD_TYPE.POSITION : PDC_MOBILITY_CARD_TYPE.TALENT;
    newProposal.cards = [...proposal.cards, { ...card, type }];
    setProposal(newProposal);
    setShowAddCard(false);
    setSelectedCard(card);
  };

  const handleSetCard = (newCard) => {
    const newProposal = cloneDeep(proposal);
    const index = newProposal.cards.findIndex((card) => compareCard(card, newCard));
    newProposal.cards[index] = newCard;
    setProposal(newProposal);
  };

  const handleDeleteCard = (newCard) => {
    const newProposal = cloneDeep(proposal);
    const index = newProposal.cards.findIndex((card) => compareCard(card, newCard));
    if (proposal.type === PDC_MOBILITY_PROPOSAL_TYPE.SINGLE) {
      newProposal.cards.splice(index);
    } else if (newProposal.cards.length > 2) {
      const addedCard = [newProposal.cards[0], newProposal.cards[1], newProposal.cards[2]];
      const isPosition = addedCard.filter((card) => card.type === PDC_MOBILITY_CARD_TYPE.POSITION).length === 1;
      const isRemoveAll =
        index < 2 &&
        ((isPosition && newProposal.cards[index].type === PDC_MOBILITY_CARD_TYPE.POSITION) ||
          (!isPosition && newProposal.cards[index].type === PDC_MOBILITY_CARD_TYPE.TALENT));
      if (isRemoveAll) {
        newProposal.cards = [];
      } else {
        newProposal.cards.splice(index, 1);
      }
    } else {
      newProposal.cards.splice(index, 1);
    }
    setProposal(newProposal);
    const isSelectedCardGone = !newProposal.cards.find((card) => compareCard(card, selectedCard || {}));
    if (isSelectedCardGone) {
      setSelectedCard(null);
    }
  };

  const handleDragEnd = (event) => {
    if (!event.over) return;
    const card = event.active.data.current;
    handleAddCard({ ...card, type: PDC_MOBILITY_CARD_TYPE.TALENT });
  };

  useEffect(() => {
    if (!selectedCard) return setRecommendations({ fromAi: [], approved: [] });
    const fetchRecommended = async () => {
      const selectedCards = proposal.cards.map(({ staff_id, position_code }) => ({ staff_id, position_code }));
      try {
        setLoadingRecommendation(true);
        const res = await pdcMeetingApi.getRecommendedReplacement(selectedCard.position_code, selectedCards);
        if (res.status === 200) {
          const fromAi = [];
          const approved = [];
          res.data.result.forEach((card) => {
            if (card.tag_color === TAG_COLOR_AI) {
              fromAi.push(card);
              return;
            }
            approved.push(card);
          });
          setRecommendations({ fromAi, approved });
        }
      } catch (error) {
        console.error(error);
        setRecommendations([]);
      } finally {
        setLoadingRecommendation(false);
      }
    };
    fetchRecommended();
  }, [selectedCard]);

  const displaySingleMobility = useMemo(() => {
    const cards = isEditingProposal && proposal.cards.length < MAX_CARD ? [...proposal.cards, TEMP_CARD] : proposal.cards;
    const chunkRows = chunk(cards, MAX_CARDS_PER_ROW);
    if (isEmpty(chunkRows)) return <></>;
    return (
      <div className={styles.listCardSingle} style={chunkRows[0].length < MAX_CARDS_PER_ROW ? null : { alignItems: 'start' }}>
        {chunkRows.map((row, index) => {
          const isOdd = !(index % 2);
          return (
            <React.Fragment key={index}>
              <div className={isOdd ? styles.rowOdd : styles.rowEven}>
                {row.map((card, idx) => {
                  const isLast = idx + 1 === row.length;
                  return (
                    <React.Fragment key={`${card.staff_id}-${card.position_code}`}>
                      {!card.staff_id && !card.position_code ? (
                        <>
                          {cards.length === 1 ? (
                            <AddCard onClick={handleClickAddCard} text1="Click plus icon to add Position or Talent" isPosition />
                          ) : (
                            <AddCard
                              onClick={handleClickAddCard}
                              text1="Click plus icon or drag and drop the card here to"
                              text2="Add Talent"
                              dropId={'drop-talent-single'}
                            />
                          )}
                        </>
                      ) : (
                        <div className="d-flex">
                          {!isLast && isOdd && <img src={mobility_arrow_1} alt="arrow_connect_1" className={styles.arrow} />}
                          <PDCTalentPositionCard
                            isEditingProposal={isEditingProposal}
                            card={card}
                            setCard={handleSetCard}
                            handleDeleteCard={handleDeleteCard}
                            selectedCard={selectedCard}
                            setSelectedCard={setSelectedCard}
                            isPosition={!index && !idx}
                          />
                          {!isLast && !isOdd && <img src={mobility_arrow_3} alt="arrow_connect_3" className={styles.arrow} />}
                        </div>
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
              <div className={styles.rowConnectArrow}>
                {chunkRows[index + 1] && <img src={mobility_arrow_2} alt="arrow_connect_2" className={styles.arrow} />}
              </div>
            </React.Fragment>
          );
        })}
      </div>
    );
  }, [proposal.cards, proposal.type, selectedCard, isEditingProposal]);

  const displayMultipleMobility = useMemo(() => {
    const talentCards = getSortedCards(proposal.cards, PDC_MOBILITY_CARD_TYPE.TALENT);
    const positionCards = getSortedCards(proposal.cards, PDC_MOBILITY_CARD_TYPE.POSITION);
    const displayTalentCards = !isEditingProposal || notHasAddCard(talentCards, positionCards) ? talentCards : [...talentCards, null];
    const displayPositionCards = !isEditingProposal || notHasAddCard(positionCards, talentCards) ? positionCards : [...positionCards, null];
    const maxLength = Math.max(talentCards.length, positionCards.length);
    const maxLengthDisplay = Math.max(displayTalentCards.length, displayPositionCards.length);
    const chunkByRows = Array.from({ length: maxLengthDisplay }, (_, index) => [displayTalentCards[index], displayPositionCards[index]]);
    return (
      <div className={styles.listCardMultiple}>
        {chunkByRows.map((row, index) => (
          <div key={index} className={styles.rows}>
            {row.map((card, idx) => {
              const isPosition = Boolean(idx);
              return (
                <>
                  {card && (
                    <div key={`${card.staff_id}-${card.position_code}`} className={styles.wrapCard}>
                      <PDCTalentPositionCard
                        isEditingProposal={isEditingProposal}
                        card={card}
                        setCard={handleSetCard}
                        handleDeleteCard={handleDeleteCard}
                        selectedCard={selectedCard}
                        setSelectedCard={setSelectedCard}
                        isPosition={isPosition}
                      />
                      <ConnectLineMultipleMobility
                        index={index}
                        rowIndex={idx}
                        maxLength={maxLength}
                        talentLength={talentCards.length}
                        positionLength={positionCards.length}
                      />
                    </div>
                  )}
                  {card === null && (
                    <div className={styles.wrapCard}>
                      {index === 0 && idx === 0 && <img src={mobility_arrow_1} alt="connect-arrow" className={styles.connector} />}
                      {isPosition ? (
                        <AddCard onClick={handleClickAddCard} text1="Click plus icon to Add Position" isPosition />
                      ) : (
                        <AddCard
                          onClick={handleClickAddCard}
                          text1="Click plus icon or drag or drop the card here to"
                          text2="Add Talent"
                          dropId={'drop-talent-multiple'}
                        />
                      )}
                    </div>
                  )}
                  {card === undefined && <div className={styles.tempCard} />}
                </>
              );
            })}
          </div>
        ))}
      </div>
    );
  }, [proposal.cards, proposal.type, selectedCard, isEditingProposal]);

  const proposalMainType = useMemo(() => {
    return getPdcMobilityProposalType(details);
  }, [details]);

  return (
    <>
      <DndContext onDragEnd={handleDragEnd}>
        <div className={styles.mobilitySection}>
          <div className={styles.topBar}>
            <div className={styles.left}>
              <div className={styles.title}>{proposal.name}</div>
              {!isEditingProposal && proposal.id && !isOnlyViewAgenda && (
                <>
                  <MainTag isMain={proposal.is_main} />
                  <Dropdown
                    menu={{
                      items: [
                        {
                          label: (
                            <div className={styles.moreOption}>
                              <img src={edit_report_name} alt="edit-proposal" />
                              <span>Edit Mobility Proposal</span>
                            </div>
                          ),
                          key: '0',
                          onClick: () => {
                            setIsEditingProposal(true);
                          },
                        },
                        {
                          label: (
                            <div className={styles.moreOption}>
                              <img src={delete_position} alt="delete-proposal" />
                              <span>Delete Mobility Proposal</span>
                            </div>
                          ),
                          key: '1',
                          onClick: () => setOpenDeleteConfirm(true),
                        },
                      ],
                    }}
                    trigger={['click']}
                    style={{ minWidth: 110 }}
                    placement="bottomRight"
                    getPopupContainer={(trigger) => trigger.parentElement}
                  >
                    <img src={more_dot_card_vertical} alt="more" className={styles.moreEditBtn} />
                  </Dropdown>
                </>
              )}
              {isEditingProposal && (
                <>
                  <BasicButton mode="icon" onClick={handleClickEditName}>
                    <img src={edit_report_name} alt="edit-name" />
                  </BasicButton>
                  <span className={styles.setAsMain}>
                    <BasicSwitch checked={proposal.is_main} onChange={handleSetAsMain} /> Set As Main
                  </span>
                </>
              )}
            </div>
            <div className={styles.right}>
              <div className={styles.select}>
                {(isEditingProposal || (isOnlyViewAgenda && !isEditingProposal)) && (
                  <BasicSelect
                    height={32}
                    value={
                      isOnlyViewAgenda && proposalMainType
                        ? proposalMainType === PDC_PROPOSAL_TYPES.SINGLE
                          ? PDC_MOBILITY_TYPE_OPTIONS[0]
                          : PDC_MOBILITY_TYPE_OPTIONS[1]
                        : proposal.type
                    }
                    options={PDC_MOBILITY_TYPE_OPTIONS}
                    placeholder="Select Mobility Type"
                    onChange={handleChangeType}
                    disabled={isOnlyViewAgenda && !isEditingProposal}
                    pageName={'PDC_MOBILITY'}
                  />
                )}
                {!isEditingProposal && !isEmpty(details?.proposals) && details?.proposals?.length > 1 && !isOnlyViewAgenda && (
                  <BasicSelect
                    height={32}
                    placeholder={'Select Mobility Proposal'}
                    value={null}
                    filterOption={false}
                    getPopupContainer={(trigger) => trigger}
                    onSelect={handleSelectProposal}
                  >
                    {selectMobilityOptions}
                  </BasicSelect>
                )}
              </div>
              {isEditingProposal && !isOnlyViewAgenda && (
                <>
                  <BasicButton width="100px" onClick={handleReset}>
                    Reset
                  </BasicButton>
                  <BasicButton mode="teal" width="115px" onClick={handleSaveMobility}>
                    Save Mobility
                  </BasicButton>
                </>
              )}
              {!isEditingProposal && details?.proposals?.length < 3 && !isOnlyViewAgenda && (
                <BasicButton mode="teal" className={styles.createMobilityBtn} onClick={handleCreateProposal}>
                  Create Mobility Proposal
                </BasicButton>
              )}
            </div>
          </div>

          {!isEditingProposal && isEmpty(details.proposals) ? (
            <NoValue
              text1={'No Mobility Proposal added, yet.'}
              text2={'No Mobility Proposal found.'}
              text3={'Click “Create Mobility Proposal” to start'}
            />
          ) : (
            <>
              {!proposal.type && isEditingProposal && (
                <NoValue text1={'No Mobility added, yet.'} text2={'Select “Mobility Type” to start and create the proposal. '} />
              )}
              {(proposal.type === PDC_MOBILITY_PROPOSAL_TYPE.SINGLE || (size(proposal.cards) === 1 && !isEditingProposal)) && displaySingleMobility}
              {proposal.type === PDC_MOBILITY_PROPOSAL_TYPE.MULTIPLE &&
                ((size(proposal.cards) > 1 && !isEditingProposal) || isEditingProposal) &&
                displayMultipleMobility}
            </>
          )}
        </div>

        {isEditingProposal && proposal.type && (
          <Spin spinning={loadingRecommendation}>
            <div className={styles.recommendSection}>
              <div className={styles.topBar}>
                <div className={styles.title}>Recommended Replacement</div>
                <div className={styles.type}>
                  <div>
                    <span /> Approved Succession Planning
                  </div>
                  <div>
                    <span /> AI Recommendation
                  </div>
                </div>
              </div>
              {recommendations.fromAi.length || recommendations.approved.length ? (
                <div className={styles.recommendations}>
                  {recommendations.approved.map((card, index) => (
                    <PDCTalentPositionCard
                      isRecommended
                      key={`${card.staff_id}-${card.position_code}`}
                      recommendedIndex={index + 1}
                      card={card}
                      setCard={handleSetCard}
                      selectedCard={{}}
                    />
                  ))}
                  {recommendations.fromAi.map((card, index) => (
                    <PDCTalentPositionCard
                      isRecommended
                      key={`${card.staff_id}-${card.position_code}`}
                      recommendedIndex={index + 1}
                      card={card}
                      setCard={handleSetCard}
                      selectedCard={{}}
                    />
                  ))}
                </div>
              ) : (
                <NoValue
                  text1={'No Recommended Replacement shown, yet.'}
                  text2={'No recommended replacement shown in this section, yet'}
                  text3={'Click the “+” button to create Mobility.'}
                />
              )}
            </div>
          </Spin>
        )}
      </DndContext>

      <ChangeNameModel open={showEditName} setOpen={setShowEditName} proposal={proposal} handleEditName={handleEditName} />
      {showAddCard && <SearchPositionTalentModal open={showAddCard} setOpen={setShowAddCard} onAdd={handleAddCard} cards={proposal.cards} />}
      <ModelTC
        info={{
          type: 'deletePdcMobilityProposal',
          visible: openDeleteConfirm,
          setVisible: setOpenDeleteConfirm,
          handleSubmit: () => handleDeleteProposal(),
        }}
      />
    </>
  );
};
FirstStepMobilitySection.propTypes = {
  details: PropTypes.object,
  setDetails: PropTypes.func,
  proposal: PropTypes.object,
  setProposal: PropTypes.func,
  saveDetails: PropTypes.func,
  isEditingProposal: PropTypes.bool,
  setIsEditingProposal: PropTypes.func,
};
export default FirstStepMobilitySection;

const ChangeNameModel = ({ open, setOpen, proposal, handleEditName }) => {
  const [name, setName] = useState();
  useEffect(() => {
    setName(proposal.name);
  }, [proposal.name, open]);
  return (
    <CustomAntModal
      width={747}
      okText="Save Changes"
      disabledOk={!name}
      title="Edit Mobility Name"
      closeType={1}
      open={open}
      setOpen={setOpen}
      onOk={() => handleEditName(name)}
      styles={{ closePosition: 30 }}
    >
      <div className="mb-4">
        <BasicLabel>Mobility Name</BasicLabel>
        <BasicInput
          placeholder="Enter Mobility Name"
          defaultValue={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </div>
    </CustomAntModal>
  );
};
ChangeNameModel.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  proposal: PropTypes.object,
  handleEditName: PropTypes.func,
};

const AddCard = ({ onClick, text1, text2, isPosition, dropId }) => {
  const { setNodeRef } = useDroppable({
    id: dropId,
    disabled: isPosition,
  });

  const handleOnClick = () => {
    onClick(isPosition);
  };

  return (
    <div className={styles.addCard} ref={setNodeRef}>
      <div>
        <BasicButton mode="icon" onClick={handleOnClick}>
          <img src={add_position} alt="add-card" />
        </BasicButton>
        <div>{text1}</div>
        <div>{text2}</div>
      </div>
    </div>
  );
};
AddCard.propTypes = {
  onClick: PropTypes.func,
  text1: PropTypes.string,
  text2: PropTypes.string,
  isPosition: PropTypes.bool,
  dropId: PropTypes.string,
};

const MainTag = ({ isMain }) => {
  return <>{isMain ? <span className={styles.mainTag}>Main</span> : ''}</>;
};
MainTag.propTypes = {
  isMain: PropTypes.bool,
};

const ConnectLineMultipleMobility = ({ index, rowIndex, maxLength, talentLength, positionLength }) => {
  return (
    <>
      {index === 0 && rowIndex === 0 && <img src={mobility_arrow_1} alt="connect-arrow" className={styles.connector} />}
      {rowIndex === 0 && (
        <>
          {index === 0 && <img src={mobility_arrow_1} alt="connect-arrow" className={styles.connector} />}
          {index > 0 && index < maxLength - 1 && <div className={styles.leftConnect} />}
        </>
      )}
      {index === 0 && rowIndex === 0 && maxLength > MIN_MULTIPLE_CARD_FIRST_ROW && (
        <>
          {talentLength < positionLength ? (
            <div className={`${styles.lineBetweenFirst} ${styles.left}`} />
          ) : (
            <div className={`${styles.lineBetweenFirst} ${styles.right}`} />
          )}
        </>
      )}
      {index > 0 && index < maxLength - 1 && <div className={`${styles.lineBetween} ${rowIndex === 0 ? styles.left : styles.right}`} />}
      {index > 0 && index === maxLength - 1 && (
        <div className={`${styles.lineBetweenLast} ${rowIndex === 0 ? styles.left : styles.right}`}>
          <span />
        </div>
      )}
      {rowIndex === 1 && (
        <>
          {index > 0 && index < maxLength - 1 && (
            <div className={styles.rightConnect}>
              <span />
            </div>
          )}
        </>
      )}
    </>
  );
};
ConnectLineMultipleMobility.propTypes = {
  index: PropTypes.number,
  rowIndex: PropTypes.number,
  maxLength: PropTypes.number,
  talentLength: PropTypes.number,
  positionLength: PropTypes.number,
};
const getSortedCards = (cards, type) => {
  return cards.filter((card) => card.type === type).sort((a, b) => a.order - b.order);
};

const notHasAddCard = (mainCards, otherCards) => {
  return (
    (mainCards.length === MIN_MULTIPLE_CARD_FIRST_ROW && !otherCards.length) ||
    otherCards.length > MIN_MULTIPLE_CARD_FIRST_ROW ||
    mainCards.length === MAX_CARD
  );
};
