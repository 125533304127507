import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as styles from './candidate-print.module.scss';
import { pdcMeetingApi } from '../../../../../services/pdcMeetingApi';
import { avatar } from '../../../../../assets/img';
import { Spin } from 'antd';
import PrintProposal from '../PrintProposal/PrintProposal';
import PrintCompareSuccessor from '../PrintCompareSuccessor/PrintCompareSuccessor';
import { PDC_MOBILITY_PROPOSAL_TYPE } from '../../../constants';
import PrintAssessment from '../PrintAssessment/PrintAssessment';
import { useSelector } from 'react-redux';
import PrintCompareAssessment from '../PrintCompareAssessment/PrintCompareAssessment';
import PrintAspiration from '../PrintAspiration/PrintAspiration';
import PrintCompareAspiration from '../PrintCompareAspiration/PrintCompareAspiration';
import PrintSupportingDocuments from '../PrintSupportingDocuments/PrintSupportingDocuments';
import { isEmpty } from 'lodash';
import { PDC_PROPOSAL_TYPES } from '../../../../../utils/constants';
import PrintMultiple from '../PrintMultiple/PrintMultiple';

//need update when change number of api call
const COMPONENT_API_COUNT = {
  proposal: 1,
  compareSuccessor: 1,
  assessment: 1,
  compareAssessment: 1,
  aspirationAndRisks: 0,
  compareAspirationAndRisks: 1,
  supportingDocuments: 0,
};
const NUM_COMMON_API = 4; //3 common below and 1 supportingDocuments
const COMPONENT_IGNORE_MULTIPLE = ['compareSuccessor', 'compareAssessment', 'compareAspirationAndRisks'];

const CandidatePrint = ({ index, idMeeting, mobilityInfo, printSectionConfig, handleFetchComplete, isDisplayCandidate }) => {
  const { id: mobility_id, mobility_proposal_id, proposal_main_type, staffs } = mobilityInfo;
  const roleId = useSelector((state) => state.user.roleActive.roleId);
  const renderedCount = useRef(0);
  //Proposal state
  const [proposalList, setProposalList] = useState([]);
  const [loading, setLoading] = useState();
  //Assessment state
  const [loadingAssessment, setLoadingAssessment] = useState(false);
  const [assessmentRemarks, setAssessmentRemarks] = useState([]);
  const [typeAssessment, setTypeAssessment] = useState('All');
  const [yearAssessment, setYearAssessment] = useState('All');
  //Aspiration state
  const [loadingAspiration, setLoadingAspiration] = useState(false);
  const [aspirations, setAspirations] = useState([]);
  const [multiplePositions, setMultiplePositions] = useState([]);

  const totalAPICall = useMemo(() => {
    let total = Object.keys(COMPONENT_API_COUNT).reduce((sum, key) => {
      const numAPI =
        proposal_main_type === PDC_MOBILITY_PROPOSAL_TYPE.SINGLE ||
        (proposal_main_type !== PDC_MOBILITY_PROPOSAL_TYPE.SINGLE && !COMPONENT_IGNORE_MULTIPLE.includes(key))
          ? COMPONENT_API_COUNT[key]
          : 0;
      const value = numAPI - (!numAPI || printSectionConfig[key].checked ? 0 : 1);
      return sum + value;
    }, 0);

    return total * staffs.length + NUM_COMMON_API;
  }, []);

  const handleFetchStepDataComplete = (_logComponent) => {
    renderedCount.current += 1;
    // console.log(`Fetched ${_logComponent} --- remaining ${totalAPICall - renderedCount.current}`); //debug logging
    if (totalAPICall === renderedCount.current) {
      handleFetchComplete(`Candidate ${index + 1}`);
    }
  };

  //common api 1: fetch proposal list
  useEffect(() => {
    async function fetchProposalListSingle() {
      try {
        setLoading(true);
        const res = await pdcMeetingApi.getProposalList(mobility_id, mobility_proposal_id);
        if (res.status === 200) {
          setProposalList(res?.data?.result || []);
        }
      } catch (error) {
        console.error(error);
      } finally {
        handleFetchStepDataComplete('Common API 1');
        setLoading(false);
      }
    }
    async function fetchProposalListMultiple() {
      try {
        setLoading(true);
        const res = await pdcMeetingApi.getProposalMultipleTalentOrPosition(mobility_id, proposal_main_type);
        if (res.status === 200) {
          setProposalList(res?.data?.result?.talents || []);
          setMultiplePositions(res?.data?.result?.positions || []);
        }
      } catch (error) {
        console.error(error);
      } finally {
        handleFetchStepDataComplete('Common API 1');
        setLoading(false);
      }
    }
    if (proposal_main_type === PDC_PROPOSAL_TYPES.SINGLE) {
      fetchProposalListSingle();
    } else {
      fetchProposalListMultiple();
    }
  }, []);

  //common api 2: fetch assessment remark
  useEffect(() => {
    async function fetchAssessment() {
      try {
        setLoadingAssessment(true);
        const res = await pdcMeetingApi.getAssessments(mobility_id, roleId);
        if (res.status === 200) {
          const dataResult = !isEmpty(res.data.result) ? res.data.result : [];
          const result = dataResult.map((item) => ({
            staff_id: item.staff_id,
            development_areas: item.development_areas,
            strengths: item.strengths,
          }));
          setAssessmentRemarks(result);
        }
      } catch (error) {
        console.error(error);
      } finally {
        handleFetchStepDataComplete('Common API 2');
        setLoadingAssessment(false);
      }
    }
    if (!printSectionConfig.assessment.checked) {
      return handleFetchStepDataComplete('Common API 2');
    }
    fetchAssessment();
  }, []);

  //common api 3: fetch aspiration & risks
  useEffect(() => {
    const fetchAspirationRisks = async () => {
      const staff_ids = staffs.map((obj) => obj?.staff_id).join(',');

      if (!mobility_id || staff_ids?.length <= 0) return handleFetchStepDataComplete('Common API 3');
      try {
        setLoadingAspiration(true);
        const res = await pdcMeetingApi.getAspirationRisk(staff_ids, mobility_id, mobility_proposal_id);
        if (res.status === 200) {
          setAspirations(res.data.result);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingAspiration(false);
        handleFetchStepDataComplete('Common API 3');
      }
    };
    if (!printSectionConfig.aspirationAndRisks.checked) {
      return handleFetchStepDataComplete('Common API 3');
    }
    fetchAspirationRisks();
  }, []);

  return (
    <div className={styles.wrapper}>
      {isDisplayCandidate && (
        <>
          {proposal_main_type !== PDC_PROPOSAL_TYPES.SINGLE && printSectionConfig.proposal.checked && (
            <PrintMultiple
              talents={proposalList}
              positions={multiplePositions}
              mainType={proposal_main_type}
              preRead={printSectionConfig.proposal.preRead}
            />
          )}
          {staffs.map(({ staff_id }, index) => {
            const proposal = proposalList[index] || {};
            return (
              <div className={styles.wrapper} key={index}>
                <Spin spinning={loading}>
                  <div className={styles.title}>
                    {proposal_main_type === PDC_PROPOSAL_TYPES.MULTIPLE_TALENT ? 'Talent' : 'Proposal'} {index + 1}
                  </div>
                  <div className={styles.proposal}>
                    <div className={styles.header}>
                      <div className={styles.name}>
                        <img src={proposal.image || proposal.image_url || avatar} alt="avatar" />
                        <div>
                          <div className={styles.label}>Name</div>
                          <div className={styles.content}>{proposal.birth_name}</div>
                        </div>
                      </div>
                      <div className={styles.position}>
                        <div className={styles.label}>Position</div>
                        <div className={styles.content}>{proposal.position_name || 'N/A'}</div>
                      </div>
                      <div className={styles.more}>
                        <div className={styles.type}>{proposal.proposal_type}</div>
                      </div>
                    </div>
                  </div>
                </Spin>
                {printSectionConfig.proposal.checked && (
                  <PrintProposal
                    preRead={printSectionConfig.proposal.preRead}
                    handleFetchComplete={handleFetchStepDataComplete}
                    proposal={proposal}
                    mobilityInfo={mobilityInfo}
                  />
                )}
                {printSectionConfig.compareSuccessor.checked && proposal_main_type === PDC_MOBILITY_PROPOSAL_TYPE.SINGLE && (
                  <PrintCompareSuccessor
                    preRead={printSectionConfig.compareSuccessor.preRead}
                    handleFetchComplete={handleFetchStepDataComplete}
                    proposal={proposal}
                  />
                )}
                {printSectionConfig.assessment.checked && (
                  <PrintAssessment
                    preRead={printSectionConfig.assessment.preRead}
                    handleFetchComplete={handleFetchStepDataComplete}
                    proposal={proposal}
                    loadingRemarks={loadingAssessment}
                    assessmentRemarks={assessmentRemarks}
                    staffId={staff_id}
                    yearAssessment={yearAssessment}
                    setYearAssessment={setYearAssessment}
                    typeAssessment={typeAssessment}
                    setTypeAssessment={setTypeAssessment}
                  />
                )}
                {printSectionConfig.compareAssessment.checked && proposal_main_type === PDC_MOBILITY_PROPOSAL_TYPE.SINGLE && (
                  <PrintCompareAssessment
                    preRead={printSectionConfig.compareAssessment.preRead}
                    handleFetchComplete={handleFetchStepDataComplete}
                    proposal={proposal}
                    staffId={staff_id}
                    mobilityId={mobility_id}
                    yearAssessment={yearAssessment}
                    typeAssessment={typeAssessment}
                  />
                )}
                {printSectionConfig.aspirationAndRisks.checked && (
                  <PrintAspiration
                    preRead={printSectionConfig.aspirationAndRisks.preRead}
                    staff_id={staff_id}
                    loadingAspiration={loadingAspiration}
                    aspirations={aspirations}
                    handleFetchComplete={handleFetchStepDataComplete}
                  />
                )}
                {printSectionConfig.compareAspirationAndRisks.checked && proposal_main_type === PDC_MOBILITY_PROPOSAL_TYPE.SINGLE && (
                  <PrintCompareAspiration
                    preRead={printSectionConfig.compareAspirationAndRisks.preRead}
                    handleFetchComplete={handleFetchStepDataComplete}
                    staff_id={staff_id}
                    mobility_id={mobility_id}
                    mobility_proposal_id={mobility_proposal_id}
                  />
                )}
              </div>
            );
          })}
          <div className={styles.divider} />
        </>
      )}
      <PrintSupportingDocuments
        isPrint={printSectionConfig.supportingDocuments.checked}
        preRead={printSectionConfig.supportingDocuments.preRead}
        handleFetchComplete={handleFetchStepDataComplete}
        idMeeting={idMeeting}
        mobilityId={mobility_id}
      />
    </div>
  );
};

export default CandidatePrint;

CandidatePrint.propTypes = {
  mobilityInfo: PropTypes.object,
  index: PropTypes.number,
  idMeeting: PropTypes.string,
  printSectionConfig: PropTypes.object,
  handleFetchComplete: PropTypes.func,
  isDisplayCandidate: PropTypes.bool,
};
