import React, { useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';
import { pdc_mobility_step1, pdc_mobility_step2, pdc_mobility_step3, pdc_mobility_step4, pdc_mobility_step5 } from '../../../../assets/img';
import { isArray, size } from 'lodash';
import { PDC_PROPOSAL_TYPES } from '../../../../utils/constants';
import { getPdcMobilityProposalType } from '../../../../utils/helper';
import { font } from '../../../../assets/common';

const tabs = [
  {
    id: 0,
    label: 'Mobility',
    width: '130px',
    left: 0,
    page: 'mobility',
    imgSrc: pdc_mobility_step1,
  },
  {
    id: 1,
    label: 'Proposal',
    width: '120px',
    left: '130px',
    page: ['talent-proposal', 'proposal', 'position-proposal'],
    imgSrc: pdc_mobility_step2,
  },
  {
    id: 2,
    label: 'Assessment',
    width: '160px',
    left: '250px',
    page: 'assessment',
    imgSrc: pdc_mobility_step3,
  },
  {
    id: 3,
    label: 'Aspirations & Risks',
    width: '200px',
    left: '410px',
    page: 'aspiration-risks',
    imgSrc: pdc_mobility_step4,
  },
  {
    id: 4,
    label: 'Supporting Documents',
    width: '250px',
    left: '610px',
    page: 'supporting-documents',
    imgSrc: pdc_mobility_step5,
  },
];

const Navbar = styled.div`
  display: flex;
  margin-top: 24px;
  justify-content: start;
  align-items: center;
  width: 100%;
  font-family: ${font.inter};
`;
const TabItem = styled.div`
  padding: 16px 24px;
  min-width: 106px;
  width: fit-content;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.0168em;
  text-align: center;
  cursor: pointer;
  color: ${(props) => (props?.active ? '#00615E' : '#15191E')};
  white-space: nowrap;
  transition: color 0.3s;
  img {
    filter: ${(props) =>
      props?.active
        ? 'brightness(0) saturate(100%) invert(29%) sepia(34%) saturate(1265%) hue-rotate(131deg) brightness(94%) contrast(100%)'
        : 'brightness(0) saturate(100%) invert(25%) sepia(2%) saturate(4780%) hue-rotate(171deg) brightness(102%) contrast(94%)'};
  }
`;
const BreakLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #5d7083;
`;
const Tabs = styled.div`
  position: relative;
  margin-bottom: 30px;
`;
const Transition = styled.div`
  position: absolute;
  width: ${(props) => props?.width};
  left: ${(props) => props?.left};
  height: 2px;
  background-color: #00a19c;
  transition: width 0.3s, left 0.3s, right 0.3s;
  transform: translateY(-50%);
`;

const MobilityFinalizeBar = (props) => {
  const { mobilityDetails } = props;
  const { idMeeting, mobilityId } = useParams();

  const location = useLocation();
  const history = useHistory();
  const currentPage = location.pathname.split('/') && location.pathname.split('/').length > 2 ? location.pathname.split('/').at(-2) : 'mobility';
  const pageSelected = tabs.find((f) => f.page.includes(currentPage));

  const getProposalAsMainType = useMemo(() => {
    return getPdcMobilityProposalType(mobilityDetails);
  }, [mobilityDetails]);

  const proposalMainId = useMemo(() => {
    return mobilityDetails?.main_proposal_id || null;
  }, [mobilityDetails]);

  const getProposalListUrl = useMemo(() => {
    if (!proposalMainId) return;
    const isLength = size(mobilityDetails.proposals.find((i) => i?.is_main)?.cards);
    if (getProposalAsMainType === PDC_PROPOSAL_TYPES.MULTIPLE_POSITION && isLength > 1) {
      return `/pdc-meeting/${idMeeting}/mobility/${mobilityDetails?.id}/position-proposal/${proposalMainId}`;
    } else if (getProposalAsMainType === PDC_PROPOSAL_TYPES.MULTIPLE_TALENT && isLength > 1) {
      return `/pdc-meeting/${idMeeting}/mobility/${mobilityDetails?.id}/talent-proposal/${proposalMainId}`;
    } else {
      return `/pdc-meeting/${idMeeting}/mobility/${mobilityDetails?.id}/proposal/${proposalMainId}`;
    }
  }, [mobilityDetails]);

  return (
    <Tabs>
      <Navbar>
        {tabs.map((m) => (
          <TabItem
            active={m?.page.toString() === pageSelected?.page?.toString() ? true : false}
            key={'position-tab' + m.id}
            id={m.id}
            onClick={() => {
              m?.page === 'mobility'
                ? history.push(`/pdc-meeting/${idMeeting}/mobility/${mobilityId}`)
                : isArray(m.page)
                ? history.push(getProposalListUrl)
                : history.push(`/pdc-meeting/${idMeeting}/mobility/${mobilityId}/${m?.page}/${proposalMainId}`);
            }}
          >
            <img src={m.imgSrc} alt={m.imgSrc} /> {m.label}
          </TabItem>
        ))}
      </Navbar>
      <BreakLine />
      <Transition width={pageSelected?.width} left={pageSelected?.left} />
    </Tabs>
  );
};

export default MobilityFinalizeBar;
