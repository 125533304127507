import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import * as styles from './pdc-mobility-process-bar.module.scss';
import { checked, pdc_mobility_step1, pdc_mobility_step2, pdc_mobility_step3, pdc_mobility_step4, pdc_mobility_step5 } from '../../../../assets/img';
import { useParams, useHistory } from 'react-router-dom';
import { PDC_PROPOSAL_TYPES } from '../../../../utils/constants';
import { getPdcMobilityProposalType } from '../../../../utils/helper';
import { isArray, size } from 'lodash';

const STEPS = [
  {
    no: 1,
    text: 'Mobility',
    imgSrc: pdc_mobility_step1,
  },
  {
    no: 2,
    text: 'Proposal',
    imgSrc: pdc_mobility_step2,
  },
  {
    no: 3,
    text: 'Assessment',
    imgSrc: pdc_mobility_step3,
  },
  {
    no: 4,
    text: 'Aspirations & Risks',
    imgSrc: pdc_mobility_step4,
  },
  {
    no: 5,
    text: 'Supporting Documents',
    imgSrc: pdc_mobility_step5,
  },
];

const PdcMobilityProcessBar = (props) => {
  const { currentStep, mobilityDetails, isEnableMobilityTab } = props;
  const { idMeeting, mobilityId } = useParams();
  const history = useHistory();

  const proposalMainStep = useMemo(() => {
    return isArray(mobilityDetails?.proposals) ? mobilityDetails?.proposals.filter((i) => i?.is_main)[0] : { step: 1 };
  }, [mobilityDetails]);

  const getProposalAsMainType = useMemo(() => {
    return getPdcMobilityProposalType(mobilityDetails);
  }, [mobilityDetails]);

  const proposalMainId = useMemo(() => {
    return mobilityDetails?.main_proposal_id || null;
  }, [mobilityDetails]);

  const getProposalListUrl = useMemo(() => {
    if (!proposalMainId) return;
    const isLength = size(mobilityDetails.proposals.find((i) => i?.is_main)?.cards);
    if (getProposalAsMainType === PDC_PROPOSAL_TYPES.MULTIPLE_POSITION && isLength > 1) {
      return `/pdc-meeting/${idMeeting}/mobility/${mobilityDetails?.id}/position-proposal/${proposalMainId}`;
    } else if (getProposalAsMainType === PDC_PROPOSAL_TYPES.MULTIPLE_TALENT && isLength > 1) {
      return `/pdc-meeting/${idMeeting}/mobility/${mobilityDetails?.id}/talent-proposal/${proposalMainId}`;
    } else {
      return `/pdc-meeting/${idMeeting}/mobility/${mobilityDetails?.id}/proposal/${proposalMainId}`;
    }
  }, [mobilityDetails]);

  const handleClickMobilityStep = (stepNumber) => {
    if ((Number(proposalMainStep?.step) < stepNumber && !isEnableMobilityTab) || !proposalMainId) return;

    switch (stepNumber) {
      case 1:
        history.push(`/pdc-meeting/${idMeeting}/mobility/${mobilityId}`);
        break;
      case 2:
        history.push(getProposalListUrl);
        break;
      case 3:
        history.push(`/pdc-meeting/${idMeeting}/mobility/${mobilityId}/assessment/${proposalMainId}`);
        break;
      case 4:
        history.push(`/pdc-meeting/${idMeeting}/mobility/${mobilityId}/aspiration-risks/${proposalMainId}`);
        break;
      case 5:
        history.push(`/pdc-meeting/${idMeeting}/mobility/${mobilityId}/supporting-documents/${proposalMainId}`);
        break;

      default:
        break;
    }
  };

  return (
    <div className={styles.wrapper}>
      {STEPS.map((step) => (
        <div
          key={step.no}
          className={styles.step}
          style={{ cursor: Number(proposalMainStep?.step) < step.no && !isEnableMobilityTab ? 'no-drop' : 'pointer' }}
          onClick={() => handleClickMobilityStep(step.no)}
        >
          <div
            className={styles.topBar}
            style={
              (currentStep > Number(proposalMainStep?.step) ? currentStep : Number(proposalMainStep?.step)) >= step.no
                ? { borderColor: '#00645C' }
                : null
            }
          />
          <div className={styles.info}>
            <div className={styles.name}>
              <img
                src={step.imgSrc}
                alt={`step-${step.no}`}
                style={
                  (currentStep > Number(proposalMainStep?.step) ? currentStep : Number(proposalMainStep?.step)) >= step.no
                    ? { filter: `invert(52%) sepia(61%) saturate(2318%) hue-rotate(140deg) brightness(75%) contrast(101%)` }
                    : null
                }
              />
              <span>{step.text}</span>
            </div>
            {Number(proposalMainStep?.step) >= step.no && <img src={checked} alt="checked" />}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PdcMobilityProcessBar;

PdcMobilityProcessBar.propTypes = {
  currentStep: PropTypes.number,
};
