import { color } from '../../../assets/common';
import { rootStyles } from '../../../utils/stylesHelper';
import styled from 'styled-components';

export const theme = (themeOption) => ({
  ...themeOption,
  borderRadius: '4px',
  colors: {
    ...themeOption.colors,
    primary: '#00a19c',
  },
});
export const stylesDisabled = {
  ...rootStyles,
  control: (provided, _state) => ({
    ...provided,
    minHeight: 44,
    border: `1px solid ${color.disable}`,
    backgroundColor: color.disable,
    '&:hover': {
      borderColor: `${color.disable}`,
      boxShadow: `0 0 0 1px ${color.disable}`,
    },
  }),
  multiValue: (provided, _state) => {
    return {
      ...provided,
      flexDirection: 'row-reverse',
      background: `${color.disable}`,
      border: '1px solid #BDBDBD',
      borderRadius: '11px',
      paddingRight: '10px',
    };
  },
};
export const stylesDisabledForMobility = {
  ...rootStyles,
  control: (provided, _state) => ({
    ...provided,
    minHeight: 44,
    border: `1px solid #cbd6e2`,
    backgroundColor: '#f0f5fa',
    cursor: 'no-drop',
    '&:hover': {
      borderColor: `#cbd6e2`,
      boxShadow: `0 0 0 1px #cbd6e2`,
    },
  }),
  multiValue: (provided, _state) => {
    return {
      ...provided,
      flexDirection: 'row-reverse',
      background: '#fff',
      border: '1px solid #BDBDBD',
      borderRadius: '11px',
      paddingRight: '10px',
      cursor: 'no-drop',
    };
  },
};
export const stylesDanger = {
  ...rootStyles,
  control: (provided, _state) => ({
    ...provided,
    minHeight: 44,
    border: `1px solid #ff4d4f`,
    '&:hover': {
      boxShadow: `0 0 0 2px rgb(0 161 156 / 20%);`,
    },
  }),
};

export const stylesNormal = {
  ...rootStyles,
  control: (provided, _state) => ({
    ...provided,
    minHeight: 44,
    border: `1px solid ${color.geyser}`,
    '&:hover': {
      borderColor: `${color.teal}`,
      boxShadow: `0 0 0 2px rgb(0 161 156 / 20%);`,
    },
  }),
};

export const SearchAsyncWrap = styled.div`
  position: relative;

  .down_icon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
  }
`;
