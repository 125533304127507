import { notification } from 'antd';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { NotificationDetail, Task } from '..';
import { nofication } from '../../assets/img/';
import { useComponentVisible } from '../../hooks';
import { meetingApi } from '../../services/tcMeeting';
import { MEETING_DETAIL_STATUS, NOTIFICATION_MEETING_TYPE } from '../../utils/constants';
import styles from './notification.module.scss';

const Notification = () => {
  const [tabSelected, setTabSelected] = useState('Notification Detail');
  const [totalNotifications, setTotalNotifications] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [temporaryCount, setTemporaryCount] = useState(0);
  const [tasks, setTasks] = useState({ data: [], total: 0, page: 1, limit: 10, loading: false, hasMore: true });
  const history = useHistory();
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const { roleActive } = useSelector((state) => state.user);

  const countNotifications = async () => {
    try {
      setTotalNotifications(0);
      const response = await meetingApi.countNotifications(roleActive);
      const rs = get(response, 'data.result.total_unseen');
      setTotalNotifications(rs || 0);
      setTemporaryCount(rs || 0);
    } catch (error) {
      setTotalNotifications(0);
    }
  };

  const getNotifications = async () => {
    try {
      setNotifications([]);
      const response = await meetingApi.getNotifications(roleActive);
      const rs = get(response, 'data.result.notifications');
      setNotifications(rs || []);
      cleanNotification();
    } catch (error) {
      setNotifications([]);
      console.log(error);
    }
  };

  const getTasks = async () => {
    try {
      setTasks((prev) => ({ ...prev, loading: true }));
      const response = await meetingApi.getTasks({ page: tasks.page, limit: tasks.limit, roleId: roleActive.roleId });
      const result = get(response, 'data.result.data');
      if (isEmpty(result)) return setTasks((prev) => ({ ...prev, hasMore: false }));
      if (tasks.page > 1) {
        setTasks((prev) => ({ ...prev, data: [...prev.data, ...result], hasMore: true }));
      } else {
        setTasks((prev) => ({ ...prev, data: !isEmpty(result) ? result : [], hasMore: true }));
      }
    } catch (error) {
      console.log(error);
      setTasks({ data: [], total: 0, page: 1, limit: 10, loading: false, hasMore: true });
    } finally {
      setTasks((prev) => ({ ...prev, loading: false }));
    }
  };

  const cleanNotification = async () => {
    try {
      await meetingApi.cleanTotalNotifications();
      setTotalNotifications(0);
    } catch (error) {
      notification.error({
        message: `Clean notification fail`,
        placement: 'topRight',
        duration: 1.5,
      });
    }
  };

  const viewNotification = async (notificationItem) => {
    try {
      if (!notificationItem || notificationItem.id === undefined) return;

      await meetingApi.updateSeenNotification(notificationItem.id);
      setIsComponentVisible(false);
      if (notificationItem.notification_type === NOTIFICATION_MEETING_TYPE.TALENT_FINDER_EXPORT) {
        window.open(notificationItem.description, '_blank');
      } else {
        if (notificationItem?.type !== 'PDC') {
          const path =
            notificationItem.notification_type === NOTIFICATION_MEETING_TYPE.SUCCESSION_PLANNING_SYNDICATION
              ? `meeting/${notificationItem.meeting_id}/agenda`
              : 'meeting/detail';
          history.push(`/${path}/${notificationItem.ref_id}`);
        }
        if (notificationItem?.type === 'PDC') {
          history.push(`/pdc-meeting/${notificationItem.meeting_id}`);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const viewTask = (task) => {
    try {
      if (!task || task.meeting_id === undefined) return;
      setIsComponentVisible(false);
      let path;
      if (task.on_step === 1) {
        path = task?.type === 'PDC' ?  `/pdc-meeting/${task.meeting_pdc_id}` : `/meeting/${task.meeting_id}`;
      }
      if (task.on_step === 2) {
        path = task?.type === 'PDC' ?  `/pdc-meeting/${task.meeting_pdc_id}/mobilities` : `/meeting/${task.meeting_id}/agendas`;
      }
      if (task.status === MEETING_DETAIL_STATUS.IN_REVIEW) {
        path =  task?.type === 'PDC' ?  `/pdc-meeting/${task.meeting_pdc_id}/in-review` :  `/meeting/detail/${task.meeting_id}`;
      }
      history.push(path);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    countNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isComponentVisible) {
      getNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isComponentVisible]);

  useEffect(() => {
    if (isComponentVisible && tasks.page && tabSelected !== 'Notification Detail') {
      getTasks();
    }
    if (tabSelected === 'Notification Detail') setTasks({ data: [], total: 0, page: 1, limit: 10, loading: false, hasMore: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isComponentVisible, tasks.page, tabSelected]);

  useEffect(() => {
    if (!isComponentVisible) {
      setTemporaryCount(0);
      setTabSelected('Notification Detail');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isComponentVisible]);

  return (
    <div className={styles.container} ref={ref}>
      <div className={styles.nofication} onKeyDown={() => {}} onClick={() => setIsComponentVisible(!isComponentVisible)} data-testid="icon">
        <img src={nofication} alt="nofication" />
        {totalNotifications !== 0 && <div className={styles.nofication_number}>{totalNotifications}</div>}
      </div>
      {isComponentVisible && (
        <div className={styles.detail}>
          <div className="d-flex justify-content-start align-items-center" style={{ gap: '10px', marginBottom: '20px' }}>
            <div
              className={'Notification Detail' === tabSelected ? styles.active : null}
              style={{ padding: '10px 15px', cursor: 'pointer' }}
              onKeyDown={() => {}}
              onClick={() => setTabSelected('Notification Detail')}
            >
              Notification
              {temporaryCount !== 0 && (
                <span
                  style={{
                    padding: '6px',
                    color: '#fff',
                    backgroundColor: '#00847C',
                    borderRadius: '50%',
                    marginLeft: '10px',
                  }}
                >
                  {temporaryCount}
                </span>
              )}
            </div>
            <div
              className={'Task' === tabSelected ? styles.active : null}
              style={{ padding: '5px 10px', cursor: 'pointer' }}
              onKeyDown={() => {}}
              onClick={() => setTabSelected('Task')}
            >
              Task
            </div>
          </div>
          {tabSelected === 'Notification Detail' ? (
            <NotificationDetail notifications={notifications} viewNotification={viewNotification} />
          ) : (
            <Task tasks={tasks} viewTask={viewTask} setTasks={setTasks} />
          )}
        </div>
      )}
    </div>
  );
};

export default withRouter(Notification);
