import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './mobility-button-controls.module.scss';
import { BasicButton } from '../../../../assets/common';
import { ModelTC } from '../../../../components';
import { AGENDA_STATUS, MEETING_DETAIL_STATUS, MESSAGE_TYPES } from '../../../../utils/constants';
import { pdcMeetingApi } from '../../../../services/pdcMeetingApi';
import { useDispatch, useSelector } from 'react-redux';
import { pushMessage } from '../../../../store/alertMessageSlice';

const MobilityBotButtonControls = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { roleId } = useSelector((state) => state.user.roleActive);
  const {
    idMeeting,
    mobilityId,
    isDisabled,
    handleSaveAsDraft,
    mobilityDetails,
    fetchMobility,
    handleClickNext,
    handleClickBack,
    isOnlyViewAgenda,
    setIsBack,
    isHRPartners,
    isSupportDocument,
    isAdditionalViewAccess,
    isChairMainOrCommiteeOrAdvocator,
    meetingDetail,
    getMainProposal,
  } = props;
  const [visibleChangeStatusModal, setVisibleChangeStatusModal] = useState({ paper_ready: false, paper_finalize: false });

  const handleClickExit = () => {
    if (setIsBack) {
      setIsBack(false);
    }
    if (meetingDetail.status === MEETING_DETAIL_STATUS.IN_PLANNING) return history.push(`/pdc-meeting/${idMeeting}/mobilities`);
    if (meetingDetail.status === MEETING_DETAIL_STATUS.IN_REVIEW) return history.push(`/pdc-meeting/${idMeeting}/in-review`);
    if (meetingDetail.status === MEETING_DETAIL_STATUS.CONCLUDED) return history.push(`/pdc-meeting/${idMeeting}/concluded`);
    history.push(`/pdc-meeting/${idMeeting}`);
  };

  const handleChangeAgendaStatus = async (status) => {
    try {
      const payload = {
        meeting_id: idMeeting,
        mobility_id: mobilityId,
        status,
      };
      let res = await pdcMeetingApi.updateMobilityAgendaStatus(payload, roleId);
      if (res.status === 200) {
        if (status === AGENDA_STATUS.PAPER_READY) {
          dispatch(
            pushMessage({
              message: `${mobilityDetails?.title} is ready for the meeting.`,
              type: MESSAGE_TYPES.SUCCESS,
            })
          );
        }
        if (status === AGENDA_STATUS.PAPER_FINALIZED) {
          dispatch(
            pushMessage({
              message: `${mobilityDetails?.title} has been successfully finalized`,
              type: MESSAGE_TYPES.SUCCESS,
            })
          );
        }
        await fetchMobility();
        if (getMainProposal) getMainProposal();
      }
    } catch (error) {
      return console.error(error);
    }
  };

  if (isAdditionalViewAccess || isChairMainOrCommiteeOrAdvocator) return <></>;

  return (
    <>
      {!isOnlyViewAgenda && (
        <div className={styles.btnRow}>
          <BasicButton width="100px" onClick={handleClickExit}>
            Exit
          </BasicButton>
          {(!isHRPartners || (isHRPartners && isSupportDocument && ![MEETING_DETAIL_STATUS.CONCLUDED].includes(meetingDetail?.status))) &&
            ![MEETING_DETAIL_STATUS.IN_REVIEW].includes(meetingDetail?.status) && (
              <BasicButton onClick={handleSaveAsDraft} disabled={isDisabled}>
                Save as Draft
              </BasicButton>
            )}
          {handleClickBack && (
            <BasicButton width="100px" onClick={handleClickBack}>
              Back
            </BasicButton>
          )}
          {mobilityDetails?.status === AGENDA_STATUS.PAPER_IN_PROGRESS &&
            [MEETING_DETAIL_STATUS.IN_PLANNING, MEETING_DETAIL_STATUS.PUBLISHED, MEETING_DETAIL_STATUS.IN_PROGRESS].includes(meetingDetail?.status) &&
            mobilityId &&
            !isHRPartners && (
              <BasicButton width="120px" mode="teal" onClick={() => setVisibleChangeStatusModal({ paper_ready: true, paper_finalize: false })}>
                Paper Ready
              </BasicButton>
            )}
          {[AGENDA_STATUS.PAPER_READY].includes(mobilityDetails?.status) &&
            [MEETING_DETAIL_STATUS.IN_PROGRESS].includes(meetingDetail?.status) &&
            !isHRPartners && (
              <BasicButton width="100px" mode="teal" onClick={() => setVisibleChangeStatusModal({ paper_ready: false, paper_finalize: true })}>
                Finalize
              </BasicButton>
            )}
          {(!isHRPartners || (isHRPartners && isSupportDocument && ![MEETING_DETAIL_STATUS.CONCLUDED].includes(meetingDetail?.status))) &&
            [MEETING_DETAIL_STATUS.IN_REVIEW].includes(meetingDetail?.status) && (
              <BasicButton onClick={handleSaveAsDraft} disabled={isDisabled} mode="teal">
                Save
              </BasicButton>
            )}
          {handleClickNext && (
            <BasicButton width="100px" mode="teal" onClick={handleClickNext}>
              Next
            </BasicButton>
          )}
        </div>
      )}
      {isOnlyViewAgenda && (
        <div className={styles.btnRow}>
          <BasicButton width="100px" onClick={handleClickExit}>
            Exit
          </BasicButton>
          {handleClickBack && (
            <BasicButton width="100px" onClick={handleClickBack}>
              Back
            </BasicButton>
          )}
          {handleClickNext && (
            <BasicButton width="100px" mode="teal" onClick={handleClickNext}>
              Next
            </BasicButton>
          )}
        </div>
      )}

      {/* Modal */}
      {visibleChangeStatusModal.paper_ready && (
        <ModelTC
          info={{
            type: 'changeToPaperReadyMobilityStatus',
            visible: visibleChangeStatusModal.paper_ready,
            setVisible: (val) => setVisibleChangeStatusModal((prev) => ({ ...prev, paper_ready: val })),
            handleSubmit: async () => {
              handleChangeAgendaStatus(AGENDA_STATUS.PAPER_READY);
              setVisibleChangeStatusModal((prev) => ({ ...prev, paper_ready: false }));
            },
            onClose: () => setVisibleChangeStatusModal((prev) => ({ ...prev, paper_ready: false })),
          }}
        />
      )}
      {visibleChangeStatusModal.paper_finalize && (
        <ModelTC
          info={{
            type: 'paperFinalizePDCMeeting',
            visible: visibleChangeStatusModal.paper_finalize,
            setVisible: (val) => setVisibleChangeStatusModal((prev) => ({ ...prev, paper_finalize: val })),
            handleSubmit: async () => {
              handleChangeAgendaStatus(AGENDA_STATUS.PAPER_FINALIZED);
              setVisibleChangeStatusModal((prev) => ({ ...prev, paper_finalize: false }));
            },
            onClose: () => setVisibleChangeStatusModal((prev) => ({ ...prev, paper_finalize: false })),
          }}
        />
      )}
    </>
  );
};
export default MobilityBotButtonControls;
