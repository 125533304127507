import __, { isEmpty } from 'lodash';
import moment from 'moment';
import * as yup from 'yup';

import { DATE_TIME_FORMAT, MEETING_CATEGORY_VALUES, ROLE } from '../../../utils/constants';

const getRoleName = (secretariat) =>
  secretariat?.transientValue?.roleName ||
  secretariat?.role ||
  secretariat?.name ||
  secretariat?.label_name;

const haveHrCoeRole = (meeting_secretariats) =>
  __(meeting_secretariats)
    .map(getRoleName)
    .some((secretariat) => secretariat === ROLE.HR_COE);

const validateSecretariatWithCategory = (meeting_secretariats) => {
  if (isEmpty(meeting_secretariats)) {
    return false;
  }
  const [secretarat] = meeting_secretariats;
  return ![
    MEETING_CATEGORY_VALUES.BUSINESS,
    MEETING_CATEGORY_VALUES.OPU,
    MEETING_CATEGORY_VALUES.DIVISION,
    MEETING_CATEGORY_VALUES.SECTOR,
    MEETING_CATEGORY_VALUES.SKG,
    MEETING_CATEGORY_VALUES.JOB_FAMILY,
  ].includes(secretarat?.value?.search_type);
};

const secretariaWithCategoryValidator = (category) => {
  return (meeting_secretariats) => {
    if (!category?.name) {
      return false;
    }
    switch (category.name) {
      case MEETING_CATEGORY_VALUES.PETRONAS:
        return haveHrCoeRole(meeting_secretariats);
      case MEETING_CATEGORY_VALUES.BUSINESS:
      case MEETING_CATEGORY_VALUES.OPU:
      case MEETING_CATEGORY_VALUES.DIVISION:
      case MEETING_CATEGORY_VALUES.SECTOR:
      case MEETING_CATEGORY_VALUES.SKG:
      case MEETING_CATEGORY_VALUES.JOB_FAMILY:
        return validateSecretariatWithCategory(meeting_secretariats);
      default:
        return false;
    }
  };
};
const handleRequiredSecretariats = (categoryName) => {
  if (categoryName?.name === MEETING_CATEGORY_VALUES.PETRONAS) {
    return `HR COE is required`;
  }
  if (
    [
      MEETING_CATEGORY_VALUES.BUSINESS,
      MEETING_CATEGORY_VALUES.OPU,
      MEETING_CATEGORY_VALUES.DIVISION,
      MEETING_CATEGORY_VALUES.SECTOR,
      MEETING_CATEGORY_VALUES.SKG,
      MEETING_CATEGORY_VALUES.JOB_FAMILY,
    ].includes(categoryName?.name)
  ) {
    return `Please select Meeting Secretariat of the same ${categoryName?.name}`;
  }
  return `Please select the meeting category`;
};

const validationSchema = yup.object({
  meeting_name: yup.string().required('Meeting Name is required.'),
  category: yup
    .object()
    .shape({
      id: yup.string().required('Meeting Category is required.'),
      name: yup.string().required('Meeting Category required.'),
    })
    .required('Category is required.'),
  category_value: yup
    .object()
    .nullable()
    .when('category.name', (name, schema) => {
      if (name === 'Business') {
        return schema.required('Business is required');
      } else if (name === 'OPU') {
        return schema.required('OPU is required');
      } else if (name === 'Division') {
        return schema.required('Division is required');
      } else if (name === 'Sector') {
        return schema.required('Sector is required');
      } else if (name === 'SKG') {
        return schema.required('SKG is required');
      } else if (name === 'Job Family') {
        return schema.required('Job Family is required');
      } else {
        return schema;
      }
    }),
  start_time: yup
    .string()
    .required('Start Time is required.')
    .test('is-greater', 'Start Time must be less than or equal to End Time', function (value) {
      const { end_time } = this.parent;
      const startTimeMoment = moment(value, DATE_TIME_FORMAT.TIME);
      const endTimeMoment = moment(end_time, DATE_TIME_FORMAT.TIME);
      if (isEmpty(end_time) || end_time === value) return true;
      return endTimeMoment.isAfter(startTimeMoment);
    }),
  end_time: yup.string().required('End Time is required.'),
  location: yup.string().required('Location is required.'),
  date: yup.string().required('Date is required.'),
  chairman: yup.array().min(1, 'Chairman is required.').max(1, 'Chairman must be only one person.'),
  meeting_committee: yup.array().min(1, 'Committee Members is required.'),
  meeting_facilitator: yup.array().min(1, 'Meeting Facilitators is required.'),
  meeting_secretariats: yup
    .array()
    .min(1, 'Meeting Secretariat is required.')
    .when('category', (category, schema) => {
      return schema.test(
        'withCategory',
        handleRequiredSecretariats(category),
        secretariaWithCategoryValidator(category)
      );
    }),
  // meeting_additional_view_accesses: yup.array().min(1, 'Additional View Access is required.').required('Additional View Access is required.'),
});

export { validationSchema };
