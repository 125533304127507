import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import * as styles from './assessment-compare-leadership-modal.module.scss';
import CustomAntModal from '../../../../components/CustomAntModal/CustomAntModal';
import AssessmentLeadershipFilter from '../AssessmentLeadershipFilter/AssessmentLeadershipFilter';
import { pdcMeetingApi } from '../../../../services/pdcMeetingApi';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';
import { PDC_LEADERSHIP_DOT_COLOR, PDC_LEADERSHIP_ROW_NAME } from '../../constants';
import { avatar } from '../../../../assets/img';
import { cloneDeep, isEmpty } from 'lodash';
import { getNoOf2NestedArr } from '../../../../utils/helper';
import { useParams } from 'react-router-dom/cjs/react-router-dom';

const STYLE_MODAL = {
  containerPaddingLeftRight: 20,
  containerPaddingTopBottom: 20,
  titleFontWeight: 600,
  titleColor: '#787587',
};
const EFFECTIVE_LEVEL_COLOR = ['#FFEFD0', '#D5DFFF', '#FDDAFF', '#EEFABC'];
const VP_LEVEL = 'VP & Above';
const CELL_WIDTH = 48;
const CELL_HEIGHT = 48;
const STYLE_CELL = { height: CELL_HEIGHT, width: CELL_WIDTH };
const DIFFERENT_BETWEEN_LADDER = 3;
const MAX_PROFICIENCY = 5;
const MAX_LADDER = 4;
const REGEX = /\((P\d+)\)/;

const AssessmentCompareLeadershipModal = (props) => {
  const { open, setOpen, isOnlyViewAgenda, staffId, positionCode } = props;
  const [type, setType] = useState('All');
  const [year, setYear] = useState('All');
  const [prevParams, setPrevParams] = useState({ type: '', year: '', staffId: '', positionCode: '' });
  const [rawData, setRawData] = useState([]);
  const [loading, setLoading] = useState(false);
  const roleId = useSelector((state) => state.user.roleActive.roleId);
  const { mobilityId } = useParams();

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await pdcMeetingApi.getCompareAssessmentEdgeChart(mobilityId, type, year, roleId, staffId, positionCode);
      if (res.status === 200) {
        setRawData(res.data.result);
        setPrevParams({ type, year, staffId, positionCode });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [roleId, type, year, staffId, positionCode]);

  useEffect(() => {
    if (
      !open ||
      (prevParams.type === type && prevParams.year === year && prevParams.positionCode == positionCode && prevParams.staffId == prevParams.staffId)
    )
      return;
    fetchData();
  }, [fetchData, open, staffId, positionCode]);

  const displayData = useMemo(() => {
    if (isEmpty(rawData)) return { data: [], effectiveLevels: [] };
    return sortAndMapData(rawData);
  }, [rawData]);

  const renderedLadderAndValue = useMemo(() => {
    const { data, effectiveLevels } = displayData;
    const ladderRows = Array.from({ length: effectiveLevels.length }, (_, rowIndex) => {
      const totalNumbers = MAX_PROFICIENCY + rowIndex * DIFFERENT_BETWEEN_LADDER;
      return Array.from({ length: totalNumbers }, (_, i) => i + 1);
    });

    const styleBorderLastLader = (num) => {
      const noBottomBorderNum = [7, 8];
      const noBottomBorderRightNum = 6;
      if (noBottomBorderNum.includes(num)) return { boxShadow: '0 -1px 0 #cbd6e2' };
      if (num === noBottomBorderRightNum) return { boxShadow: '-1px -1px 0 #cbd6e2' };
      return {};
    };

    const revertOrderIndex = (length, index) => {
      return length - 1 - index;
    };

    return (
      <>
        <div className={styles.ladders}>
          {ladderRows.map((row, rowIndex) => (
            <div key={effectiveLevels[rowIndex]} className={styles.row}>
              {row.map((num, index) => (
                <div
                  key={num}
                  className={`${styles.cell} ${num > MAX_PROFICIENCY ? styles.hide : ''}`}
                  style={{ ...STYLE_CELL, ...styleBorderLastLader(num) }}
                >
                  {num <= MAX_PROFICIENCY ? num : ''}
                  {index === 0 && <div className={styles.nameLevel}>{effectiveLevels[revertOrderIndex(ladderRows.length, rowIndex)]}</div>}
                </div>
              ))}
            </div>
          ))}
        </div>

        <div className={styles.values}>
          {data.map((talent) => (
            <div key={talent.staffId}>
              {talent.edges.map((edge, index) => (
                <div key={index} className={styles.row}>
                  {edge.displayCol.map((col, idx) => (
                    <div key={idx} className={styles.cell} style={{ ...STYLE_CELL, backgroundColor: col.backgroundColor }}>
                      {col.isSMA && <span className={styles.sma} />}
                      {col.isExternal && <span className={styles.external} />}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          ))}
        </div>
      </>
    );
  }, [displayData]);

  const widthAntModal = useMemo(() => {
    const totalLevel = displayData.effectiveLevels.length;
    if (totalLevel <= MAX_LADDER) return 1230;
    return 1230 + CELL_WIDTH * DIFFERENT_BETWEEN_LADDER * (totalLevel - MAX_LADDER);
  }, [displayData.effectiveLevels.length]);

  return (
    <CustomAntModal
      hideDefaultBtn
      styles={STYLE_MODAL}
      title={`Leadership EDGE Results (SMA vs External Assessments)`}
      open={open}
      setOpen={setOpen}
      width={widthAntModal}
      destroyOnClose
    >
      <Spin spinning={loading}>
        <div className={styles.filter}>
          <AssessmentLeadershipFilter type={type} setType={setType} year={year} setYear={setYear} isDisabled={isOnlyViewAgenda} />
        </div>
        <div className={styles.desc}>
          {type === 'All' ? (
            <>
              {Object.keys(PDC_LEADERSHIP_DOT_COLOR).map((showType) => (
                <div className={styles.name} key={showType}>
                  <div className={styles.dot} style={{ background: PDC_LEADERSHIP_DOT_COLOR[showType].color }} />
                  <div>{PDC_LEADERSHIP_DOT_COLOR[showType].text}</div>
                </div>
              ))}
            </>
          ) : (
            <div className={styles.name}>
              <div className={styles.dot} style={{ background: PDC_LEADERSHIP_DOT_COLOR[type.toLowerCase()].color }} />
              <div>{PDC_LEADERSHIP_DOT_COLOR[type.toLowerCase()].text}</div>
            </div>
          )}
          {displayData.effectiveLevels.map((level, index) => (
            <div className={styles.name} key={level}>
              <span className={styles.effective} style={{ background: EFFECTIVE_LEVEL_COLOR[index] ?? '#EEFABC' }} />
              {level !== VP_LEVEL ? `Effective Level ${level || ''}` : VP_LEVEL}
            </div>
          ))}
        </div>
        <div className={styles.table}>
          <div className={styles.descColumn} style={{ paddingTop: `${displayData.effectiveLevels.length * CELL_HEIGHT}px` }}>
            {displayData.data.map((info) => (
              <div key={info.staffId}>
                <div className={styles.talentInfo}>
                  <img src={info.image || avatar} alt="avatar" />
                  <div className={styles.name}>
                    <div>{info.staffName}</div>
                    <div>{info.staffId}</div>
                  </div>
                  <div className={styles.jg}>JG: {info.jg}</div>
                </div>

                <div className={styles.rowName}>
                  {PDC_LEADERSHIP_ROW_NAME.map((item, parentIndex) => (
                    <div key={item.name}>
                      <div className={styles.parent} style={{ background: item.color }}>
                        {item.name}
                      </div>

                      <div className={styles.childWrapper} style={{ height: CELL_HEIGHT * 2 }}>
                        {item.childNames.map((childName, childIndex) => (
                          <div className={styles.child} key={childName} style={{ height: CELL_HEIGHT }}>
                            <div className={styles.childName}>
                              <div className={styles.childNo} style={{ background: item.childColor }}>
                                {getNoOf2NestedArr(parentIndex, childIndex)}
                              </div>
                              <div className={styles.childTitle}>{childName}</div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div className={styles.dataColumn}>{renderedLadderAndValue}</div>
        </div>
      </Spin>
    </CustomAntModal>
  );
};

export default AssessmentCompareLeadershipModal;

AssessmentCompareLeadershipModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  isOnlyViewAgenda: PropTypes.bool,
  staffId: PropTypes.string,
  positionCode: PropTypes.string,
};

export function sortByRoleLevel(array, field) {
  const extractRoleLevel = (roleLevel) => {
    const match = roleLevel?.match(REGEX);
    return match ? match?.[1] : null;
  };

  return array.sort((a, b) => {
    if (a[field] === VP_LEVEL) return 1;
    if (b[field] === VP_LEVEL) return -1;
    const roleA = extractRoleLevel(a[field]);
    const roleB = extractRoleLevel(b[field]);

    return roleA - roleB;
  });
}

export function sortByEdge(edges) {
  const hierarchy = [
    'Energise: Interpersonal Effectiveness',
    'Energise: Foster Collaboration & Teamwork',
    'Decide: Analysis & Problem Solving',
    'Decide: Set Goals & Drive Directions',
    'Grow: Lead Change & Innovation',
    'Grow: Commitment to Learning & Development',
    'Execute: Deliver Performance',
    'Execute: Professionalism & Expertise',
  ];
  const hierarchyOrder = Object.fromEntries(hierarchy.map((item, index) => [item, index]));
  return edges.sort((a, b) => {
    const orderA = hierarchyOrder[a.edge] ?? Number.MAX_SAFE_INTEGER;
    const orderB = hierarchyOrder[b.edge] ?? Number.MAX_SAFE_INTEGER;
    return orderA - orderB;
  });
}

export function checkExternal(currentLevel, assessedLevel, valueLevels, index) {
  const valueLevel = valueLevels[index].roleLevel;
  const isSameLevel =
    currentLevel?.match(REGEX)[1] === assessedLevel?.match(REGEX)[1] && currentLevel?.match(REGEX)[1] === valueLevel?.match(REGEX)[1];
  const isSGMLevel = currentLevel.includes('SGM');
  const isDifferentOrSGM = currentLevel?.match(REGEX)[1] !== assessedLevel?.match(REGEX)[1] || isSGMLevel;
  const preValueLevel = valueLevels[index - 1]?.roleLevel;
  return (isSameLevel && !isSGMLevel) || (isDifferentOrSGM && preValueLevel === currentLevel);
}

export function sortAndMapData(rawData) {
  const sortedData = sortByRoleLevel(cloneDeep(rawData), 'currentRoleLevel');

  const sortedEffectiveLevel = sortByRoleLevel(cloneDeep(sortedData[0].edges[0].effectiveProficiency), 'roleLevel');
  const totalLevel = sortedEffectiveLevel.length;
  const maxCol = (totalLevel - 1) * DIFFERENT_BETWEEN_LADDER + MAX_PROFICIENCY;
  return {
    effectiveLevels: sortedEffectiveLevel.map((i) => i.roleLevel),
    data: sortedData.map((staff) => {
      const sortedEdges = sortByEdge(cloneDeep(staff.edges));
      return {
        ...staff,
        edges: sortedEdges.map((item) => {
          const col = Array.from({ length: maxCol }, () => ({}));
          const sortedEffectiveLevelValue = sortByRoleLevel(cloneDeep(item.effectiveProficiency), 'roleLevel');

          sortedEffectiveLevelValue.forEach((p, index) => {
            const colLadderIndex = index * DIFFERENT_BETWEEN_LADDER;
            const colEffectiveIndex = p.value ? Math.floor(p.value) - 1 + colLadderIndex : -1;
            const colSmaIndex = item?.proficiency?.sma ? Math.floor(item.proficiency.sma) - 1 + colLadderIndex : -1;
            const colExternalIndex = item?.proficiency?.external ? Math.floor(item.proficiency.external) - 1 + colLadderIndex : -1;

            if (colEffectiveIndex >= 0) {
              col[colEffectiveIndex].backgroundColor = EFFECTIVE_LEVEL_COLOR[index];
            }
            if (colSmaIndex >= 0) {
              col[colSmaIndex].isSMA = staff.currentRoleLevel?.match(REGEX)[1] === p.roleLevel?.match(REGEX)[1];
            }
            if (colExternalIndex >= 0) {
              col[colExternalIndex].isExternal = checkExternal(staff.currentRoleLevel, staff.assessedRoleLevel, sortedEffectiveLevel, index);
            }
          });
          return { displayCol: col };
        }),
      };
    }),
  };
}
