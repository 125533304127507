import { map } from 'lodash';
import React, { useCallback, useEffect, useRef } from 'react';
import AsyncSelect from 'react-select/async';

import { Avatar, Individual, Name, SearchItem } from '../../../Meeting/MeetingDetails/components/TalentNameField/styled';
import { usePDCTalentSearch } from './PDCTalentSearch';
import { decodeBase64ToImage } from '../../../../utils/helper';
import { makeSelectStyle } from '../../../Meeting/MeetingDetails/components/FieldSelect/styled';


const CustomOption = ({ data, innerProps, innerRef }) => {
  return (
    <SearchItem ref={innerRef} {...innerProps}>
      <Avatar>
        <img src={decodeBase64ToImage(data?.image)} alt="avatar" />
      </Avatar>
      <Individual>
        <Name>{data.birthName}</Name>
        <div>{data.opu}</div>
        <div>{data.sg}</div>
      </Individual>
    </SearchItem>
  );
};

// eslint-disable-next-line react/display-name
export default React.memo(({ field: { value, name }, form: { setFieldValue, getFieldMeta }, mobilityIdName, idMeeting, ...props }) => {

  const { loading, searchProfiles } = usePDCTalentSearch({
    mapData: (profiles) =>
      map(profiles, ({ birthName, birth_name, opu, sg, image, staffId }) => ({
        label: birthName || birth_name || '-',
        value: { birthName, birth_name, opu, sg, image, staffId },
      })),
  });

  const timerRef = useRef(null);
  const rejectRef = useRef(null);

  const cancelSearchProfilesRequest = useCallback(() => {
    // Clear previous timeout
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Cancel previous promise
    if (rejectRef.current) {
      rejectRef.current({ canceled: true });
    }
  }, []);

  const loadOptions = useCallback(
    (searchInput) => {
      return new Promise((resolve, reject) => {
        cancelSearchProfilesRequest();

        rejectRef.current = reject;
        timerRef.current = setTimeout(() => {
          searchProfiles(searchInput, idMeeting)
            .then(resolve)
            .catch(reject)
            .finally(() => {
              rejectRef.current = null;
            });
        }, 500);
      });
    },
    [searchProfiles, cancelSearchProfilesRequest]
  );

// Reset options when changing agenda ID
//   useEffect(() => {
//     if (agendaId) {
//       loadOptions('', agendaId);
//     }
//   }, [agendaId, loadOptions]);

  // Cancel request When unmount component
  useEffect(() => {
    return () => {
      cancelSearchProfilesRequest();
    };
  }, [cancelSearchProfilesRequest]);

  const handleChange = useCallback(
    (option) => {
      if (option) {
        setFieldValue(name, { value: option?.staffId, label: option?.birthName });
        setFieldValue(mobilityIdName, { value: option?.mobility_id });
      } else {
        setFieldValue(name, null);
        setFieldValue(mobilityIdName, null);
      }
    },
    [setFieldValue, name, mobilityIdName]
  );
  const meta = getFieldMeta(name);

  return (
    <AsyncSelect
      {...props}
      isLoading={loading}
      isSearchable
      isClearable
      value={value}
      styles={makeSelectStyle({ meta })}
      menuPlacement="auto"
      maxMenuHeight={180}
      loadOptions={loadOptions}
      onChange={handleChange}
      components={{
        IndicatorSeparator: () => <></>,
        DropdownIndicator: () => <></>,
        Option: CustomOption,
      }}
      isDisabled={props?.disabled}
    />
  );
});
