import { Spin } from 'antd';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import { avatar } from '../../../assets/img';
import { BreadCrumb, ModelTC } from '../../../components';
import { useShowPopupSaveAgenda } from '../../../hooks/useShowPopupSaveAgenda';
import { pdcMeetingApi } from '../../../services/pdcMeetingApi';
import { NOTIFICATION_TYPE, PDC_PROPOSAL_TYPES, USER_ACCESS_MESSAGE } from '../../../utils/constants';
import { getBreadCrumbList } from '../../../utils/helper';
import { showNotification } from '../../../utils/notification';
import PdcMobilityProcessBar from '../components/PdcMobilityProcessBar/PdcMobilityProcessBar';
import * as styles from './pdc-proposal-position-list.module.scss';
// import styled from 'styled-components';
import { useSelector } from 'react-redux';
import GlobalAlertMessage from '../../../components/GlobalAlertMessage/GlobalAlertMessage';
import { useUpdatePdcMobilityStepNumber } from '../../../hooks/useUpdatePdcMobilityStepNumber';
import MobilityBotButtonControls from '../components/MobilityButtonControls/MobilityBotButtonControls';
import MobilityTopButtonControls from '../components/MobilityButtonControls/MobilityTopButtonControls';
import MobilityFinalizeBar from '../components/MobilityFinalizeBar/MobilityFinalizeBar';

// const CustomBtn = styled.button`
//   border: none;
//   background: transparent;
//   padding: 5px 7px;

//   img {
//     width: 24px;
//     height: 24px;
//   }

//   &.compare-position {
//     position: absolute;
//     top: 0;
//     right: -10px;
//   }
// `;

const BREAD_CRUMB = getBreadCrumbList(['Homepage', 'PDC Meeting', 'Mobility Agenda', 'Proposal']);

const PdcProposalPositionList = (props) => {
  const history = useHistory();
  const { idMeeting, mobilityId, proposalId } = useParams();
  const roleActive = useSelector((state) => state.user.roleActive);
  const [loading, setLoading] = useState(false);
  const [proposalList, setProposalList] = useState({
    positions: [],
    talents: [],
  });
  const [isBack, setIsBack] = useState(false);
  const [compareData, setCompareData] = useState({
    initData: [],
    currentData: [],
  });

  // Get the condition to edit mobility agenda
  const {
    isOnlyViewAgenda,
    isShowTopButtons,
    isShowBotButtons,
    isAdditionalViewAccess,
    meetingDetail,
    mobilityDetails,
    isEnableMobilityTab,
    proposalMainStep,
    fetchMobilityDetails,
    isShownMobilityTabBar,
    isHRPartners,
    isChairMainOrCommiteeOrAdvocator,
  } = props;
  const { updateStepNumber } = useUpdatePdcMobilityStepNumber();

  // Update current data to compare value
  useEffect(() => {
    setCompareData((prev) => ({
      ...prev,
      currentData: proposalList,
    }));
  }, [proposalList]);

  const isChangeProposalList = useMemo(() => {
    return JSON.stringify(compareData.initData) !== JSON.stringify(compareData.currentData);
  }, [compareData]);

  const { isShowPopupSaveAgenda, handleKeepNavigate, setIsShowPopupSaveAgenda } = useShowPopupSaveAgenda(isChangeProposalList && !isBack);

  const fetchMobility = useCallback(async () => {
    if (!mobilityId) return;
    try {
      setLoading(true);
      const res = await pdcMeetingApi.getProposalMultipleTalentOrPosition(mobilityId, PDC_PROPOSAL_TYPES.MULTIPLE_POSITION);
      if (res.status === 200) {
        setProposalList(res?.data?.result);
        setCompareData((prev) => ({
          ...prev,
          initData: res?.data?.result,
          currentData: res?.data?.result,
        }));
      }
    } catch (error) {
      console.error(error);
      showNotification(USER_ACCESS_MESSAGE.AN_UNEXPECTED_ERROR, NOTIFICATION_TYPE.FAILED);
    } finally {
      setLoading(false);
    }
  }, [mobilityId]);

  // Fetch mobility in the first time
  useEffect(() => {
    fetchMobility();
  }, [fetchMobility]);

  const handleClickBack = async () => {
    history.push(`/pdc-meeting/${idMeeting}/mobility/${mobilityId}`);
  };

  const handleSaveBeforeExit = async () => {
    setIsBack(true);
    setIsShowPopupSaveAgenda(false);
    await handleSaveAsDraft();
    handleKeepNavigate();
  };

  const handleClickNext = async () => {
    if (proposalMainStep?.step < 2) {
      await updateStepNumber(mobilityId, 2);
    }
    history.push(`/pdc-meeting/${idMeeting}/mobility/${mobilityId}/assessment/${proposalId}?mobilityType=positions`);
  };

  const onChangeJobPurpose = (event, record, jobIndex) => {
    const value = event?.currentTarget?.innerHTML || '';
    setCompareData((prev) => ({
      ...prev,
      currentData: {
        ...prev.currentData,
        positions: prev?.currentData?.positions.map((pos) => {
          if (pos?.position_code === record?.position_code) {
            return {
              ...pos,
              job_purpose: pos.job_purpose.map((job, indx) => {
                if (indx === jobIndex) {
                  return value;
                } else {
                  return job;
                }
              }),
            };
          } else {
            return pos;
          }
        }),
      },
    }));
  };

  const handleSaveAsDraft = async () => {
    if (!mobilityId || !proposalId) return;

    try {
      setLoading(true);
      const payload = {
        mobility_id: mobilityId,
        proposal_id: proposalId,
        positions: !isEmpty(compareData?.currentData?.positions)
          ? compareData?.currentData?.positions.map((p) => ({
              position_code: p?.position_code,
              staff_id: p?.staff_id,
              job_purpose: p?.job_purpose,
            }))
          : [],
        talents: !isEmpty(compareData?.currentData?.talents)
          ? compareData?.currentData?.talents.map((t) => ({
              position_code: t?.position_code,
              staff_id: t?.staff_id,
            }))
          : [],
      };
      const res = await pdcMeetingApi.updateProposalMultiplePosition(payload, roleActive?.roleId);
      if (res.status === 200) {
        if (proposalMainStep?.step < 2) {
          await updateStepNumber(mobilityId, 2);
        }
        fetchMobility();
      }
    } catch (error) {
      console.error(error);
      showNotification(USER_ACCESS_MESSAGE.AN_UNEXPECTED_ERROR, NOTIFICATION_TYPE.FAILED);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div style={{ marginBottom: '15px' }}>
        <BreadCrumb level={4} breadCrumbList={BREAD_CRUMB} />
      </div>
      <GlobalAlertMessage style={{ marginBottom: '20px' }} />
      {isShowTopButtons && (
        <MobilityTopButtonControls
          idMeeting={idMeeting}
          mobilityId={mobilityId}
          mobilityDetails={mobilityDetails}
          fetchMobility={fetchMobilityDetails}
          isAdditionalViewAccess={isAdditionalViewAccess}
          isHRPartners={isHRPartners}
          isChairMainOrCommiteeOrAdvocator={isChairMainOrCommiteeOrAdvocator}
          meetingDetail={meetingDetail}
        />
      )}
      {isShownMobilityTabBar ? (
        <MobilityFinalizeBar mobilityDetails={mobilityDetails} />
      ) : (
        <PdcMobilityProcessBar currentStep={2} mobilityDetails={mobilityDetails} isEnableMobilityTab={isEnableMobilityTab} />
      )}

      <Spin spinning={loading}>
        <div className={styles.proposalWrap}>
          <div className={styles.proposalPositionList}>
            {!isEmpty(compareData?.currentData?.talents) &&
              compareData?.currentData?.talents.map((t, index) => (
                <div key={`${t?.position_code}.${index}`} className={styles.proposalItem}>
                  <div className={`${styles.proposalItemContent}`}>
                    <div className={styles.infoLeft}>
                      <div className={styles.avatar}>
                        <img src={t?.image_url || avatar} alt="" />
                      </div>
                      <div className={styles.name} style={{ minWidth: '400px', maxWidth: '535px' }}>
                        {![t?.position_code, t?.birth_name].includes('VACANT') ? (
                          <Link to={`/profile/${t?.staff_id}`} target="_blank" className={styles.fieldNameLink}>
                            <div className={styles.fieldName}>
                              {t.birth_name || '-'} ({t?.top_talent_status || '-'}, {t?.age || '-'})
                            </div>
                          </Link>
                        ) : (
                          <div className={styles.fieldNameDiff}>
                            <span>{t.birth_name || '-'}</span>
                          </div>
                        )}
                        <div className={styles.fieldDesc}>
                          {t?.position_name}, {t?.business_unit}
                        </div>
                        <div className={styles.normalText}>
                          {t?.division || '-'} | {t?.opu_name || '-'}
                        </div>
                      </div>
                    </div>
                    <div className={styles.fieldItem}>
                      <span className={styles.greyLabel}>JG</span>
                      <span className={styles.boldValue}>{t?.job_grade || '-'}</span>
                    </div>
                    <div className={styles.fieldItem}>
                      <span className={styles.greyLabel}>SG</span>
                      <span className={styles.boldValue}>{t?.sg || '-'}</span>
                    </div>
                    <div className={styles.fieldItem}>
                      <span className={styles.greyLabel}>Retirement:</span>
                      <span className={styles.boldValue}>{t?.retirement_or_contract || '-'}</span>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className={styles.proposalList}>
            {!isEmpty(compareData?.currentData?.positions) &&
              compareData?.currentData?.positions.map((p, index) => (
                <div key={`${p?.staff_id}.${index}`} className={styles.proposalItem}>
                  <div className={styles.proposalItemHead}>
                    <h3>Position {index + 1}</h3>
                  </div>
                  <div className={`${styles.proposalItemContent}`}>
                    <div className={styles.contentItem}>
                      <div className={styles.fieldItem}>
                        <span className={styles.greyLabel}>Position</span>
                        <span className={styles.positionName}>{p?.position_name || '-'}</span>
                      </div>
                      <div className={styles.fieldItem}>
                        <span className={styles.normalText}>PID: </span>
                        <span className={styles.normalText}>{p?.position_code || '-'}</span>
                      </div>
                      <div className={styles.fieldItem}>
                        <span className={styles.normalText}>Current Incumbent: </span>
                        <span className={styles.normalText}>{p?.birth_name || '-'}</span>
                      </div>
                      {/* <CustomBtn className="compare-position">
                        <img src={Compare_2} alt="" onClick={() => {}} />
                      </CustomBtn> */}
                    </div>
                    <div className={styles.contentItem}>
                      <span className={styles.greyLabel} style={{ display: 'block', marginBottom: '5px' }}>
                        Job Purpose
                      </span>
                      <div
                        className={styles.purposeItem}
                        style={{
                          background: isOnlyViewAgenda || isHRPartners ? '#f5f5f5' : '#fff',
                          pointerEvents: isOnlyViewAgenda || isHRPartners ? 'none' : 'auto',
                        }}
                      >
                        {!isEmpty(p?.job_purpose) ? (
                          p?.job_purpose.map((i, jobIndex) => {
                            return (
                              <span
                                style={{ display: 'block', marginBottom: '5px', wordBreak: 'break-word' }}
                                key={`${p?.position_code}_${jobIndex}`}
                                contentEditable={!(isOnlyViewAgenda || isHRPartners)}
                                onBlur={(event) => {
                                  if (isOnlyViewAgenda || isHRPartners) return;
                                  onChangeJobPurpose(event, p, jobIndex);
                                }}
                                dangerouslySetInnerHTML={{ __html: i }}
                              ></span>
                            );
                          })
                        ) : (
                          <span
                            key={p?.position_code}
                            style={{
                              display: 'block',
                              marginBottom: '5px',
                              wordBreak: 'break-word',
                            }}
                            contentEditable={!(isOnlyViewAgenda || isHRPartners)}
                            onBlur={(event) => {
                              event.persist();
                              if (isOnlyViewAgenda || isHRPartners) return;
                              const tempVal = event?.currentTarget?.innerHTML;
                              setCompareData((prev) => ({
                                ...prev,
                                currentData: {
                                  ...prev.currentData,
                                  positions: prev?.currentData?.positions.map((pos) => {
                                    if (pos?.position_code === p?.position_code) {
                                      return {
                                        ...pos,
                                        job_purpose: [`${tempVal}` || ''],
                                      };
                                    } else {
                                      return pos;
                                    }
                                  }),
                                },
                              }));
                            }}
                            dangerouslySetInnerHTML={{ __html: '' }}
                          ></span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>

        {isShowBotButtons && (
          <MobilityBotButtonControls
            idMeeting={idMeeting}
            mobilityId={mobilityId}
            isDisabled={false}
            handleSaveAsDraft={handleSaveAsDraft}
            mobilityDetails={mobilityDetails}
            fetchMobility={fetchMobilityDetails}
            handleClickNext={handleClickNext}
            handleClickBack={handleClickBack}
            isOnlyViewAgenda={isOnlyViewAgenda}
            isHRPartners={isHRPartners}
            isAdditionalViewAccess={isAdditionalViewAccess}
            isChairMainOrCommiteeOrAdvocator={isChairMainOrCommiteeOrAdvocator}
            meetingDetail={meetingDetail}
          />
        )}
      </Spin>

      <ModelTC
        info={{
          type: 'withoutSaving',
          visible: isShowPopupSaveAgenda,
          setVisible: setIsShowPopupSaveAgenda,
          onClose: handleKeepNavigate,
          handleSubmit: handleSaveBeforeExit,
        }}
      />
    </>
  );
};

export default PdcProposalPositionList;
