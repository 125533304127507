import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { pdcMeetingApi } from '../services/pdcMeetingApi';

export const useGetPdcMobilityAgenda = ({ idMeeting, mobilityId }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const fetch = useCallback(async () => {
    if (!idMeeting || !mobilityId) return false;

    try {
      setLoading(true);
      const response = await pdcMeetingApi.getPdcMobilityDetails(idMeeting, mobilityId);
      setData(response.data.result);
    } catch (error) {
      setData(null);
      setError(error);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idMeeting, mobilityId]);

  useEffect(() => {
    if (isEmpty(idMeeting)) {
      return;
    }
    fetch();
  }, [idMeeting, fetch]);

  return { data, fetch, loading, error };
};
