import React from 'react';
import { isEmpty } from 'lodash';
import { proposal_no_setting } from '../../../../../assets/img';
import styled from 'styled-components';
import ProposalTemplateContent from './ProposalTemplateContent';

const ProposalWrapper = styled.div`
  margin-bottom: 20px;
  border-radius: 0px 0px 8px 8px;
  border-right: 1px solid var(--color-border-weak, #cbd6e2);
  border-bottom: 1px solid var(--color-border-weak, #cbd6e2);
  border-left: 1px solid var(--color-border-weak, #cbd6e2);

  .noContent {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
  }
`;

const PdcProposalDetail = ({ data, setProposalList, isOnlyViewAgenda, prevData }) => {
  return (
    <ProposalWrapper>
      {data?.proposal_type && !isEmpty(data?.proposal_settings) && !isEmpty(data?.proposal_item_list) ? (
        <ProposalTemplateContent data={data} setProposalList={setProposalList} isOnlyViewAgenda={isOnlyViewAgenda} prevData={prevData} />
      ) : (
        <div className="noContent">
          <img src={proposal_no_setting} alt="" />
        </div>
      )}
    </ProposalWrapper>
  );
};

export default PdcProposalDetail;
