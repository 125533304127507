import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import * as styles from './print-compare-successor.module.scss';
import PreReadLabel from '../PreReadLabel/PreReadLabel';
import styled from 'styled-components';
import { Spin } from 'antd';
import { isArray, isEmpty } from 'lodash';
import UserCareerTable from '../../../PdcProposalList/components/UserCareerTable/UserCareerTable';
import { pdcMeetingApi } from '../../../../../services/pdcMeetingApi';
import { avatar, dot_active_fulfilment } from '../../../../../assets/img';
import AutoResizeTextarea from '../../../../../components/AutoResizeTextArea/AutoResizeTextArea';
import { handleShowCTForProposal, formatTextStringJson } from '../../../../../utils/helper';

const PrintCompareSuccessor = ({ preRead, proposal, handleFetchComplete }) => {
  const [loading, setLoading] = useState(false);
  const [compareItem, setCompareItem] = useState([]);

  useEffect(() => {
    const fetchTalentDetails = async () => {
      try {
        const res = await pdcMeetingApi.getStaffDetails(proposal?.staff_id);
        if (res.status === 200) {
          const result = res?.data?.result;
          const talentDetails = isArray(result) ? result : [];
          const data = talentDetails.map((successor) => {
            if (successor?.staff_id === proposal?.staff_id) {
              return {
                ...successor,
                experiences_checked: successor?.experiences || [],
              };
            } else {
              return successor;
            }
          });

          setCompareItem(data);
        }
      } catch (e) {
        console.error(e);
      }
    };
    const handleCompareTalent = async () => {
      if (!isEmpty(proposal?.proposal_compare_successors)) {
        const data = proposal.proposal_compare_successors.map((i) => ({
          ...i,
          ...(i?.staffInfo || {}),
          experiences: i?.experiences || [],
        }));
        setCompareItem(data);
      } else {
        await fetchTalentDetails(proposal);
      }
      handleFetchComplete('Compare Successor');
    };

    if (isEmpty(proposal)) return;
    handleCompareTalent();
    setLoading(false);
  }, [proposal]);

  return (
    <Spin spinning={loading}>
      <div className={styles.wrapper}>
        <div className={styles.title}>
          Compare Successor
          {preRead && <PreReadLabel />}
        </div>
        <CustomModalContent>
          {compareItem
            .map((i, indx) => ({ ...i, recordNo: indx }))
            .map((talent) => (
              <TalentItem key={talent?.staffId || talent?.staff_id} record={{ ...talent }} compareItem={compareItem} />
            ))}
        </CustomModalContent>
      </div>
    </Spin>
  );
};

export default PrintCompareSuccessor;

PrintCompareSuccessor.propTypes = {
  preRead: PropTypes.bool,
  proposal: PropTypes.object,
  handleFetchComplete: PropTypes.func,
};

const TalentItem = (props) => {
  const { record, compareItem } = props;

  const cloneListExperience = useMemo(() => {
    return !isEmpty(record?.experiences) ? record.experiences : [];
  }, [record]);

  const widthItem = useMemo(() => {
    return compareItem.length > 3 ? '352px' : '100%';
  }, [compareItem.length]);

  return (
    <TalentItemWrap
      style={{
        maxWidth: widthItem,
        flex: '1 1 0%',
      }}
    >
      <div className="topHead">
        <span className="title">Successor #{record?.recordNo + 1} Talent Information</span>
      </div>

      <div className="contentWrap">
        <div className="talentInfo">
          <div className="sectionItem firstSection">
            <div className="avatar">
              <img src={record?.image || avatar} alt="avatar_profile" width="137" height="137" style={{ borderRadius: '100px' }} />
            </div>

            <div className="name">
              <div className="talentName">{`${record?.birthName || ''} ${
                record?.talentCategory ? `(${handleShowCTForProposal(record?.talentCategory)})` : ''
              }`}</div>
              <div className="positionName">{`${record?.currentPosition?.position_name} ${
                record?.currentPosition?.business_unit ? `, ${record?.currentPosition?.business_unit}` : ''
              }`}</div>
            </div>
          </div>

          <div className="sectionItem talentDetail">
            <div className="subTitle">Talent Details</div>
            <div className="fieldItem">
              <span className={'greyLabel'}>{'Age'}</span>
              <span className={'normalText'}>{record?.age || '-'}</span>
            </div>
            <div className="fieldItem">
              <span className={'greyLabel'}>{'Retirement Date'}</span>
              <span className={'normalText'}>{record?.retirementDate || '-'} </span>
            </div>
            <div className="fieldItem">
              <span className={'greyLabel'}>{'JG (YIP)'}</span>
              <span className={'normalText'}>{`${record?.jg} (${record?.years_in_position})`}</span>
            </div>
            <div className="fieldItem">
              <span className={'greyLabel'}>{'SG (YiSG)'}</span>
              <span className={'normalText'}>{`${record?.sg} (${record?.yearsInSg})`}</span>
            </div>
            <div className="fieldItem">
              <span className={'greyLabel'}>{`BePCB ${!isEmpty(record?.rating) ? `'${record.rating[0].year.slice(2)}` : ''}`}</span>
              <span className={'normalText'}>{!isEmpty(record?.rating) ? record.rating[0].description : '-'}</span>
            </div>
          </div>

          <div className="sectionItem">
            <UserCareerTable data={record?.rating || []} isSearchTalent={true} />
          </div>
        </div>
        <div className="sectionItem">
          <div className="subTitle">Experience</div>
          {!isEmpty(cloneListExperience) && (
            <div key={record?.staff_id}>
              {cloneListExperience.map((item, index) => (
                <div key={index}>
                  <img src={dot_active_fulfilment} />
                  {item.label}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="sectionItem">
          <div className="subTitle">Leadership Insights</div>
          <div className="fieldItem mb-12">
            <div className={'smallTitle mb-8'}>
              <span>{'Strengths'}</span>
            </div>
            <CustomTextarea
              disabled
              placeholder="Enter Strengths"
              style={{ minHeight: '120px' }}
              value={formatTextStringJson(record?.keystrength || '')}
            />
          </div>

          <div className="fieldItem">
            <div className={'smallTitle mb-8'}>
              <span>{'Development Areas'}</span>
            </div>
            <CustomTextarea
              disabled
              placeholder="Enter Development Areas"
              style={{ minHeight: '120px' }}
              value={formatTextStringJson(record?.dev_area || '')}
            />
          </div>
        </div>
      </div>
    </TalentItemWrap>
  );
};

const CustomModalContent = styled.div`
  display: flex;
  align-items: stretch;
  position: relative;
  gap: 16px;
  flex-wrap: wrap;

  .smallTitle {
    color: #394655;
    font-family: var(--font-type-default, Inter);
    font-size: var(--font-size-body-sm, 14px);
    font-style: normal;
    font-weight: var(--font-weight-medium, 500);
    line-height: var(--font-line-height-body-sm, 20px); /* 142.857% */
    letter-spacing: var(--font-letter-spacing-body-sm, 0.25px);
  }
`;

const TalentItemWrap = styled.div`
  width: 50%;
  border: solid 1px #d3dee8;
  border-radius: 4px;

  .ant-input[disabled] {
    color: #15191e;
    background: #f0f5fa;
  }

  .deleteIcon {
    cursor: pointer;
  }

  .topHead {
    position: relative;
    border-radius: 4px 4px 0px 0px;
    background: #d9f2f7;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;

    img {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 24px;
      height: 24px;
      padding: 5px;
    }
  }

  .contentWrap {
    padding: 24px;
  }

  .talentInfo {
    .avatar {
      margin-bottom: 10px;

      img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
      }
    }

    .talentName {
      color: var(--Emphasis-Body-N800, #3f3c4c);
      text-align: center;
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.3px;
      margin-bottom: 10px;
    }

    .positionName {
      color: var(--Emphasis-Body-N800, #3f3c4c);
      text-align: center;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.3px;
    }
  }

  .firstSection {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .talentDetail {
    .fieldItem {
      display: flex;
      justify-content: space-between;
    }
    .greyLabel {
      min-width: 120px;
    }
    .normalText {
      width: calc(100% -120px);
      text-align: right;
    }
  }

  .sectionItem {
    margin-bottom: 30px;
  }

  .fieldItem {
    margin-bottom: 5px;
  }

  .title {
    color: var(--Other-Colors-Typography, #000);
    text-align: center;
    font-family: var(--font-type-default, Inter);
    font-size: var(--font-size-body-md, 16px);
    font-style: normal;
    font-weight: var(--font-weight-bold, 700);
    line-height: var(--font-line-height-body-md, 24px); /* 150% */
    letter-spacing: var(--font-letter-spacing-body-md, 0.3px);
  }

  .subTitle {
    color: var(--Emphasis-Body-N800, #3f3c4c);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.3px;
    margin-bottom: 16px;
  }

  .greyLabel {
    color: #7d8c9f;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 171.429% */
  }

  .normalText {
    color: var(--Emphasis-Body-N800, #3f3c4c);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
  }

  .mb-8 {
    margin-bottom: 8px;
  }

  .mb-12 {
    margin-bottom: 12px;
  }
`;

const CustomTextarea = styled(AutoResizeTextarea)`
  resize: none;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  color: #15191e;
  background: #f0f5fa;
`;
