import React, { useEffect, useState, useMemo } from 'react';
import { Setting_2 } from '../../assets/img';
import { PROPOSAL_LABEL, PROPOSAL_SELECT_OPTIONS } from '../../utils/constants';
import BasicTreeSelect from '../BasicTreeSelect/BasicTreeSelect';
import { isEmpty } from 'lodash';
const ProposalSetting = (props) => {
  const {
    defaultValues,
    initProposalOptions,
    setProposalType,
    proposalType,
    currentItem,
    setItemExpand,
    setProposalList,
    fetchTemplateContent,
    handleOpenSettingProposal,
    setIsOpenSettingHasType,
  } = props;
  const [selectedOptions, setSelectedOptions] = useState(!isEmpty(defaultValues) ? defaultValues : []);

  useEffect(() => {
    setProposalType(currentItem?.proposal_type || null);
  }, [currentItem]);

  const prevSelectedOptions = useMemo(() => {
    const convertDefault = defaultValues?.length === 40 ? [{ label: 'Select All', value: 'select_all' }, ...defaultValues] : defaultValues;
    return convertDefault;
  }, [JSON.stringify(defaultValues)]);

  const handleApply = async () => {
    if (!proposalType) return;
    setItemExpand({ ...currentItem, proposal_type: proposalType });
    setProposalList((prev) => {
      if (!isEmpty(prev)) {
        return prev.map((item) => {
          if (item?.staff_id === currentItem?.staff_id) {
            return {
              ...item,
              proposal_type: proposalType,
              proposal_item_list: selectedOptions,
            };
          } else {
            return item;
          }
        });
      }
    });
    await fetchTemplateContent({ ...currentItem, proposal_type: proposalType }, selectedOptions, true);
  };

  useEffect(() => {
    const convertDefault = defaultValues?.length === 40 ? [{ label: 'Select All', value: 'select_all' }, ...defaultValues] : defaultValues;
    setSelectedOptions(convertDefault);
  }, [JSON.stringify(defaultValues)]);

  return (
    <BasicTreeSelect
      placement="bottomRight"
      treeDefaultExpandAll={false}
      dropdownStyle={{ minWidth: 415, maxWidth: 415 }}
      onApply={handleApply}
      options={PROPOSAL_SELECT_OPTIONS}
      selectedOptions={selectedOptions}
      setSelectedOptions={setSelectedOptions}
      prevSelectedOption={prevSelectedOptions}
      valueSelectAll={PROPOSAL_LABEL.SELECT_ALL}
      style={{ height: 24 }}
      type="proposal"
      initProposalOptions={initProposalOptions}
      handleChangeProposalType={(value) => {
        setIsOpenSettingHasType(false);
        setProposalType(value);
      }}
      proposalType={proposalType}
      handleOpenSettingProposal={handleOpenSettingProposal}
      currentItemProposal={currentItem}
    >
      <img src={Setting_2} alt="Setting_2" />
    </BasicTreeSelect>
  );
};
export default ProposalSetting;
