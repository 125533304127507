import { Collapse } from 'antd';
import moment from 'moment';
import React, { useMemo } from 'react';
import MonthlyCollapse from '../MonthlyCollapse/MonthlyCollapse';
import { Container } from './styled';
const { Panel } = Collapse;

const QuaterCollapse = (props) => {
  const { date, data } = props;

  function getTotalMeeting(arr, month) {
    let result = 0;
    arr.forEach((item) => {
      if (moment(item.date).local().format('MM') === month) {
        result += item.total;
      }
    });
    return result === 0 ? '' : `(${result})`;
  }
  const monthArr = useMemo(() => {
    return [
      [
        {
          header: `January ${date.getFullYear()} ${getTotalMeeting(data, '01')}`,
          key: `1`,
          value: '01',
        },
        {
          header: `February ${date.getFullYear()} ${getTotalMeeting(data, '02')}`,
          key: `2`,
          value: '02',
        },
        {
          header: `March ${date.getFullYear()} ${getTotalMeeting(data, '03')}`,
          key: `3`,
          value: '03',
        },
        {
          header: `April ${date.getFullYear()} ${getTotalMeeting(data, '04')}`,
          key: `4`,
          value: '04',
        },
      ],
      [
        { header: `May ${date.getFullYear()} ${getTotalMeeting(data, '05')}`, key: `1`, value: '05' },
        {
          header: `June ${date.getFullYear()} ${getTotalMeeting(data, '06')}`,
          key: `2`,
          value: '06',
        },
        {
          header: `July ${date.getFullYear()} ${getTotalMeeting(data, '07')}`,
          key: `3`,
          value: '07',
        },
        {
          header: `August ${date.getFullYear()} ${getTotalMeeting(data, '08')}`,
          key: `4`,
          value: '08',
        },
      ],
      [
        {
          header: `September ${date.getFullYear()} ${getTotalMeeting(data, '09')}`,
          key: `1`,
          value: '09',
        },
        {
          header: `October ${date.getFullYear()} ${getTotalMeeting(data, '10')}`,
          key: `2`,
          value: '10',
        },
        {
          header: `November ${date.getFullYear()} ${getTotalMeeting(data, '11')}`,
          key: `3`,
          value: '11',
        },
        {
          header: `December ${date.getFullYear()} ${getTotalMeeting(data, '12')}`,
          key: `4`,
          value: '12',
        },
      ],
    ];
  }, [date, data]);

  return (
    <Container>
      <Collapse bordered={false} defaultActiveKey={['1', '2', '3']} expandIconPosition="end">
        <Panel header={`January - April ${date.getFullYear()}`} key="1" id="panel-1">
          <MonthlyCollapse monthly={monthArr[0]} date={date} {...props} />
        </Panel>
        <Panel header={`May - August ${date.getFullYear()}`} key="2" id="panel-2">
          <MonthlyCollapse monthly={monthArr[1]} date={date} {...props} />
        </Panel>
        <Panel header={`September - December ${date.getFullYear()}`} key="3" id="panel-3">
          <MonthlyCollapse monthly={monthArr[2]} date={date} {...props} />
        </Panel>
      </Collapse>
    </Container>
  );
};

export default QuaterCollapse;
