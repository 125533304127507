import React from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useCanEditPdcMobilityAgenda } from '../../../../hooks/useCanEditPdcMobilityAgenda';
import AspirationRisks from '../../AspirationRisks/AspirationRisks';
import CreateEditPdcMobility from '../../CreateEditPdcMobility/CreateEditPdcMobility';
import PDCAssessment from '../../PDCAssessment/PDCAssessment';
import PdcProposalList from '../../PdcProposalList/PdcProposalList';
import PdcProposalPositionList from '../../PdcProposalPositionList/PdcProposalPositionList';
import PdcProposalTalentList from '../../PdcProposalTalentList/PdcProposalTalentList';
import PdcSupportingDocuments from '../../PdcSupportingDocuments/PdcSupportingDocuments';

// HOC for Access Control
const LayoutMobilityAgenda = (props) => {
  const { type } = props;
  const { idMeeting, mobilityId } = useParams();
  // Get the condition to edit mobility agenda
  const {
    isOnlyViewAgenda,
    isShowTopButtons,
    isShowBotButtons,
    isAdditionalViewAccess,
    meetingDetail,
    mobilityDetails,
    isEnableMobilityTab,
    proposalMainStep,
    fetchMobilityDetails,
    isShownMobilityTabBar,
    isHRPartners,
    isChairMainOrCommiteeOrAdvocator,
  } = useCanEditPdcMobilityAgenda({
    idMeeting,
    mobilityId,
  });

  switch (type) {
    case 1:
    case 2:
      return (
        <CreateEditPdcMobility
          isOnlyViewAgenda={isOnlyViewAgenda}
          isShowTopButtons={isShowTopButtons}
          isShowBotButtons={isShowBotButtons}
          isAdditionalViewAccess={isAdditionalViewAccess}
          isEnableMobilityTab={isEnableMobilityTab}
          meetingDetail={meetingDetail}
          mobilityDetails={mobilityDetails}
          proposalMainStep={proposalMainStep}
          fetchMobilityDetails={fetchMobilityDetails}
          isShownMobilityTabBar={isShownMobilityTabBar}
          isHRPartners={isHRPartners}
          isChairMainOrCommiteeOrAdvocator={isChairMainOrCommiteeOrAdvocator}
        />
      );
    case 3:
      return (
        <PdcProposalList
          isOnlyViewAgenda={isOnlyViewAgenda}
          isShowTopButtons={isShowTopButtons}
          isShowBotButtons={isShowBotButtons}
          isAdditionalViewAccess={isAdditionalViewAccess}
          isEnableMobilityTab={isEnableMobilityTab}
          meetingDetail={meetingDetail}
          mobilityDetails={mobilityDetails}
          proposalMainStep={proposalMainStep}
          fetchMobilityDetails={fetchMobilityDetails}
          isShownMobilityTabBar={isShownMobilityTabBar}
          isHRPartners={isHRPartners}
          isChairMainOrCommiteeOrAdvocator={isChairMainOrCommiteeOrAdvocator}
        />
      );
    case 4:
      return (
        <PDCAssessment
          isOnlyViewAgenda={isOnlyViewAgenda}
          isShowTopButtons={isShowTopButtons}
          isShowBotButtons={isShowBotButtons}
          isAdditionalViewAccess={isAdditionalViewAccess}
          isEnableMobilityTab={isEnableMobilityTab}
          meetingDetail={meetingDetail}
          mobilityDetails={mobilityDetails}
          proposalMainStep={proposalMainStep}
          fetchMobilityDetails={fetchMobilityDetails}
          isShownMobilityTabBar={isShownMobilityTabBar}
          isHRPartners={isHRPartners}
          isChairMainOrCommiteeOrAdvocator={isChairMainOrCommiteeOrAdvocator}
        />
      );
    case 5:
      return (
        <PdcProposalTalentList
          isOnlyViewAgenda={isOnlyViewAgenda}
          isShowTopButtons={isShowTopButtons}
          isShowBotButtons={isShowBotButtons}
          isAdditionalViewAccess={isAdditionalViewAccess}
          isEnableMobilityTab={isEnableMobilityTab}
          meetingDetail={meetingDetail}
          mobilityDetails={mobilityDetails}
          proposalMainStep={proposalMainStep}
          fetchMobilityDetails={fetchMobilityDetails}
          isShownMobilityTabBar={isShownMobilityTabBar}
          isHRPartners={isHRPartners}
          isChairMainOrCommiteeOrAdvocator={isChairMainOrCommiteeOrAdvocator}
        />
      );
    case 6:
      return (
        <PdcProposalPositionList
          isOnlyViewAgenda={isOnlyViewAgenda}
          isShowTopButtons={isShowTopButtons}
          isShowBotButtons={isShowBotButtons}
          isAdditionalViewAccess={isAdditionalViewAccess}
          isEnableMobilityTab={isEnableMobilityTab}
          meetingDetail={meetingDetail}
          mobilityDetails={mobilityDetails}
          proposalMainStep={proposalMainStep}
          fetchMobilityDetails={fetchMobilityDetails}
          isShownMobilityTabBar={isShownMobilityTabBar}
          isHRPartners={isHRPartners}
          isChairMainOrCommiteeOrAdvocator={isChairMainOrCommiteeOrAdvocator}
        />
      );
    case 7:
      return (
        <AspirationRisks
          isOnlyViewAgenda={isOnlyViewAgenda}
          isShowTopButtons={isShowTopButtons}
          isShowBotButtons={isShowBotButtons}
          isAdditionalViewAccess={isAdditionalViewAccess}
          isEnableMobilityTab={isEnableMobilityTab}
          meetingDetail={meetingDetail}
          mobilityDetails={mobilityDetails}
          proposalMainStep={proposalMainStep}
          fetchMobilityDetails={fetchMobilityDetails}
          isShownMobilityTabBar={isShownMobilityTabBar}
          isHRPartners={isHRPartners}
          isChairMainOrCommiteeOrAdvocator={isChairMainOrCommiteeOrAdvocator}
        />
      );
    case 8:
      return (
        <PdcSupportingDocuments
          isOnlyViewAgenda={isOnlyViewAgenda}
          isShowTopButtons={isShowTopButtons}
          isShowBotButtons={isShowBotButtons}
          isAdditionalViewAccess={isAdditionalViewAccess}
          isEnableMobilityTab={isEnableMobilityTab}
          meetingDetail={meetingDetail}
          mobilityDetails={mobilityDetails}
          proposalMainStep={proposalMainStep}
          fetchMobilityDetails={fetchMobilityDetails}
          isShownMobilityTabBar={isShownMobilityTabBar}
          isHRPartners={isHRPartners}
          isChairMainOrCommiteeOrAdvocator={isChairMainOrCommiteeOrAdvocator}
        />
      );
    default:
      return <></>;
  }
};

export default LayoutMobilityAgenda;
