import { Tooltip } from 'antd';
import { isEmpty, uniqueId } from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';
import { Table } from './SummaryMeetingStyled';

function convertToOrderedKeys(data) {
  const keyOrderMap = [];
  for (const item of data) {
    for (const key in item) {
      if (key !== 'proposal') {
        const order = item[key].order;
        const effectiveOrder = order === null ? null : order;
        keyOrderMap.push({ key, order: effectiveOrder });
      }
    }
  }

  // Sort by order
  keyOrderMap.sort((a, b) => {
    if (a.order === null && b.order === null) {
      return a.key.localeCompare(b.key); // Both null, sort alphabetically
    } else if (a.order === null) {
      return 1; // a is null, put it after b (towards the end)
    } else if (b.order === null) {
      return -1; // b is null, put it after a (towards the end)
    } else {
      return a.order - b.order; // Both have order, sort by order
    }
  });
  // Extract keys
  const orderedKeys = keyOrderMap.map((item) => item.key);
  console.log(orderedKeys);
  return orderedKeys;
}

const SummaryMeeting = (props) => {
  const { data, isByDate } = props;

  const columns = useMemo(() => {
    return ['Proposal', ...new Set(convertToOrderedKeys(data))];
  }, [data]);

  if (isEmpty(columns)) return <></>;
  const convertData = data.map((m) => {
    const updatedObject = {};
    for (const property in m) {
      if (property === 'proposal') {
        updatedObject['Proposal'] = { value: m.proposal };
      } else {
        updatedObject[property] = { value: m[property]?.total, talent: m[property]?.talent };
      }
    }
    return updatedObject;
  });
  const total = {
    Proposal: 'Total',
  };
  columns
    .filter((t) => t !== 'Proposal')
    .forEach((f) => {
      let count = 0;
      convertData.forEach((c) => {
        count += c[f]?.value || 0;
      });
      total[f] = count;
    });

  return (
    <Table>
      <div className="table-container">
        <table className="styled-table">
          <thead>
            <tr>
              {columns.map((m) => (
                <th key={uniqueId()}>{m}</th>
              ))}
              <th key={uniqueId()}>Total</th>
            </tr>
          </thead>
          <tbody>
            {convertData.map((d) => (
              <tr key={uniqueId()}>
                {columns.map((m) => (
                  <td key={uniqueId()}>
                    {isByDate && m === 'Proposal'
                      ? moment(d[m]?.value, 'DD/MM/YYYY').format('D MMM YYYY')
                      : (d[m]?.value && !isEmpty(d[m]?.talent) && (
                          <>
                            <Tooltip
                              title={
                                <div>
                                  {!isEmpty(d[m]?.talent) &&
                                    d[m]?.talent.map((t, index) => (
                                      <div key={t} style={{ textWrap: 'nowrap' }}>
                                        {index + 1}. {t}
                                      </div>
                                    ))}
                                </div>
                              }
                              {...{
                                overlayInnerStyle: {
                                  width: 'fit-content',
                                  fontSize: '12px',
                                  lineHeight: '18px',
                                  fontWeight: 400,
                                  fontFamily: '"Inter", sans-serif',
                                  padding: '12px',
                                  borderRadius: '4px',
                                  backgroundColor: '#000',
                                },
                              }}
                            >
                              <div>{d[m]?.value}</div>
                            </Tooltip>
                          </>
                        )) ||
                        d[m]?.value ||
                        '-'}
                  </td>
                ))}
                <td key={uniqueId()}>
                  {Object.values(d)
                    .map((m) => m.value)
                    .filter((item) => typeof item === 'number')
                    .reduce((sum, num) => sum + num, 0)}
                </td>
              </tr>
            ))}
            <tr key={uniqueId()}>
              {columns.map((m) => (
                <td key={uniqueId()}>{total[m]}</td>
              ))}
              <td key={uniqueId()}>
                {Object.values(total)
                  .filter((item) => typeof item === 'number')
                  .reduce((sum, num) => sum + num, 0)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Table>
  );
};

export default SummaryMeeting;
