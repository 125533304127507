import React, { useEffect, useState, useMemo } from 'react';
import { map } from 'lodash';
import { Space } from 'antd';

import {
  MEETING_DETAIL_STATUS,
  REMARK_MODE,
  REMARK_TYPE_OPTIONS_PDC,
} from '../../../../utils/constants';
import { pdcMeetingApi } from '../../../../services/pdcMeetingApi';
import AddPDCRemark from '../AddPDCRemark/AddPDCRemark';
import ViewRemarks from '../../../Meeting/MeetingDetails/components/ViewRemarks/ViewRemarks';
import { AiContainerForm } from '../../../Meeting/MeetingDetails/Styled';

const PDCRemarks = ({ idMeeting, meetingStatus }) => {
  const [remarksData, setRemarkData] = useState([]);
  const [remarkFilters, setRemarkFilters] = useState(REMARK_TYPE_OPTIONS_PDC);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [addRemarkSuccess, setAddRemarkSuccess] = useState(false);
  const [editRemark, setEditRemark] = useState(null);

  const getRemarksList = async (page) => {
    setLoading(true);
    try {
      if (remarkFilters?.remarkType) {
        const categoryParams = remarkFilters?.remarkType
          ?.map((item) => item.value)
          .filter((item) => item && item !== 'select-all')
          .join(',');
        const res = await pdcMeetingApi.getRemarksList(
          idMeeting,
          categoryParams,
          page
        );
        if (res.status === 200) {
          setCurrentPage(page)
          setRemarkData(res.data.result);
          setAddRemarkSuccess(false);
        }
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    const page = 1;
    getRemarksList(page);
  }, [remarkFilters]);

  useEffect(() => {
    const page = 1;
    if (addRemarkSuccess) getRemarksList(page);
  }, [addRemarkSuccess]);

  const remarks = useMemo(
    () =>
      map(
        remarksData,
        ({ category, remark, birthName, id, staffId, image, total, mobility_id }) => ({
          category,
          remarks: remark,
          birthName: birthName,
          remarkId: id,
          staffId,
          image,
          total,
          mobility_id,
        })
      ),
    [remarksData]
  );

  return (
    <AiContainerForm>
      <Space direction="vertical" size={38} className="w-100">
        {meetingStatus !== MEETING_DETAIL_STATUS.CONCLUDED &&
          <AddPDCRemark
            idMeeting={idMeeting}
            setAddRemarkSuccess={setAddRemarkSuccess}
            editRemark={editRemark}
            setEditRemark={setEditRemark}
          />
        }
        <ViewRemarks
          remarks={remarks}
          getRemarksList={getRemarksList}
          onFilters={setRemarkFilters}
          isLoading={loading}
          mode={REMARK_MODE.PDC}
          currentPage={currentPage}
          setEditRemark={setEditRemark}
          idMeeting={idMeeting}
          isHiddenAction={meetingStatus === MEETING_DETAIL_STATUS.CONCLUDED}
        />
      </Space>
    </AiContainerForm>
  );
};

export default PDCRemarks;
