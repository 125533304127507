import styled from 'styled-components';
import { font } from '../../../assets/common';

export const MeetingName = styled.div`
  font-family: ${font.inter};
  font-size: 24px;
  font-weight: 700;
  line-height: 29.05px;
  letter-spacing: 0.30000001192092896px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
`;

export const Footer = styled.div`
  margin-top: 30px;
  font-family: ${font.inter} !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .right {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
  }
`;
