import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { MEETING_DETAIL_STATUS } from '../../../../utils/constants';
import { color, font, fontSize } from '../../../../assets/common';

export const StyledStatus = styled.div`
  padding: 0 8px;
  height: 30px;
  display: flex;
  align-items: center;
  border: 1px solid ${color.geyser};
  border-radius: 6px;
  margin-left: 10px;
  background: white;
  p {
    font-size: ${fontSize.small};
    color: ${color.darkBlue};
    font-family: ${font.inter};
    font-weight: 700;
    position: relative;
    margin-bottom: 0;
    margin-left: 20px;
    &::before {
      position: absolute;
      content: '';
      width: 12px;
      height: 12px;
      border-radius: 4px;
      background-color: ${(props) => props.color};
      left: -13px;
      top: 49%;
      transform: translate(-50%, -50%);
    }
  }
`;

const handleBackgroundColorType = (status) => {
  switch (status) {
    case MEETING_DETAIL_STATUS.CONCLUDED:
    case MEETING_DETAIL_STATUS.PUBLISHED:
      return color.darkGreen;
    case MEETING_DETAIL_STATUS.IN_PROGRESS:
      return color.mySin;
    case MEETING_DETAIL_STATUS.CANCELED:
      return '#ec2e2e';
    default:
      return color.mySin;
  }
};

const PDCMeetingStatus = ({ status }) => {
  return (
    <StyledStatus color={handleBackgroundColorType(status)}>
      <p>{status || MEETING_DETAIL_STATUS.IN_PLANNING}</p>
    </StyledStatus>
  );
};

export default PDCMeetingStatus;

PDCMeetingStatus.propTypes = {
  status: PropTypes.string,
};
