import { useState } from 'react';

import { pdcMeetingApi } from '../../../../services/pdcMeetingApi';

export const usePDCTalentSearch = () => {
  const [loading, setLoading] = useState(false);

  const searchProfiles = async (search, meeting_id) => {
    try {
      const response = await pdcMeetingApi.searchPDCRemarkStaff(
        search,
        meeting_id
      );
      if (
        response.data &&
        response.data.result &&
        response.data.result.profiles?.length > 0
      ) {
        const { profiles } = response.data.result;
        setLoading(false);
        return profiles;
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
    return [];
  };

  return { loading, searchProfiles };
};
