import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Spin } from 'antd';
import { useParams } from 'react-router-dom';

import { formatDisplayTime } from '../../utils/helper';
import { BreadCrumb } from '../../components';
import { homepage_icon, play_video } from '../../assets/img';
import * as styles from './news-and-announcements.module.scss';
import { adminApi } from '../../services/admin';

function NewAndAnnouncementsDetail() {
  const { annId } = useParams();
  const [loading, setLoading] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [videoDuration, setVideoDuration] = useState(0);
  const [newsAnnouncementDetail, setNewsAnnouncementDetail] = useState({});
  const containerRef = useRef();

  const breadCrumbList = [
    { name: 'Homepage', icon: homepage_icon, url: '/homepage' },
    { name: 'New And Announcement', url: '/home/news-announcement-list' },
    { name: newsAnnouncementDetail?.title || null }
  ];

  const getViewNewsAnnouncementDetail = async () => {
    setLoading(true);
    try {
      const res = await adminApi.getViewNewsAnnouncementDetail(annId);
      if (res.status === 200) {
        setNewsAnnouncementDetail(res.data.result);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getViewNewsAnnouncementDetail();
  }, []);

  const handlePlayClick = () => {
    setIsVideoPlaying(true);
    const video = containerRef.current.getElementsByTagName('video')[0];
    video.play();
  };

  const displayVideoDuration = useMemo(() => {
    return formatDisplayTime(videoDuration);
  }, [videoDuration]);

  return (
    <Spin spinning={loading} size="large">
      <BreadCrumb level={6} breadCrumbList={breadCrumbList} />
      {newsAnnouncementDetail &&
        <div
          style={{
            backgroundColor: '#FFFFFF',
            marginTop: '20px',
            borderRadius: '4px',
            padding: '32px 28px',
            display: 'grid',
            gap: '20px',
            marginBottom: '20px',
          }}
          ref={containerRef}
        >
          <div
            style={{
              fontFamily: 'Inter',
              fontWeight: '600',
              fontSize: '32px',
              color: '#333333',
            }}
          >
            {newsAnnouncementDetail?.title}
          </div>
          <div
            style={{
              fontWeight: '400',
              fontSize: '12px',
              display: 'flex',
              gap: '16px',
            }}
          >
            {newsAnnouncementDetail?.published_date}
            <span style={{ fontFamily: 'Inter' }}>|</span>
            {newsAnnouncementDetail?.published_by}
          </div>
          <div style={{ display: 'grid', gap: '60px', marginTop: '32px' }}>
            {newsAnnouncementDetail?.attachment && newsAnnouncementDetail?.attachment.map((item) => {
              if (item && item.type === 'img') {
                return (
                  <img
                    key={item.content}
                    style={{ minHeight: '400px', width: '100%' }}
                    src={item.content}
                  />
                );
              } else if (item && item.type === 'text') {
                return (
                  <div
                    key={item.content}
                    style={{
                      fontFamily: 'Inter',
                      fontWeight: '300',
                      fontSize: '16px',
                      color: '#333333',
                      lineHeight: '24px',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    {item.content}
                  </div>
                );
              } else if (item && item.type === 'mp4') {
                return (
                  <div key={item.content} style={{ position: 'relative' }}>
                    <video
                      style={{ minHeight: '400px' }}
                      src={item.content}
                      className={styles.videoElement}
                      onPlaying={() => setIsVideoPlaying(true)}
                      onPause={() => {
                        const video =
                          containerRef.current.getElementsByTagName('video')[0];
                        if (!video.paused) {
                          setIsVideoPlaying(true);
                        } else {
                          setIsVideoPlaying(false);
                        }
                      }}
                      onLoadedMetadata={(e) =>
                        setVideoDuration(e.target.duration)
                      }
                      controls
                    />
                    {!isVideoPlaying && (
                      <button
                        className={styles.overlay}
                        onClick={handlePlayClick}
                      >
                        <div className={styles.playButton}>
                          <img src={play_video} alt="play" />
                        </div>
                        <div className={styles.durationLabel}>
                          {displayVideoDuration}
                        </div>
                      </button>
                    )}
                  </div>
                );
              }
            })}
          </div>
        </div>
      }
    </Spin>
  );
}

export default NewAndAnnouncementsDetail;
