import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateRoles } from '../pages/Meeting/redux/meetingDetailSlice';
import { pdcMeetingApi } from '../services/pdcMeetingApi';

export const useGetRoleInPdcMeeting = ({ idMeeting }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { roleId } = useSelector((state) => state.user.roleActive);
  const dispatch = useDispatch();

  const getRoleInMeeting = useCallback(async () => {
    setLoading(true);
    try {
      const response = await pdcMeetingApi.getRoleInPdcMeeting({ idMeeting, roleId });

      setData(response.data.result);
      dispatch(updateRoles(response.data.result));
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }, [idMeeting, roleId, setData, setLoading]);

  useEffect(() => {
    if (isEmpty(idMeeting)) {
      return;
    }
    getRoleInMeeting();
  }, [idMeeting, getRoleInMeeting]);

  return { data, loading, error, getRoleInMeeting };
};
