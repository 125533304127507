import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './print-meeting-detail.module.scss';
import { Spin } from 'antd';
import { BasicInput, BasicLabel, BasicSelect } from '../../../../../assets/common';
import { AiDatePicker, AiTimePickerField } from '../../../../Meeting/MeetingDetails/Styled';
import { DATE_TIME_FORMAT, MEETING_CATEGORY_OPTIONS } from '../../../../../utils/constants';
import moment from 'moment';
import PDCMeetingStatus from '../../../components/PDCMeetingStatus/PDCMeetingStatus';
import PreReadLabel from '../PreReadLabel/PreReadLabel';
import { isEmpty } from 'lodash';
import { getLabelNameForSecretariatOfTcMeeting } from '../../../../../utils/helper';

const PrintMeetingDetail = React.memo(({ data, loading, preRead }) => {
  return (
    <div className={styles.wrapper}>
      <Spin spinning={loading}>
        <div className={styles.name}>
          <div>
            <div>Meeting Details</div> <PDCMeetingStatus status={data?.status} />
          </div>
          {preRead && <PreReadLabel />}
        </div>
        <div className={styles.row}>
          <BasicLabel required>Meeting Name</BasicLabel>
          <BasicInput disabled defaultValue={data?.meeting_name} padding="10px 16px" />
        </div>
        <div className={styles.rowMulti}>
          <div className={styles.col}>
            <BasicLabel required>Date</BasicLabel>
            <AiDatePicker
              format={DATE_TIME_FORMAT.DATE_SPACE}
              value={data?.date && moment(data?.date, DATE_TIME_FORMAT.DATE_SPACE)}
              placeholder="Select Date"
              getPopupContainer={(trigger) => trigger.parentElement}
              disabled
            />
          </div>
          <div className={styles.col}>
            <BasicLabel required>Meeting Category</BasicLabel>
            <BasicSelect value={data?.category} options={MEETING_CATEGORY_OPTIONS} disabled padding="10px 16px" />
          </div>

          {!isEmpty(data?.category) && ['Business', 'OPU', 'Sector', 'Division', 'SKG', 'Job Family'].includes(data?.category) && (
            <div className={styles.col}>
              <BasicLabel required>{data?.category}</BasicLabel>
              <BasicSelect value={data?.category_value} disabled padding="10px 16px" />
            </div>
          )}
          <div className={styles.inputItem}>
            <BasicLabel required>Start time</BasicLabel>
            <AiTimePickerField
              placeholder={'Select Time'}
              value={data?.start_time && moment(data?.start_time, DATE_TIME_FORMAT.TIME).local()}
              style={{ width: '100%' }}
              format={DATE_TIME_FORMAT.TIME}
              getPopupContainer={(trigger) => trigger.parentElement}
              disabled
            />
          </div>
          <div className={styles.inputItem}>
            <BasicLabel required>End time</BasicLabel>
            <AiTimePickerField
              placeholder={'Select Time'}
              value={data?.end_time && moment(data?.end_time, DATE_TIME_FORMAT.TIME).local()}
              style={{ width: '100%' }}
              format={DATE_TIME_FORMAT.TIME}
              getPopupContainer={(trigger) => trigger.parentElement}
              disabled
            />
          </div>
        </div>
        <div className={styles.row}>
          <BasicLabel required>Location</BasicLabel>
          <BasicInput disabled defaultValue={data?.location} padding="10px 16px" />
        </div>
        <div className={styles.row}>
          <BasicLabel required>Chairman</BasicLabel>
          <BasicSelect mode="multiple" value={data?.chairman} disabled />
        </div>
        <div className={styles.row}>
          <BasicLabel required>Committee Members</BasicLabel>
          <BasicSelect mode="multiple" value={data?.meeting_committee} disabled />
        </div>
        <div className={styles.row}>
          <BasicLabel required>Meeting Facilitators</BasicLabel>
          <BasicSelect mode="multiple" value={data?.meeting_facilitator} disabled />
        </div>
        <div className={styles.row}>
          <BasicLabel required>Meeting Secretariat</BasicLabel>
          <BasicSelect mode="multiple" value={data?.meeting_secretariats?.map((i, idx) => ({ label: getLabelNameForSecretariatOfTcMeeting(data?.category, i), value: idx }))} disabled />
        </div>
        <div className={styles.row}>
          <BasicLabel required>Additional View Access</BasicLabel>
          <BasicSelect mode="multiple" value={data?.meeting_additional_view_accesses?.map((i, idx) => ({ label: getLabelNameForSecretariatOfTcMeeting(data?.category, i), value: idx }))} disabled />
        </div>
      </Spin>
    </div>
  );
});

PrintMeetingDetail.displayName = 'PrintMeetingDetail';

export default PrintMeetingDetail;

PrintMeetingDetail.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  preRead: PropTypes.bool,
};
