import { Divider } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { CALENDAR, DATE_TIME_FORMAT, MEETING_STATUS } from '../../../../utils/constants';
import { changeMeetingStatus } from '../../../Meeting/redux/meetingDetailSlice';
import DetailList from './DetailList';
import { Container } from './styled';

const DetailMonthlyYearView = (props) => {
  const { value, data } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const handleClickMeeting = (meeting) => {
    return meeting.status === CALENDAR.MEETING_STATUS.IN_PLANNING && location.pathname !== '/pdc' && !meeting.is_secretariat_or_facilitator
      ? null
      : getRedirectUrl(meeting);
  };

  const getRedirectUrl = (meeting) => {
    dispatch(changeMeetingStatus(false));
    return location.pathname === '/pdc'
      ? history.push(
          meeting.status === CALENDAR.MEETING_STATUS.IN_REVIEW
            ? `/pdc-meeting/${meeting.meeting_pdc_id}/in-review`
            : meeting.status === CALENDAR.MEETING_STATUS.CONCLUDED
            ? `/pdc-meeting/${meeting.meeting_pdc_id}/concluded`
            : `/pdc-meeting/${meeting.meeting_pdc_id}`
        )
      : history.push(
          meeting.status === CALENDAR.MEETING_STATUS.IN_PLANNING ? `/meeting/${meeting.meeting_id}` : `/meeting/detail/${meeting.meeting_id}`
        );
  };

  return (
    <Container>
      {!isEmpty(data) &&
        data.map((item, index) => (
          <div
            className="d-flex align-items-start"
            style={{
              gap: '10px',
              marginBottom: `${value === moment(item.date).local().format('MM') ? '20px' : ''}`,
            }}
            key={`detail-monthly-year-view${index}`}
          >
            <div className="date">{value === moment(item.date).local().format('MM') ? moment(item.date).local().format('DD') : ''}</div>
            <div style={{ width: '100%' }}>
              {value === moment(item.date).local().format('MM') && <Divider style={{ margin: '0', backgroundColor: '#3F3C4C' }} />}
              <div className="day-of-the-week">{item.dayOfTheWeek}</div>
              {!isEmpty(item.meetings) &&
                value === moment(item.date).local().format('MM') &&
                item.meetings.map((d, i) => (
                  <div style={{ marginTop: '15px' }} key={`detail-monthly-year-view-detail-${i}`}>
                    <div
                      style={{
                        backgroundColor: MEETING_STATUS.find((v) => v.status === d.status)?.color,
                        borderRadius: '6px',
                        color: 'white',
                        display: 'inline-block',
                        padding: '5px 12px',
                        fontSize: '12px',
                        fontWeight: '500',
                      }}
                    >
                      {d.status}
                    </div>
                    <div
                      className="meeting-name"
                      onKeyDown={() => {}}
                      onClick={() => {
                        handleClickMeeting(d);
                      }}
                      style={
                        d.status === CALENDAR.MEETING_STATUS.IN_PLANNING && !d.is_secretariat_or_facilitator && location.pathname !== '/pdc'
                          ? { cursor: 'default' }
                          : null
                      }
                    >
                      {d.name}
                    </div>
                    <div className="meeting-time">
                      {moment(d.start_at).format(DATE_TIME_FORMAT.TIME)} - {moment(d.end_at).format(DATE_TIME_FORMAT.TIME)}
                    </div>
                  </div>
                ))}
              {item.total > 2 && value === moment(item.date).local().format('MM') && (
                <DetailList total={item.total - 2} currentDate={item.date} limit={item.total} {...props} />
              )}
            </div>
          </div>
        ))}
    </Container>
  );
};

export default DetailMonthlyYearView;
