import React from 'react';
import { useSelector } from 'react-redux';

import { Header, Sidebar, Footer } from '..';
import styles from './layout.module.scss';

const Layout = (props) => {
  const { children } = props;
  const statusLeftMenu = useSelector((state) => state.page.statusLeftMenu);
  return (
    <React.Fragment>
      <Header />
      <div className={styles.layout_container}>
        <div className={statusLeftMenu ? styles.sidebar_show : styles.sidebar_hide}>
          <Sidebar statusLeftMenu={statusLeftMenu} />
        </div>
        <div className={styles.layout_content}>{children}</div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default Layout;
