import React, { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { checked, meeting_step_1, meeting_step_2, meeting_step_3 } from '../../../../assets/img';
import styles from './meeting-progress-bar.module.scss';
import { isEmpty, size } from 'lodash';

const peogressBarList = [
  {
    id: 1,
    icon: meeting_step_1,
    step: 'STEP 1',
    stepName: 'Meeting Detail',
  },
  {
    id: 2,
    icon: meeting_step_2,
    step: 'STEP 2',
    stepName: 'Mobility Agenda',
  },
  {
    id: 3,
    icon: meeting_step_3,
    step: 'STEP 3',
    stepName: 'Summary',
  },
];

const MeetingProgressBar = ({ isOnlyViewMeeting, meetingDetail }) => {
  const history = useHistory();
  const { idMeeting } = useParams();

  const getMeetingTabDone = useMemo(() => {
    let tempSteps = [];
    if (meetingDetail?.on_step) {
      for (let i = 1; i <= Number(meetingDetail.on_step); i++) {
        tempSteps = [...tempSteps, i];
      }
    }
    return tempSteps;
  }, [meetingDetail]);

  const getMeetingTabActive = useMemo(() => {
    const tempTabNumber = !meetingDetail?.on_step ? 0 : meetingDetail.on_step;
    let tempActiveSteps = [];
    switch (tempTabNumber) {
      case 0:
        tempActiveSteps = isEmpty(getMeetingTabDone) ? [1] : [];
        break;
      case 1:
        tempActiveSteps = size(getMeetingTabDone) === 1 ? [1, 2] : [];
        break;
      case 2:
        tempActiveSteps = size(getMeetingTabDone) === 2 ? [1, 2, 3] : [];
        break;
      case 3:
        tempActiveSteps = size(getMeetingTabDone) === 3 ? [1, 2, 3] : [];
        break;

      default:
        break;
    }
    return tempActiveSteps;
  }, [meetingDetail, getMeetingTabDone]);

  const handleChecked = (item) => {
    handleRedirectUrl(item.id);
  };

  const handleRedirectUrl = (tabActive) => {
    if (!idMeeting) {
      return history.push(`/pdc-meeting`);
    }

    switch (tabActive) {
      case 1:
        history.push(`/pdc-meeting/${idMeeting}`);
        break;
      case 2:
        history.push(`/pdc-meeting/${idMeeting}/mobilities`);
        break;
      case 3:
        history.push(`/pdc-meeting/${idMeeting}/summary`);
        break;

      default:
        break;
    }
  };

  return (
    <div className={styles.progress_meeting_details}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '20px',
          width: '100%',
        }}
      >
        {peogressBarList.map((item, index) => (
          <div
            key={index}
            className={getMeetingTabActive.includes(item.id) || getMeetingTabDone.includes(item.id) ? styles.step_item_active : styles.step_item}
            style={{ width: '33%', cursor: 'pointer' }}
            onKeyDown={() => {}}
            onClick={() => {
              if (isOnlyViewMeeting) return;
              handleChecked(item);
            }}
          >
            <div
              style={{
                width: '100%',
                backgroundColor: `${getMeetingTabActive.includes(item.id) || getMeetingTabDone.includes(item.id) ? '#009089' : '#8D8D8D'}`,
                height: '4px',
              }}
            ></div>
            <div className="d-flex justify-content-flex-start align-items-center w-100" style={{ gap: '10px', padding: '20px' }}>
              <img
                className={getMeetingTabActive.includes(item.id) || getMeetingTabDone.includes(item.id) ? styles.step_icon_active : styles.step_icon}
                src={item.icon}
                alt=""
              />
              <div className="col-10">
                <div
                  className={styles.step}
                  style={{
                    color: `${getMeetingTabActive.includes(item.id) || getMeetingTabDone.includes(item.id) ? '#009089' : '#8D8D8D'}`,
                  }}
                >
                  {item.step}
                </div>
                <div className={styles.step_children}>{item.stepName}</div>
              </div>
              <img src={getMeetingTabDone.includes(item.id) && !isEmpty(getMeetingTabDone) ? checked : null} alt={''} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default MeetingProgressBar;
