import React, { memo, useEffect, useMemo, useState } from 'react';
import { Row, Spin } from 'antd';
import { FastField, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import {
  AiTitle,
  AiH3,
  FieldRow,
  Hidden,
  AiLabel,
  AiButton,
} from '../../../Meeting/MeetingDetails/Styled';
import FieldGroup from '../../../Meeting/MeetingDetails/components/FieldGroup/FieldGroup';
import {
  ADD_REMARK_SUCCESS_MESSAGE,
  CATEGORY_OPTIONS_PDC,
  EDIT_REMARK_SUCCESS_MESSAGE,
  FILL_OUT_REQUIRE_MESSAGE,
  MESSAGE_TYPES,
} from '../../../../utils/constants';
import FieldSelect from '../../../Meeting/MeetingDetails/components/FieldSelect/FieldSelect';
import FieldInput from '../../../Meeting/MeetingDetails/components/FieldInput/FieldInput';
import PDCTalentNameField from './PDCTalentNameField';
import { pdcMeetingApi } from '../../../../services/pdcMeetingApi';
import { pushMessage } from '../../../../store/alertMessageSlice';

const AddPDCRemark = memo(({ idMeeting, setAddRemarkSuccess, editRemark, setEditRemark }) => {
  const [isLoading, setLoading] = useState(false);
  const userInfos = useSelector((state) => state.user);
  const { roleId } = useSelector((state) => state.user.roleActive);
  const isEdit = useMemo(() => !!editRemark , [editRemark]);
  const { isSubmittedFormChanges } = useSelector(
    (state) => state.app.pdcMeetingModule.prePDCMeeting
  );
  const dispatch = useDispatch();
  const [validateOnChange, setValidateOnChange] = useState(false);
  const INITIAL_VALUES = {
    staff: null,
    remark: '',
    category: null,
    mobility_id: null,
  };

  const DisplayBox = React.memo(({ children, isDisplay }) => {
    return (
      <>
        {isDisplay
          ? children
          : React.Children.map(children, (child) => <Hidden>{child}</Hidden>)}
      </>
    );
  });
  DisplayBox.displayName = 'DisplayBox';

  const CategorySelect = React.memo((props) => {
    return (
      <FastField
        name="category"
        options={CATEGORY_OPTIONS_PDC}
        component={FieldSelect}
        placeholder="Select Category"
        {...props}
      />
    );
  });
  CategorySelect.displayName = 'CategorySelect';

  const handleSubmit = async (values, actions) => {
    try {
      setLoading(true);
      const body = values
        ? {
            staff_id: values?.staff?.value,
            remarks: values?.remark,
            category: values?.category.value,
            mobility_id: values?.mobility_id?.value,
            meeting_id: idMeeting,
          }
        : {};
      const res = await pdcMeetingApi.savePDCRemarks({
        body,
        roleId,
        userInfos,
      });
      if (res.status === 200) {
        setAddRemarkSuccess(true);
        dispatch(
          pushMessage({
            type: MESSAGE_TYPES.SUCCESS,
            message: ADD_REMARK_SUCCESS_MESSAGE,
            timeShow: 0,
          })
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
    setValidateOnChange(false);
    actions.resetForm();
  };

  const handleUpdateSubmit = async (values, actions) => {
    try {
      setLoading(true);
      if (editRemark) {
        const body = values
          ? {
            staff_id: values?.staff?.value,
            remarks: values?.remark,
            category: values?.category.value,
            mobility_id: values?.mobility_id?.value,
          }
          : {};
        const res = await pdcMeetingApi.updatePDCRemark({
          body,
          meeting_id: idMeeting,
          id: editRemark?.remarkId,
          roleId,
          userInfos,
        });
        if (res.status === 200) {
          setAddRemarkSuccess(true);
          setEditRemark(null);
          dispatch(
            pushMessage({
              type: MESSAGE_TYPES.SUCCESS,
              message: EDIT_REMARK_SUCCESS_MESSAGE,
              timeShow: 0,
            })
          );
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
    setValidateOnChange(false);
    actions.resetForm();
  };

  const validationSchema = yup.object().shape({
    remark: yup.string().nullable().required('Remarks is required.'),
    category: yup.object().nullable().required('Category is required.'),
    staff: yup.object().nullable().required('Talent Name is required.'),
  });

  const formikRef = React.createRef();
  useEffect(() => {
    if (editRemark) {
      formikRef.current.setFieldValue('staff', {
        value: editRemark?.staffId,
        label: editRemark?.birthName,
      });
      formikRef.current.setFieldValue('mobility_id', {
        value: editRemark?.mobility_id,
      });
      formikRef.current.setFieldValue('remark', editRemark?.remarks);
      formikRef.current.setFieldValue('category', {
        value: editRemark?.category,
        label: editRemark?.category,
      });
    }
  }, [editRemark]);

  return (
    <Formik
      innerRef={formikRef}
      validateOnBlur={false}
      validateOnChange={validateOnChange}
      initialValues={INITIAL_VALUES}
      onSubmit={(values, actions) => isEdit ? handleUpdateSubmit(values, actions) : handleSubmit(values, actions)}
      validationSchema={validationSchema}
    >
      {({
        values,
        setFieldValue,
        getFieldMeta,
        handleSubmit,
        setTouched,
        validateForm,
        setValues,
      }) => {
        const handleCustomSubmit = async () => {
          await setTouched({
            remark: true,
            category: true,
            staff: true,
          });
          const validationErrors = await validateForm();
          if (Object.keys(validationErrors).length > 0) {
            console.log('Validation failed', validationErrors);
            dispatch(
              pushMessage({
                type: MESSAGE_TYPES.ERROR,
                message: FILL_OUT_REQUIRE_MESSAGE,
                timeShow: 0,
              })
            );
            return;
          }

          handleSubmit();
        };
        const handleCancel = () => {
          setAddRemarkSuccess(false);
          setValues({
            ...INITIAL_VALUES,
          });
          setEditRemark(null);
        };
        return (
          <>
            <Spin size="large" spinning={isLoading}>
              <AiTitle as="div">
                <AiH3>{`${isEdit ? `Edit` : `Add`} Remark`}</AiH3>
              </AiTitle>
              <Row className="my-3">
                <FieldRow className="w-100">
                  <DisplayBox isDisplay={true}>
                    <FieldGroup
                      width="608px"
                      label={<AiLabel>Talent Name</AiLabel>}
                    >
                      <PDCTalentNameField
                        field={{ value: values.staff, name: 'staff' }}
                        form={{
                          setFieldValue: setFieldValue,
                          getFieldMeta: getFieldMeta,
                        }}
                        mobilityIdName={'mobility_id'}
                        placeholder="Enter Talent Name"
                        formValues={values}
                        disabled={false}
                        idMeeting={idMeeting}
                      />
                    </FieldGroup>
                  </DisplayBox>
                  <DisplayBox isDisplay={true}>
                    <FieldGroup
                      width="608px"
                      label={<AiLabel>Category</AiLabel>}
                    >
                      <CategorySelect />
                    </FieldGroup>
                  </DisplayBox>
                </FieldRow>
                <FieldGroup
                  width="100%"
                  label={<AiLabel>Remarks</AiLabel>}
                  className="mt-3"
                >
                  <FieldInput
                    fieldAs="textarea"
                    height="unset"
                    rows={5}
                    name="remark"
                    placeholder="Enter your remarks here"
                  />
                </FieldGroup>
              </Row>
              <Row style={{ gap: '8px' }} justify="end">
                <AiButton
                  border={true}
                  borderColor={`#00a19c`}
                  style={{ width: `100px` }}
                  onClick={handleCancel}
                >
                  Cancel
                </AiButton>
                <AiButton
                  onKeyDown={() => {}}
                  onClick={() => {
                    handleCustomSubmit();
                    setValidateOnChange(true);
                  }}
                  mode="teal"
                  disabled={isSubmittedFormChanges}
                >
                  {`${isEdit ? `Save` : `Add`} Remark`}
                </AiButton>
              </Row>
            </Spin>
          </>
        );
      }}
    </Formik>
  );
});

AddPDCRemark.displayName = 'AddPDCRemark';

export default AddPDCRemark;
