import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  finder_grid_disable,
  finder_list_disable,
  view_grid_enable,
  view_list_enable,
} from '../../../../../assets/img';
import BoxPrintSP from '../../../../Agenda/components/BoxDSP/BoxPrintSP';
import GirdBoxPrintSP from '../../../../Agenda/components/BoxDSP/GirdBoxPrintSP';
import PrintApprovedSp from '../../../../Agenda/components/PrintApprovedSP/PrintApprovedSp';
import PositionItem from '../Positions/PositionItem';
import styles from './view-sucession-plan-popup.module.scss';
import { blockStyleForSP } from './ViewSucessionPlanPopup';

// import { INIT_POSITION_VALUES } from '../../../../../utils/constants';
// import { handleShowCT, handleShowRetirementDate, handleShowRetirementToView, handleShowYIPAndYISG } from '../../../../../utils/helper';
// const ROW_STYLES = {
//   row_item: { alignContent: 'flex-start', width: '960px' },
//   row_only_one_item: { width: '480px', alignContent: 'start' },
//   row_first_item: { width: '480px', alignContent: 'start', paddingRight: '30px' },
// };

const GroupButton = styled.div`
  display: flex;
  align-items: flex-end;
  transform: translate(4%, 20%);
  position: absolute;
  bottom: 10px;
  right: -15px;

  img {
    width: 45px;
    height: 45px;
    cursor: pointer;
    pointer-events: all;
  }

  img:active {
    transform: translateY(3%);
  }

  @media print {
    right: 0 !important;
  }
`;

const PrintSucessionPlanPopup = (props) => {
  const { isShowSuccessionPlanPopup, setIsShowSuccessionPlanPopup, positions, isDisabledPrintBtn } =
    props;

  const agendaIncumbent = useSelector((state) => state.app.meetingModule.agenda.agendaIncumbent);
  const [line1, setLine1] = useState([]);
  const [line2, setLine2] = useState([]);
  const [line3, setLine3] = useState([]);
  // const [information, setInformation] = useState({
  //   ...INIT_POSITION_VALUES,
  // });
  const printRef = React.createRef();
  const [columnMode, setColumnMode] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);

  const columnGirdStyle = {
    border: 'solid 1px #d3dee8',
    borderRadius: '5px',
    maxWidth: '32.33%',
  };

  const customFileName = useMemo(() => {
    const lastPosition = !isEmpty(agendaIncumbent?.positions)
      ? agendaIncumbent?.positions[agendaIncumbent.positions.length - 1]
      : null;
    return lastPosition
      ? `SP_${lastPosition?.position_name}_${
          lastPosition?.last_approved_date
            ? moment(lastPosition.last_approved_date).format('YYYYMMDD')
            : ''
        }`
      : `View_Succession_Plan`;
  }, [agendaIncumbent]);

  useEffect(() => {
    setLine1(agendaIncumbent.first_line);
    setLine2(agendaIncumbent.second_line);
    setLine3(agendaIncumbent.third_line);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    JSON.stringify(agendaIncumbent.first_line),
    JSON.stringify(agendaIncumbent.second_line),
    JSON.stringify(agendaIncumbent.third_line),
  ]);

  // const handleDataInformationStaff = useCallback(() => {
  //   setInformation({
  //     ...INIT_POSITION_VALUES,
  //   });

  //   positions.map((item) => {
  //     if (!isEmpty(item?.incumbent)) {
  //       setInformation((preState) => {
  //         return {
  //           ...preState,
  //           name: [...preState.name, `${item.incumbent?.name ?? 'None '} ${item.incumbent?.ct ? handleShowCT(item.incumbent?.ct) : ''}`],
  //           age: [...preState.age, item.incumbent.age],
  //           retirementOrContractEndDate: [
  //             ...preState.retirementOrContractEndDate,
  //             `${handleShowRetirementDate(item.incumbent?.retirement_date || item.incumbent?.contract_end_date)} (${
  //               item.incumbent?.retirement_date || item.incumbent?.contract_end_date ? handleShowRetirementToView(item.incumbent) : 'None'
  //             })`,
  //           ],
  //           proposedSuccessionPlanning: [
  //             ...preState.proposedSuccessionPlanning,
  //             `${item.incumbent?.position_name} (Position ID: ${item?.position_code})`,
  //           ],
  //           roleAndJG: [...preState.roleAndJG, `${item.incumbent?.role_level} ${item.incumbent?.jg_new ? `(${item.incumbent?.jg_new})` : ''}`],
  //           yearsInPositionAndSince: [
  //             ...preState.yearsInPositionAndSince,
  //             `${handleShowYIPAndYISG(item.incumbent?.years_in_position, item.incumbent?.date_in_position)} ${
  //               item.incumbent?.date_in_position ? `(since ${moment(new Date(item.incumbent?.date_in_position)).format('DD/MM/YYYY')})` : ''
  //             }`,
  //           ],
  //           incumbents: !isEmpty(item?.incumbent) ? [...preState.incumbents, item.incumbent] : preState.incumbents,
  //         };
  //       });
  //     }
  //     return null;
  //   });
  // }, [positions]);

  // useEffect(() => {
  //   handleDataInformationStaff();
  // }, [positions, handleDataInformationStaff]);

  return (
    <div>
      <Modal
        show={isShowSuccessionPlanPopup}
        onHide={() => setIsShowSuccessionPlanPopup(false)}
        dialogClassName={styles.viewSusccessionPlanPopup}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <div
          className={styles.viewSusccessionPlanContent}
          style={{ marginBottom: 0, paddingBottom: 0 }}
          ref={printRef}
        >
          <table role="presentation" aria-hidden="true" style={{ width: '100%' }}></table>
          <Modal.Header
            className={styles.modalHeader}
            closeButton={true}
            style={{ border: `none` }}
          >
            <div className={styles.inforWrapper}>
              <h2 className={`${styles.mainTitle} mainTitleHeader`}>
                Proposed Succession Planning
                {/* Proposed Succession Planning - {handleShowInformation(information.proposedSuccessionPlanning)} */}
              </h2>
              <table
                className={styles.wrapperInfo}
                role="presentation"
                aria-hidden="true"
                style={{ width: '100%' }}
              >
                <tbody>
                  {!isEmpty(positions) && (
                    <tr style={{ width: '100%' }}>
                      <td style={{ width: '100%', paddingRight: '90px' }}>
                        {positions.map((item, index) => (
                          <PositionItem
                            key={index}
                            data={item}
                            isEdit={false}
                            handleDeletePosition={() => {}}
                            lastItem={positions.length - 1 === index}
                          />
                        ))}
                      </td>
                      {/* <td style={{ paddingRight: `20px` }}>
                      <div className={styles.mainAvatar} style={{ marginRight: 0 }}>
                        <img
                          className={`mainAvatar`}
                          src={!isEmpty(positions) ? decodeBase64ToImage(positions[0]?.incumbent?.image) : avatar}
                          alt="avatar"
                        />
                      </div>
                    </td>
                    <td className={`itemInfoPosition`}>
                      <tr className={styles.rowItem} style={ROW_STYLES.row_item}>
                        <td className={`${styles.fontMinimal} fontMinimalText`} style={ROW_STYLES.row_first_item}>
                          Name: {handleShowInformation(information.name)}
                        </td>
                        <td className={`${styles.fontMinimal} fontMinimalText`} style={{ width: '480px', alignContent: 'start' }}>
                          Role: {handleShowInformation(information.roleAndJG)}
                        </td>
                      </tr>
                      <tr className={styles.rowItem} style={ROW_STYLES.row_item}>
                        <td className={`${styles.fontMinimal} fontMinimalText`} style={ROW_STYLES.row_first_item}>
                          Age: {handleShowInformation(information.age)}
                        </td>
                        <td className={`${styles.fontMinimal} fontMinimalText`} style={{ width: '480px', alignContent: 'start' }}>
                          {getRetirementContractEndLabel(information.incumbents)} {handleShowInformation(information.retirementOrContractEndDate)}
                        </td>
                      </tr>
                      <tr className={styles.rowItem} style={ROW_STYLES.row_item}>
                        <td className={`${styles.fontMinimal} fontMinimalText`} style={ROW_STYLES.row_only_one_item}>
                          Years In Position: {handleShowInformation(information.yearsInPositionAndSince)}
                        </td>
                      </tr>
                    </td> */}
                    </tr>
                  )}
                  <tr style={{ position: 'relative' }}>
                    <GroupButton>
                      <img
                        src={columnMode ? view_grid_enable : finder_grid_disable}
                        alt="Grid"
                        onKeyDown={() => {}}
                        onClick={() => {
                          setColumnMode(true);
                        }}
                      />
                      <img
                        src={!columnMode ? view_list_enable : finder_list_disable}
                        alt="List"
                        onKeyDown={() => {}}
                        onClick={() => {
                          setColumnMode(false);
                        }}
                      />
                    </GroupButton>
                  </tr>
                </tbody>
              </table>
            </div>
          </Modal.Header>

          <Modal.Body className={styles.modalBody} style={{ marginBottom: 0, paddingBottom: 0 }}>
            {!columnMode ? (
              <div className="d-flex justify-content-between align-items-start no-gutters">
                <div className={`col-12`}>
                  <BoxPrintSP
                    text={'1st Line'}
                    line={line1}
                    blockStyle={blockStyleForSP.first_line}
                    isPrinting={isPrinting}
                  />
                  <BoxPrintSP
                    text={'2nd Line'}
                    line={line2}
                    blockStyle={blockStyleForSP.second_line}
                    isPrinting={isPrinting}
                  />
                  <BoxPrintSP
                    text={'3rd Line'}
                    line={line3}
                    blockStyle={blockStyleForSP.third_line}
                    isPrinting={isPrinting}
                  />
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-between align-items-stretch no-gutters">
                <div className={`col-4 girdColumnWrapper`} style={columnGirdStyle}>
                  <GirdBoxPrintSP
                    text={'1st Line'}
                    line={line1}
                    isPrinting={isPrinting}
                    blockStyle={blockStyleForSP.first_line}
                  />
                </div>
                <div className={`col-4 girdColumnWrapper`} style={columnGirdStyle}>
                  <GirdBoxPrintSP
                    text={'2nd Line'}
                    line={line2}
                    isPrinting={isPrinting}
                    blockStyle={blockStyleForSP.second_line}
                  />
                </div>
                <div className={`col-4 girdColumnWrapper`} style={columnGirdStyle}>
                  <GirdBoxPrintSP
                    text={'3rd Line'}
                    line={line3}
                    isPrinting={isPrinting}
                    blockStyle={blockStyleForSP.third_line}
                  />
                </div>
              </div>
            )}
          </Modal.Body>
        </div>
        <div className={styles.wrapperButton}>
          <PrintApprovedSp
            componentRef={printRef}
            customFileName={customFileName}
            style={{ backgroundColor: isDisabledPrintBtn ? '#6AC2BE' : '#00a19c', color: 'white' }}
            isDisabled={isDisabledPrintBtn}
            setIsPrinting={setIsPrinting}
          />
        </div>
      </Modal>
    </div>
  );
};

export default PrintSucessionPlanPopup;
