import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import * as styles from './print-cover.module.scss';
import { avatar_not_found, pdc_print_cover, petronas_logo_2 } from '../../../../../assets/img';
import moment from 'moment';
import { BasicButton, BasicSelect } from '../../../../../assets/common';
import { MEETING_DETAIL_STATUS } from '../../../../../utils/constants';
import { debounce } from 'lodash';
import { Spin } from 'antd';
import { pdcMeetingApi } from '../../../../../services/pdcMeetingApi';
import { useSelector } from 'react-redux';
import { decodeBase64ToImage } from '../../../../../utils/helper';

const PrintCover = ({ data, selectedEndorsed, setSelectedEndorsed, isErrorEndorsed, setIsErrorEndorsed }) => {
  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const roleActive = useSelector((state) => state.user.roleActive);

  const year = useMemo(() => {
    const currentYear = new Date().getFullYear();
    if (!data) return currentYear;
    return data.status === MEETING_DETAIL_STATUS.CONCLUDED ? moment(data.date).year() : currentYear;
  }, [data]);

  const handleClickChange = () => {
    setSelectedEndorsed(null);
  };

  const fetchData = async (keyword) => {
    setLoading(true);
    try {
      const permission = {
        role: roleActive.roleId,
      };
      const params = { search: keyword, limit: 4, page: 1 };
      const res = await pdcMeetingApi.getSearchStaff(params, permission);
      if (res.status === 200) {
        setOptions(res?.data?.result?.profiles || []);
      }
    } catch (error) {
      console.error('Failed to fetch data:', error);
      setOptions([]);
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchData = debounce(fetchData, 500);

  const handleSearch = (value) => {
    if (value) {
      debouncedFetchData(value);
    }
  };

  const handleClickSelect = async () => {
    setSelectedEndorsed(value);
    setIsErrorEndorsed(false);
    setValue(null);
    try {
      await pdcMeetingApi.saveEndorsedBy(data.meeting_pdc_id, value.staffId);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelect = (value) => {
    const item = options.find((item) => item.staffId === value);
    setValue(item);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        <div className={styles.name}>
          <div>PROPOSALS</div>
          <div>{data?.meeting_name}</div>
        </div>
        <div className={styles.date}>
          <div>{data?.date && moment(data?.date).format('DD.MM.YYYY')}</div>
        </div>
        <div className={styles.desc1}>
          The PETRONAS Group adopts zero tolerance against all forms of bribery and corruption. We abide by the PETRONAS Code Of Conduct and Business
          Ethics (CoBE) & Anti-Bribery and Corruption (ABC) Manual, guided by our Shared Values and Statement of Purpose.
        </div>
        <div className={styles.desc2}>
          <div>©{year} Petroliam Nasional Berhard (PETRONAS)</div>
          <div>
            All rights reserved. No part of this document may be reproduced in any possible, stored in a retrieval system, transmitted and/or
            disseminated in any form or by any means (digital, mechanical, hard copy, recording or otherwise) without the permission of the copyright
            owner.
          </div>
        </div>
      </div>
      <div className={styles.right}>
        {selectedEndorsed ? (
          <div className={styles.endorsed}>
            <div>Endorsed by</div>
            <div />
            <div>
              <div className={styles.positionWidth}>{selectedEndorsed.birthName || 'N/A'}</div>
              <span className={styles.changeBtn} onClick={handleClickChange}>
                Change
              </span>
            </div>
            <div className={styles.positionWidth}>{selectedEndorsed.positionName || 'N/A'} / ELT PDC Secretary</div>
          </div>
        ) : (
          <Spin spinning={loading}>
            <div className={styles.selection}>
              <div>Select Endorser</div>
              <div>Endorser</div>
              <BasicSelect
                showSearch
                placeholder={
                  value ? (
                    <div>
                      <span>{value?.birthName || value?.positionName}</span>
                    </div>
                  ) : (
                    'Search By Staff Name/ Staff ID'
                  )
                }
                value={null}
                filterOption={false}
                onSearch={handleSearch}
                onSelect={handleSelect}
                getPopupContainer={(trigger) => trigger}
                virtual={false}
                error={isErrorEndorsed && !value?.birthName}
              >
                {options.map((item) => (
                  <BasicSelect.Option key={item.staffId} value={item.staffId}>
                    <div className={styles.option}>
                      <div className={styles.avatar}>
                        <img alt="avatar" src={item.image ? decodeBase64ToImage(item.image) : avatar_not_found} />
                      </div>
                      <div className={styles.optionInfo}>
                        <div>{item.birthName || 'N/A'}</div>
                        <div>{item.positionName || 'N/A'} / ELT PDC Secretary</div>
                      </div>
                    </div>
                  </BasicSelect.Option>
                ))}
              </BasicSelect>
              {isErrorEndorsed && !value?.birthName && <div className={styles.requireField}>Required field</div>}
              <BasicButton disabled={!value} mode="teal" className="mt-5" onClick={handleClickSelect}>
                Select
              </BasicButton>
            </div>
          </Spin>
        )}
      </div>
      <img className={styles.cover} src={pdc_print_cover} alt="print-cover" />
      <img className={styles.logo} src={petronas_logo_2} alt="petronas_logo" />
    </div>
  );
};

export default PrintCover;

PrintCover.propTypes = {
  data: PropTypes.object,
};
