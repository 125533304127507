import { Pagination, Spin } from 'antd';
import { cloneDeep, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactDragListView from 'react-drag-listview';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import styled from 'styled-components';
import { BasicButton } from '../../../assets/common';
import {
  close_icon_light,
  deleteIcon,
  drag_drop,
  filter_mobility_icon,
  iconPaperBringToForward,
  paperInProgress,
  paperReady,
} from '../../../assets/img';
import { BreadCrumb, ModelTC } from '../../../components';
import GlobalAlertMessage from '../../../components/GlobalAlertMessage/GlobalAlertMessage';
import { useGetPDCMeeting } from '../../../hooks/useGetPDCMeeting';
import { useGetRoleInPdcMeeting } from '../../../hooks/useGetRoleInPdcMeeting';
import { useUpdatePdcMeetingStepNumber } from '../../../hooks/useUpdatePdcMeetingStepNumber';
import { pdcMeetingApi } from '../../../services/pdcMeetingApi';
import { pushMessage } from '../../../store/alertMessageSlice';
import { AGENDA_STATUS, MEETING_DETAIL_STATUS, MESSAGE_TYPES, NOTIFICATION_TYPE, ROLE, USER_ACCESS_MESSAGE } from '../../../utils/constants';
import { getBreadCrumbList } from '../../../utils/helper';
import { showNotification } from '../../../utils/notification';
import AddExistingPDCMeeting from '../components/AddExistingPDCMeeting/AddExistingPDCMeeting';
import MeetingProgressBar from '../components/MeetingProgressBar/MeetingProgressBar';
import PDCMeetingStatus from '../components/PDCMeetingStatus/PDCMeetingStatus';
import { PDC_MOBILITY_STATUS } from '../constants';
import * as styles from './pdc-meeting-mobilities.module.scss';

const INIT_DATA = { group_mobilities: [], total: 0 };
const BREAD_CRUMB = getBreadCrumbList(['Homepage', 'PDC Meeting', 'Mobility Agenda']);

const PDCMeetingMobilities = ({ isComponent, isEditMode, meetingStatus, isExistingPaperReady, setIsExistingPaperReady }) => {
  const history = useHistory();
  let { idMeeting } = useParams();
  const roleActive = useSelector((state) => state.user.roleActive);
  const { data: meetingRole } = useGetRoleInPdcMeeting({ idMeeting });

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(INIT_DATA);
  const [page, setPage] = useState(1);
  const [openAddExistingMobility, setOpenAddExistingMobility] = useState(null);
  const [showConfirmDeleteMeeting, setShowConfirmDeleteMeeting] = useState(false);
  const [isShowSOrtByBuModal, setIsShowSortByBuModal] = useState(false);
  const [buSortList, setBuSortList] = useState([]);
  const [prevBuSortList, setPrevBuSortList] = useState([]);

  // Get pdc meeting
  const { data: meetingDetail } = useGetPDCMeeting({ idMeeting });

  // Fetch api to update step number
  const { updateMeetingStepNumber } = useUpdatePdcMeetingStepNumber();

  // Fetch Bu sort list
  const fetchBuSortList = useCallback(async () => {
    if (!idMeeting || !roleActive?.roleId) return;

    try {
      setLoading(true);
      const res = await pdcMeetingApi.getBuSortListOfMeeting(idMeeting, roleActive?.roleId);
      if (res.status === 200) {
        const tempData = !isEmpty(res?.data?.result) ? res?.data?.result.map((item) => ({ value: item?.value, name: item?.label })) : [];
        setBuSortList(tempData);
        setPrevBuSortList(tempData);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [idMeeting, roleActive]);

  useEffect(() => {
    fetchBuSortList();
  }, [fetchBuSortList]);

  const isAdminRole = useMemo(() => {
    return roleActive?.roleName ? roleActive?.roleName.includes(ROLE.ADMIN) : false;
  }, [roleActive]);



  const isCanEdit = useMemo(() => {
    return (!isComponent || isEditMode) && (meetingRole?.is_secretariat_or_facilitator || isAdminRole);
  }, [isComponent, isEditMode, meetingRole?.is_secretariat_or_facilitator, isAdminRole]);

  const displayData = useMemo(() => {
    const LIMIT_PER_PAGE = 10;
    const agendaTypes =
      meetingStatus === MEETING_DETAIL_STATUS.IN_PROGRESS
        ? [AGENDA_STATUS.PAPER_READY, AGENDA_STATUS.PAPER_FINALIZED]
        : [MEETING_DETAIL_STATUS.IN_REVIEW, MEETING_DETAIL_STATUS.CONCLUDED].includes(meetingStatus)
        ? [AGENDA_STATUS.PAPER_FINALIZED]
        : [AGENDA_STATUS.PAPER_READY];
    const allGroupedMobilities = [];
    data.group_mobilities?.forEach((group) => {
      group.mobilities.forEach((mobility) => {
        if (!isComponent || (isComponent && agendaTypes.includes(mobility.status)) || isEditMode) {
          mobility.status === AGENDA_STATUS.PAPER_READY &&
            setIsExistingPaperReady &&
            setIsExistingPaperReady((prev) => ({ ...prev, data: [...prev.data, mobility] }));
          allGroupedMobilities.push({
            business_unit: group.business_unit,
            ...mobility,
          });
        }
      });
    });

    const totalMobilities = allGroupedMobilities.length;

    const startIndex = (page - 1) * LIMIT_PER_PAGE;
    const endIndex = startIndex + LIMIT_PER_PAGE;
    const paginatedMobilities = allGroupedMobilities.slice(startIndex, endIndex);

    const result = {};
    paginatedMobilities.forEach((mobility) => {
      if (!result[mobility.business_unit]) {
        result[mobility.business_unit] = [];
      }
      result[mobility.business_unit].push(mobility);
    });

    // Format the result for output
    const groupedResult = Object.keys(result).map((business_unit) => ({
      business_unit: business_unit || null,
      mobilities: result[business_unit],
    }));

    return {
      total: totalMobilities,
      group_mobilities: groupedResult,
    };
  }, [data, isEditMode, meetingStatus, page]);

  const fetchMobilities = useCallback(async () => {
    try {
      setLoading(true);
      const res = await pdcMeetingApi.getPdcMobilities({ idMeeting, page: 1, roleId: roleActive?.roleId });
      if (res.status === 200) {
        setData(res.data.result);
      }
    } catch (error) {
      console.error(error);
      showNotification(USER_ACCESS_MESSAGE.AN_UNEXPECTED_ERROR, NOTIFICATION_TYPE.FAILED);
      setData(INIT_DATA);
    } finally {
      setLoading(false);
    }
  }, [idMeeting]);

  const handleClickAddNew = () => {
    history.push(`/pdc-meeting/${idMeeting}/mobility/create`);
  };

  const handleClickDelete = () => {
    setShowConfirmDeleteMeeting(true);
  };

  const handleDeleteMeeting = async () => {
    try {
      setLoading(true);
      let res = await pdcMeetingApi.deleteMeeting({ idMeeting });
      if (res.status === 200) {
        history.push('/pdc');
        // showNotification('Meeting deleted successfully!', NOTIFICATION_TYPE.SUCCESSFUL);
      }
    } catch (error) {
      console.error(error);
      showNotification('Meeting delete failed!', NOTIFICATION_TYPE.FAILED);
    } finally {
      setLoading(false);
      setShowConfirmDeleteMeeting(false);
    }
  };

  const handleClickExit = () => {
    history.push('/pdc');
  };

  const handleClickBack = () => {
    history.push(`/pdc-meeting/${idMeeting}`);
  };

  const handleClickNext = async () => {
    if (Number(meetingDetail?.on_step) < 2) {
      await updateMeetingStepNumber(idMeeting, 2);
    }
    history.push(`/pdc-meeting/${idMeeting}/summary`);
  };

  const handleChangePage = (value) => {
    setPage(value);
  };

  const handleClickMobility = (id) => {
    history.push(`/pdc-meeting/${idMeeting}/mobility/${id}`);
  };

  const handleCloseSortModal = () => {
    setIsShowSortByBuModal(false);
  };

  useEffect(() => {
    fetchMobilities();
  }, [fetchMobilities]);

  // Handle click outside
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const dropdownRef = useRef(null);
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsShowSortByBuModal(false);
    }
  };

  const handleApply = async () => {
    try {
      setLoading(true);
      const payload = {
        meeting_id: idMeeting,
        bu_sort_list: !isEmpty(buSortList) ? buSortList.map((i) => i.value) : [],
      };
      const res = await pdcMeetingApi.updateBuSortListOfMeeting(payload, idMeeting, roleActive?.roleId);
      if (res.status === 200) {
        fetchBuSortList();
        fetchMobilities(1);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setIsShowSortByBuModal(false);
    }
  };

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const data = [...cloneDeep(buSortList)];
      const item = data.splice(fromIndex, 1)[0];
      data.splice(toIndex, 0, item);
      setBuSortList(data);
    },
    nodeSelector: '.sortItem',
    handleSelector: '.sortItem',
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEndMobility = async (fromIndex, toIndex, type, index) => {
    if (toIndex < 0) return;

    if (type === 'SUBLIST-ITEM') {
      const subList = reorder(displayData.group_mobilities[index].mobilities, fromIndex, toIndex);
      const mobilities = JSON.parse(JSON.stringify(displayData.group_mobilities));
      mobilities[index].mobilities = subList;

      try {
        setLoading(true);
        const payload = {
          meeting_id: idMeeting,
          group_mobilities: mobilities.map((i) => ({
            business_unit: i?.business_unit,
            mobilities: !isEmpty(i?.mobilities) ? i?.mobilities.map((m) => ({ id: m?.id, title: m?.title })) : [],
          })),
        };
        const res = await pdcMeetingApi.updateMobilitiesOfMeeting(payload, idMeeting, roleActive?.roleId);
        if (res.status === 200) {
          fetchMobilities(1);
          if (Number(meetingDetail?.on_step) < 2) {
            await updateMeetingStepNumber(idMeeting, 2);
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }

      return setData((prev) => {
        return {
          ...prev,
          group_mobilities: mobilities,
        };
      });
    } else return new Error('NOT A VALID LIST');
  };

  useEffect(() => {
    if (!isEditMode) setPage(1);
  }, [isEditMode]);

  return (
    <>
      {!isComponent && (
        <>
          <BreadCrumb level={3} breadCrumbList={BREAD_CRUMB} />
          <GlobalAlertMessage style={{ marginBottom: '20px' }} />
          <MeetingProgressBar meetingDetail={meetingDetail} />
        </>
      )}

      <Spin spinning={loading}>
        <div className={styles.wrapper}>
          <div className={styles.titleRow}>
            {!isComponent ? (
              <div className={styles.title}>
                <span>Mobility Agenda</span>
                <PDCMeetingStatus status={data.meeting_status} />
              </div>
            ) : (
              <div className={styles.title}>
                <span>Mobility Agenda</span>
              </div>
            )}
            {isCanEdit && (
              <div className={styles.btnRow}>
                <BasicButton onClick={() => setOpenAddExistingMobility(true)}>Add Existing Mobility</BasicButton>
                <BasicButton mode="teal" onClick={handleClickAddNew}>
                  Add New Mobility
                </BasicButton>
                <CustomSortWrap ref={dropdownRef}>
                  <CustomBtn
                    onClick={() => {
                      setIsShowSortByBuModal(!isShowSOrtByBuModal);
                      setBuSortList(prevBuSortList);
                    }}
                  >
                    <img src={filter_mobility_icon} alt="" />
                  </CustomBtn>
                  {isShowSOrtByBuModal && (
                    <CustomSortContent>
                      <div className="sortHead">
                        <span>Sort by : Business</span>
                        <img src={close_icon_light} alt="" onClick={handleCloseSortModal} />
                      </div>
                      <div className="sortList">
                        <ReactDragListView {...dragProps}>
                          <div className="sortList">
                            {!isEmpty(buSortList) &&
                              buSortList.map((item, index) => (
                                <div key={index} className="sortItem">
                                  <div className="name">
                                    <span>{index + 1}</span>
                                    <span>{item.name || 'N/A'}</span>
                                  </div>
                                  <img className="drag_drop_icon" src={drag_drop} alt="" />
                                </div>
                              ))}
                          </div>
                        </ReactDragListView>
                      </div>
                      <div className="sortBot">
                        <BasicButton style={{ minWidth: '118px' }} onClick={handleCloseSortModal}>
                          Cancel
                        </BasicButton>
                        <BasicButton style={{ minWidth: '118px' }} mode="teal" disabled={isEmpty(buSortList)} onClick={handleApply}>
                          Apply
                        </BasicButton>
                      </div>
                    </CustomSortContent>
                  )}
                </CustomSortWrap>
              </div>
            )}
          </div>
          {displayData.total > 0 && (
            <>
              <div className={styles.list}>
                {displayData.group_mobilities.map((group, index) => (
                  <div key={group.business_unit} className={styles.buGroupWrap}>
                    <div className={`${styles.businessUnit} business_unit_list`}>Business: {group.business_unit || 'N/A'}</div>
                    <ReactDragListView
                      nodeSelector=".ant-spin-nested-loading.mobility_item"
                      handleSelector=".ant-spin-nested-loading.mobility_item"
                      lineClassName="dragLine"
                      onDragEnd={(fromIndex, toIndex) => onDragEndMobility(fromIndex, toIndex, 'SUBLIST-ITEM', index)}
                    >
                      <div className={styles.businessGroup}>
                        {group.mobilities.map((mobility) => (
                          <MobilityItem
                            key={mobility.id}
                            data={mobility}
                            fetchMobilities={fetchMobilities}
                            idMeeting={idMeeting}
                            handleClickMobility={handleClickMobility}
                            isCanEdit={isCanEdit}
                            isExistingPaperReady={isExistingPaperReady}
                            fetchBuSortList={fetchBuSortList}
                            meetingStatus={meetingDetail?.status}
                          />
                        ))}
                      </div>
                    </ReactDragListView>
                  </div>
                ))}
              </div>

              <div className="mt-3">
                <Pagination
                  total={displayData.total}
                  showTotal={(total, range) => ShowTotal({ total, range })}
                  current={page}
                  defaultPageSize={10}
                  showSizeChanger={false}
                  onChange={handleChangePage}
                />
              </div>
            </>
          )}
        </div>
      </Spin>

      {!isComponent && (
        <div className={styles.bottomRow}>
          <div>
            {(meetingRole?.is_secretariat_or_facilitator || isAdminRole) && (
              <BasicButton mode="remove" onClick={handleClickDelete}>
                Delete Meeting
              </BasicButton>
            )}
          </div>
          <div className={styles.btnRow}>
            <BasicButton width="100px" onClick={handleClickExit}>
              Exit
            </BasicButton>
            <BasicButton width="100px" onClick={handleClickBack}>
              Back
            </BasicButton>
            <BasicButton mode="teal" width="100px" onClick={handleClickNext}>
              Next
            </BasicButton>
          </div>
        </div>
      )}

      <ModelTC
        info={{
          type: 'deleteMeeting',
          visible: showConfirmDeleteMeeting,
          setVisible: setShowConfirmDeleteMeeting,
          handleSubmit: handleDeleteMeeting,
          onClose: () => {},
          loading,
        }}
      />
      {openAddExistingMobility && (
        <AddExistingPDCMeeting
          open={openAddExistingMobility}
          setOpen={setOpenAddExistingMobility}
          idMeeting={idMeeting}
          fetchMobilities={fetchMobilities}
          fetchBuSortList={fetchBuSortList}
        />
      )}
    </>
  );
};
PDCMeetingMobilities.propTypes = {
  isComponent: PropTypes.bool,
  isEditMode: PropTypes.bool,
  meetingStatus: PropTypes.string,
};
export default PDCMeetingMobilities;

const MobilityItem = ({ data, fetchMobilities, handleClickMobility, isCanEdit, isExistingPaperReady, fetchBuSortList, meetingStatus }) => {
  const { id, advocators, status, title } = data;
  const [showConfirmArchive, setShowConfirmArchive] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const displayAdvocators = useMemo(() => {
    return advocators.map((ad) => ad.name).join(` and `);
  }, [advocators]);

  const handleClickArchive = (e) => {
    e.stopPropagation();
    setShowConfirmArchive(true);
  };

  const handleArchive = async () => {
    try {
      setLoading(true);
      const res = await pdcMeetingApi.archivePdcMobility(id);
      if (res.status === 200) {
        await fetchMobilities(1);
        await fetchBuSortList();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setShowConfirmArchive(false);
    }
  };

  const handleClickDelete = (e) => {
    e.stopPropagation();
    setShowConfirmDelete(true);
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      const res = await pdcMeetingApi.deletePdcMobility(id);
      if (res.status === 200) {
        fetchMobilities(1);
        fetchBuSortList();
        dispatch(
          pushMessage({
            type: MESSAGE_TYPES.SUCCESS,
            message: 'Mobility has been deleted successfully.',
            timeShow: 0,
          })
        );
      }
    } catch (error) {
      showNotification('Mobility delete failed!', NOTIFICATION_TYPE.SUCCESSFUL);
      console.error(error);
    } finally {
      setLoading(false);
      setShowConfirmDelete(false);
    }
  };

  return (
    <Spin spinning={loading} wrapperClassName="mobility_item">
      <div
        onClick={() => handleClickMobility(id)}
        className={`${styles.item} ${isExistingPaperReady?.isHighLight && status === AGENDA_STATUS.PAPER_READY && styles.highlight}`}
      >
        <div className={styles.advocatorCol}>
          {isCanEdit && <img src={drag_drop} alt="drag-drop" />}
          <div className={styles.advocator}>
            <div>Advocator Name</div>
            <div>{displayAdvocators}</div>
          </div>
        </div>
        <div className={styles.statusCol}>
          <div className={styles.title} style={{ width: '650px', wordBreak: 'break-word' }}>
            {title}
          </div>
          <div className={styles.btnRow}>
            {![MEETING_DETAIL_STATUS.IN_REVIEW, MEETING_DETAIL_STATUS.CONCLUDED].includes(meetingStatus) && <MobilityStatus status={status} />}
            {isCanEdit && (
              <>
                {[AGENDA_STATUS.PAPER_IN_PROGRESS, AGENDA_STATUS.PAPER_READY].includes(data.status) && (
                  <BasicButton mode="icon" onClick={handleClickArchive}>
                    <img src={iconPaperBringToForward} alt="archive" />
                  </BasicButton>
                )}
                <BasicButton mode="icon" onClick={handleClickDelete}>
                  <img src={deleteIcon} alt="delete" />
                </BasicButton>
              </>
            )}
          </div>
        </div>
      </div>
      <ModelTC
        info={{
          type: 'archivePdcMobility',
          visible: showConfirmArchive,
          setVisible: setShowConfirmArchive,
          handleSubmit: handleArchive,
          onClose: () => setShowConfirmArchive(false),
          loading,
        }}
      />
      <ModelTC
        info={{
          type: 'deletePdcMobility',
          visible: showConfirmDelete,
          setVisible: setShowConfirmDelete,
          handleSubmit: handleDelete,
          onClose: () => setShowConfirmDelete(false),
          loading,
        }}
      />
    </Spin>
  );
};
MobilityItem.propTypes = {
  data: PropTypes.object,
  fetchMobilities: PropTypes.func,
  handleClickMobility: PropTypes.func,
  isCanEdit: PropTypes.bool,
  fetchBuSortList: PropTypes.func,
};

export const MobilityStatus = ({ status }) => {
  const displayByStatus = useMemo(() => {
    const result = {
      imgSrc: paperInProgress,
      styles: { color: '#f08800', background: '#fdf7b0' },
    };
    switch (status) {
      case PDC_MOBILITY_STATUS.PAPER_IN_PROGRESS:
        result.imgSrc = paperInProgress;
        result.styles = { color: '#f08800', background: '#fdf7b0' };
        break;
      case PDC_MOBILITY_STATUS.PAPER_READY:
      case PDC_MOBILITY_STATUS.FINALIZED:
        result.imgSrc = paperReady;
        result.styles = { color: '#007724', background: '#e5f4e7' };
        break;
      default:
        break;
    }
    return result;
  }, [status]);

  return (
    <div className={styles.mobilityStatus} style={displayByStatus.styles}>
      <img src={displayByStatus.imgSrc} alt="mobility-status" />
      <div>{status}</div>
    </div>
  );
};
MobilityStatus.propTypes = {
  status: PropTypes.string,
};

const ShowTotal = ({ total, range }) => (
  <span>
    Showing <span>{range[0]}</span> to <span>{range[1]}</span> of <span>{total}</span> results
  </span>
);
ShowTotal.propTypes = {
  total: PropTypes.number,
  range: PropTypes.array,
};

// Style component
const CustomSortWrap = styled.div`
  position: relative;
`;

const CustomBtn = styled.button`
  border: none;
  background: none;
  outline: none;
  background: transparent;
`;

const CustomSortContent = styled.div`
  position: absolute;
  top: 40px;
  right: 0;
  min-width: 314px;
  padding: 32px;
  border-radius: 6px;
  background: #fff;
  z-index: 10;
  box-shadow: 0 5px 10px -3px grey;

  .sortHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    span {
      color: var(--Emphasis-Body-N800, #3f3c4c);
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.3px;
    }

    img {
      width: 24px;
      cursor: pointer;
    }
  }

  .sortList {
    margin-bottom: 30px;
    .sortItem {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
      cursor: pointer;

      .name {
        max-width: 220px;
        display: flex;
        gap: 15px;
      }
    }
  }

  .sortBot {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
