import { combineReducers } from '@reduxjs/toolkit';
import pdcMeetingDetailReducer from './meetingDetailSlice';
import meetingProgressBarReducer from './meetingProgressBarSlice';
import pdcSupportingDocumentsReducer from './pdcSupportingDocumentSlice';

const reducer = combineReducers({
  prePDCMeeting: pdcMeetingDetailReducer,
  meetingStepperDetail: meetingProgressBarReducer,
  pdcSupportingDocuments: pdcSupportingDocumentsReducer,
});

export default reducer;
