import { Tooltip } from 'antd';
import { chunk } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  chevron_down,
  in_active_icon,
  red_dot,
  suspended_icon,
  warning_dot,
} from '../../../../assets/img/';
import CardSettings from '../../../../components/CardSettings/CardSettings';
import { useCardSetting } from '../../../../hooks/useCardSetting';
import { SP_CARD_DEFAULT } from '../../../../utils/constants';
import {
  decodeBase64ToImage,
  handleShowCT,
  handleShowRetirementDate,
  handleShowTextTooltip,
  handleShowTooltip,
  handleShowYIPAndYISG,
  hideOtherTalentLabel,
} from '../../../../utils/helper';
import { ApprovedLabel } from '../../../Meeting/AgendaDetails/components/Recommendations/Box/ApprovedLabel';
import styles from './box-dsp.module.scss';

const convertLine = [
  { text: '1st Line', value: 'first_line' },
  { text: '2nd Line', value: 'second_line' },
  { text: '3rd Line', value: 'third_line' },
];

const CardBox = (props) => {
  const { text, item, index, incumbents, color } = props;
  const boxRef = useRef();
  const cardSettingsDSP = useSelector((state) => state.page.cardSettingsDSP);
  const lineSelected = convertLine.find((f) => f.text === text).value;
  const lineSettings = cardSettingsDSP[lineSelected];
  useCardSetting({ boxRef, lineSettings });

  return (
    <div
      className={`${styles.card_box} card-dsp`}
      style={{
        padding: '10px',
        border: '1px solid #e7e7f0',
        borderTop: `8px solid ${color}`,
        borderRadius: '6px',
        width: '390px',
      }}
      data-testid="view-card-box"
      ref={boxRef}
    >
      <div
        className="d-flex no-gutters justify-content-start align-items-stretch w-100"
        style={{ gap: '10px' }}
      >
        <div className={`${styles.left}`}>
          <div className="row flex-column no-gutters align-items-start h-100">
            <div className={styles.number}>{index + 1}</div>
            <div className={styles.image} data-cardSettings={SP_CARD_DEFAULT.PROFILE_PICTURE}>
              <img src={decodeBase64ToImage(item?.image)} alt="" />
              {['INACTIVE', 'SUSPENDED'].includes(item?.assignment_status_type) && (
                <Tooltip
                  placement="top"
                  title={
                    <div
                      style={{
                        padding: '5px',
                      }}
                    >
                      <div
                        style={{
                          color: '#F3F3F3',
                          fontFamily: 'Inter',
                          fontSize: '12px',
                          fontWeight: 600,
                          marginBottom: '10px',
                        }}
                      >
                        {item?.assignment_status_type === 'INACTIVE' ? 'In-active' : 'Suspended'}
                      </div>
                      <div
                        className="d-flex justify-content-start align-items-center"
                        style={{
                          gap: '10px',
                        }}
                      >
                        <img
                          src={item?.assignment_status_type === 'INACTIVE' ? red_dot : warning_dot}
                          alt="icon"
                        />
                        <div
                          style={{
                            color: '#F3F3F3',
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: 400,
                          }}
                        >
                          {item?.action_name} {item?.action_reason && `(${item?.action_reason})`}
                        </div>
                      </div>
                    </div>
                  }
                  color={'black'}
                  key={'black'}
                  overlayInnerStyle={{ borderRadius: '6px' }}
                  data-testid="show-tooltip"
                >
                  <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
                    <img
                      src={
                        item?.assignment_status_type === 'INACTIVE'
                          ? in_active_icon
                          : suspended_icon
                      }
                      style={{ height: '15px', width: '15px' }}
                      alt="icon"
                    />
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
        </div>

        <div className={`${styles.right}`}>
          <div className="d-flex no-gutters justify-content-between align-items-stretch">
            <div className={styles.name}>
              <Link
                to={`/profile/${item?.staff_id}`}
                target="_blank"
                className={styles.recommendation__name}
                draggable="false"
                data-cardSettings={SP_CARD_DEFAULT.STAFF_NAME}
              >
                {item?.birth_name || item?.candidate_name} &#160;
              </Link>
              <div
                className={styles.recommendation__name}
                data-cardSettings={SP_CARD_DEFAULT.TOP_TALENT_STATUS}
              >
                {!hideOtherTalentLabel(
                  item.assignment_status_type,
                  item.role_level,
                  item?.top_talent_status
                ) &&
                  !['119463', '1032501'].includes(item?.staff_id) &&
                  handleShowCT(item?.top_talent_status)}
              </div>
              <div
                className={styles.recommendation__name}
                data-cardSettings={SP_CARD_DEFAULT.MATCHING_PERCENTAGE}
              >
                {item?.percentage ? `${item?.percentage}%` : ''}
              </div>
            </div>
          </div>

          <div className="mb-1" data-cardSettings={SP_CARD_DEFAULT.POSITION}>
            <span
              style={{ fontWeight: '500', fontSize: '14px', lineHeight: '14px', color: '#3d3d3d' }}
            >
              {item?.position_name}
            </span>
          </div>
          <div style={{ fontSize: '12px' }}>
            <div className="mb-1" data-cardSettings={SP_CARD_DEFAULT.BUSINESS}>
              <span>Business: </span>
              <span style={{ fontWeight: '500' }}>{item?.business_unit}</span>
            </div>
            <div className="mb-1" data-cardSettings={SP_CARD_DEFAULT.SECTOR}>
              <span>Sector: </span>
              <span style={{ fontWeight: '500' }}>{item?.sector}</span>
            </div>
            <div className="mb-1" data-cardSettings={SP_CARD_DEFAULT.OPU}>
              <span>OPU: </span>
              <span style={{ fontWeight: '500' }}>{item?.opu}</span>
            </div>
            <div className="mb-1" data-cardSettings={SP_CARD_DEFAULT.DIVISION}>
              <span>Division: </span>
              <span style={{ fontWeight: '500' }}>{item?.division}</span>
            </div>
            <div className="mb-1" data-cardSettings={SP_CARD_DEFAULT.DEPARTMENT}>
              <span>Department: </span>
              <span style={{ fontWeight: '500' }}>{item?.department_name}</span>
            </div>
          </div>
          <div
            className="mb-1 d-flex justify-content-start"
            style={{ gap: '10px', flexWrap: 'wrap', alignItems: 'center' }}
          >
            <div data-cardSettings={SP_CARD_DEFAULT.STAFF_ID}>
              <span>ID: </span>
              <span style={{ fontWeight: 'bold' }}> {item?.identity_number || item?.staff_id}</span>
            </div>
            <span>
              {lineSettings
                .map((i) => i.value)
                .some((l) =>
                  [
                    SP_CARD_DEFAULT.FIRST_LINE,
                    SP_CARD_DEFAULT.SECOND_LINE,
                    SP_CARD_DEFAULT.THIRD_LINE,
                  ].includes(l)
                ) && (
                <Tooltip
                  placement="bottom"
                  title={handleShowTextTooltip(item, incumbents, lineSettings)}
                  color={'black'}
                  key={'black'}
                  overlayInnerStyle={{
                    borderRadius: '6px',
                    minWidth: 'max-content',
                    padding: '12px',
                    width: '100%',
                  }}
                  className={styles.sp_counter}
                >
                  {handleShowTooltip(item, incumbents, lineSettings)}
                </Tooltip>
              )}
            </span>
            <div data-cardSettings={SP_CARD_DEFAULT.APPROVAL_STATUS}>
              <ApprovedLabel positions={incumbents} item={item} />
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          border: '1px solid #CBD6E2',
          backgroundColor: '#F2F8FD',
          borderRadius: '6px',
          fontSize: '12px',
          padding: '10px',
        }}
        data-cardSettings={'border'}
      >
        <div className="mb-2 d-flex justify-content-start" style={{ gap: '20px' }}>
          <div data-cardSettings={SP_CARD_DEFAULT.YEAR_IN_POSITION}>
            <span>YIP: </span>
            <div style={{ fontWeight: 'bold' }}>
              {handleShowYIPAndYISG(
                item?.years_in_position ?? item?.year_in_position,
                item?.date_in_position
              )}
            </div>
          </div>
          <div data-cardSettings={SP_CARD_DEFAULT.YEAR_IN_SG}>
            <span>YISG: </span>
            <div style={{ fontWeight: 'bold' }}>
              {item?.sg} (
              {handleShowYIPAndYISG(item?.years_in_sg ?? item?.year_in_sg, item?.date_in_sg)})
            </div>
          </div>
        </div>
        <div className="mb-2 d-flex justify-content-start" style={{ gap: '28px' }}>
          <div data-cardSettings={SP_CARD_DEFAULT.AGE}>
            <span>Age: </span>
            <div style={{ fontWeight: 'bold' }}>{item?.age}</div>
          </div>
          <div data-cardSettings={SP_CARD_DEFAULT.RETIREMENT_DATE}>
            <span>Retirement: </span>
            <div style={{ fontWeight: 'bold' }}>
              {handleShowRetirementDate(item.retirement_date)}
              {item?.retirement_date && (item?.dateOfBirth || item?.birth_date)
                ? `(${
                    Number(moment(item?.retirement_date).format('YYYY')) -
                    Number(moment(item?.dateOfBirth).format('YYYY'))
                  })`
                : ''}
            </div>
          </div>
          <div className="mb-2" data-cardSettings={SP_CARD_DEFAULT.PERFORMANCE_RATING}>
            <span>Performance Rating: </span>
            <div style={{ fontWeight: 'bold' }}>{item?.performance_rating}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

CardBox.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  incumbents: PropTypes.array,
};

const BoxDSP = (props) => {
  const { color, text, line, incumbents, numRows1, numRows2 } = props;
  const containerRef = useRef(null);
  const [collapseMenu, setCollapseMenu] = useState(true);
  const chunkLine = line.length ? chunk(line, 3) : [];

  const isPageBreak = () => {
    const page1Rows = 4;
    const pageRows = 6;
    const numRows1And2 = numRows1 + numRows2;
    switch (text) {
      case '1st Line':
        break;
      case '2nd Line':
        if ((numRows1 - 4) % pageRows === 0 && line?.length) return true;
        break;
      default:
        if ((numRows1 === 0 && numRows2 === page1Rows) || (numRows1And2 - 4) % pageRows === 0) {
          return true;
        }
        break;
    }
    return false;
  };

  const boxRef = useRef();
  const cardSettingsDSP = useSelector((state) => state.page.cardSettingsDSP);
  const lineSelected = convertLine.find((f) => f.text === text).value;
  const lineSettings = cardSettingsDSP[lineSelected];
  useCardSetting({ boxRef, lineSettings });

  return (
    <>
      <div
        style={
          isPageBreak()
            ? {
                breakInside: 'auto',
              }
            : null
        }
      />
      <div className={`${styles.container}`}>
        <table className={`${styles.collapse} ${styles.printTable}`} data-testid="box-line">
          <thead>
            <tr>
              <th colSpan={3}>
                <div
                  onKeyDown={() => {}}
                  onClick={() => setCollapseMenu(!collapseMenu)}
                  className={styles.collapseHeader}
                  style={{ backgroundColor: color }}
                  data-testid="click-collapse"
                >
                  <div data-testid="name-box-line" className={styles.left}>
                    {text}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'center',
                      gap: '30px',
                    }}
                  >
                    <div
                      style={{
                        paddingTop: '8px',
                        cursor: 'pointer',
                      }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <CardSettings text={text} lineSelected={lineSelected} type="card-dsp" />
                    </div>
                    <img src={chevron_down} alt="" className={collapseMenu ? styles.animate : ``} />
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody
            data-testid="view-collapse-body"
            className={styles.collapseBody}
            style={{ display: 'grid', gridTemplateRows: collapseMenu ? '1fr' : '0fr' }}
            ref={containerRef}
          >
            <div style={{ overflow: 'hidden' }}>
              {chunkLine &&
                chunkLine?.length > 0 &&
                chunkLine?.map((items, idx) => {
                  return (
                    <tr key={idx}>
                      {items.map((item, index) => (
                        <td key={index}>
                          <CardBox
                            item={item}
                            index={index + 3 * idx}
                            incumbents={incumbents}
                            text={text}
                            color={color}
                          />
                        </td>
                      ))}
                      {items.length === 1 && (
                        <>
                          <td></td>
                          <td></td>
                        </>
                      )}
                      {items.length === 2 && <td></td>}
                    </tr>
                  );
                })}
            </div>
          </tbody>
        </table>
      </div>
    </>
  );
};

BoxDSP.propTypes = {
  color: PropTypes.string,
  text: PropTypes.string,
  line: PropTypes.array,
  incumbents: PropTypes.array,
  numRows1: PropTypes.number,
  numRows2: PropTypes.number,
};

export default BoxDSP;
