import React, { useEffect, useRef, useState } from 'react';
import * as styles from './print-select-button.module.scss';
import { AiCheckBox, BasicButton, BasicSwitch } from '../../../../../assets/common';
import { pdcMeetingApi } from '../../../../../services/pdcMeetingApi';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { Arrow_Down_2 } from '../../../../../assets/img';
import { PRINT_MEETING_OPTIONS, PRINT_MOBILITY_OPTIONS } from '../../../constants';
import { cloneDeep, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

const PrintSelectButton = ({ border }) => {
  const [open, setOpen] = useState(false);
  const [mobilities, setMobilities] = useState([]);
  const [expandIds, setExpandIds] = useState([]);
  const [selectedData, setSelectedData] = useState({
    common: {
      details: { checked: true, preRead: true },
      summary: {
        checked: true,
        preRead: true,
      },
    },
  });
  const [selectedAll, setSelectedAll] = useState({});
  const ref = useRef();
  const { idMeeting } = useParams();
  const roleActive = useSelector((state) => state.user.roleActive);

  const handleClickExpand = (mobilityId) => {
    const isCollapse = expandIds.includes(mobilityId);
    const newExpandIds = isCollapse ? expandIds.filter((id) => id !== mobilityId) : [...expandIds, mobilityId];
    setExpandIds(newExpandIds);
  };

  const initSelectedData = (resMobilities) => {
    const selectedMobility = {
      mobilities: resMobilities,
    };
    const selectedAll = {};
    resMobilities.forEach((mobility) => {
      selectedMobility[mobility.id] = {};
      PRINT_MOBILITY_OPTIONS.forEach(({ value }) => {
        selectedMobility[mobility.id][value] = { checked: true, preRead: true };
        selectedAll[mobility.id] = true;
      });
    });
    return { selectedMobility, selectedAll };
  };

  const handleChange = (parentName, content, field, value) => {
    const newSelectedData = cloneDeep(selectedData);
    newSelectedData[parentName][content][field] = value;
    setSelectedData(newSelectedData);
    if (isNaN(parentName) || field !== 'checked') return;

    let isCheckedAll = true;
    for (const key in newSelectedData[parentName]) {
      if (!newSelectedData[parentName][key].checked) {
        isCheckedAll = false;
        break;
      }
    }
    const newSelectedAll = cloneDeep(selectedAll);
    newSelectedAll[parentName] = isCheckedAll;
    setSelectedAll(newSelectedAll);
  };

  const handleChangeCheckBoxAll = (checked, id) => {
    const newState = cloneDeep(selectedData[id]);
    setSelectedAll((prev) => ({
      ...prev,
      [id]: checked,
    }));
    for (const key in newState) {
      newState[key].checked = checked;
    }
    setSelectedData((prev) => ({
      ...prev,
      [id]: newState,
    }));
  };

  const handleClickPrintPreview = () => {
    localStorage.setItem('printPdcFilter', JSON.stringify(selectedData));
    window.open(`/preview-print-pdc/${idMeeting}`, '_blank');
    setOpen(false);
  };

  useEffect(() => {
    if (!idMeeting) return;
    const fetchMobilities = async () => {
      try {
        const res = await pdcMeetingApi.getPrintPdcMobilities({ idMeeting, roleId: roleActive?.roleId });
        if (res.status === 200) {
          const result = res.data.result;
          setMobilities(result);
          const { selectedMobility, selectedAll } = initSelectedData(result);
          setSelectedData({ ...selectedData, ...selectedMobility });
          setSelectedAll(selectedAll);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchMobilities();
  }, [idMeeting]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [open]);

  return (
    <div className={styles.wrapper} ref={ref}>
      <BasicButton border={border || 'none'} type="button" onClick={() => setOpen(!open)}>
        Print
      </BasicButton>
      <div className={styles.dropdown} style={open ? { zIndex: 10, opacity: 1 } : { zIndex: -10, opacity: 0 }}>
        <div className={styles.title}>
          <div>CONTENT</div>
          <div>PRE-READ</div>
        </div>
        <div className={styles.list}>
          {PRINT_MEETING_OPTIONS.map(({ value, label }) => (
            <div className={styles.row} key={value}>
              <div>
                <AiCheckBox
                  checked={selectedData?.common?.[value]?.checked}
                  onChange={(e) => handleChange('common', value, 'checked', e.target.checked)}
                />{' '}
                {label}
              </div>
              <BasicSwitch
                bgColor="#002a53"
                disabled={!selectedData?.common?.[value]?.checked}
                checked={selectedData?.common[value]?.preRead}
                onChange={(checked) => handleChange('common', value, 'preRead', checked)}
              />
            </div>
          ))}
          {!isEmpty(mobilities) &&
            mobilities.map((mobility, index) => (
              <React.Fragment key={mobility.id}>
                <div className={styles.row}>
                  <div>
                    <BasicButton mode="icon" type="button" onClick={() => handleClickExpand(mobility.id)}>
                      <img
                        src={Arrow_Down_2}
                        alt="expand"
                        className={`${styles.expandBtn} ${expandIds.includes(mobility.id) ? styles.expand : ''}`}
                      />
                    </BasicButton>
                    <AiCheckBox checked={selectedAll?.[mobility.id]} onChange={(e) => handleChangeCheckBoxAll(e.target.checked, mobility.id)} />
                    <div>
                      {index + 1}. {mobility.title}
                    </div>
                  </div>
                </div>
                <div key={mobility.id} className={`${styles.mobilities} ${expandIds.includes(mobility.id) ? styles.expand : ''}`}>
                  {PRINT_MOBILITY_OPTIONS.map(({ value, label }) => (
                    <div key={value} className={styles.row}>
                      <div style={{ marginLeft: 22 }}>
                        <AiCheckBox
                          checked={selectedData?.[mobility.id]?.[value]?.checked}
                          onChange={(e) => handleChange(mobility.id, value, 'checked', e.target.checked)}
                        />{' '}
                        {label}
                      </div>
                      <BasicSwitch
                        disabled={!selectedData?.[mobility.id]?.[value]?.checked}
                        bgColor="#002a53"
                        checked={selectedData?.[mobility.id]?.[value]?.preRead}
                        onChange={(checked) => handleChange(mobility.id, value, 'preRead', checked)}
                      />
                    </div>
                  ))}
                </div>
              </React.Fragment>
            ))}
        </div>
        <div className={styles.btnPreview}>
          <BasicButton
            mode="teal"
            type="button"
            onClick={handleClickPrintPreview}
            disabled={!selectedData.common.details.checked && !selectedData.common.summary.checked}
          >
            Print Preview
          </BasicButton>
        </div>
      </div>
    </div>
  );
};

export default PrintSelectButton;
