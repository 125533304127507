import { Input, List } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  background-color: white;
  width: fit-content;
  width: 100%;
`;
export const WrapperPopUp = styled.div`
  position: absolute;
  z-index: 1000;
  top: 50px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #d3dee8;
  background: var(--Shades-White, #fff);
  box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.2);
`;

export const WrapperPopUpImage = styled.div`
  position: absolute;
  z-index: 1000;
  top: 30px;
  transform: translateX(-26%);
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #d3dee8;
  background: var(--Shades-White, #fff);
  box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.2);
`;

export const TitleDiv = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    !props?.columnNumber ? 'repeat(5, 250px)' : `repeat(${Number(props.columnNumber)}, ${1230 / Number(props.columnNumber)}px)`};
  border-bottom: 1px solid var(--Light-Gray-300, #d6d6d6);
  background: var(--Light-Blue-Grays-100, #f2f4f8);
  height: 44px;
  div {
    color: #181818;
    padding-left: 20px;
    font-size: 14px;
    font-weight: 500;
    line-height: 44px; /* 142.857% */
    letter-spacing: 0.235px;
  }
`;

export const ListAntd = styled(List)`
  .ant-list-items {
    li:first-child {
      height: auto !important;
    }
    .ant-list-item {
      align-items: flex-start;
      cursor: pointer;
      display: grid;
      grid-template-columns: ${(props) =>
        !props?.columnNumber ? 'repeat(5, 250px)' : `repeat(${props.columnNumber}, ${1230 / Number(props.columnNumber)}px)`};
      border-bottom: 1px solid var(--Light-Gray-300, #d6d6d6);
      background: var(--Base-White, #fff);
      padding: 0;
      &:hover {
        background: #e0f3f3;
        .function-name {
          background: #e0f3f3 !important;
        }
      }
      div {
        color: var(--Base-Black, #181818);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.235px;
        padding: 12px 0;
        padding-left: 20px;
      }
      .function-name {
        background-color: #f2f4f8;
        height: 100%;
      }
    }
  }
`;
export const InputStyle = styled(Input)`
  padding: 0 5px !important;
  width: 100%;
  border-radius: 4px;
  border: ${(props) => (props.status === 'error' ? '1px solid red' : `1px solid #d9d9d9`)};
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  :hover {
    border: 1px solid ${(props) => (props.isError ? '#DA2228' : '#40a9ff')} !important;
  }
  .ant-input {
    outline: none !important;
    border: none !important;
  }
  input {
    padding: 10px 0 !important;
  }
  .ant-input-prefix {
    margin: 0 !important;
  }
  .ant-input-suffix {
    margin: 0 !important;
  }
`;

export const SelectedItem = styled.div`
  border-radius: 4px;
  border: ${(props) => (props.status === 'error' ? '1px solid red' : `1px solid #d9d9d9`)};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  background: #fff;
  /* Shadow sm */
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;
  width: 100%;

  :hover {
    border: 1px solid ${(props) => (props.status === 'error' ? 'red' : '#00a19c')} !important;
  }

  &.isDisabled {
    background: #e0e0e0 !important;

    .optionItem {
      background: #e0e0e0 !important;
    }
  }

  .optionList {
    width: 100%;
    display: flex;
    flex: 1;
    align-items: center;
    flex-wrap: wrap;
    cursor: default;
    padding: 8px 10px 8px 15px;
    gap: 8px;

    .optionItem {
      display: inline-block;
      color: var(--Emphasis-Body-N800, #424242);
      text-align: center;
      font-feature-settings: 'liga' off, 'clig' off;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.5px;
      border-radius: var(--border-width-00-px, 11px);
      border: 1px solid #bdbdbd;
      background: var(--Primary-N0, #fff);
      margin-bottom: 2px;
      padding: 5px 10px;

      img {
        cursor: pointer;
        margin-right: 5px;
      }
    }
  }

  .textPlacebolder {
    width: 100%;
    color: var(--Grays-300, #8d8d8d);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    align-items: center;
    min-height: 45px;
    padding: 8px 10px 8px 15px;
  }

  .clearBtnWrap {
    padding: 0 8px;
  }

  .clearBtn {
    padding: 5px !important;
    width: 24px;
    opacity: 0.6;
  }
`;

export const SearchInputWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  flex-wrap: wrap;
  cursor: default;
  outline: 0 !important;
  position: relative;
  overflow: hidden;
  transition: all 100ms;
  background-color: hsl(0, 0%, 100%);
  border-radius: 4px;
  border: ${(props) => (props.status === 'error' ? '1px solid red' : `1px solid #d9d9d9`)};
  gap: 8px;

  .optionList {
    width: 100%;
    display: flex;
    flex: 1;
    align-items: center;
    flex-wrap: wrap;
    cursor: default;
    padding: 8px 10px 8px 15px;
    gap: 8px;

    .optionItem {
      display: inline-block;
      color: var(--Emphasis-Body-N800, #424242);
      text-align: center;
      font-feature-settings: 'liga' off, 'clig' off;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.5px;
      border-radius: var(--border-width-00-px, 11px);
      border: 1px solid #bdbdbd;
      background: var(--Primary-N0, #fff);
      margin-bottom: 2px;
      padding: 5px 10px;

      img {
        cursor: pointer;
        margin-right: 5px;
      }
    }
  }

  .clearBtnWrap {
    padding: 0 8px;
    align-self: stretch;
    display: flex;
    flex-shrink: 0;
  }

  .clearBtn {
    padding: 5px !important;
    width: 24px;
    opacity: 0.6;
  }
`;

export const InputStyleCustom = styled.input`
  border: none;
  outline: none;
  flex: 1 1 auto;
  display: inline-grid;
  grid-area: 1 / 1 / 2 / 3;
  grid-template-columns: 0 min-content;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: hsl(0, 0%, 20%);

  &::placeholder {
    color: var(--Grays-300, #8d8d8d);
    font-size: 14px;
  }
`;
