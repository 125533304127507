import { Col, Row, Space, Spin } from 'antd';
import { useFormik } from 'formik';
import __, { find, get, isArray, isEmpty, map } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { BasicButton } from '../../../assets/common';
import { homepage_icon } from '../../../assets/img';
import { AsyncMultipleSelect, BreadCrumb, FieldError, ModelTC } from '../../../components';
import GlobalAlertMessage from '../../../components/GlobalAlertMessage/GlobalAlertMessage';
import SearchByAdditionalViewAccessForTcMeeting from '../../../components/SearchByAdditionalViewAccessForTcMeeting/SearchByAdditionalViewAccessForTcMeeting';
import SearchByCategoryForTcMeeting from '../../../components/SearchByCategoryForTcMeeting/SearchByCategoryForTcMeeting';
import SearchBySecretariatForTcMeeting from '../../../components/SearchBySecretariatForTcMeeting/SearchBySecretariatForTcMeeting';
import { isCheckError } from '../../../hooks/useFormik';
import { useGetPDCMeeting } from '../../../hooks/useGetPDCMeeting';
import { useGetRoleInPdcMeeting } from '../../../hooks/useGetRoleInPdcMeeting';
import { useSecretarialOptions } from '../../../hooks/useSecretarialOptions';
import { pdcMeetingApi } from '../../../services/pdcMeetingApi';
import { meetingApi } from '../../../services/tcMeeting';
import { pushMessage } from '../../../store/alertMessageSlice';
import {
  AN_UNEXPECTED_ERROR,
  DATE_TIME_FORMAT,
  MEETING_CATEGORY_OPTIONS,
  MEETING_CATEGORY_VALUES,
  MEETING_DETAIL_STATUS,
  MESSAGE_TYPES,
  NOTIFICATION_TYPE,
  PAGE_NAME,
  ROLE,
  USER_ACCESS_MESSAGE,
} from '../../../utils/constants';
import {
  getAsyncOptionAdvocatorsHrPartners,
  getBusinessIdToPopulateForAdditionalView,
  getFieldValueForTCMeetingInFirstTime,
  getLabelNameForSecretariatOfTcMeeting,
} from '../../../utils/helper';
import { showNotification } from '../../../utils/notification';
import ViewMoMFile from '../../Meeting/components/ViewMoMFile/ViewMoMFile';
import MeetingCategory from '../../Meeting/MeetingDetails/components/MeetingCategory';
import {
  AiContainerForm,
  AiDatePicker,
  AiH2,
  AiInput,
  AiLabel,
  AiTimePickerField,
  AiTitleWrapper,
  MeetingTitle,
} from '../../Meeting/MeetingDetails/Styled';
import ContainerSummary from '../components/ContainerSummary/ContainerSummary';
import PDCRemarks from '../components/PDCRemarks/PDCRemarks';
import { validationSchema } from '../PDCMeetingDetails/meeting-validations';
import PDCMeetingMobilities from '../PDCMeetingMobilities/PDCMeetingMobilities';
import PrintSelectButton from '../PDCPrintPage/components/PrintSelectButton/PrintSelectButton';
import { addOrUpdateMeeting, clearPDCMeeting, initialStateMeeting } from '../redux/meetingDetailSlice';
import styles from './concluded-meeting-details.module.scss';
import PDCMeetingStatus from '../components/PDCMeetingStatus/PDCMeetingStatus';

const ConcludedMeetingDetail = () => {
  const { meeting, isEditMode } = useSelector((state) => state.app.pdcMeetingModule.prePDCMeeting);
  const roleActive = useSelector((state) => state.user.roleActive);
  const [isOpenCategoryDropdown, setIsOpenCategoryDropdown] = useState();
  const [meetingSecretariatValue, setMeetingSecretariatValue] = useState([]);
  const [additionalViewAccessValue, setAdditionalViewAccessValue] = useState([]);
  // Category Value
  const [isLoadAddtionalDataInFirstTime, setIsLoadAdditionalDataInFirstTime] = useState(true);

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showNotifyModal, setShowNotifyModal] = useState(false);

  const [loading, setLoading] = useState(false);

  // Upload file state
  const [fileUpload, setFileUpload] = useState([]);

  const isAdminRole = useMemo(() => {
    return roleActive?.roleName ? roleActive?.roleName.includes(ROLE.ADMIN) : false;
  }, [roleActive]);

  // Set Meeting Secretariats
  useEffect(() => {
    formik.setFieldValue('meeting_secretariats', meetingSecretariatValue || []);
  }, [meetingSecretariatValue]);

  // Set Meeting Additional View Access
  useEffect(() => {
    formik.setFieldValue('meeting_additional_view_accesses', additionalViewAccessValue || []);
  }, [additionalViewAccessValue]);

  const dispatch = useDispatch();
  const history = useHistory();

  let { idMeeting } = useParams();

  const { data: meetingDetail, fetch: fetchMeeting, loading: loadingGetMeeting } = useGetPDCMeeting({ idMeeting });

  const { secretarialOptions } = useSecretarialOptions('add_meeting');

  const { data: meetingRole, getRoleInMeeting } = useGetRoleInPdcMeeting({ idMeeting });

  const breadCrumbList = useMemo(() => {
    return [
      { name: 'Homepage', icon: homepage_icon, url: '/homepage' },
      { name: 'PDC Meeting', url: '/pdc' },
      { name: meetingDetail.meeting_name || '' },
    ];
  }, [meetingDetail.meeting_name]);

  const resetMeetingDetails = () => {
    if (!idMeeting) return;

    formik.setValues({
      ...initialStateMeeting,
      meeting_pdc_id: idMeeting || null,
    });
    dispatch(clearPDCMeeting());
    setMeetingSecretariatValue([]);
    setAdditionalViewAccessValue([]);
  };

  useEffect(() => {
    if (meetingDetail?.meeting_pdc_id) {
      dispatch(
        addOrUpdateMeeting({
          ...meetingDetail,
          category: find(MEETING_CATEGORY_OPTIONS, {
            name: meetingDetail.category,
          }),
          category_value: {
            name: meetingDetail.category_value,
            category_label: meetingDetail.category_label,
          },
          meeting_secretariats: meetingDetail?.meeting_secretariats,
          meeting_additional_view_accesses: meetingDetail?.meeting_additional_view_accesses,
        })
      );
      setMeetingSecretariatValue(getFieldValueForTCMeetingInFirstTime(meetingDetail?.meeting_secretariats));
      setMeetingSecretariatValue(getFieldValueForTCMeetingInFirstTime(meetingDetail?.meeting_additional_view_accesses));
      if (meetingDetail?.file_url) {
        setFileUpload([
          {
            url: meetingDetail?.file_url,
            file_size: meetingDetail?.file_size,
            file_name: meetingDetail?.file_name,
          },
        ]);
      }
    } else {
      resetMeetingDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingDetail, secretarialOptions, dispatch]);

  const convertMeetingFromStore = useCallback((meeting) => {
    const {
      category,
      chairman,
      meeting_committee,
      meeting_facilitator,
      meeting_secretariats,
      meeting_additional_view_accesses,
      end_time,
      start_time,
      date,
      category_value,
      ...rest
    } = meeting;
    const dateWithFormat = moment(date).format('YYYY-MM-DD');
    return {
      ...rest,
      meeting_category: category.name,
      chairman_id: __(chairman).castArray().head()?.value,
      meeting_committee_ids: map(meeting_committee, 'value'),
      meeting_facilitator_ids: map(meeting_facilitator, 'value'),
      meeting_secretariats: meeting_secretariats,
      meeting_additional_view_accesses,
      end_time: moment(`${dateWithFormat} ${end_time}`, DATE_TIME_FORMAT.DATE_TIME).toDate(),
      start_time: moment(`${dateWithFormat} ${start_time}`, DATE_TIME_FORMAT.DATE_TIME).toDate(),
      on_step: 1,
      category_value: category_value?.name || null,
      category_label: category_value?.category_label || formik.values.category_value?.category_label || null,
    };
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: meeting,
    validationSchema,
    onSubmit: (values) => {
      return values;
    },
  });

  useEffect(() => {
    formik.setValues(meeting);
    formik.setFieldValue(
      'meeting_secretariats',
      getFieldValueForTCMeetingInFirstTime(meeting?.meeting_secretariats || meeting?.roles?.meeting_secretariats || meeting?.roles?.secretariat)
    );
    formik.setFieldValue(
      'meeting_additional_view_accesses',
      getFieldValueForTCMeetingInFirstTime(
        meeting?.meeting_additional_view_accesses || meeting?.roles?.meeting_additional_view_accesses || meeting?.roles?.additional_view_access
      )
    );
    setMeetingSecretariatValue(
      getFieldValueForTCMeetingInFirstTime(meeting?.meeting_secretariats || meeting?.roles?.meeting_secretariats || meeting?.roles?.secretariat)
    );
    setAdditionalViewAccessValue(
      getFieldValueForTCMeetingInFirstTime(
        meeting?.meeting_additional_view_accesses || meeting?.roles?.meeting_additional_view_accesses || meeting?.roles?.additional_view_access
      )
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meeting]);

  const onChangeInputData = (value, key) => {
    formik.setFieldValue(key, value);
  };

  const onChange = (date, dateString) => {
    formik.setFieldValue('date', dateString);
  };

  const onChangeSelectData = (event, key) => {
    formik.setFieldValue(key, event);
  };

  const handleSetMeetingSecretariatValue = (val) => {
    const isCheck = meetingSecretariatValue.filter((item) => item?.label_name === val?.label_name).length > 0;
    if (!isCheck) {
      setMeetingSecretariatValue((prev) => {
        return [...prev, val];
      });
    }
    setIsLoadAdditionalDataInFirstTime(false);
  };

  const handleSetAdditionalViewAccess = (val) => {
    const isCheck = additionalViewAccessValue.filter((item) => item?.label_name === val?.label_name).length > 0;
    if (!isCheck) {
      setAdditionalViewAccessValue((prev) => {
        return [...prev, val];
      });
    }
    setIsLoadAdditionalDataInFirstTime(true);
  };

  useEffect(() => {
    if (!roleActive?.roleId || isEmpty(formik.values.category?.name) || (isLoadAddtionalDataInFirstTime && !isEmpty(meetingSecretariatValue))) {
      return;
    }

    if (
      isEmpty(meetingSecretariatValue) &&
      ![MEETING_CATEGORY_VALUES.SKG, MEETING_CATEGORY_VALUES.JOB_FAMILY].includes(formik.values.category?.name)
    ) {
      setAdditionalViewAccessValue([]);
      formik.setFieldValue('meeting_additional_view_accesses', []);
      return;
    }

    const fetchDataToPopulateForAdditionalView = async (secretariats) => {
      try {
        const params = {
          meeting_category: formik.values.category?.name,
        };
        const body = !isEmpty(secretariats)
          ? secretariats.map((i) => ({
              role_id: i?.role_id,
              business_id: getBusinessIdToPopulateForAdditionalView(formik.values.category?.name, i),
            }))
          : [];
        const res = await meetingApi.getDataToPopulateForAdditionalView(params, body, roleActive?.roleId);
        if (res.status === 200 && res.data.result.length > 0 && isArray(res.data.result)) {
          res.data.result.forEach((item) => {
            const tempVal = {
              ...item,
              label_name: getLabelNameForSecretariatOfTcMeeting(formik.values.category?.name, item),
              search_type: formik.values.category?.name,
            };
            handleSetAdditionalViewAccess(tempVal);
          });
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (
      ![MEETING_CATEGORY_OPTIONS.PETRONAS, MEETING_CATEGORY_VALUES.SKG, MEETING_CATEGORY_VALUES.JOB_FAMILY].includes(formik.values.category?.name)
    ) {
      fetchDataToPopulateForAdditionalView(meetingSecretariatValue);
    }
  }, [meetingSecretariatValue, isLoadAddtionalDataInFirstTime]);

  const handleSelectMeetingCategory = (val) => {
    formik.setFieldValue('category', val);
    formik.setFieldValue('category_value', null);
    setIsLoadAdditionalDataInFirstTime(false);

    const tempVal = {
      role: ROLE.HR_COE,
      role_id: 7,
      label_name: getLabelNameForSecretariatOfTcMeeting(MEETING_CATEGORY_VALUES.PETRONAS, { role: ROLE.HR_COE, role_id: 7 }),
      search_type: MEETING_CATEGORY_VALUES.PETRONAS,
    };
    if (val.name === MEETING_CATEGORY_VALUES.PETRONAS) {
      setMeetingSecretariatValue([tempVal]);
      setAdditionalViewAccessValue([tempVal]);
    } else if ([MEETING_CATEGORY_VALUES.SKG, MEETING_CATEGORY_VALUES.JOB_FAMILY].includes(val.name)) {
      setAdditionalViewAccessValue([tempVal]);
    } else {
      setMeetingSecretariatValue([]);
      setAdditionalViewAccessValue([]);
    }
  };

  const handleUpdateMeeting = async () => {
    try {
      setLoading(true);
      const data = convertMeetingFromStore({ idMeeting, ...formik.values });
      const res = await pdcMeetingApi.updateMeeting(data, roleActive);
      const title = get(res, 'data.result.name');
      if (res.status === 200) {
        fetchMeeting();
        getRoleInMeeting();
        dispatch(
          pushMessage({
            type: MESSAGE_TYPES.SUCCESS,
            message: title + ' successfully saved.',
            timeShow: 0,
          })
        );
      }
    } catch (error) {
      console.error(error);
      showNotification(AN_UNEXPECTED_ERROR, NOTIFICATION_TYPE.FAILED);
    } finally {
      setLoading(false);
      setShowUpdateModal(false);
    }
  };

  const handleClickNotify = async () => {
    setShowNotifyModal(false);
    if (idMeeting) {
      try {
        await pdcMeetingApi.notifyPDCMeeting(idMeeting, roleActive?.roleId);
        dispatch(
          pushMessage({
            type: MESSAGE_TYPES.SUCCESS,
            message: 'Meeting successfully send to Notify Facilitator',
            timeShow: 0,
          })
        );
      } catch (error) {
        dispatch(
          pushMessage({
            type: MESSAGE_TYPES.ERROR,
            message: USER_ACCESS_MESSAGE.AN_UNEXPECTED_ERROR,
            timeShow: 0,
          })
        );
        console.error(error);
      }
    }
  };

  const isViewMOMFileSection = useMemo(() => {
    return (
      meetingDetail &&
      !isEmpty(fileUpload) &&
      fileUpload[0]?.url &&
      (meetingRole?.is_secretariat_or_facilitator ||
        meetingRole?.is_chairman ||
        meetingRole?.is_commitee ||
        meetingRole?.is_hr_partners ||
        meetingRole?.is_advocator ||
        meetingRole?.is_additional_view_access ||
        isAdminRole) &&
      [MEETING_DETAIL_STATUS.CONCLUDED].includes(meetingDetail?.status)
    );
  }, [meetingDetail, meetingRole, fileUpload, isAdminRole]);

  return (
    <div className={styles.wrapper}>
      <BreadCrumb level={6} breadCrumbList={breadCrumbList} />
      <GlobalAlertMessage style={{ margin: '10px 0 30px 0' }} />
      <Spin spinning={loadingGetMeeting}>
        <form onSubmit={formik.handleSubmit}>
          {idMeeting && meetingDetail.status && (
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex justify-content-start align-items-center">
                <div className={styles.title} style={{ maxWidth: '800px', wordBreak: 'break-word' }}>
                  {meetingDetail.meeting_name}
                </div>
                <div style={{ width: '170px' }}>
                  <PDCMeetingStatus status={meeting.status} />
                </div>
              </div>
              <div className={styles.titleRow}>
                <div className={styles.btnRow}>
                  <BasicButton border="none" onClick={() => history.push('/pdc')}>
                    Back
                  </BasicButton>
                  {(meetingRole?.is_secretariat_or_facilitator || isAdminRole) && <PrintSelectButton />}
                </div>
              </div>
            </div>
          )}
          {isViewMOMFileSection && (
            <AiContainerForm style={{ marginBottom: '29px', paddingBottom: '29px', marginTop: '10px' }}>
              <AiTitleWrapper>
                <MeetingTitle>
                  <AiH2 style={{ fontSize: '18px' }}>{'Approved Minutes of Meeting'}</AiH2>
                </MeetingTitle>
              </AiTitleWrapper>
              {meetingDetail?.file_url && meetingDetail?.file_size && <ViewMoMFile meetingDetail={meetingDetail} pageName={PAGE_NAME.PDC_MEETING} />}
            </AiContainerForm>
          )}
          <AiContainerForm>
            <MeetingTitle>
              <AiH2>Meeting Details</AiH2>
            </MeetingTitle>
            <Row className="mb-3">
              <Col span={24}>
                <AiLabel>Meeting Name</AiLabel>
                <AiInput
                  maxLength={100}
                  placeholder="Enter Meeting Name"
                  name="meeting_name"
                  value={formik.values.meeting_name}
                  onChange={formik.handleChange}
                  status={isCheckError(formik, 'meeting_name') ? 'error' : ''}
                  disabled={true}
                />
                <FieldError name="meeting_name" {...formik} />
              </Col>
            </Row>
            <div className={styles.rowInput}>
              <div className={styles.inputItem}>
                <AiLabel>Date</AiLabel>
                <Space direction="vertical" style={{ width: '100%' }}>
                  <AiDatePicker
                    onChange={onChange}
                    format={DATE_TIME_FORMAT.DATE_SPACE}
                    status={isCheckError(formik, 'date') ? 'error' : ''}
                    value={formik.values.date && moment(formik.values.date, DATE_TIME_FORMAT.DATE_SPACE)}
                    placeholder="Select Date"
                    getPopupContainer={(trigger) => trigger.parentElement}
                    disabled={true}
                  />
                  <FieldError name="date" {...formik} />
                </Space>
              </div>
              <div className={styles.inputItem}>
                <AiLabel>Meeting Category</AiLabel>
                <MeetingCategory
                  selected={formik.values.category}
                  setSelected={(event) => {
                    handleSelectMeetingCategory(event);
                  }}
                  options={MEETING_CATEGORY_OPTIONS}
                  placeholder="Select Meeting Category"
                  status={isCheckError(formik, 'category') ? 'error' : ''}
                  disabled={true}
                />
                <FieldError name="category.id" {...formik} />
              </div>
              {!isEmpty(formik.values.category) &&
                ['Business', 'OPU', 'Sector', 'Division', 'SKG', 'Job Family'].includes(formik.values.category?.name) && (
                  <div className={styles.inputItem}>
                    <AiLabel>{formik.values.category?.name}</AiLabel>
                    <SearchByCategoryForTcMeeting
                      openDropdown={isOpenCategoryDropdown}
                      setOpenDropdown={setIsOpenCategoryDropdown}
                      setValue={({ val }) => formik.setFieldValue('category_value', val)}
                      searchName={formik.values.category?.name}
                      fieldValue={formik.values.category_value}
                      status={isCheckError(formik, 'category_value') ? 'error' : ''}
                      disabled={true}
                    />
                    <FieldError name="category_value" {...formik} />
                  </div>
                )}
              <div className={styles.inputItem}>
                <AiLabel>Start time</AiLabel>
                <AiTimePickerField
                  placeholder={'Select Time'}
                  value={formik.values.start_time ? moment(formik.values.start_time, DATE_TIME_FORMAT.TIME).local() : null}
                  style={{ width: '100%' }}
                  onChange={(_event, value) => onChangeInputData(value, 'start_time')}
                  status={isCheckError(formik, 'start_time') ? 'error' : ''}
                  showTime={{ format: DATE_TIME_FORMAT.TIME, use12Hours: true }}
                  format={DATE_TIME_FORMAT.TIME}
                  showNow={false}
                  inputReadOnly={true}
                  allowClear={false}
                  getPopupContainer={(trigger) => trigger.parentElement}
                  disabled={true}
                />
                <FieldError name="start_time" {...formik} />
              </div>
              <div className={styles.inputItem}>
                <AiLabel>End time</AiLabel>
                <AiTimePickerField
                  placeholder={'Select Time'}
                  value={formik.values.end_time ? moment(formik.values.end_time, DATE_TIME_FORMAT.TIME).local() : null}
                  style={{ width: '100%' }}
                  onChange={(_event, value) => onChangeInputData(value, 'end_time')}
                  status={isCheckError(formik, 'end_time') ? 'error' : ''}
                  showTime={{ format: DATE_TIME_FORMAT.TIME, use12Hours: true }}
                  format={DATE_TIME_FORMAT.TIME}
                  showNow={false}
                  inputReadOnly={true}
                  allowClear={false}
                  getPopupContainer={(trigger) => trigger.parentElement}
                  disabled={true}
                />
                <FieldError name="end_time" {...formik} />
              </div>
            </div>

            <Row className="mb-3">
              <Col span={24}>
                <AiLabel>Location</AiLabel>
                <AiInput
                  maxLength={100}
                  placeholder="Enter Location"
                  name="location"
                  value={formik.values.location}
                  onChange={formik.handleChange}
                  status={isCheckError(formik, 'location') ? 'error' : ''}
                  disabled={true}
                />
                <FieldError name="location" {...formik} />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col span={24}>
                <AiLabel>Chairman</AiLabel>
                <AsyncMultipleSelect
                  value={formik.values.chairman}
                  placeholder="Enter Chairman Name"
                  loadOptions={(e) => getAsyncOptionAdvocatorsHrPartners(e, roleActive.roleId)}
                  onChange={(e) => onChangeSelectData(e, 'chairman')}
                  status={isCheckError(formik, 'chairman') ? 'error' : ''}
                  isDisabled={true}
                />
                <FieldError name="chairman" {...formik} />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col span={24}>
                <AiLabel>Committee Members</AiLabel>
                <AsyncMultipleSelect
                  placeholder={'Enter Committee Members Name'}
                  loadOptions={(e) => getAsyncOptionAdvocatorsHrPartners(e, roleActive.roleId)}
                  onChange={(e) => onChangeSelectData(e, 'meeting_committee')}
                  value={formik.values.meeting_committee}
                  status={isCheckError(formik, 'meeting_committee') ? 'error' : ''}
                  isDisabled={true}
                />
                <FieldError name="meeting_committee" {...formik} />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col span={24}>
                <AiLabel>Meeting Facilitators</AiLabel>
                <AsyncMultipleSelect
                  placeholder={'Enter Meeting Facilitators Name'}
                  onChange={(e) => onChangeSelectData(e, 'meeting_facilitator')}
                  loadOptions={(e) => getAsyncOptionAdvocatorsHrPartners(e, roleActive.roleId)}
                  value={formik.values.meeting_facilitator}
                  status={isCheckError(formik, 'meeting_facilitator') ? 'error' : ''}
                  isDisabled={true}
                />
                <FieldError name="meeting_facilitator" {...formik} />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col span={24}>
                <AiLabel>Meeting Secretariat</AiLabel>
                <SearchBySecretariatForTcMeeting
                  searchName={formik.values.category?.name}
                  setValue={({ val }) => {
                    handleSetMeetingSecretariatValue(val);
                  }}
                  fieldValues={meetingSecretariatValue}
                  setMeetingSecretariatValue={setMeetingSecretariatValue}
                  status={isCheckError(formik, 'meeting_secretariats') ? 'error' : ''}
                  isDisabled={true}
                />
                <FieldError name="meeting_secretariats" {...formik} />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col span={24}>
                <AiLabel noRequire={true}>Additional View Access</AiLabel>
                <SearchByAdditionalViewAccessForTcMeeting
                  searchName={formik.values.category?.name}
                  setValue={({ val }) => handleSetAdditionalViewAccess(val)}
                  fieldValues={additionalViewAccessValue}
                  setAdditionalViewAccessValue={setAdditionalViewAccessValue}
                  isDisabled={true}
                  // status={isCheckError(formik, 'meeting_additional_view_accesses') ? 'error' : ''}
                />
                {/* <FieldError name="meeting_additional_view_accesses" {...formik} /> */}
              </Col>
            </Row>
          </AiContainerForm>
        </form>
      </Spin>
      <div style={{ marginBottom: 30 }}>
        <PDCMeetingMobilities isComponent meetingStatus={meetingDetail.status} isEditMode={isEditMode} />
      </div>
      <div style={{ marginBottom: 30 }}>
        <ContainerSummary />
      </div>
      {(meetingRole?.is_secretariat_or_facilitator || isAdminRole) && (
        <div style={{ marginBottom: 30 }}>
          <PDCRemarks idMeeting={idMeeting} meetingStatus={meetingDetail?.status} />
        </div>
      )}
      {/*
       * IMPLEMENT MODEL
       */}
      {showUpdateModal && (
        <ModelTC
          info={{
            type: 'saveTheMeeting',
            visible: showUpdateModal,
            setVisible: setShowUpdateModal,
            handleSubmit: handleUpdateMeeting,
            loading: loading,
          }}
        />
      )}
      {showNotifyModal && (
        <ModelTC
          info={{
            type: 'notifyFacilitator',
            visible: showNotifyModal,
            setVisible: setShowNotifyModal,
            handleSubmit: handleClickNotify,
            loading: loading,
          }}
        />
      )}
    </div>
  );
};
export default ConcludedMeetingDetail;
