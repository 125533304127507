import { createSlice } from '@reduxjs/toolkit';

const initialStateStepper = {
  currentTabActive: 1,
  currentTabFinished: [],
  tabsActive: [1],
};
const meetingProgressBarSlice = createSlice({
  name: 'pdcMeetingProgressBar',
  initialState: {
    stepper: initialStateStepper,
    loading: false,
  },
  reducers: {
    getStepper: (state) => {
      return state.stepper;
    },
    updateTabsActive: (state, action) => {
      state.stepper.tabsActive = action.payload;
    },
    addOrUpdateStepper: (state, action) => {
      state.stepper = action.payload;
    },
    clearStepper: (state) => {
      state.stepper = initialStateStepper;
    },
  },
});
const { actions, reducer: meetingProgressBarReducer } = meetingProgressBarSlice;
export const { getStepper, addOrUpdateStepper, clearStepper, updateTabsActive } = actions;
export default meetingProgressBarReducer;
